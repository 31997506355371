import React, { useEffect } from 'react'

import {
  TextField,
  Grid,
  Box,
  RadioGroup,
  Radio,
  FormControlLabel,
  Typography,
  Divider,
} from '@material-ui/core'

import { Label } from 'components'
import { Controller } from 'react-hook-form'

import * as service from 'service'
import helpers from 'helpers'

const DataController = ({
  errors,
  control,
  smallCompany,
  setSmallCompany,
  smallAgentTreatment,
  setSmallAgentTreatment,
  constroller,
  setValue,
  watch,
  companyPostalCode,
}) => {
  const handleSmallCompany = (e) => {
    setSmallCompany(e.target.value)
  }

  const handleSmallTreatment = (e) => {
    setSmallAgentTreatment(e.target.value)
  }

  useEffect(() => {
    const cep = watch('cep')

    if (companyPostalCode === cep) return

    const validateCep =
      cep.length === 9 && cep !== helpers.formatters.cep(constroller?.cep)

    const getAddressByPostalCode = async (cep) => {
      const response = await service.externalAPIs.viaCEP.get(cep)

      if (!response?.data?.erro) {
        setValue('state', response?.data?.uf)
        setValue('city', response?.data?.localidade)
      }
    }

    if (validateCep) {
      getAddressByPostalCode(cep)
    }
    // eslint-disable-next-line
  }, [watch('cep')])

  return (
    <>
      <Grid item xs={12}>
        <Box py={2}>
          <Box mb={2}>
            <Typography variant="h6">Dados do controlador</Typography>
          </Box>
          <Divider />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box mt={2}>
          <Controller
            as={
              <TextField
                label="Nome da Empresa"
                type="text"
                color="primary"
                variant="outlined"
                error={!!errors.nameController}
                helperText={errors?.nameController?.message}
                fullWidth
              />
            }
            control={control}
            name="nameController"
            mode="onBlur"
          />
        </Box>
      </Grid>
      <Grid item md={6} xs={12}>
        <Box mt={2}>
          <Controller
            as={
              <TextField
                label="CNPJ / CPF"
                type="text"
                color="primary"
                variant="outlined"
                error={!!errors.documentController}
                helperText={errors?.documentController?.message}
                fullWidth
              />
            }
            onChange={([text]) =>
              helpers.formatters.cnpjOrCpf(text.target.value)
            }
            control={control}
            name="documentController"
            mode="onBlur"
          />
        </Box>
      </Grid>
      <Grid item md={6} xs={12}>
        <Box mt={2}>
          <Controller
            as={
              <TextField
                inputProps={{
                  minLength: 9,
                  maxLength: 9,
                }}
                label="CEP"
                type="text"
                color="primary"
                variant="outlined"
                error={!!errors.cep}
                helperText={errors?.cep?.message}
                fullWidth
              />
            }
            onChange={([text]) => helpers.formatters.cep(text.target.value)}
            control={control}
            name="cep"
            mode="onChange"
          />
        </Box>
      </Grid>
      <Grid item md={6} xs={12}>
        <Box mt={2}>
          <Controller
            as={
              <TextField
                label="Cidade"
                type="text"
                color="primary"
                variant="outlined"
                error={!!errors.city}
                helperText={errors?.city?.message}
                fullWidth
              />
            }
            control={control}
            name="city"
            mode="onBlur"
          />
        </Box>
      </Grid>
      <Grid item md={6} xs={12}>
        <Box mt={2}>
          <Controller
            as={
              <TextField
                inputProps={{
                  maxLength: 2,
                }}
                label="Estado (UF)"
                type="text"
                color="primary"
                variant="outlined"
                error={!!errors.state}
                helperText={errors?.state?.message}
                fullWidth
              />
            }
            control={control}
            name="state"
            mode="onBlur"
          />
        </Box>
      </Grid>
      <Grid item md={6} xs={12}>
        <Box mt={2}>
          <Controller
            as={
              <TextField
                label="Telefone"
                type="text"
                color="primary"
                variant="outlined"
                error={!!errors.phoneController}
                helperText={errors?.phoneController?.message}
                fullWidth
              />
            }
            onChange={([text]) =>
              helpers.formatters.phoneWithoutCountryCode(text.target.value)
            }
            control={control}
            name="phoneController"
            mode="onBlur"
          />
        </Box>
      </Grid>
      <Grid item md={6} xs={12}>
        <Box mt={2}>
          <Controller
            as={
              <TextField
                label="E-mail"
                type="text"
                color="primary"
                variant="outlined"
                error={!!errors.emailController}
                helperText={errors?.emailController?.message}
                fullWidth
              />
            }
            control={control}
            name="emailController"
            mode="onBlur"
          />
        </Box>
      </Grid>
      <Grid item md={6} xs={12}>
        <Box mt={2} style={{ backgroundColor: '#F4F6F8', padding: '5px' }}>
          <Typography variant="h6">
            Declara ser microempresa ou empresa de pequeno porte:
          </Typography>

          <RadioGroup value={smallCompany} row onChange={handleSmallCompany}>
            <FormControlLabel
              value="true"
              control={
                <Radio size="medium" checked={smallCompany === 'true'} />
              }
              label="Sim"
            />
            <FormControlLabel
              value="false"
              control={
                <Radio size="medium" checked={smallCompany === 'false'} />
              }
              label="Não"
            />
          </RadioGroup>
        </Box>
      </Grid>

      <Grid item md={6} xs={12}>
        <Box mt={2} style={{ backgroundColor: '#F4F6F8', padding: '5px' }}>
          <Typography variant="h6">
            Declara ser agente de tratamento de pequeno porte:
          </Typography>
          <RadioGroup
            row
            value={smallAgentTreatment}
            onChange={handleSmallTreatment}
          >
            <FormControlLabel
              value="true"
              control={
                <Radio size="medium" checked={smallAgentTreatment === 'true'} />
              }
              label="Sim"
            />
            <FormControlLabel
              value="false"
              control={
                <Radio
                  size="medium"
                  checked={smallAgentTreatment === 'false'}
                />
              }
              label="Não"
            />
          </RadioGroup>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box mt={2}>
          <Label title="Informe o número aproximado de titulares cujos dados são tratados por sua organização">
            <Controller
              as={
                <TextField
                  inputProps={{
                    min: 0,
                  }}
                  type="number"
                  color="primary"
                  variant="outlined"
                  error={!!errors.treatmentHolder}
                  helperText={errors?.treatmentHolder?.message}
                  fullWidth
                />
              }
              control={control}
              name="treatmentHolder"
              mode="onBlur"
            />
          </Label>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box py={2}>
          <Divider />
        </Box>
      </Grid>
    </>
  )
}

export default DataController
