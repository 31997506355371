import helpers from 'helpers'

const formattedValue = function (field, value, registerType) {
  if (value === null) return '-'

  if (registerType === 'CompanySupplier' && field === 'status') {
    return helpers.companySuppliers.label(value)
  }
  if (
    ['QuestionControl', 'Question', 'QuestionPlanActionEvidency'].includes(
      registerType,
    ) &&
    field === 'status'
  ) {
    return helpers.logs.questionStatus(registerType, value)
  }

  if (typeof value === 'object')
    return Object.values(value).toString()?.replaceAll(',', ', ')

  if (typeof value === 'boolean') {
    if (value) {
      return 'Sim'
    } else {
      return 'Não'
    }
  } else {
    switch (field) {
      case 'status_id':
        return helpers.dataProcess.status(parseInt(value))
      case 'fragility_id':
        return helpers.dataProcess.fragilityLabel(parseInt(value))
      case 'adopted_fragility_id':
        return helpers.dataProcess.fragilityLabel(parseInt(value))
      case 'suggestion_fragility_id':
        return helpers.dataProcess.fragilityLabel(parseInt(value))
      case 'status':
        return helpers.auditProcess.status(value)
      case 'data_type':
        return helpers.auditProcess.dataType(value)
      case 'kind':
        return helpers.logs.kindType(value)
      case 'deadline_date':
        return helpers.formatters.date(value)
      case 'start_date':
        return helpers.formatters.date(value)
      default:
        return value
    }
  }
}

export default formattedValue
