import { validates } from 'helpers'

import * as yup from 'yup'

const schema = yup.object({
  cardOwnerName: yup.string().min(4).max(150).required(),
  cardNumber: yup.string().min(16).max(23).required(),
  // .test(
  //   'cardNumberIsValid',
  //   'Cartão inválido',
  //   (value) => !!validates.cardFlag.id(value)
  // ),
  cardExpiringDate: yup
    .date()
    .required()
    .typeError('Precisa ser uma data válida'),
  cvv: yup
    .string()
    .min(3, 'Código Inválido')
    .max(4, 'Código Inválido')
    .required(),
})

export default schema
