import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'

import constants from 'constants/index'

const styles = ({ selectedTab }) =>
  makeStyles((theme) => ({
    colorCustomizationContainer: {
      display:
        selectedTab === constants.cookies.banner.COLOR_CUSTOMIZATION_CARD_ID
          ? 'flex'
          : 'none',
      height: '100%',
      flexDirection: 'row',
    },
    logoUploadContainer: {
      display:
        selectedTab === constants.cookies.banner.LOGO_CUSTOMIZATION_CARD_ID
          ? 'flex'
          : 'none',
      height: '100%',
      flexDirection: 'row',
    },
    descriptionCustomizationContainer: {
      display:
        selectedTab ===
        constants.cookies.banner.DESCRIPTION_CUSTOMIZATION_CARD_ID
          ? 'flex'
          : 'none',
      height: '100%',
      flexDirection: 'row',
    },
    bannerPreviewContainer: {
      display:
        selectedTab !== constants.cookies.banner.LOGO_CUSTOMIZATION_CARD_ID
          ? 'block'
          : 'none',
      height: '100%',
    },
    logoPreviewContainer: {
      display:
        selectedTab === constants.cookies.banner.LOGO_CUSTOMIZATION_CARD_ID
          ? 'block'
          : 'none',
      height: '100%',
    },
  }))

styles.propTypes = {
  selectedTab: PropTypes.number.isRequired,
}

export default styles
