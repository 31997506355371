import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { isEmpty } from 'lodash'

import mixpanel from 'mixpanel-browser'

import FirstStepContext from 'contexts/FirstStepContext'
import {
  CertificationPage,
  Checkpoint,
  ConclusionPage,
  CookiePage,
  DPOnetPage,
  LGPDPage,
  ProductConfirmationPage,
  ProductPage,
  StampPage,
  WelcomePage,
} from 'views/UserSteps/components'

import useAuth from 'hooks/useAuth'
import useSnackbar from 'hooks/useSnackbar'

import * as service from 'service'
import helpers from 'helpers'
import constants from 'constants/index'

const FirstStepProvider = ({ children }) => {
  const {
    user,
    loadDataWithAnimation,
    homeRoute,
    company,
    loadUser,
    loaded: loadedUser,
  } = useAuth()
  const history = useHistory()
  const snackbar = useSnackbar()
  const userSteps = user?.userSteps
  const {
    STEP_CHECKPOINT_INDEX,
    STEP_CONCLUSION_INDEX,
    STEP_COOKIE_PRODUCTS_INDEX,
    STEP_PRODUCT_CONFIRM_INDEX,
    STEP_CONCLUSION_TAG,
    STEP_CERTIFICATION_TAG,
    STEP_STAMP_TAG,
  } = constants.userSteps

  const [activeStep, setActiveStep] = useState(0)
  const [activeSubStep, setActiveSubStep] = useState(0)
  const [backgroundKind, setBackgroundKind] = useState(null)
  const [loaded, setLoaded] = useState(false)
  const [nextDisabled, setNextDisabled] = useState(false)
  const [showBackButton, setShowBackButton] = useState(false)
  const [certificationLink, setCertificationLink] = useState()
  const [loadingStep, setLoadingStep] = useState(false)

  const { arrayIndexSize } = helpers.functions

  const [isCheckpointStep, isCheckpointSubStep, isProductStep, isFinalStep] =
    useMemo(
      () => [
        // Retorna se etapa é de Checkpoint
        activeStep === STEP_CHECKPOINT_INDEX[0] &&
          activeSubStep === STEP_CHECKPOINT_INDEX[1],

        // Retorna se etapa é uma subetapa de Checkpoint
        activeStep === STEP_CHECKPOINT_INDEX[0] &&
          activeSubStep !== STEP_CHECKPOINT_INDEX[1],

        // Retorna se etapa é a subetapa de productos
        [STEP_COOKIE_PRODUCTS_INDEX[0], STEP_PRODUCT_CONFIRM_INDEX[0]].includes(
          activeStep,
        ) &&
          [
            STEP_COOKIE_PRODUCTS_INDEX[1],
            STEP_PRODUCT_CONFIRM_INDEX[1],
          ].includes(activeSubStep),

        // Retorna se etapa é de Conclusão
        activeStep === STEP_CONCLUSION_INDEX[0] &&
          activeSubStep === STEP_CONCLUSION_INDEX[1],
      ],
      //eslint-disable-next-line
      [activeStep, activeSubStep],
    )

  const showNextButton = useMemo(
    () => !isCheckpointSubStep,
    [isCheckpointSubStep],
  )

  const createUserSteps = async () => {
    try {
      setLoadingStep(true)
      await service.dponet.users.createStep(user.id)
      await loadUser()
    } catch (error) {
      snackbar.open({
        message: helpers.formatters.errorMessage(error?.response?.data?.error),
        variant: 'error',
      })
    } finally {
      setLoadingStep(false)
    }
  }

  useEffect(() => {
    if (isEmpty(userSteps) && loadedUser && !!user && !loadingStep) {
      createUserSteps()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadedUser])

  const pages = [
    [<WelcomePage />, <DPOnetPage />], // Etapa DPONET
    [<LGPDPage />], // Etapa LGPD
    [<StampPage />], // Etapa selo
    [<CertificationPage />], // Etapa certificação
    [
      <Checkpoint />,
      <CookiePage />,
      <ProductPage />,
      <ProductConfirmationPage />,
    ], // Etapa cookies
    [<ConclusionPage />], // Etapa conclusão
  ]

  useEffect(
    () => {
      const show = activeStep > 0 && !isProductStep && !isFinalStep
      setShowBackButton(show)
    },
    //eslint-disable-next-line
    [activeStep, activeSubStep],
  )

  const getActivePage = useCallback(() => {
    return pages[activeStep][activeSubStep]
    //eslint-disable-next-line
  }, [activeStep, activeSubStep])

  const canGoTo = useCallback(
    (step) =>
      !!userSteps?.find(
        (userStep) => userStep?.tag === helpers.userSteps.getTagByIndex(step),
      ),
    [userSteps],
  )

  const goToStep = (step) => {
    if (canGoTo(step)) {
      setActiveStep(step)
      setActiveSubStep(0)
    } else {
      if (step < arrayIndexSize(pages)) {
        goToStep(step + 1)
      }
    }
  }

  const handleNext = async () => {
    if (
      activeStep === 1 &&
      company.statusId === constants.userCompanies.TRIAL_STATUS
    ) {
      const tags = [STEP_STAMP_TAG, STEP_CERTIFICATION_TAG]
      await Promise.all(
        tags.map(async (tag) =>
          concludeStep(helpers.userSteps.getStepIdByTag(tag, userSteps)),
        ),
      )
      return jumpToFinalize(
        'Empresa trial não tem acesso ao selo e certificado',
      )
    }
    if (activeStep < arrayIndexSize(pages)) {
      const activePage = pages[activeStep]
      if (activeSubStep === arrayIndexSize(activePage)) {
        setNextDisabled(true)
        await concludeStep(helpers.userSteps.getStepId(activeStep, userSteps))
        goToStep(activeStep + 1)
        setNextDisabled(false)
      } else {
        setActiveSubStep(activeSubStep + 1)
      }
    } else {
      if (!!user?.allStepsConcluded) {
        return history.push(homeRoute)
      }

      setNextDisabled(true)
      await concludeStep(
        helpers.userSteps.getStepIdByTag(STEP_CONCLUSION_TAG, userSteps),
      )
      setNextDisabled(false)
      loadDataWithAnimation()
      history.push(homeRoute)
    }
  }

  const jumpToFinalize = async (observations) => {
    setNextDisabled(true)
    await concludeStep(
      helpers.userSteps.getStepId(activeStep, userSteps),
      observations,
    )
    setActiveStep(STEP_CONCLUSION_INDEX[0])
    setActiveSubStep(STEP_CONCLUSION_INDEX[1])
    setNextDisabled(false)
  }

  const handleBack = () => {
    const backSubStep = () => setActiveSubStep(activeSubStep - 1)
    if (activeStep > 0) {
      if (activeSubStep > 0) {
        return backSubStep()
      }
      const subStep =
        activeStep === STEP_CONCLUSION_INDEX[0]
          ? constants.userSteps.STEP_COOKIE_INDEX[1]
          : arrayIndexSize(pages[activeStep - 1])
      setActiveSubStep(subStep)
      return setActiveStep(activeStep - 1)
    }
    if (activeSubStep > 0) {
      return backSubStep()
    }
  }

  const concludeStep = async (stepId, observations = undefined) => {
    if (!!user?.allStepsConcluded) return

    const response = await service.dponet.users.updateStep({
      userId: user.id,
      stepId,
      concluded: true,
      observations,
      concludedAt: new Date(),
    })

    return response.data
  }

  const registerTrack = (action) => {
    mixpanel.track('Ação na Jornada do Primeiro Acesso', {
      action,
    })
  }

  return (
    <FirstStepContext.Provider
      value={{
        activeStep,
        activeSubStep,
        backgroundKind,
        certificationLink,
        concludeStep,
        getActivePage,
        handleBack,
        handleNext,
        isCheckpointStep,
        isCheckpointSubStep,
        isFinalStep,
        isProductStep,
        jumpToFinalize,
        loaded,
        loadingStep,
        nextDisabled,
        pagesLength: arrayIndexSize(pages),
        registerTrack,
        setActiveStep,
        setActiveSubStep,
        setBackgroundKind,
        setCertificationLink,
        setLoaded,
        setNextDisabled,
        setShowBackButton,
        showBackButton,
        showNextButton,
      }}
    >
      {children}
    </FirstStepContext.Provider>
  )
}

export default FirstStepProvider
