import React from 'react'
import PropTypes from 'prop-types'

import { Box, Card, CardActionArea, Typography } from '@material-ui/core'

import helpers from 'helpers'

import useStyles from './styles'

const PartnerServiceCard = ({ action, isDisabled, partnerService }) => {
  const classes = useStyles()
  const { mixpanel } = helpers

  const handleClick = (serviceName) => {
    if (!isDisabled) {
      mixpanel.track('Marktplace', 'Listagem de serviços', {
        action: `Click no card do serviço ${serviceName}`,
      })
      action()
    }
  }

  const companyServiceTitle = partnerService?.companyService?.name
  const serviceCategoryTitle =
    partnerService?.companyService?.serviceCategory?.name
  const partnerTitle = partnerService?.partner?.tradeName

  return (
    <Card className={classes.cardContainer} title="Solicitar serviço">
      <CardActionArea
        disabled={isDisabled}
        className={classes.cardActionContainer}
        onClick={() => {
          handleClick(companyServiceTitle)
        }}
      >
        <Box>
          <img
            className={classes.logoContainer}
            src={partnerService?.partner?.logo}
            alt={`logo ${partnerTitle}`}
          />
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="start"
          justifyContent="space-between"
          paddingX={3}
        >
          <Typography variant="h4">
            {serviceCategoryTitle + ' - ' + companyServiceTitle}
          </Typography>

          <Box className={classes.description}>
            <Typography>{partnerService?.description}</Typography>
          </Box>
          <Typography>
            Oferecido por:
            <span className={classes.partnerName}>{' ' + partnerTitle}</span>
          </Typography>
        </Box>
      </CardActionArea>
    </Card>
  )
}

PartnerServiceCard.defaultProps = {
  isDisabled: false,
}

PartnerServiceCard.propTypes = {
  isDisabled: PropTypes.bool,
  action: PropTypes.func.isRequired,
  partnerService: PropTypes.object.isRequired,
}

export default PartnerServiceCard
