import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { isEmpty } from 'lodash'

import { useForm, Controller } from 'react-hook-form'

import { DatePicker } from '@material-ui/pickers'
import { Autocomplete } from '@material-ui/lab'
import { Grid, TextField } from '@material-ui/core'

import useSnackbar from 'hooks/useSnackbar'

import helpers from 'helpers'
import * as service from 'service'

import schema from './schema'
import constants from 'constants/index'
import usePermissions from 'hooks/usePermissions'

const { countText } = helpers.functions

const ActivitiesForm = ({
  setOpen,
  refresh,
  task,
  setLoading,
  userCompanies,
  setUserCompanies,
}) => {
  const snackbar = useSnackbar()
  const permissions = usePermissions()

  const editionPermission = permissions.some([
    constants.permissions.TASKS.UPDATE,
    constants.permissions.TASKS.CREATE,
  ])
  const generalEditionPermitted =
    editionPermission && task?.status !== constants.activities.FINALIZED_STATUS

  const { control, handleSubmit, errors, watch } = useForm({
    validationSchema: schema,
    defaultValues: {
      startDate: task?.startDate || null,
      dueDate: task?.dueDate || null,
      name: task?.name || '',
      responsibleId: task?.responsible || null,
      description: task?.description || '',
      observations: task?.observations || '',
    },
  })

  useEffect(() => {
    const loadUserCompanies = async () => {
      const response = await service.dponet.usersCompanies.list({
        listByCompany: true,
        showSelf: true,
        perPage: 9999999,
        status: constants.userCompanies.ACTIVE,
      })
      setUserCompanies(
        response?.data?.userCompanies.map((userCompany) => {
          return {
            id: userCompany.user?.id,
            name: userCompany?.user?.name,
          }
        }),
      )
    }

    if (isEmpty(userCompanies)) loadUserCompanies()

    //eslint-disable-next-line
  }, [])

  const onSubmit = async (data) => {
    setLoading(true)
    let activityData = helpers.activities.formatData(data)

    try {
      await handleAction(activityData)
      refresh()
      setOpen(false)
      snackbar.open({
        message: 'Atividade criada com sucesso',
        variant: 'success',
      })
    } catch (error) {
      snackbar.open({
        message:
          helpers.formatters.errorMessage(error?.response?.data?.error) ||
          'Ocorreu um erro! Tente novamente.',
        variant: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  const handleAction = async (activityData) => {
    try {
      if (!!task?.id) {
        if (editionPermission) {
          return await service.dponet.activities.edit({
            taskId: task?.id,
            ...activityData,
          })
        }

        return await service.dponet.activities.editObservations({
          taskId: task?.id,
          observations: activityData?.observations,
        })
      }

      await service.dponet.activities.create({
        ...activityData,
      })
    } catch (error) {
      throw error
    }
  }

  return (
    <form id="activities-form" onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Controller
            as={
              <TextField
                label="Titulo da atividade"
                type="text"
                color="primary"
                variant="outlined"
                error={!!errors.name}
                helperText={countText(
                  errors?.name?.message,
                  watch('name')?.length,
                  120,
                )}
                disabled={!generalEditionPermitted}
                fullWidth
              />
            }
            control={control}
            name="name"
            mode="onBlur"
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="responsibleId"
            onChange={([, data]) => data}
            mode="onChange"
            as={
              <Autocomplete
                disabled={!generalEditionPermitted}
                options={userCompanies || []}
                getOptionLabel={(option) => option?.name}
                getOptionSelected={(option, value) =>
                  option.id === value.id || value.id === 0
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Responsável"
                    variant="outlined"
                    error={!!errors.responsibleId}
                    helperText={errors?.responsibleId?.message}
                    fullWidth
                  />
                )}
              />
            }
          />
        </Grid>
        <Grid item xs={6}>
          <Controller
            as={
              <DatePicker
                fullWidth
                format="DD/MM/yyyy"
                label="Data do Início"
                helperText={errors?.startDate?.message}
                error={!!errors.startDate}
                inputVariant="outlined"
                onChange={(newValue) => ({ value: newValue })}
                disabled={!generalEditionPermitted}
              />
            }
            control={control}
            name="startDate"
            mode="onChange"
          />
        </Grid>
        <Grid item xs={6}>
          <Controller
            as={
              <DatePicker
                fullWidth
                format="DD/MM/yyyy"
                label="Prazo"
                helperText={errors?.dueDate?.message}
                error={!!errors.dueDate}
                disabled={!generalEditionPermitted}
                inputVariant="outlined"
                onChange={(newValue) => ({ value: newValue })}
              />
            }
            control={control}
            name="dueDate"
            mode="onChange"
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            as={
              <TextField
                label="Descrição"
                type="text"
                color="primary"
                variant="outlined"
                error={!!errors.description}
                helperText={countText(
                  errors?.description?.message,
                  watch('description')?.length,
                  1200,
                )}
                fullWidth
                multiline
                minRows={10}
                disabled={!generalEditionPermitted}
              />
            }
            control={control}
            name="description"
            mode="onBlur"
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            as={
              <TextField
                label="Observação"
                type="email"
                color="primary"
                variant="outlined"
                error={!!errors.observations}
                helperText={countText(
                  errors?.observations?.message,
                  watch('observations')?.length,
                  250,
                )}
                fullWidth
                multiline
                minRows={3}
              />
            }
            control={control}
            name="observations"
            mode="onBlur"
          />
        </Grid>
      </Grid>
    </form>
  )
}

ActivitiesForm.propTypes = {
  setOpen: PropTypes.func,
  refresh: PropTypes.func,
  task: PropTypes.object,
  setLoading: PropTypes.func,
}

export default ActivitiesForm
