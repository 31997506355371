import { makeStyles } from '@material-ui/core'

const styles = ({ colorInput, isDragging }) =>
  makeStyles((theme) => ({
    formControl: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      cursor: 'pointer',
      height: 150,
      gap: theme.spacing(1),
      padding: theme.spacing(2),
      border: `1px dashed ${colorInput()}`,
      color: colorInput(),
    },
    title: {
      fontWeight: isDragging ? 500 : 400,
    },
    input: {
      display: 'none',
    },
  }))

export default styles
