import React, { Fragment, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { Plus as PlusIcon, Trash as TrashIcon } from 'react-feather'
import { Box, Button, FormHelperText, Grid, TextField } from '@material-ui/core'
import { KeyboardDatePicker } from '@material-ui/pickers'

import helpers from 'helpers'

import useSnackbar from 'hooks/useSnackbar'

import schema from './schema'

const BankSlip = ({ setLoading }) => {
  const [newEmail, setNewEmail] = useState('')
  const snackbar = useSnackbar()

  const { control, register, errors, handleSubmit, setValue, watch } = useForm({
    validationSchema: schema,
    defaultValues: { collectionDate: null, invoiceAmount: '', emails: [] },
  })

  const emails = watch('emails')

  const handleChangeEmail = (event) => setNewEmail(event.target.value)

  const handleAddEmail = () => {
    if (newEmail) {
      setValue('emails', [...emails, newEmail])
      setNewEmail('')
    }
  }

  const handleRemoveEmail = (index) => {
    const updatedEmails = emails.filter((_, i) => i !== index)
    setValue('emails', updatedEmails)
  }

  const onSubmit = (data) => {
    try {
      setLoading(true)

      snackbar.open({
        message: 'Método de pagamento atualizado com sucesso!',
        variant: 'success',
      })
    } catch (error) {
      snackbar.open({
        message: helpers.formatters.errorMessage(error.response.data.error),
        variant: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    register('emails')
  }, [register])

  return (
    <Grid
      container
      spacing={2}
      component="form"
      id="blank-slip-form"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Grid item xs={6}>
        <Controller
          control={control}
          name="collectionDate"
          as={
            <KeyboardDatePicker
              fullWidth
              format="DD/MM/yyyy"
              label="Data de cobrança"
              inputVariant="outlined"
              onChange={(newValue) => ({
                value: newValue,
              })}
              error={!!errors?.collectionDate}
              helperText={<>{errors?.collectionDate?.message}</>}
              disablePast
              shouldDisableDate={(date) => date.isSame(new Date(), 'day')}
              clearable
            />
          }
        />
      </Grid>
      <Grid item xs={6}>
        <Controller
          control={control}
          name="invoiceAmount"
          onChange={([event]) =>
            helpers.formatters.float.input(event.target.value)
          }
          as={
            <TextField
              variant="outlined"
              label="Valor da cobrança"
              error={!!errors?.invoiceAmount}
              helperText={<>{errors?.invoiceAmount?.message}</>}
              InputProps={{
                inputMode: 'decimal',
                startAdornment: 'R$',
              }}
              fullWidth
            />
          }
        />
      </Grid>
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={10}>
            <TextField
              variant="outlined"
              label="Email de cobrança"
              error={!!errors?.emails}
              value={newEmail}
              onChange={handleChangeEmail}
              fullWidth
            />
          </Grid>
          <Grid item xs={2} component={Box} pl={2} display="flex">
            <Button
              variant="contained"
              color="primary"
              startIcon={<PlusIcon />}
              fullWidth
              onClick={handleAddEmail}
            >
              Adicionar
            </Button>
          </Grid>
          {!!errors?.emails && (
            <Grid item xs={12} component={Box} pl={2}>
              <FormHelperText error={!!errors?.emails}>
                {errors?.emails?.message}
              </FormHelperText>
            </Grid>
          )}
        </Grid>
      </Grid>
      {emails?.map((email, index) => (
        <Fragment key={index}>
          <Grid item xs={10}>
            <TextField
              variant="outlined"
              size="small"
              value={email}
              fullWidth
              disabled
            />
          </Grid>
          <Grid item xs={2} component={Box} pl={2} display="flex">
            <Button
              variant="contained"
              color="secondary"
              startIcon={<TrashIcon />}
              fullWidth
              onClick={() => handleRemoveEmail(index)}
            >
              Excluir
            </Button>
          </Grid>
        </Fragment>
      ))}
      <Grid item xs={12}>
        <Button
          variant="outlined"
          color="primary"
          fullWidth
          type="submit"
          form="blank-slip-form"
        >
          Emitir Boleto
        </Button>
      </Grid>
    </Grid>
  )
}

export default BankSlip
