import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import { isEmpty, sortBy } from 'lodash'
import clsx from 'clsx'
import {
  Menu as MenuIcon,
  Grid as GridButton,
  Filter as FilterIcon,
  HelpCircle as HelpCircleIcon,
} from 'react-feather'
import {
  CircularProgress,
  useMediaQuery,
  Box,
  Container,
  TablePagination,
  Button,
  Tabs,
  Tab,
  IconButton,
  Divider,
  Typography,
  Tooltip,
} from '@material-ui/core'
import { useTheme } from '@material-ui/styles'
import { Skeleton, Alert } from '@material-ui/lab'

import {
  Page,
  ContentHeader,
  FilterButton,
  Filters,
  Permitted,
  DriveTour,
} from 'components'
import { Main, CompanyAuditAlert } from './components'

import usePagination from 'hooks/usePagination'
import useFilter from 'hooks/useFilter'
import useFetch from 'hooks/useFetch'
import useDataProcessOptions from 'hooks/useDataProcessOptions'
import useAuth from 'hooks/useAuth'
import usePermissions from 'hooks/usePermissions'

import helpers from 'helpers'

import { routes } from 'Routes'
import useStyles from './styles'
import * as service from 'service'
import constants from 'constants/index'

const DataProcessesMain = ({
  defaultFilters = {},
  origin,
  hasTour = true,
  ...rest
}) => {
  const classes = useStyles()
  const history = useHistory()
  const theme = useTheme()
  const filter = useFilter(defaultFilters)
  const auth = useAuth()
  const permissions = usePermissions()

  const { setDataProcesses, dataProcesses, loadData, data, reload } =
    useDataProcessOptions()

  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  })
  const { perPage, page, handleChangePage, handleChangeRowsPerPage } =
    usePagination(12)

  const [isLoading, setIsLoading] = useState(true)
  const [refresh, setRefresh] = useState(true)
  const [dataSources, setDataSources] = useState([])
  const [eventSources, setEventSources] = useState()
  const [hasDataProcessUndefined, setHasDataProcessUndefined] = useState(false)
  const [calledFunction, setCalledFunction] = useState(false)
  const [value, setValue] = useState(constants.dataProcess.ALL_PROCESSES_VALUE)
  const [listingType, setListingType] = useState('cards')
  const [openTour, setOpenTour] = useState(false)

  const handleChangeTab = (_, value) => {
    filter.setFilters({})
    setValue(value)
  }

  const isAuditProcess = (value) => {
    return value === constants.dataProcess.AUDIT_PROCESSES_VALUE
  }

  const auditFilters = () => {
    if (!isAuditProcess(value)) return {}

    return {
      auditStatus: [
        constants.auditProcess.WAITING_ACTION_STATUS,
        constants.auditProcess.WAITING_UPDATE_STATUS,
      ],
    }
  }

  useEffect(() => {
    setIsLoading(true)

    const loadDataProcess = async () => {
      const filters = filter.filters

      if (filters.anotherSourceDescription) {
        filters.sourceDescription = filters.anotherSourceDescription
        delete filters['anotherSourceDescription']
      }

      filter.setFilters(filters)

      const response = await service.dponet.dataProcesses.get({
        perPage,
        page,
        ...filters,
        ...defaultFilters,
        ...auditFilters(),
      })
      setDataProcesses(response?.data)

      const allDataProcesses = response?.data.dataProcesses
      if (
        allDataProcesses.find(
          (dataProcess) =>
            dataProcess.storageMode ===
            constants.dataProcess.STORY_MODE_UNDEFINED_TYPE,
        )
      ) {
        setHasDataProcessUndefined(true)
      }
    }

    const loadRelations = async () => {
      await loadData(auth?.company?.id)
    }

    loadDataProcess().then(() =>
      loadRelations().then(() => setIsLoading(false)),
    )
    //eslint-disable-next-line
  }, [refresh, page, perPage, filter.filters, reload, value])

  useEffect(() => {
    const filterSourceDescription = filter.filters.sourceDescription
    const numberSource = parseInt(filterSourceDescription)

    if (filterSourceDescription) {
      if (isNaN(numberSource)) {
        setEventSources(constants.dataProcess.ANOTHER_SOURCE)
      } else {
        changeDataSources(numberSource)
        setEventSources(numberSource)
      }
    } else {
      setEventSources()
    }
    //eslint-disable-next-line
  }, [data, filter.filters])

  const navigateTo = (route) => {
    history.push(route)
  }

  const { response: responseDepartments, isLoading: isLoadingDepartments } =
    useFetch(
      service.dponet.departments.get,
      {
        perPage: 1000,
      },
      [],
    )

  const departments = responseDepartments?.data?.departments ?? []

  const changeDataSources = (value) => {
    const optionsFormatted = helpers.dataProcess.mountDataProcessSources(
      data?.options?.dataProcessSources || [],
      departments || [],
      value,
    )
    setDataSources(optionsFormatted)
  }

  const handleChangeSource = (event) => {
    const value = parseInt(event.target.value)
    setEventSources(value)

    changeDataSources(value)
  }

  const pageTitle = () => {
    if (defaultFilters.suggestedChanges)
      return 'Processos com alterações sugeridas'
    if (isAuditProcess(value)) return 'Processos em auditoria'

    return 'Todos os processos'
  }

  const contentTitle = () => {
    if (isAuditProcess(value)) return 'Processos em auditoria'

    return 'Todos os processos'
  }

  var tab = [constants.dataProcess.ALL_PROCESSES_TAB]

  if (!!auth.company.onGoingCompanyAudit) {
    tab = constants.dataProcess.TABS
  }

  const bringUndefinedDataProcesses = async () => {
    setIsLoading(true)
    filter.setFilters({
      storageMode: constants.dataProcess.STORY_MODE_UNDEFINED_TYPE,
    })
    setCalledFunction(true)
    setIsLoading(false)
  }

  const alertValidationWithDeadline = hasDataProcessUndefined && !calledFunction

  const isUnnecessaryDatas = dataProcesses?.dataProcesses
    ?.map((dataProcess) => dataProcess?.processesUnnecessary)
    .includes(true)

  const filterIsApplied =
    filter?.filters?.storageMode || filter?.filters?.unnecessaryDatas

  const unnecessaryProcesses = async () => {
    setIsLoading(true)
    filter.setFilters({ unnecessaryDatas: ['Unnecessary'] })
    setIsLoading(false)
  }

  const filtersMatrixSteps = (elementId, permission) => {
    const requiredPermission =
      constants.dataProcess.DATA_PROCESS_MAIN_DRIVER_STEPS_WITH_PERMISSIONS[
        elementId
      ]

    const isStep3 = elementId.includes(
      constants.dataProcess.DATA_PROCESS_MAIN_DRIVER_STEP_4,
    )
    const isStep4 = elementId.includes(
      constants.dataProcess.DATA_PROCESS_MAIN_DRIVER_STEP_5,
    )

    if (!alertValidationWithDeadline && isStep3) return false
    if (!isUnnecessaryDatas && isStep4) return false

    return requiredPermission ? permission(requiredPermission) : true
  }

  const hasCreatePermission = permissions.permitted(
    constants.permissions.DATA_PROCESSES.CREATE,
  )

  const suggestedChanges = defaultFilters.suggestedChanges
    ? 'suggested'
    : 'main'

  const validationSteps = () => {
    if (defaultFilters.suggestedChanges) {
      return constants.dataProcess
        .DATA_PROCESS_SUGGESTED_MAIN_DRIVER_STEPS_OBJECT
    } else {
      const filteredSteps =
        constants.dataProcess.DATA_PROCESS_MAIN_DRIVER_STEPS_OBJECT.filter(
          (step) => filtersMatrixSteps(step.element, permissions.permitted),
        )

      if (!isEmpty(dataProcesses.dataProcesses)) {
        const uniqueSteps = helpers.driverJs.removeDuplicates([
          ...filteredSteps,
          ...dataProcesses.dataProcesses
            .map((process) =>
              helpers.dataProcess.driverJs.statusSteps(process.statusId),
            )
            .filter(Boolean),
        ])
        const sortedSteps = sortBy(Array.from(uniqueSteps), 'element')

        return sortedSteps
      }

      return filteredSteps
    }
  }

  const clearFilter = () => filter.setFilters({})

  const handleOpenTour = () => {
    const where = defaultFilters.suggestedChanges
      ? 'Alterações sugeridas de processos'
      : 'Todos os processos'
    const title = 'Tour'
    const action = defaultFilters.suggestedChanges
      ? 'tour-alteracoes-sugeridas-processos'
      : 'tour-todos-processos'

    const params = {
      action: action,
    }

    helpers.mixpanel.track(where, title, params)
    setOpenTour(true)
  }

  return (
    <>
      {isLoading ? (
        <Box
          display="flex"
          width="100%"
          minHeight="700px"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress />
        </Box>
      ) : (
        <Page title={pageTitle()}>
          <Container maxWidth={false} className={classes.container}>
            <ContentHeader
              menu="Processos"
              title={contentTitle()}
              subtitle={contentTitle()}
              smFull
              {...rest}
            >
              <Box
                className={clsx(
                  classes.contentHeader,
                  classes.contentHeaderAdjustment,
                )}
              >
                {hasTour && (
                  <Tooltip
                    title={
                      hasCreatePermission
                        ? 'Iniciar o tour guiado'
                        : 'O tour guiado é destinado aos usuários com permissão para gerenciar processos'
                    }
                  >
                    <Box
                      className={clsx(
                        classes.contentHeaderBox,
                        classes.contentHeaderBoxSm,
                      )}
                    >
                      <Button
                        id={helpers.dataProcess.driverJs.tourId(
                          suggestedChanges,
                        )}
                        variant="contained"
                        color="primary"
                        startIcon={<HelpCircleIcon size={20} />}
                        onClick={handleOpenTour}
                        disabled={!hasCreatePermission}
                        fullWidth
                      >
                        Tutorial
                      </Button>
                    </Box>
                  </Tooltip>
                )}
                <Permitted tag={constants.permissions.DATA_PROCESSES.CREATE}>
                  <Box
                    className={clsx(
                      classes.contentHeaderBox,
                      classes.contentHeaderBoxSm,
                    )}
                  >
                    <Button
                      id={constants.dataProcess.DATA_PROCESS_MAIN_DRIVER_STEP_2}
                      variant="contained"
                      color="primary"
                      className={classes.button}
                      onClick={() => navigateTo(routes.dataProcess.new)}
                      fullWidth
                    >
                      NOVO PROCESSO
                    </Button>
                  </Box>
                </Permitted>
                <Box
                  className={clsx(
                    classes.contentHeaderBox,
                    classes.contentHeaderBoxSm,
                  )}
                >
                  <FilterButton
                    setDrawerOpen={filter.setDrawerOpen}
                    fullWidth
                  />
                </Box>
              </Box>
            </ContentHeader>
            <Box
              mt={4}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Box>
                <Tabs
                  value={value}
                  onChange={handleChangeTab}
                  indicatorColor="primary"
                  textColor="primary"
                >
                  {tab.map((tab) => (
                    <Tab
                      label={tab.label}
                      value={tab.value}
                      key={tab.value}
                      onClick={(event) => handleChangeTab(event, tab.value)}
                      id={helpers.dataProcess.driverJs.tabId(suggestedChanges)}
                    />
                  ))}
                </Tabs>
              </Box>
              <Box display="flex" alignItems="center">
                {filterIsApplied && (
                  <Box display="flex">
                    <IconButton
                      color="secondary"
                      component="span"
                      onClick={clearFilter}
                    >
                      <FilterIcon size="16px" />
                      <Typography color="primary">Limpar o filtro</Typography>
                    </IconButton>
                  </Box>
                )}
                <Box>
                  <IconButton
                    color="secondary"
                    component="span"
                    onClick={() => {
                      setListingType('table')
                    }}
                    id="id-indexdataprocess-button-btntable"
                  >
                    <MenuIcon size="16px" />
                  </IconButton>
                </Box>
                <IconButton
                  color="secondary"
                  component="span"
                  onClick={() => {
                    setListingType('cards')
                  }}
                  id="id-indexdataprocess-button-btncard"
                >
                  <GridButton size="16px" />
                </IconButton>
              </Box>
            </Box>
            <Divider />
            {alertValidationWithDeadline && (
              <Alert
                id={constants.dataProcess.DATA_PROCESS_MAIN_DRIVER_STEP_4}
                className={classes.alertUndefinedProcesses}
                variant="filled"
                severity="info"
                onClick={bringUndefinedDataProcesses}
              >
                Você tem processo(s) com prazo de armazenamento&nbsp;
                <i>Não Definido</i>. Clique aqui para saber quais são!
              </Alert>
            )}
            {isUnnecessaryDatas && (
              <Alert
                id={constants.dataProcess.DATA_PROCESS_MAIN_DRIVER_STEP_5}
                className={classes.alertUndefinedProcesses}
                variant="filled"
                severity="info"
                onClick={unnecessaryProcesses}
              >
                Você tem processo(s) com o tratamento de necessidade e
                proporcionalidade <i>pendente</i>. Clique aqui para saber quais
                são!
              </Alert>
            )}
            {isAuditProcess(value) && !!dataProcesses && (
              <Box className={classes.alertBox}>
                <CompanyAuditAlert
                  companyAuditEndDate={auth.company.onGoingCompanyAudit.endDate}
                />
              </Box>
            )}
            <Box my={2}>
              <Main
                dataProcesses={dataProcesses}
                isLoading={isLoading}
                refresh={refresh}
                setRefresh={() => setRefresh(!refresh)}
                setIsLoading={setIsLoading}
                listingType={listingType}
                {...(origin && { origin })}
              />
            </Box>
            <Box px={2} display="flex" justifyContent="flex-end">
              {!isLoading && dataProcesses?.meta ? (
                <TablePagination
                  component="div"
                  count={dataProcesses.meta.totalCount}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                  page={page - 1}
                  rowsPerPage={perPage}
                  rowsPerPageOptions={[4, 12, 36, 100]}
                  labelRowsPerPage={isDesktop ? 'Por página' : ''}
                  nextIconButtonProps={{ size: 'small' }}
                  backIconButtonProps={{ size: 'small' }}
                />
              ) : (
                <Box py="11px">
                  <Skeleton variant="rect" width={200} height={30} />
                </Box>
              )}
            </Box>
            {!isLoadingDepartments ? (
              <Filters filter={filter} isDataProcess alphabeticalOrder>
                <input textfieldinput="true" label="Identificador" name="id" />
                <input textfieldinput="true" label="Nome" name="name" />
                <input
                  datepickerinput="true"
                  label="Data de criação"
                  name="createdAt"
                />
                <select textfieldinput="true" label="Status" name="statusId">
                  <option value=""></option>
                  <option value="1">Pendente</option>
                  <option value="2">Aguardando Revisão</option>
                  <option value="3">Reprovado</option>
                  <option value="4">Aprovado</option>
                  <option value="6">Inativo</option>
                  <option value="10">Pendente LIA</option>
                  <option value="[11, 12]">Revisão LIA</option>
                  <option value="13">Reprovado LIA</option>
                </select>
                <select
                  textfieldinput="true"
                  label="Origem"
                  value={eventSources}
                  name={constants.dataProcess.SOURCE_DESCRIPTION_FIELD}
                  onChange={handleChangeSource}
                  willclearfield={
                    constants.dataProcess.DATA_PROCESS_SOURCES_FIELD
                  }
                >
                  <option value=""></option>
                  <option value={constants.dataProcess.TITULAR_DATA_SOURCE}>
                    O próprio titular
                  </option>
                  <option value={constants.dataProcess.DEPARTMENT_SOURCE}>
                    Outro departamento
                  </option>
                  <option value={constants.dataProcess.THIRD_PARTY_SOURCE}>
                    Um terceiro
                  </option>
                  <option value={constants.dataProcess.PUBLIC_SOURCE}>
                    Dados públicos
                  </option>
                  <option value={constants.dataProcess.ANOTHER_SOURCE}>
                    Outro
                  </option>
                </select>
                {[
                  constants.dataProcess.DEPARTMENT_SOURCE,
                  constants.dataProcess.THIRD_PARTY_SOURCE,
                  constants.dataProcess.PUBLIC_SOURCE,
                ].includes(eventSources) && (
                  <select
                    multipleselectinput="true"
                    name={constants.dataProcess.DATA_PROCESS_SOURCES_FIELD}
                    label={helpers.dataProcess.getSourceFilterLabel(
                      eventSources,
                    )}
                    optionvalues={dataSources?.map((source) => ({
                      id: source.entityId,
                      name: source.entityName,
                    }))}
                  />
                )}
                {constants.dataProcess.ANOTHER_SOURCE === eventSources && (
                  <input
                    textfieldinput="true"
                    name="anotherSourceDescription"
                    label="Outra origem"
                    {...(isNaN(parseInt(filter.filters.sourceDescription)) && {
                      value: filter.filters.sourceDescription,
                    })}
                    required
                  />
                )}
                <select
                  textfieldinput="true"
                  label="Documentos anexados"
                  name="document"
                >
                  <option value=""></option>
                  <option value={true}>Com documento</option>
                  <option value={false}>Sem documento</option>
                </select>
                <select
                  textfieldinput="true"
                  label="Departamento"
                  name="departmentId"
                >
                  <option value=""></option>
                  {departments.map((department) => (
                    <option key={department.id} value={department.id}>
                      {department.name}
                    </option>
                  ))}
                </select>
                <select
                  multipleselectinput="true"
                  label="Risco"
                  name="fragilityId"
                  optionvalues={constants.dataProcess.FRAGILITY_FILTER_OPTIONS}
                />
                <select
                  textfieldinput="true"
                  label="Ameaças vinculadas"
                  name="companyFragilities"
                >
                  <option value=""></option>
                  <option value={true}>Sim</option>
                  <option value={false}>Não</option>
                </select>
                {data?.options?.titularCategories && (
                  <select
                    multipleselectinput="true"
                    label="Titular de dados"
                    name="titularCategories"
                    optionvalues={data.options.titularCategories}
                  />
                )}
                {data?.options?.dataCollectedOptions && (
                  <select
                    multipleselectinput="true"
                    compareByName="true"
                    label="Dado tratado"
                    name="dataCollectedOptionNames"
                    optionvalues={helpers.dataProcess.formatByNameInFilter(
                      data?.options?.dataCollectedOptions,
                    )}
                  />
                )}
                {data?.options?.shareProcesses && (
                  <select
                    multipleselectinput="true"
                    compareByName="true"
                    label="Com quem compartilha"
                    name="shareProcessNames"
                    optionvalues={helpers.dataProcess.formatByNameInFilter(
                      data?.options?.shareProcesses,
                    )}
                  />
                )}
                <select
                  textfieldinput="true"
                  label="Transferência internacional"
                  name="internationalTransfers"
                >
                  <option value=""></option>
                  <option value={true}>Sim</option>
                  <option value={false}>Não</option>
                </select>
                {data?.options?.legalFrameworks && (
                  <select
                    multipleselectinput="true"
                    label="Enquadramento legal"
                    name="legalFrameworks"
                    optionvalues={helpers.dataProcess.formatLegalFrameworksFilterOptions(
                      data.options.legalFrameworks,
                    )}
                  />
                )}
                <select
                  multipleselectinput="true"
                  label="Tipo de dados"
                  name="dataTypes"
                  optionvalues={constants.dataProcess.DATA_TYPE_OPTIONS}
                />
                <select
                  multipleselectinput="true"
                  label="Compartilhamento"
                  name="shareTypes"
                  optionvalues={constants.dataProcess.SHARE_TYPE_OPTIONS}
                />
                <select
                  textfieldinput="true"
                  label="Tempo de armazenamento"
                  name="storageMode"
                >
                  <option value=""></option>
                  <option value={constants.dataProcess.STORY_MODE_DEFINED_TYPE}>
                    Definido
                  </option>
                  <option
                    value={constants.dataProcess.STORY_MODE_UNDEFINED_TYPE}
                  >
                    Indefinido
                  </option>
                  <option
                    value={constants.dataProcess.STORY_MODE_PERMANENT_TYPE}
                  >
                    Permanente
                  </option>
                </select>
                {data?.options?.storageLocations && (
                  <select
                    multipleselectinput="true"
                    label="Local de armazenamento"
                    name="storageLocations"
                    optionvalues={data?.options?.storageLocations}
                  />
                )}
              </Filters>
            ) : (
              <></>
            )}
          </Container>
        </Page>
      )}
      <DriveTour
        stepsMatrix={validationSteps()}
        customStyle={classes.customPopover}
        open={openTour}
        setOpen={setOpenTour}
      />
    </>
  )
}

DataProcessesMain.propTypes = {
  defaultFilters: PropTypes.object,
  origin: PropTypes.string,
  hasTour: PropTypes.bool,
}

export default DataProcessesMain
