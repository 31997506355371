import components from '../components'
import moment from 'moment'
import palette from 'theme/palette'

const createSubTableReportLia = (
  pdf,
  lia_answer,
  padding,
  columnStyle,
  noOrder,
  dateNow,
) => {
  const { questionOrder, questionText, answer: rawAnswer } = lia_answer

  const getQuestion = (noOrder, order, text) => {
    if (noOrder) return text

    return `${order + 1} - ${text}`
  }

  const formatAnswer = (order, answer) => {
    if (order === 39) {
      return moment(answer).format('DD/MM/YYYY')
    } else if (order === 37) {
      return answer === 'yes' ? 'SIM' : 'NÃO'
    }
    return answer
  }

  const question = getQuestion(noOrder, questionOrder, questionText)
  const answer = formatAnswer(questionOrder, rawAnswer)

  const afterDrawTable = () => {
    components.numberPage(pdf, palette.primary.main, true)
    components.lineWithDate(pdf, `Visão Geral | ${dateNow}`)
  }

  return components.table(
    pdf,
    null,
    [[question, answer]],
    pdf.autoTable.previous.finalY,
    0.5,
    padding,
    true,
    columnStyle,
    undefined,
    undefined,
    afterDrawTable,
  )
}

export default createSubTableReportLia
