import { Fragment } from 'react'

import constants from 'constants/index'
import helpers from 'helpers'

const cardFlagDefinitionByLabel = (label) => {
  label = helpers.functions.capitalizeText(label)
  return constants.cardFlag.CARD_FLAG_DEFINITION_BY_LABEL[label] || Fragment
}

export default {
  cardFlagDefinitionByLabel,
}
