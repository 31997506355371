const styles = (theme) => ({
  floatingBlue: {
    backgroundColor: theme.palette.layout.main,
    position: 'absolute',
    width: 30,
    height: 30,
  },
  floatingGray: {
    backgroundColor: theme.palette.background.default,
    width: 30,
    height: 30,
    borderRadius: '20px 0 0 0',
  },
  floatingWhite: {
    backgroundColor: theme.palette.white,
    width: 30,
    height: 30,
    borderRadius: '20px 0 0 0',
  },
})

export default styles
