import React, { useState, useEffect } from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { PlusCircle as PlusCircleIcon } from 'react-feather'
import PropTypes from 'prop-types'
import { isEmpty } from 'lodash'
import {
  Grid,
  Button,
  makeStyles,
  Box,
  Tooltip,
  CircularProgress,
  Typography,
} from '@material-ui/core'

import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
} from 'components/Table'
import { MenuButton } from 'components'
import DialogRemoveDataLifeCycle from '../../DialogRemoveDataLifeCycle'
import DialogDataLifeCycle from '../../DialogDataLifeCycle'
import { DescriptionPaper } from '../..'

import useFetch from 'hooks/useFetch'
import useSnackbar from 'hooks/useSnackbar'
import useDataProcess from 'hooks/useDataProcess'

import * as service from 'service'
import styles from '../styles'
import constants from 'constants/index'
import helpers from 'helpers'

const useStyles = makeStyles(styles)

const DataLifeCycle = ({ description }) => {
  const classes = useStyles()
  const snackbar = useSnackbar()
  const { data, nextStep, toNextStep, setToNextStep } = useDataProcess()
  const { dataProcess } = data
  const [dialogRemoveDataLifeCycle, setDialogRemoveDataLifeCycle] =
    useState(false)
  const [removableDataLifeCycleId, setRemovableDataLifeCycleId] = useState()
  const [dialogDataLifeCycle, setDialogDataLifeCycle] = useState(false)
  const [editableDataLifeCycle, setEditableDataLifeCycle] = useState({})

  const [loading, setLoading] = useState(false)
  const [mode, setMode] = useState('create')

  const { response, isLoading, refresh } = useFetch(
    service.dponet.dataLifeCycles.get,
    {
      dataProcessId: dataProcess.id,
    },
  )

  const lifeCycles = response?.data?.lifeCycles

  useEffect(() => {
    if (toNextStep) {
      if (!isLoading) {
        if (!isEmpty(lifeCycles)) {
          nextStep()
          setToNextStep(false)
        } else {
          snackbar.open({
            message:
              'É necessário ter pelo menos um dado salvaguarda e ciclo de vida.',
            variant: 'error',
          })
          setToNextStep(false)
        }
      }
    }
    // eslint-disable-next-line
  }, [nextStep])

  const handleDelete = async (dataProcessId, dataLifeCycleId) => {
    setLoading(true)
    try {
      await service.dponet.dataLifeCycles.destroy({
        dataProcessId,
        dataLifeCycleId,
      })
      setDialogRemoveDataLifeCycle(false)

      snackbar.open({
        message: 'Salvaguarda e ciclo de vida removido com sucesso!',
        variant: 'success',
      })

      refresh()
    } catch (err) {
      snackbar.open({
        message:
          'Não foi possível remover a salvaguarda e ciclo de vida. Tente novamente!',
        variant: 'error',
      })
    }
    setLoading(false)
  }

  const handleOpenRemoveDialog = (dataLifeCycle) => {
    setRemovableDataLifeCycleId(dataLifeCycle?.id)
    setDialogRemoveDataLifeCycle(true)
  }

  const handleOpenDialogDataLifeCycle = (
    dataLifeCycle = {},
    mode = 'create',
  ) => {
    setEditableDataLifeCycle(dataLifeCycle)
    setMode(mode)
    setDialogDataLifeCycle(true)
  }

  return (
    <>
      {isLoading || loading ? (
        <Box
          width="100%"
          minHeight="350px"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress />
        </Box>
      ) : (
        <Box id={constants.dataProcess.DATA_PROCESS_NEW_DRIVER_STEP_5}>
          <DescriptionPaper description={description}>
            <Button
              variant="outlined"
              startIcon={<PlusCircleIcon size={16} />}
              onClick={() => handleOpenDialogDataLifeCycle()}
            >
              Adicionar
            </Button>
          </DescriptionPaper>
          <Grid container className={classes.root}>
            <Grid xs={12} item>
              <Box maxHeight="82%">
                <PerfectScrollbar>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell width="20%">Ambiente de alocação</TableCell>
                        <TableCell width="20%">
                          Onde os dados são armazenados
                        </TableCell>
                        <TableCell width="20%">
                          Por quanto tempo os dados serão armazenados
                        </TableCell>
                        <TableCell width="15%">
                          Justificativa de retenção
                        </TableCell>
                        <TableCell width="15%">
                          Como os dados são dispostos
                        </TableCell>
                        <TableCell width="10%" align="right">
                          Ações
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {lifeCycles?.map((dataLifeCycle) => (
                        <TableRow key={dataLifeCycle?.id}>
                          <TableCell>
                            <Tooltip
                              title={
                                dataLifeCycle?.internalAllocationMode?.name ||
                                ''
                              }
                            >
                              <Typography className={classes.ellipsisTable}>
                                {dataLifeCycle?.internalAllocationMode?.name ||
                                  ''}
                              </Typography>
                            </Tooltip>
                          </TableCell>
                          <TableCell>
                            <Tooltip
                              title={dataLifeCycle?.storageLocation?.name || ''}
                            >
                              <Typography className={classes.ellipsisTable}>
                                {dataLifeCycle?.storageLocation?.name || ''}
                              </Typography>
                            </Tooltip>
                          </TableCell>
                          <TableCell>
                            <Tooltip
                              title={helpers.dataProcess.storageModeType(
                                dataLifeCycle?.storageType,
                                dataLifeCycle?.value,
                                dataLifeCycle?.volumetry,
                                !!dataLifeCycle?.value &&
                                  !!dataLifeCycle?.volumetry,
                              )}
                            >
                              <Typography className={classes.ellipsisTable}>
                                {helpers.dataProcess.storageModeType(
                                  dataLifeCycle?.storageType,
                                  dataLifeCycle?.value,
                                  dataLifeCycle?.volumetry,
                                  !!dataLifeCycle?.value &&
                                    !!dataLifeCycle?.volumetry,
                                )}
                              </Typography>
                            </Tooltip>
                          </TableCell>
                          <TableCell>
                            <Tooltip
                              title={dataLifeCycle?.retentionFinality || ''}
                            >
                              <Typography className={classes.ellipsisTable}>
                                {dataLifeCycle?.retentionFinality || ''}
                              </Typography>
                            </Tooltip>
                          </TableCell>
                          <TableCell>
                            <Tooltip
                              title={helpers.dataProcess.discardMode(
                                dataLifeCycle,
                              )}
                            >
                              <Typography className={classes.ellipsisTable}>
                                {helpers.dataProcess.discardMode(dataLifeCycle)}
                              </Typography>
                            </Tooltip>
                          </TableCell>
                          <TableCell>
                            <Box display="flex" justifyContent="flex-end">
                              <MenuButton>
                                <Button
                                  color="secondary"
                                  fullWidth
                                  onClick={() =>
                                    handleOpenDialogDataLifeCycle(
                                      dataLifeCycle,
                                      'edit',
                                    )
                                  }
                                >
                                  Editar
                                </Button>
                                <Button
                                  color="secondary"
                                  fullWidth
                                  onClick={() =>
                                    handleOpenRemoveDialog(dataLifeCycle)
                                  }
                                >
                                  Excluir
                                </Button>
                              </MenuButton>
                            </Box>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </PerfectScrollbar>
              </Box>
            </Grid>
          </Grid>
        </Box>
      )}
      <DialogRemoveDataLifeCycle
        open={dialogRemoveDataLifeCycle}
        setOpen={setDialogRemoveDataLifeCycle}
        dataProcessId={dataProcess?.id}
        loading={loading}
        dataLifeCycleId={removableDataLifeCycleId}
        handleRemove={handleDelete}
      />
      <DialogDataLifeCycle
        open={dialogDataLifeCycle}
        setOpen={setDialogDataLifeCycle}
        dataProcessId={dataProcess?.id}
        setLoading={setLoading}
        dataLifeCycle={editableDataLifeCycle}
        dataProcess={dataProcess}
        refresh={refresh}
        mode={mode}
      />
    </>
  )
}

DataLifeCycle.propTypes = {
  description: PropTypes.string,
}

DataLifeCycle.defaultProps = {
  description: '',
}

export default DataLifeCycle
