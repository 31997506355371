import PropTypes from 'prop-types'
import React, { useState } from 'react'

import { useForm, Controller } from 'react-hook-form'
import useSnackbar from 'hooks/useSnackbar'
import useDataProcessOptions from 'hooks/useDataProcessOptions'
import useAuth from 'hooks/useAuth'

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  TextField,
  Box,
  MenuItem,
  Typography,
  Button,
} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { Label } from 'components'

import MultiSelect from '@kenshooui/react-multi-select'
import { makeStyles } from '@material-ui/core/styles'
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons'

import { dataCollectedSchema } from './schemas'

import helpers from 'helpers'
import * as service from 'service'
import styles from './styles'

import constants from 'constants/index'

const useStyles = makeStyles(styles)

const FormModal = ({
  dataCollected,
  toSelectItems,
  selectedFields,
  handleCloseModal,
  dataProcessId,
  mode,
  submitSuccess,
  refresh,
}) => {
  const { company } = useAuth()
  const classes = useStyles()
  const dataProcessOptions = useDataProcessOptions()
  const snackbar = useSnackbar()
  const { options } = dataProcessOptions.data
  const { titularCategories } = options
  const [selectedItems, changeSelectedItems] = useState(selectedFields)
  const [loading, setLoading] = useState(false)
  const { treatDataToSubmit } = helpers.dataCollected
  const [expanded, setExpanded] = useState(false)

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }

  const edit = mode === 'edit'
  const submitRoute = edit
    ? service.dponet.dataCollecteds.put
    : service.dponet.dataCollecteds.create

  const handleChange = (selected) => {
    changeSelectedItems(selected)
  }

  const { control, handleSubmit, errors } = useForm({
    validationSchema: dataCollectedSchema(
      company?.kind !== constants.userCompanies.KIND_TEMPLATE,
    ),
    defaultValues: {
      titularAgeRanges:
        helpers.dataCollected.nameOfTitularAgeRange(
          dataCollected?.titularAgeRange,
        ) || [],
      holderNumber: dataCollected?.holderNumber || null,
      titularCategoryId:
        helpers.functions.dig(dataCollected.titularCategory, 'id') || '',
      anotherPersonalNames: '',
      anotherComportamentalNames: '',
      anotherFinancialNames: '',
    },
  })

  const dataCollectedsIsBlank = (dataCollecteds) => {
    if (edit) return !dataCollecteds.dataCollectedOptionsIds.length

    return (
      !dataCollecteds.anotherComportamentalNames.length &&
      !dataCollecteds.anotherPersonalNames.length &&
      !dataCollecteds.anotherFinancialNames.length &&
      !dataCollecteds.dataCollectedOptionsIds.length
    )
  }

  const onSubmit = async (data) => {
    setLoading(true)
    try {
      let dataCollecteds = treatDataToSubmit(data, selectedItems, edit)

      if (dataCollectedsIsBlank(dataCollecteds)) {
        snackbar.open({
          message:
            'Por favor selecione ao menos um dado tratado para o tratamento.',
          variant: 'error',
        })
        return setLoading(false)
      }

      await submitRoute({
        dataProcessId,
        dataCollectedId: dataCollected?.id,
        dataCollecteds: dataCollecteds,
      })
      submitSuccess()
    } catch (error) {
      snackbar.open({
        message: 'Ocorreu algum erro! Tente novamente!',
        variant: 'error',
      })
      setLoading(false)
    }
  }

  const multiSelectSearch = (value) => (item) => {
    return (
      String(item.label).toLowerCase().includes(value.toLowerCase()) ||
      String(item.group).toLowerCase().includes(value.toLowerCase())
    )
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid
        container
        spacing={2}
        justify="flex-start"
        alignItems="center"
        className={classes.root}
      >
        <Grid item xs={12}>
          <Box mt={1}>
            <Controller
              as={
                <TextField
                  label="Categoria do titular"
                  select
                  color="primary"
                  variant="outlined"
                  error={!!errors.titularCategoryId}
                  helperText={
                    errors &&
                    errors.titularCategoryId &&
                    errors.titularCategoryId.message
                  }
                  fullWidth
                >
                  {titularCategories.map((titularCategory) => (
                    <MenuItem
                      key={titularCategory.id}
                      value={titularCategory.id}
                    >
                      <Typography>{titularCategory.name}</Typography>
                    </MenuItem>
                  ))}
                </TextField>
              }
              control={control}
              name="titularCategoryId"
              mode="onBlur"
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box mt={1}>
            <Label title="Faixa etária" item>
              <Controller
                control={control}
                name="titularAgeRanges"
                onChange={([, data]) => data}
                mode="onChange"
                as={
                  <Autocomplete
                    multiple
                    options={constants.dataCollected.OPTION_AGE_RANGE}
                    getOptionLabel={(option) => option.name}
                    getOptionSelected={(option, value) =>
                      option.id === value.id || value.id === 0
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        error={!!errors?.titularAgeRanges}
                        helperText={errors?.titularAgeRanges?.message}
                      />
                    )}
                  />
                }
              />
            </Label>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Label title="Quantidade aproximada de titulares" item>
            <Controller
              as={
                <TextField
                  type="number"
                  color="primary"
                  variant="outlined"
                  error={!!errors.holderNumber}
                  helperText={errors?.holderNumber?.message ?? ''}
                  fullWidth
                  inputProps={{
                    min: 0,
                  }}
                />
              }
              control={control}
              name="holderNumber"
              mode="onBlur"
            />
          </Label>
        </Grid>
        <Grid item xs={12}>
          <Box mt={1}>
            <Typography variant="subtitle1" color="textSecondary">
              Dado(s) Tratado(s)
            </Typography>
          </Box>
          <MultiSelect
            messages={helpers.dataCollected.messagesMultiSelect()}
            items={toSelectItems}
            withGrouping
            selectedItems={selectedItems}
            filterFunction={multiSelectSearch}
            onChange={handleChange}
            wrapperClassName={classes.multiSelect}
          />
        </Grid>
        <Grid container item xs={12}>
          <Box width="100%">
            <Accordion
              expanded={expanded === 'panel-another'}
              onChange={handleAccordionChange('panel-another')}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel-another-content"
                className={classes.accordionSummaryColor}
                id="panel-another-header"
              >
                <Typography className={classes.accordionHeader}>
                  Não encontrou o dado que procurava na lista acima?
                </Typography>
                <Typography className={classes.secondaryAccordionHeader}>
                  Clique aqui e insira estes dados manualmente.
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container>
                  <Box mt={1}>
                    <Box mb={2}>
                      <Typography variant="h6">
                        <strong>Importante!</strong> Antes de cadastrar qualquer
                        dado tratado manualmente certifique-se de que o mesmo
                        não está presente na lista acima.
                      </Typography>
                    </Box>
                    <Typography variant="subtitle1" color="textSecondary">
                      Outros dados tratados
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      Informe outros dados tratados no processo (que não estão
                      listados acima), separando-os por vírgula (,)
                    </Typography>
                  </Box>
                  <Grid item xs={12}>
                    <Box mt={2} mr={2}>
                      <Label
                        title="Outros dados pessoais"
                        description="Ex: Nacionalidade, Idade"
                        lg={6}
                        item
                        xs={12}
                      />
                      <Controller
                        as={
                          <TextField
                            label="Outros dados pessoais"
                            color="primary"
                            variant="outlined"
                            error={!!errors.anotherPersonalNames}
                            helperText={
                              errors &&
                              errors.anotherPersonalNames &&
                              errors.anotherPersonalNames.message
                            }
                            fullWidth
                          />
                        }
                        control={control}
                        name="anotherPersonalNames"
                        mode="onBlur"
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Label
                      title="Outros dados financeiros"
                      description="Ex: Validade do cartão de crédito, Renda mensal"
                      lg={6}
                      item
                      xs={12}
                    />
                    <Box mt={2} mr={2}>
                      <Controller
                        as={
                          <TextField
                            label="Outros dados financeiros"
                            color="primary"
                            variant="outlined"
                            error={!!errors.anotherFinancialNames}
                            helperText={
                              errors &&
                              errors.anotherFinancialNames &&
                              errors.anotherFinancialNames.message
                            }
                            fullWidth
                          />
                        }
                        control={control}
                        name="anotherFinancialNames"
                        mode="onBlur"
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box mt={2} mr={2}>
                      <Label
                        title="Outros dados comportamentais"
                        description="Ex: Hábitos alimentares, Pratica atividades físicas"
                        lg={6}
                        item
                        xs={12}
                      />
                      <Controller
                        as={
                          <TextField
                            label="Outros dados comportamentais"
                            color="primary"
                            variant="outlined"
                            error={!!errors.anotherComportamentalNames}
                            helperText={
                              errors &&
                              errors.anotherComportamentalNames &&
                              errors.anotherComportamentalNames.message
                            }
                            fullWidth
                          />
                        }
                        control={control}
                        name="anotherComportamentalNames"
                        mode="onBlur"
                      />
                    </Box>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Box>
        </Grid>
      </Grid>
      <Box display="flex" justifyContent="flex-start" my={2} mr={2}>
        <Box pl={2} pr={1}>
          <Button
            color="secondary"
            type="button"
            variant="outlined"
            onClick={handleCloseModal}
            disabled={loading}
          >
            Voltar
          </Button>
        </Box>
        <Button
          disabled={loading}
          type="submit"
          variant="contained"
          color="primary"
        >
          {loading ? 'Aguarde...' : 'Salvar'}
        </Button>
      </Box>
    </form>
  )
}

FormModal.propType = {
  dataCollected: PropTypes.object,
  toSelectItems: PropTypes.object,
  selectedFields: PropTypes.arrayOf(PropTypes.object),
  handleCloseModal: PropTypes.func,
  dataProcessId: PropTypes.number,
  mode: PropTypes.string,
  submitSuccess: PropTypes.func,
}

FormModal.defaultProps = {
  dataCollected: {},
  toSelectItems: {},
  selectedFields: [],
  handleCloseModal: () => {},
  submitSuccess: () => {},
}

export default FormModal
