import React, { Fragment, useState } from 'react'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Box, Button, Paper, Tooltip, Typography } from '@material-ui/core'

import { AuditsForm, ProcessTable } from './components'
import { AuditsFinalizeModal, AuditsInactivationModal } from '..'

import theme from 'theme'
import { routes } from 'Routes'
import useStyles from './styles'
import constants from 'constants/index'

const AuditsPageStructure = ({
  automationData,
  cancelButtonLabel = 'Cancelar',
  companyAudit,
  formRef = 'audits-info-form',
  formType,
  isAutomation = false,
  setLoading,
  refresh = () => {},
  auditConfigId,
}) => {
  const [open, setOpen] = useState(false)
  const [openInactivationModal, setOpenInactivationModal] = useState(false)

  const classes = useStyles()
  const history = useHistory()

  const isShow = formType === 'show'
  const isNew = formType === 'new'
  const isEdit = formType === 'edit'
  const isInactive =
    companyAudit?.status === constants.audits.INACTIVE_STATUS_ID
  const isPreview = companyAudit?.status === constants.audits.PREVIEW_STATUS_ID
  const isFinalized =
    companyAudit?.status === constants.audits.FINALIZED_STATUS_ID

  const previewTooltip =
    !isPreview && (isAutomation || isEdit)
      ? 'Não é possível editar uma auditoria que não esteja prevista'
      : ''

  const disabledInputs = !isPreview && !isNew

  const handleOpenModal = () => setOpen(true)
  const handleOpenInactivationModal = () => setOpenInactivationModal(true)
  const handleBackButton = () => history.push(routes.audits.all)

  return (
    <Box display="flex" flexDirection="column" gridGap={theme.spacing(2)}>
      <AuditsForm
        auditConfigId={auditConfigId}
        automationData={automationData}
        companyAudit={companyAudit}
        disabledInputs={disabledInputs}
        formRef={formRef}
        handleBack={handleBackButton}
        isAutomation={isAutomation}
        isNew={isNew}
        isShow={isShow}
        previewTooltip={previewTooltip}
        refresh={refresh}
        setLoading={setLoading}
      />
      {!isNew && companyAudit?.observation && (
        <Paper className={classes.infoPaper}>
          <Typography variant="h6" gutterBottom>
            Comentários e/ou pareceres a respeito da auditoria:
          </Typography>
          <Typography>{companyAudit?.observation}</Typography>
        </Paper>
      )}
      {!isNew && (
        <Fragment>
          <Typography variant="h6" color="primary">
            Processos
          </Typography>
          <ProcessTable companyAuditId={companyAudit?.id} />
        </Fragment>
      )}
      <Box display="flex" gridGap={theme.spacing(2)} justifyContent="flex-end">
        <Button variant="outlined" color="primary" onClick={handleBackButton}>
          {cancelButtonLabel}
        </Button>
        {!isShow && !isFinalized && (
          <Tooltip title={previewTooltip}>
            <Box>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                form={formRef}
                disabled={disabledInputs}
              >
                Salvar
              </Button>
            </Box>
          </Tooltip>
        )}
        {(isShow || isAutomation) && !isFinalized && (
          <Fragment>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleOpenInactivationModal}
            >
              {isInactive ? 'Ativar' : 'Inativar'}
            </Button>
            <Tooltip
              title={
                isPreview || isInactive
                  ? `Não é possível finalizar uma auditoria ${
                      isPreview ? 'prevista' : 'inativada'
                    }`
                  : ''
              }
            >
              <Box>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleOpenModal}
                  disabled={isPreview || isInactive}
                >
                  Finalizar
                </Button>
              </Box>
            </Tooltip>
          </Fragment>
        )}
      </Box>
      <AuditsFinalizeModal
        companyAuditId={companyAudit?.id}
        open={open}
        setLoading={setLoading}
        setOpen={setOpen}
        refresh={refresh}
      />
      <AuditsInactivationModal
        companyAuditId={companyAudit?.id}
        isInactive={isInactive}
        open={openInactivationModal}
        setLoading={setLoading}
        setOpen={setOpenInactivationModal}
        refresh={refresh}
      />
    </Box>
  )
}

AuditsPageStructure.propTypes = {
  auditConfigId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  automationData: PropTypes.object,
  cancelButtonLabel: PropTypes.string,
  companyAudit: PropTypes.object,
  formRef: PropTypes.string,
  formType: PropTypes.oneOf(['show', 'new', 'edit']),
  isAutomation: PropTypes.bool,
  refresh: PropTypes.func,
  setLoading: PropTypes.func.isRequired,
}

export default AuditsPageStructure
