import { makeStyles } from '@material-ui/core'

const styles = makeStyles(() => ({
  editor: {
    '& span > a > svg': {
      display: 'none',
    },
  },
}))

export default styles
