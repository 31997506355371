import React from 'react'
import { include } from 'named-urls'
import { Switch, Redirect, Route } from 'react-router-dom'

import { RouteWithLayout } from './components'

import {
  Auth as AuthLayout,
  MainLayout,
  Portal as PortalLayout,
  JourneyStepsLayout,
  Checkout as CheckoutLayout,
  JourneyStepsWithoutUserBoxLayout,
} from './layouts'

import CompanyFragilityProvider from 'providers/CompanyFragilityProvider'
import ConsentProvider from 'providers/ConsentProvider'
import DataProcessOptionsProvider from 'providers/DataProcessOptionsProvider'
import DataProcessProvider from 'providers/DataProcessProvider'
import FirstStepProvider from 'providers/FirstStepProvider'
import IncidentManegementProvider from 'providers/IncidentManegementProvider'
import SubscriptionProvider from 'providers/SubscriptionProvider'
import SuggestedChangeProvider from 'providers/SuggestedChangeProvider'
import SupplierInviteProvider from 'providers/SupplierInviteProvider'
import TrailStepProvider from 'providers/TrailStepProvider'

// import LoginTransparentIntegration from 'views/LoginTransparentIntegration'
import Audits from 'views/Audits'
import Certificate from 'views/Certificate'
import CompanyFragilities from 'views/CompanyFragilities'
import Consent from 'views/Consent'
import Cookies from 'views/Cookies'
import DataProcesses from 'views/DataProcesses'
import Departments from 'views/Departments'
import Documents from 'views/Documents'
import DocumentsTemplate from 'views/DocumentsTemplate'
import FaqsDocumentations from 'views/FaqsDocumentations'
import Forbidden from 'views/Forbidden'
import ForgotPassword from 'views/ForgotPassword'
import IncidentManagements from 'views/IncidentManagements'
import Incidents from 'views/Incidents'
import LastUpdates from 'views/LastUpdates'
import Login, { IntegrationLogin } from 'views/Login'
import Marketplace from 'views/Marketplace'
import MyLgpd from 'views/MyLgpd'
import NewPassword from 'views/NewPassword'
import NotFound from 'views/NotFound'
import PrivacyPolicies from 'views/PrivacyPolicies'
import PrivacyStamps from 'views/PrivacyStamps/PrivacyStamp'
import Profiles from 'views/Profiles'
import QuestionControls from 'views/QuestionControls'
import Questionnaires from 'views/Questionnaires'
import Reports from 'views/Reports'
import SelectCompany from 'views/SelectCompany'
import Settings from 'views/Settings'
import Subscription from 'views/Subscription'
import SubscriptionCompany from 'views/SubscriptionCompany'
import SupplierInvite from 'views/SupplierInvite'
import Suppliers from 'views/Suppliers'
import SupplierStep from 'views/SupplierStep'
import TechnicalFaqs from 'views/TechnicalFaqs'
import TitularsSolicitations from 'views/TitularsSolicitations'
import TrainningJourney from 'views/TrainningJourney'
import TwoFactorAuthentication from 'views/TwoFactorAuthentication'
import Users from 'views/Users'
import UserSteps from 'views/UserSteps'
import UserTrailSteps from 'views/UserTrailSteps'

import permission from 'constants/permissions'

const Routes = () => {
  return (
    <Switch>
      <RouteWithLayout
        path={routes.root}
        component={Login}
        layout={AuthLayout}
        exact
      />
      <RouteWithLayout
        path={routes.outsourcedDpoLogin}
        component={IntegrationLogin}
        layout={AuthLayout}
        exact
      />
      <RouteWithLayout
        path={routes.dpoEasyLogin}
        component={IntegrationLogin}
        layout={AuthLayout}
        exact
      />
      <RouteWithLayout
        path={routes.twoFactorAuthentication}
        component={TwoFactorAuthentication.Main}
        layout={AuthLayout}
        exact
      />
      <RouteWithLayout
        path={routes.forgotPassword}
        component={ForgotPassword}
        layout={AuthLayout}
        exact
      />
      <RouteWithLayout
        path={routes.newPassword}
        component={NewPassword}
        layout={AuthLayout}
        exact
      />
      <RouteWithLayout
        path={routes.selectCompany}
        component={SelectCompany}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.userSteps}
        component={UserSteps.Main}
        layout={JourneyStepsLayout}
        provider={FirstStepProvider}
        auth
      />
      <RouteWithLayout
        path={routes.userTrailSteps}
        component={UserTrailSteps.Main}
        layout={JourneyStepsLayout}
        provider={TrailStepProvider}
        auth
      />
      <RouteWithLayout
        path={routes.trainningJourney}
        component={TrainningJourney.Main}
        layout={JourneyStepsLayout}
        auth
      />
      <RouteWithLayout
        path={routes.supplierStep}
        component={SupplierStep.Main}
        layout={JourneyStepsLayout}
        auth
      />
      <RouteWithLayout
        path={routes.supplierInvite}
        component={SupplierInvite.Main}
        layout={JourneyStepsWithoutUserBoxLayout}
        redirect={false}
        provider={SupplierInviteProvider}
      />
      <RouteWithLayout
        path={routes.consultCertificate}
        component={Certificate.Consult}
        layout={JourneyStepsLayout}
      />
      <RouteWithLayout
        path={routes.myLgpd.dashboard}
        component={MyLgpd.Dashboard}
        somePermissions={[
          'view_dashboard',
          'view_dashboard_gap',
          'view_dashboard_process',
          'view_dashboard_rnc',
        ]}
        layout={MainLayout}
        auth
      />
      <RouteWithLayout
        path={routes.myLgpd.home}
        component={MyLgpd.Home}
        layout={MainLayout}
        auth
      />
      <RouteWithLayout
        provider={DataProcessOptionsProvider}
        path={routes.dataProcess.all}
        component={DataProcesses.Main}
        permission="list_data_processes"
        layout={MainLayout}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.audits.all}
        component={Audits.Main}
        permission={permission.DATA_PROCESSES.LIST}
        layout={MainLayout}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.audits.show}
        component={Audits.Show}
        permission={permission.DATA_PROCESSES.LIST}
        layout={MainLayout}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.audits.new}
        component={Audits.New}
        permission={permission.DATA_PROCESSES.LIST}
        layout={MainLayout}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.audits.edit}
        component={Audits.Edit}
        permission={permission.DATA_PROCESSES.LIST}
        layout={MainLayout}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.audits.automation}
        component={Audits.Automation}
        permission={permission.DATA_PROCESSES.LIST}
        layout={MainLayout}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.dataProcess.resume}
        component={DataProcesses.Resume}
        layout={MainLayout}
        exact
        auth
      />
      <RouteWithLayout
        provider={DataProcessOptionsProvider}
        path={routes.dataProcess.suggested}
        component={DataProcesses.Suggested}
        layout={MainLayout}
        permission="list_and_manage_suggested_data_processes"
        exact
        auth
      />
      <RouteWithLayout
        provider={DataProcessOptionsProvider}
        path={routes.dataProcess.view}
        component={DataProcesses.Details}
        layout={MainLayout}
        permission="list_data_processes"
        exact
        auth
      />
      <RouteWithLayout
        provider={DataProcessOptionsProvider}
        path={routes.dataProcess.suggestedChanges}
        component={DataProcesses.SuggestionChangesMain}
        layout={MainLayout}
        permission="create_data_process"
        exact
        auth
      />
      <RouteWithLayout
        path={routes.dataProcess.new}
        component={DataProcesses.New}
        provider={DataProcessProvider}
        layout={MainLayout}
        permission="create_data_process"
        exact
        auth
      />
      <RouteWithLayout
        provider={DataProcessOptionsProvider}
        path={routes.dataProcess.edit}
        component={DataProcesses.EditPage}
        layout={MainLayout}
        permission="create_data_process"
        exact
        auth
      />
      <RouteWithLayout
        provider={SuggestedChangeProvider}
        path={routes.dataProcess.viewSuggestions}
        component={DataProcesses.SuggestionChanges}
        layout={MainLayout}
        permission="create_data_process"
        exact
        auth
      />
      <RouteWithLayout
        path={routes.department.all}
        component={Departments.Main}
        layout={MainLayout}
        permission="list_departments"
        exact
        auth
      />
      <RouteWithLayout
        path={routes.department.new}
        component={Departments.New}
        layout={MainLayout}
        permission="create_department"
        exact
        auth
      />
      <RouteWithLayout
        path={routes.department.edit}
        component={Departments.Edit}
        layout={MainLayout}
        permission={permission.DEPARTMENTS.CREATE}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.privacyPolicy.all}
        component={PrivacyPolicies.Main}
        layout={MainLayout}
        permission="list_privacy_policy"
        exact
        auth
      />
      <RouteWithLayout
        path={routes.privacyPolicy.new}
        component={PrivacyPolicies.New}
        layout={MainLayout}
        permission="create_privacy_policy"
        exact
        auth
      />
      <RouteWithLayout
        path={routes.privacyPolicy.edit}
        component={PrivacyPolicies.Edit}
        layout={MainLayout}
        permission={permission.PRIVACY_POLICIES.CREATE}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.titularsSolicitations}
        component={TitularsSolicitations.Main}
        layout={MainLayout}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.user.all}
        component={Users.Main}
        layout={MainLayout}
        permission="list_users"
        exact
        auth
      />
      <RouteWithLayout
        path={routes.user.new}
        component={Users.New}
        layout={MainLayout}
        permission="create_user"
        exact
        auth
      />
      <RouteWithLayout
        path={routes.user.edit}
        component={Users.Edit}
        layout={MainLayout}
        permission="edit_user"
        exact
        auth
      />
      <RouteWithLayout
        path={routes.settings}
        component={Settings.SettingsMain}
        layout={MainLayout}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.documents}
        component={Documents.DocumentsMain}
        layout={MainLayout}
        permission={permission.DOCUMENT.LIST}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.documentsTemplate}
        component={DocumentsTemplate.DocumentsTemplateMain}
        layout={MainLayout}
        permission={permission.DOCUMENTS_TEMPLATE.LIST}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.questionnaire.all}
        component={Questionnaires.Main}
        layout={MainLayout}
        reduceMargin
        exact
        auth
      />
      <RouteWithLayout
        path={routes.questionnaire.reply}
        component={Questionnaires.Reply}
        layout={MainLayout}
        exact
        auth
      />

      <RouteWithLayout
        path={routes.companyFragilities.all}
        component={CompanyFragilities.CompanyFragilityMain}
        layout={MainLayout}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.companyFragilities.new}
        provider={CompanyFragilityProvider}
        component={CompanyFragilities.CompanyFragilityNew}
        layout={MainLayout}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.companyFragilities.edit}
        provider={CompanyFragilityProvider}
        component={CompanyFragilities.CompanyFragilityEdit}
        layout={MainLayout}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.incidents.all}
        component={Incidents.Main}
        layout={MainLayout}
        exact
        permission="list_incidents"
        auth
      />
      <RouteWithLayout
        path={routes.incidents.edit}
        provider={IncidentManegementProvider}
        component={IncidentManagements.Edit}
        layout={MainLayout}
        exact
        somePermissions={[
          permission.INCIDENTS.CREATE,
          permission.INCIDENTS.LIST,
        ]}
        auth
      />
      <RouteWithLayout
        path={routes.incidentManagements.new}
        provider={IncidentManegementProvider}
        component={IncidentManagements.New}
        layout={MainLayout}
        auth
      />
      <RouteWithLayout
        path={routes.privacyStamp}
        component={PrivacyStamps}
        layout={MainLayout}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.profiles.all}
        component={Profiles.Main}
        layout={MainLayout}
        exact
        permission={permission.PROFILES.LIST}
        auth
      />
      <RouteWithLayout
        path={routes.profiles.new}
        component={Profiles.New}
        layout={MainLayout}
        exact
        permission={permission.PROFILES.CREATE}
        auth
      />
      <RouteWithLayout
        path={routes.profiles.edit}
        component={Profiles.Edit}
        layout={MainLayout}
        exact
        permission={permission.PROFILES.EDIT}
        auth
      />
      <RouteWithLayout
        path={routes.marketplace.all}
        component={Marketplace.Main}
        layout={MainLayout}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.marketplace.services}
        component={Marketplace.Services}
        layout={MainLayout}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.marketplace.segments}
        component={Marketplace.Segments}
        layout={MainLayout}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.marketplace.show}
        component={Marketplace.Show}
        layout={MainLayout}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.marketplace.orders}
        component={Marketplace.Orders}
        layout={MainLayout}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.marketplace.products}
        component={Marketplace.ProductsAndServices}
        layout={MainLayout}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.supplier.all}
        component={Suppliers.SuppliersMain}
        permission={permission.COMPANY_SUPPLIERS.LIST_IAM_SUPPLIER}
        layout={MainLayout}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.mySuppliers.all}
        component={Suppliers.ClientsMain}
        permission={permission.COMPANY_SUPPLIERS.LIST_IAM_CLIENT}
        layout={MainLayout}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.supplier.show}
        component={Suppliers.SupplierShow}
        layout={MainLayout}
        reduceMargin
        exact
        auth
      />
      <RouteWithLayout
        path={routes.mySuppliers.show}
        component={Suppliers.ClientShow}
        layout={MainLayout}
        reduceMargin
        exact
        auth
      />
      <RouteWithLayout
        path={routes.mySuppliers.new}
        component={Suppliers.ClientNew}
        layout={MainLayout}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.mySuppliers.edit}
        component={Suppliers.ClientEdit}
        layout={MainLayout}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.mySuppliers.logs}
        component={Suppliers.Logs}
        layout={MainLayout}
        reduceMargin
        exact
        auth
      />
      <RouteWithLayout
        path={routes.supplier.logs}
        component={Suppliers.Logs}
        layout={MainLayout}
        reduceMargin
        exact
        auth
      />
      <RouteWithLayout
        path={routes.mySuppliers.logs}
        component={Suppliers.Logs}
        layout={MainLayout}
        reduceMargin
        exact
        auth
      />
      <RouteWithLayout
        path={routes.supplier.logs}
        component={Suppliers.Logs}
        layout={MainLayout}
        reduceMargin
        exact
        auth
      />
      <RouteWithLayout
        path={routes.subscription.default}
        component={Subscription}
        layout={PortalLayout}
        provider={SubscriptionProvider}
        exact
        redirect={false}
      />
      <RouteWithLayout
        path={routes.subscription.trial}
        component={SubscriptionCompany.Main}
        layout={CheckoutLayout}
        provider={SubscriptionProvider}
        exact
        redirect={false}
      />
      <RouteWithLayout
        path={routes.reports.management}
        component={Reports.MainManagement}
        layout={MainLayout}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.reports.control}
        component={Reports.MainControlReport}
        layout={MainLayout}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.reports.fragility}
        component={Reports.MainFragilityReport}
        layout={MainLayout}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.reports.ripd}
        component={Reports.MainRipd}
        layout={MainLayout}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.reports.ropa}
        component={Reports.MainRopa}
        layout={MainLayout}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.reports.accountability}
        component={Reports.MainAccountability}
        layout={MainLayout}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.reports.validate}
        component={Reports.ValidateReport}
        layout={JourneyStepsLayout}
      />
      <RouteWithLayout
        path={routes.reports.training}
        component={Reports.TrainingReport}
        layout={MainLayout}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.reports.lia}
        component={Reports.MainLia}
        layout={MainLayout}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.supplierSuggestions.all}
        component={Suppliers.SuggestionMain}
        permission={permission.SUPPLIER_SUGGESTIONS.LIST}
        layout={MainLayout}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.supplierInvites.all}
        component={Suppliers.SupplierInvites}
        layout={MainLayout}
        exact
        auth
      />
      {/* <RouteWithLayout
        path={routes.loginTransparentIntegration}
        component={LoginTransparentIntegration}
        layout={AuthLayout}
        exact
      /> */}

      <RouteWithLayout
        path={routes.cookies.banners}
        component={Cookies.Banners}
        layout={MainLayout}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.cookies.settings}
        component={Cookies.Settings}
        layout={MainLayout}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.cookies.consents}
        component={Cookies.Consents}
        layout={MainLayout}
        exact
        auth
      />

      <RouteWithLayout
        path={routes.lastUpdates.all}
        component={LastUpdates}
        permission={permission.LAST_UPDATE.LIST}
        layout={MainLayout}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.faqsDocumentations.all}
        component={FaqsDocumentations.main}
        permission={permission.FAQS_DOCUMENTATIONS.LIST}
        layout={MainLayout}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.faqsDocumentations.show}
        component={FaqsDocumentations.show}
        permission={permission.FAQS_DOCUMENTATIONS.LIST}
        layout={MainLayout}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.consent.all}
        component={Consent.ConsentMain}
        permission={permission.CONSENTS.LIST}
        layout={MainLayout}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.consent.new}
        component={Consent.ConsentNew}
        permission={permission.CONSENTS.CREATE}
        provider={ConsentProvider}
        layout={MainLayout}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.consent.show}
        component={Consent.ConsentShow}
        permission={permission.CONSENTS.SHOW}
        provider={ConsentProvider}
        layout={MainLayout}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.consent.consentReceipt}
        component={Consent.ConsentReceiptMain}
        permission={permission.CONSENT_RECEIPTS.LIST}
        layout={MainLayout}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.consent.consentReceiptNew}
        component={Consent.ConsentReceiptNew}
        permission={permission.CONSENTS.CREATE}
        layout={MainLayout}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.consent.consentReceiptDetails}
        component={Consent.ConsentReceiptDetails}
        permission={permission.CONSENTS.SHOW}
        layout={MainLayout}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.technicalFaqs.lgpdShow}
        component={TechnicalFaqs.LGPDShow}
        layout={MainLayout}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.technicalFaqs.lgpdMain}
        component={TechnicalFaqs.LGPDMain}
        layout={MainLayout}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.technicalFaqs.lgpdSpecificShow}
        component={TechnicalFaqs.LGPDShow}
        layout={MainLayout}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.technicalFaqs.lgpdTicket}
        component={TechnicalFaqs.PersonalizedService}
        layout={MainLayout}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.technicalFaqs.informationSecurityMain}
        component={TechnicalFaqs.InformationSecurityMain}
        layout={MainLayout}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.technicalFaqs.informationSecurityShow}
        component={TechnicalFaqs.InformationSecurityShow}
        layout={MainLayout}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.technicalFaqs.informationSecuritySpecificShow}
        component={TechnicalFaqs.InformationSecurityShow}
        layout={MainLayout}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.technicalFaqs.informationSecurityTicket}
        component={TechnicalFaqs.PersonalizedService}
        layout={MainLayout}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.technicalFaqs.tickets}
        component={TechnicalFaqs.Ticket}
        layout={MainLayout}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.technicalFaqs.ticketDetails}
        component={TechnicalFaqs.TicketDetails}
        layout={MainLayout}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.questionControls.all}
        component={QuestionControls.Main}
        permission={permission.DIAGNOSTICS.LIST}
        layout={MainLayout}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.questionControls.edit}
        component={QuestionControls.Edit}
        permission={permission.DIAGNOSTICS.UPDATE}
        layout={MainLayout}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.questionControls.show}
        component={QuestionControls.Show}
        layout={MainLayout}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.questionControls.new}
        component={QuestionControls.New}
        layout={MainLayout}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.questionControls.all}
        component={QuestionControls.Main}
        layout={MainLayout}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.questionControls.edit}
        component={QuestionControls.Edit}
        permission={permission.DIAGNOSTICS.UPDATE}
        layout={MainLayout}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.questionControls.show}
        component={QuestionControls.Show}
        layout={MainLayout}
        exact
        auth
      />
      <RouteWithLayout
        path={routes.questionControls.show}
        component={QuestionControls.Show}
        layout={MainLayout}
        exact
        auth
      />
      <Route component={NotFound} exact path={routes.notFound} />
      <Route component={Forbidden} exact path={routes.forbidden} />
      <Redirect to={routes.notFound} />
    </Switch>
  )
}

export const routes = {
  root: '/',

  forgotPassword: '/forgot',

  outsourcedDpoLogin: '/outsourced-dpo/:authToken/:companyId',
  dpoEasyLogin: '/dpo-easy/:authToken/:cookieToken',

  login: include('/login', {
    main: '',
  }),

  // loginTransparentIntegration: '/login/:integrationToken/:publicToken',

  twoFactorAuthentication: '/two-factor-authentication/:accessToken',

  selectCompany: '/select-company',

  newPassword: '/new-password/:reset_password_token?',

  userSteps: '/user-steps',

  supplierStep: '/supplier-step',

  userTrailSteps: '/user-trails',

  myLgpd: include('/my-lgpd', {
    dashboard: 'dashboard',
    home: 'home',
  }),

  supplierInvite: '/supplier-invite/:companyToken',

  dataProcess: include('/data-processes', {
    all: '',
    view: 'show/:dataProcessId',
    new: 'new',
    edit: ':dataProcessId/edit',
    resume: 'resume',
    suggested: 'suggested',
    suggestedChanges: 'suggestions',
    viewSuggestions: ':dataProcessId/suggestions',
  }),

  audits: include('/audits', {
    all: '',
    new: 'new',
    show: ':auditId/show',
    edit: ':auditId/edit',
    automation: ':auditConfigId/automation',
  }),

  department: include('/departments', {
    all: '',
    new: 'new',
    edit: ':departmentId/edit',
  }),

  user: include('/users', {
    all: '',
    new: 'new',
    edit: ':userId/edit',
    account: '/account',
  }),

  supplier: include('/suppliers', {
    all: '',
    show: ':supplierId',
    logs: ':supplierId/logs',
  }),

  mySuppliers: include('/my-suppliers', {
    all: '',
    new: 'new',
    show: ':supplierId/show',
    edit: ':supplierId/edit',
    logs: ':supplierId/logs',
  }),

  supplierSuggestions: include('/supplier-suggestions', {
    all: '',
  }),

  supplierInvites: include('/supplier-invites', {
    all: '',
  }),

  settings: '/settings',

  documents: '/documents',

  documentsTemplate: '/documents-template',

  privacyPolicy: include('/privacy-policies', {
    all: '',
    new: 'new',
    edit: ':privacyPolicyId/edit',
  }),

  titularsSolicitations: '/titulars-solicitations',

  marketplace: include('/marketplace', {
    all: '',
    services: 'services/:id',
    segments: 'segments/:id',
    products: 'products',
    show: 'show/:partnerServiceId',
    orders: 'orders',
  }),

  privacyStamp: '/privacy-portal/stamps',

  questionnaire: include('/questionnaires', {
    all: '',
    reply: ':questionnaireId/reply',
  }),

  companyFragilities: include('/fragilities', {
    all: '',
    new: 'new',
    edit: ':companyFragilityId/edit',
  }),

  incidents: include('/incidents', {
    all: '',
    new: 'new',
    edit: ':incidentId/edit',
    editComplete: ':incidentId/edit_complete',
  }),

  incidentManagements: include('/incident-managements', {
    all: '',
    new: 'new',
    edit: ':incidentManagementId/edit',
  }),

  profiles: include('/profiles', {
    all: '',
    new: 'new',
    edit: ':profileId/edit',
  }),

  subscription: include('/subscription', {
    default: '',
    trial: 'trial',
  }),

  reports: {
    accountability: '/accountability',
    all: '?chooseReport=true',
    control: '/control',
    fragility: '/fragility',
    management: '/management',
    reportRmc: '/rmcReport',
    ripd: '/ripd',
    ropa: '/ropa',
    training: '/training',
    validate: '/reports/validate/:token',
    lia: '/reports/lia',
  },

  cookies: include('/cookies-banner', {
    settings: ':id/settings',
    banners: '',
    consents: 'consents',
  }),

  lastUpdates: include('/last-updates', {
    all: '',
  }),

  technicalFaqs: include('/technical-faqs', {
    all: '',
    lgpdMain: 'lgpd',
    lgpdShow: 'lgpd/show/all',
    lgpdSpecificShow: 'lgpd/show/:subCategoryId',
    lgpdTicket: 'lgpd/ticket',
    informationSecurityMain: 'informationSecurity',
    informationSecurityShow: 'informationSecurity/show/all',
    informationSecuritySpecificShow: 'informationSecurity/show/:subCategoryId',
    informationSecurityTicket: 'informationSecurity/ticket',
    tickets: 'tickets',
    ticketDetails: 'tickets/:ticketId/details',
  }),

  consent: include('/consent', {
    all: '',
    new: 'new',
    show: ':consentFormId/show',
    consentReceipt: 'receipts',
    consentReceiptNew: 'receipts/new',
    consentReceiptDetails: ':consentFormId/receipts/:consentReceiptId',
  }),

  faqsDocumentations: include('/faqs-documentations', {
    all: '',
    show: 'show/:faqsDocumentationId',
  }),

  questionControls: include('/question-controls', {
    all: '',
    new: 'new',
    show: ':questionControlId/show',
    edit: ':questionControlId/edit',
  }),

  notFound: '/not-found',
  forbidden: '/forbidden',

  resume: '/resume',

  consultCertificate: '/certificate/:token',

  trainningJourney: '/trainning/:trainningJourneyId',
}

export default Routes
