import React, { useState } from 'react'
import PropTypes from 'prop-types'

import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
  Typography,
} from '@material-ui/core'

import { TomticketForm } from './components'
import { LoadingFeedback } from 'components'

import useSnackbar from 'hooks/useSnackbar'

import * as service from 'service'
import helpers from 'helpers'

const DialogTomticket = ({ open, setOpen }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [dataDocument, setDataDocument] = useState([])

  const handleClose = () => setOpen(false)

  const snackbar = useSnackbar()

  const handleCreate = async (data) => {
    try {
      setIsLoading(true)

      await service.dponet.tomticketIntermediator.createTicket({
        ...helpers.formatters.formatDataTomticket(data, dataDocument),
      })

      snackbar.open({
        message: 'Ticket enviado com sucesso!',
        variant: 'success',
      })
      setOpen(false)
    } catch (error) {
      snackbar.open({
        message:
          error?.response?.data?.error?.message ||
          'O sistema de abertura de tickets está indisponível no momento. Você pode entrar em contato através do e-mail atendimento@dponet.com.br',
        variant: 'error',
      })
    } finally {
      setIsLoading(false)
      setDataDocument([])
    }
  }

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle>
        <Box>
          <Typography variant="h4">Atendimento</Typography>
        </Box>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <LoadingFeedback open={isLoading} />
        <Box py={2.5}>
          <TomticketForm
            handleCreate={handleCreate}
            dataDocument={dataDocument}
            setDataDocument={setDataDocument}
          />
        </Box>
      </DialogContent>
      <Divider />
      <Box display="flex" justifyContent="flex-end" mr={2}>
        <DialogActions>
          <Box mr={2}>
            <Button
              type="button"
              variant="outlined"
              onClick={handleClose}
              component="a"
            >
              Voltar
            </Button>
          </Box>
          <Button
            variant="contained"
            type="submit"
            color="primary"
            form="tomticket-form"
          >
            Enviar
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  )
}

DialogTomticket.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func.isRequired,
}

DialogTomticket.defaultProps = {
  open: false,
  setOpen: () => {},
}

export default DialogTomticket
