import React, { Fragment } from 'react'
import { useFormContext } from 'react-hook-form'
import { MoreHorizontal as MoreHorizontalIcon } from 'react-feather'
import { useSlate } from 'slate-react'
import {
  Box,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
  Tooltip,
  Typography,
} from '@material-ui/core'

import validates from 'helpers/validates'

import useStyles from './styles'
import theme from 'theme'

const MoreOptionsMenu = ({
  hiddenGroups,
  handleClick,
  handleCloseMenu,
  anchorElState,
  controlName,
}) => {
  const classes = useStyles()
  const editor = useSlate()
  const { anchorEl, setAnchorEl } = anchorElState

  const { errors } = useFormContext()

  const handleOpenMenu = (event) => setAnchorEl(event.currentTarget)

  return (
    <Box display="flex">
      <Tooltip title="Exibir ou ocultar itens adicionais da barra de ferramentas">
        <IconButton className={classes.iconButton} onClick={handleOpenMenu}>
          <MoreHorizontalIcon />
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
      >
        {hiddenGroups?.map((group, index) => (
          <MenuList key={index}>
            {group?.map((element, elementIndex) => {
              const Logo = element?.Icon || Fragment
              const isActive =
                element.type === 'mark'
                  ? validates.slateJs.isMarkActive(editor, element.format)
                  : validates.slateJs.isBlockActive(editor, element.format)

              return (
                <MenuItem
                  key={elementIndex}
                  onMouseDown={(event) => {
                    event.preventDefault()
                    handleClick(element?.format, element?.type)
                  }}
                >
                  <ListItemIcon
                    style={{
                      color: isActive
                        ? errors?.[controlName]
                          ? theme.palette.error.main
                          : 'black'
                        : 'gray',
                    }}
                  >
                    {validates.slateJs.toolbarTitleLabel(element?.format) ? (
                      <Typography>
                        {validates.slateJs.toolbarTitleLabel(element?.format)}
                      </Typography>
                    ) : (
                      <Logo />
                    )}
                  </ListItemIcon>
                  <ListItemText>
                    <Typography style={{ fontWeight: isActive ? 900 : 400 }}>
                      {element?.description}
                    </Typography>
                  </ListItemText>
                  <Box
                    display="flex"
                    justifyContent="start"
                    minWidth={42}
                    ml={2}
                  >
                    <Typography variant="caption" color="text.secondary">
                      {element?.shortcutKey}
                    </Typography>
                  </Box>
                </MenuItem>
              )
            })}
          </MenuList>
        ))}
      </Menu>
    </Box>
  )
}

export default MoreOptionsMenu
