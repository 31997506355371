import React from 'react'
import PropTypes from 'prop-types'

import { useHistory } from 'react-router-dom'

import usePermission from 'hooks/usePermissions'

import { Box, Card } from '@material-ui/core'

import PerfectScrollbar from 'react-perfect-scrollbar'

import { routes } from 'Routes'
import { reverse } from 'named-urls'

import { DataProcessStatusChip } from 'views/DataProcesses/components/index'
import { DataProcessFragilityChip } from 'components'

import constants from 'constants/index'
import helpers from 'helpers'

import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
} from 'components/Table'

import useStyles from './styles'

const DataProcessRow = ({ dataProcess }) => {
  const history = useHistory()
  const { some } = usePermission()

  const permitRedirectToShow = () => {
    return some([
      constants.permissions.DATA_PROCESSES.LIST,
      constants.permissions.DATA_PROCESSES.CREATE,
    ])
  }

  const handleGoView = (dataProcessId) => {
    if (permitRedirectToShow)
      history.push(
        reverse(routes.dataProcess.view, { dataProcessId: dataProcessId }),
      )
  }

  return (
    <TableRow
      onClick={() => handleGoView(dataProcess.id)}
      pointer="true"
      key={dataProcess.id}
    >
      <TableCell>{dataProcess.id}</TableCell>
      <TableCell>{dataProcess.name}</TableCell>
      <TableCell>{dataProcess.company.name}</TableCell>
      <TableCell>{dataProcess.department.name}</TableCell>
      <TableCell disableTooltip>
        <DataProcessFragilityChip
          title={helpers.dataProcess.fragilityLabel(
            dataProcess?.adoptedFragilityId,
          )}
          fragilityId={dataProcess?.adoptedFragilityId}
        />
      </TableCell>
      <TableCell>{`${helpers.formatters.date(
        dataProcess.updatedAt,
      )} - ${helpers.formatters.hours(dataProcess.updatedAt)}`}</TableCell>
      <TableCell disableTooltip>
        <DataProcessStatusChip
          title={helpers.dataProcess.status(dataProcess.statusId)}
          statusId={dataProcess.statusId}
        />
      </TableCell>
    </TableRow>
  )
}

DataProcessRow.propTypes = {
  dataProcess: PropTypes.object.isRequired,
}

const DataProcessTable = ({ dataProcesses, isLoading }) => {
  const classes = useStyles()

  return (
    <Box className={classes.root} width="100%">
      <Card>
        <PerfectScrollbar
          options={{ wheelPropagation: false, useBothWheelAxes: true }}
        >
          <Table
            emptyMessage="Nenhum processamento de dados encontrada"
            isLoading={isLoading}
            noWrap
          >
            <TableHead>
              <TableRow>
                <TableCell width="10%">Identificador</TableCell>
                <TableCell width="20%">Nome</TableCell>
                <TableCell width="20%">Empresa</TableCell>
                <TableCell width="20%">Departamento</TableCell>
                <TableCell width="10%">Risco</TableCell>
                <TableCell width="10%">Ultima atualização</TableCell>
                <TableCell width="10%">Status</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {dataProcesses?.map((dataProcess) => (
                <DataProcessRow
                  dataProcess={dataProcess}
                  key={dataProcess.id}
                />
              ))}
            </TableBody>
          </Table>
        </PerfectScrollbar>
      </Card>
    </Box>
  )
}

DataProcessTable.propTypes = {
  dataProcesses: PropTypes.object,
  isLoading: PropTypes.bool,
  origin: PropTypes.string,
  onEvent: PropTypes.func,
  showDetails: PropTypes.bool,
  setIsLoading: PropTypes.func,
  defaultStatus: PropTypes.number,
}

DataProcessTable.defaultProps = {
  onEvent: () => {},
  showDetails: true,
  setIsLoading: () => {},
}

export default DataProcessTable
