import React from 'react'
import { useFormContext } from 'react-hook-form'
import { Button, Grid, Link, Paper, Typography } from '@material-ui/core'

import helpers from 'helpers'

import styles from './styles'

const BannerPreview = () => {
  const { watch } = useFormContext()

  const primaryColor = helpers.palette.validateColor(watch('primaryColor'))
  const secondaryColor = helpers.palette.validateColor(watch('secondaryColor'))
  const calculatedSecondaryColor =
    helpers.palette.calculateColors(secondaryColor)
  const secondaryBGColor = helpers.palette.hexToRgba(secondaryColor, 0.06)

  const useStyles = styles({
    primaryColor,
    secondaryColor,
    secondaryBGColor,
    calculatedSecondaryColor,
  })
  const classes = useStyles()

  const description = watch('description')

  return (
    <Paper variant="outlined" className={classes.paperContainer}>
      <Typography className={classes.title}>
        Controle sua privacidade
      </Typography>
      <Typography className={classes.globalTypography}>
        {description}
      </Typography>
      <Link
        href="#"
        variant="body1"
        underline="always"
        className={classes.globalTypography}
      >
        Política de Cookies
      </Link>
      <Grid container justifyContent="flex-end" spacing={2}>
        <Grid item xs={12} lg={4}>
          <Button className={classes.settingsButton} variant="text" fullWidth>
            Gerenciar cookies
          </Button>
        </Grid>
        <Grid item xs={12} lg={4}>
          <Button className={classes.rejectButton} variant="outlined" fullWidth>
            Rejeitar
          </Button>
        </Grid>
        <Grid item xs={12} lg={4}>
          <Button
            className={classes.acceptButton}
            variant="contained"
            color="primary"
            fullWidth
          >
            Aceitar Todos
          </Button>
        </Grid>
      </Grid>
    </Paper>
  )
}

export default BannerPreview
