import { useContext } from 'react'

import DataProcessContext from 'contexts/DataProcessContext'

const useDataProcess = () => {
  const context = useContext(DataProcessContext)

  if (context === undefined) {
    throw new Error(
      'useDataProcess must be used within a DataProcessContext.Provider',
    )
  }

  const toStep = (step, activeStep) => {
    if (step < activeStep) {
      context.setActiveStep(step)
    }
  }

  const nextStep = () => {
    context.setActiveStep((prevActiveStep) => prevActiveStep + 1)
    if (context.lastActiveStep === context.activeStep) {
      context.setLastActiveStep((prevActiveStep) => prevActiveStep + 1)
    }
  }

  const backStep = () => {
    context.setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  return {
    laws: context.laws,
    code: context.code,
    setCode: context.setCode,
    activeStep: context.activeStep,
    lastActiveStep: context.lastActiveStep,
    setLastActiveStep: context.setLastActiveStep,
    setActiveStep: context.setActiveStep,
    data: context.data,
    setData: context.setData,
    toNextStep: context.toNextStep,
    setToNextStep: context.setToNextStep,
    loadOptions: context.loadOptions,
    reloadOptions: context.reloadOptions,
    counter: context.counter,
    departments: context.departments,
    storageLocations: context.storageLocations,
    storageTimes: context.storageTimes,
    discardModes: context.discardModes,
    internalAllocationModes: context.internalAllocationModes,
    recoveries: context.recoveries,
    shareProcesses: context.shareProcesses,
    protections: context.protections,
    dataCollectedOptions: context.dataCollectedOptions,
    legalFrameworks: context.legalFrameworks,
    titularCategories: context.titularCategories,
    dataProcessSources: context.dataProcessSources,
    sourceOptions: context.sourceOptions,
    setSourceOptions: context.setSourceOptions,
    loadingReplyLiaAnswer: context.loadingReplyLiaAnswer,
    setLoadingReplyLiaAnswer: context.setLoadingReplyLiaAnswer,
    toStep,
    nextStep,
    backStep,
  }
}

export default useDataProcess
