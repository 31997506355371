import React from 'react'
import { Box, Card, Link, Typography } from '@material-ui/core'

import helpers from 'helpers'

import theme from 'theme'
import useStyles from './styles'
import constants from 'constants/index'

const PaymentCard = ({ creditCard }) => {
  const classes = useStyles()

  const Logo = helpers.validates.cardFlags.cardFlagDefinitionByLabel(
    creditCard?.brand,
  )

  return (
    <Card
      component={Box}
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      p={2}
    >
      <Box display="flex" gridGap={theme.spacing(3)} alignItems="center">
        <Box
          width={54}
          height={33.6}
          className={{
            [classes.amexBox]:
              creditCard?.brand === constants.cardFlag.AMEX_LABEL_ID,
          }}
        >
          <Logo width="100%" height="100%" />
        </Box>
        <Box display="flex" flexDirection="column">
          <Typography>
            {creditCard?.brand} {creditCard?.maskedCard}
          </Typography>
          <Typography variant="caption" color="textSecondary">
            Expira em {creditCard?.expiration}
          </Typography>
        </Box>
      </Box>
      {/* Sera implementado na v2 da issue 2533 */}
      {/* <Link variant="body1" underline="always" className={classes.link}>
        Cobrar novamente
      </Link> */}
    </Card>
  )
}

export default PaymentCard
