import api from 'service/api'

const dponetAPI = api.create('dponet')

const get = async ({ incidentId = '', ...params }) => {
  return await dponetAPI.get(`/incidents/${incidentId}`, { params })
}

const create = async (data) => {
  return await dponetAPI.post(`/incidents`, data)
}

const put = async ({ incidentId = '', ...data }) => {
  return await dponetAPI.put(`/incidents/${incidentId}`, data)
}

const linkProcess = async ({ incidentId, dataProcessId }) => {
  return await dponetAPI.post(
    `/incidents/${incidentId}/link_data_process/${dataProcessId}`,
  )
}

const unlinkProcess = async ({ incidentId, dataProcessId }) => {
  return await dponetAPI.delete(
    `/incidents/${incidentId}/unlink_data_process/${dataProcessId}`,
  )
}

const logs = async ({ incidentId }) => {
  return await dponetAPI.get(`/incidents/${incidentId}/logs`)
}

const options = async ({ ...params }) => {
  return await dponetAPI.get('/incident_options', { params })
}

const addStepRisk = async ({ incidentId = '', ...data }) => {
  return await dponetAPI.put(`/risk_consequence/${incidentId}`, data)
}

const addStepSecurity = async ({ incidentId = '', ...data }) => {
  return await dponetAPI.put(
    `/technical_administrative_security/${incidentId}`,
    data,
  )
}

const createMinimal = async (data) => {
  return await dponetAPI.post(`/incidents/minimal`, data)
}

const concludeStep = async ({ incidentId = '', step = '' }) => {
  return await dponetAPI.put(`/incidents/${incidentId}/steps/${step}/conclude`)
}

const incidents = {
  get,
  create,
  put,
  linkProcess,
  unlinkProcess,
  logs,
  options,
  addStepRisk,
  addStepSecurity,
  createMinimal,
  concludeStep,
}

export default incidents
