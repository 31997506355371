import { colors } from '@material-ui/core'
import dataProcessConstants from 'constants/dataProcess'

const PREVIEW_STATUS_ID = 'envisaged'
const PROGRESS_STATUS_ID = 'in_progress'
const FINALIZED_STATUS_ID = 'finalized'
const OVERDUE_STATUS_ID = 'overdue'
const INACTIVE_STATUS_ID = 'inactive'
const ACTIVE_STATUS_ID = 'active'

const PREVIEW_STATUS_LABEL = 'Prevista'
const PROGRESS_STATUS_LABEL = 'Em Andamento'
const FINALIZED_STATUS_LABEL = 'Finalizada'
const OVERDUE_STATUS_LABEL = 'Atrasada'
const INACTIVE_STATUS_LABEL = 'Inativa'

const PROGRAMMED_KIND = 'programmed'
const MANUAL_KIND = 'manual'

const APPLICATION_CHOICE_COMPANY_ID = 'company'
const APPLICATION_CHOICE_DEPARTMENTS_ID = 'departments'

const TABS = [
  { name: 'Todas', value: null },
  { name: PREVIEW_STATUS_LABEL, value: PREVIEW_STATUS_ID },
  { name: PROGRESS_STATUS_LABEL, value: PROGRESS_STATUS_ID },
  { name: OVERDUE_STATUS_LABEL, value: OVERDUE_STATUS_ID },
  { name: INACTIVE_STATUS_LABEL, value: INACTIVE_STATUS_ID },
  { name: FINALIZED_STATUS_LABEL, value: FINALIZED_STATUS_ID },
]

const KIND_TRANSLATION = {
  [PROGRAMMED_KIND]: 'Programada',
  [MANUAL_KIND]: 'Manual',
}

const CHIP_STATUS_LABEL = {
  [PREVIEW_STATUS_ID]: PREVIEW_STATUS_LABEL,
  [PROGRESS_STATUS_ID]: PROGRESS_STATUS_LABEL,
  [FINALIZED_STATUS_ID]: FINALIZED_STATUS_LABEL,
  [OVERDUE_STATUS_ID]: OVERDUE_STATUS_LABEL,
  [INACTIVE_STATUS_ID]: INACTIVE_STATUS_LABEL,
}

const CHIP_STATUS_COLOR = {
  [PREVIEW_STATUS_ID]: colors.lightBlue[800],
  [PROGRESS_STATUS_ID]: colors.orange[800],
  [FINALIZED_STATUS_ID]: colors.green[800],
  [OVERDUE_STATUS_ID]: colors.red[800],
  [INACTIVE_STATUS_ID]: colors.blueGrey[800],
}

const CHIP_STATUS_BACKGROUND_COLOR = {
  [PREVIEW_STATUS_ID]: colors.lightBlue[100],
  [PROGRESS_STATUS_ID]: colors.orange[100],
  [FINALIZED_STATUS_ID]: colors.green[100],
  [OVERDUE_STATUS_ID]: colors.red[100],
  [INACTIVE_STATUS_ID]: colors.blueGrey[100],
}

const KEYBOARD_DATE_PICKER_CONTROL_FORM = [
  {
    name: 'startDate',
    label: 'Data de início',
  },
  {
    name: 'endDate',
    label: 'Data de fim',
  },
]

const DATA_PROCESS_CHIP_STATUS_LABEL = {
  [dataProcessConstants.PENDING_STATUS]: 'Pendente',
  [dataProcessConstants.REVIEWING]: 'Aguardando Revisão',
  [dataProcessConstants.WAITING_REVIEW_STATUS]: 'Aguardando Revisão',
  [dataProcessConstants.DISAPPROVED_STATUS]: 'Reprovado',
  [dataProcessConstants.APPROVED_STATUS]: 'Aprovado',
  [dataProcessConstants.INACTIVE]: 'Inativo',
}

const DATA_PROCESS_CHIP_STATUS_COLOR = {
  [dataProcessConstants.PENDING_STATUS]: colors.lightBlue[800],
  [dataProcessConstants.REVIEWING]: colors.orange[800],
  [dataProcessConstants.WAITING_REVIEW_STATUS]: colors.orange[800],
  [dataProcessConstants.DISAPPROVED_STATUS]: colors.red[800],
  [dataProcessConstants.APPROVED_STATUS]: colors.green[800],
  [dataProcessConstants.INACTIVE]: colors.blueGrey[800],
}

const DATA_PROCESS_CHIP_STATUS_BACKGROUND_COLOR = {
  [dataProcessConstants.PENDING_STATUS]: colors.lightBlue[100],
  [dataProcessConstants.REVIEWING]: colors.orange[100],
  [dataProcessConstants.WAITING_REVIEW_STATUS]: colors.orange[100],
  [dataProcessConstants.DISAPPROVED_STATUS]: colors.red[100],
  [dataProcessConstants.APPROVED_STATUS]: colors.green[100],
  [dataProcessConstants.INACTIVE]: colors.blueGrey[100],
}

const APPLICATION_CHOICE_FORM_CONTROL_LABEL = [
  {
    label: 'Toda a empresa',
    value: APPLICATION_CHOICE_COMPANY_ID,
  },
  {
    label: 'Departamentos específicos',
    value: APPLICATION_CHOICE_DEPARTMENTS_ID,
  },
]

export default {
  ACTIVE_STATUS_ID,
  APPLICATION_CHOICE_COMPANY_ID,
  APPLICATION_CHOICE_DEPARTMENTS_ID,
  APPLICATION_CHOICE_FORM_CONTROL_LABEL,
  CHIP_STATUS_BACKGROUND_COLOR,
  CHIP_STATUS_COLOR,
  CHIP_STATUS_LABEL,
  DATA_PROCESS_CHIP_STATUS_BACKGROUND_COLOR,
  DATA_PROCESS_CHIP_STATUS_COLOR,
  DATA_PROCESS_CHIP_STATUS_LABEL,
  FINALIZED_STATUS_ID,
  INACTIVE_STATUS_ID,
  KEYBOARD_DATE_PICKER_CONTROL_FORM,
  KIND_TRANSLATION,
  PREVIEW_STATUS_ID,
  PROGRAMMED_KIND,
  TABS,
}
