import React, { Fragment } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { Box, Divider, TextField, useMediaQuery } from '@material-ui/core'

import theme from 'theme'

const DescriptionCustomization = () => {
  const { control, errors } = useFormContext()

  const lgUp = useMediaQuery(theme.breakpoints.up('lg'), {
    defaultMatches: true,
  })

  return (
    <Fragment>
      <Box display="flex" width="100%">
        <Controller
          as={
            <TextField
              label="Descrição"
              error={!!errors?.description}
              helperText={errors?.description?.message}
              type="text"
              minRows={11}
              maxRows={11}
              multiline
              fullWidth
            />
          }
          control={control}
          name="description"
        />
      </Box>
      {lgUp && <Divider orientation="vertical" variant="middle" />}
    </Fragment>
  )
}

export default DescriptionCustomization
