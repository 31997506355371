import moment from 'moment'

const demoEndDateFormatter = (demoEndDate) => {
  const dateNow = moment().format('YYYY-MM-DD')
  const endDate = moment(demoEndDate).format('YYYY-MM-DD')
  const diffDate = moment(endDate).diff(moment(dateNow))
  const duration = moment.duration(diffDate)

  const isMonths = duration._data.months > 0
  const isDays = duration._data.days > 0
  const isMonthsAndDays = isMonths && isDays

  const months = isMonths ? `${duration._data.months} mes(es)` : ''
  const days = isDays ? `${duration._data.days} dia(s)` : ''
  const separate = isMonthsAndDays ? ' e ' : ''

  return `A versão de teste expira em ${months}${separate}${days}!`
}

export default demoEndDateFormatter
