import React from 'react'
import { routes } from 'Routes'
import PropTypes from 'prop-types'
import { reverse } from 'named-urls'
import { useHistory } from 'react-router-dom'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { Box, Typography, Button } from '@material-ui/core'

import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from 'components/Table'
import { MenuButton, Permitted } from 'components'

import helpers from 'helpers'

import constants from 'constants/index'

import styles from './styles'
import permissions from 'constants/permissions'

const ConsentTableRow = ({ consent, refresh = () => {}, ...rest }) => {
  const history = useHistory()
  const classes = styles()

  const redirect = (consentFormId) => {
    history.push(
      reverse(routes.consent.show, {
        consentFormId,
      }),
    )
  }

  return (
    <>
      <TableRow {...rest}>
        <TableCell>
          <Typography className={classes.rowText}>{consent?.id}</Typography>
        </TableCell>
        <TableCell>
          <Typography className={classes.rowText}>{consent?.title}</Typography>
        </TableCell>
        <TableCell>
          <Typography className={classes.rowText}>
            {helpers.formatters.date(consent?.updatedAt) || '-'}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography>{consent?.author?.name}</Typography>
        </TableCell>
        <TableCell>
          <Typography className={classes.rowText}>
            {constants.consent.STATUS_lABEL[consent?.status]}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography className={classes.rowText}>
            {(consent?.answersCollected?.total ?? '0') +
              '/' +
              (consent?.answersCollected?.revoked ?? '0')}
          </Typography>
        </TableCell>
        <Permitted tag={permissions.CONSENTS.SHOW}>
          <TableCell align="right">
            <MenuButton>
              <Button
                color="secondary"
                onClick={() => redirect(consent?.id)}
                fullWidth
                size="small"
              >
                Ver detalhes
              </Button>
            </MenuButton>
          </TableCell>
        </Permitted>
      </TableRow>
    </>
  )
}

const ConsentTable = ({ consents, refresh = () => {} }) => {
  const classes = styles()

  return (
    <>
      <Box width="100%">
        <PerfectScrollbar>
          <Table
            size="small"
            emptyMessage="Nenhum formulário encontrado"
            noWrap
          >
            <TableHead>
              <TableRow>
                <TableCell width="10%">
                  <Box my={1}>
                    <Typography className={classes.headText}>ID</Typography>
                  </Box>
                </TableCell>
                <TableCell width="15%">
                  <Typography className={classes.headText}>Título</Typography>
                </TableCell>
                <TableCell width="15%">
                  <Typography className={classes.headText}>
                    Última Atualização
                  </Typography>
                </TableCell>
                <TableCell width="15%">
                  <Typography className={classes.headText}>
                    Criado por
                  </Typography>
                </TableCell>
                <TableCell width="15%">
                  <Typography className={classes.headText}>Status</Typography>
                </TableCell>
                <TableCell width="15%">
                  <Typography className={classes.headText}>
                    Coletados/Revogados
                  </Typography>
                </TableCell>
                <Permitted tag={permissions.CONSENTS.SHOW}>
                  <TableCell align="right">
                    <Typography className={classes.headText}>Ações</Typography>
                  </TableCell>
                </Permitted>
              </TableRow>
            </TableHead>
            <TableBody>
              {consents?.map((consent) => (
                <ConsentTableRow
                  consent={consent}
                  key={consent?.id}
                  refresh={refresh}
                />
              ))}
            </TableBody>
          </Table>
        </PerfectScrollbar>
      </Box>
    </>
  )
}

ConsentTableRow.propTypes = {
  consent: PropTypes.object,
  refresh: PropTypes.func,
}

ConsentTable.propTypes = {
  consents: PropTypes.array,
  refresh: PropTypes.func,
}

export default ConsentTable
