import React from 'react'
import PropTypes from 'prop-types'
import useSnackbar from 'hooks/useSnackbar'
import useDataLifeCycle from 'hooks/useDataLifeCycle'
import useDataProcessOptions from 'hooks/useDataProcessOptions'

import {
  Modal,
  Grid,
  Typography,
  Paper,
  IconButton as IconButtonMaterial,
} from '@material-ui/core'

import FormModal from './Form'

import CloseIcon from '@material-ui/icons/Close'

const DataLifeCycleModal = ({
  open,
  dataProcessId,
  setOpen = () => {},
  dataProcess,
  classes = {},
  ...props
}) => {
  const snackbar = useSnackbar()
  const dataLifeCycleHook = useDataLifeCycle()
  const { loadData: loadDataProcessOption } = useDataProcessOptions()

  const submitSuccess = async () => {
    snackbar.open({
      message: `Salvaguarda e ciclo de vida ${
        props?.dataLifeCycle ? 'atualizadas' : 'criada'
      } com sucesso`,
      variant: 'success',
    })
    setOpen(false)
    await loadDataProcessOption()
    dataLifeCycleHook.loadDataLifeCycle(dataProcessId)
  }

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      className={classes.modal}
      aria-labelledby="modal-collected-field"
      aria-describedby="modal-collected-field-description"
      disableAutoFocus
    >
      <Paper className={classes.paperModal}>
        <Grid container justify="space-between" alignItems="center">
          <Typography variant="h4" id="modal-collected-field">
            {props?.dataLifeCycle ? 'Editar' : 'Adicionar'} salvaguarda e ciclo
            de vida
          </Typography>
          <IconButtonMaterial
            onClick={() => setOpen(false)}
            color="primary"
            aria-label="close modal"
          >
            <CloseIcon />
          </IconButtonMaterial>
        </Grid>
        <FormModal
          dataLifeCycle={props?.dataLifeCycle}
          handleCloseModal={() => setOpen(false)}
          dataProcessId={dataProcessId}
          submitSuccess={submitSuccess}
          dataProcess={dataProcess}
        />
      </Paper>
    </Modal>
  )
}

DataLifeCycleModal.propTypes = {
  dataProcessId: PropTypes.number.isRequired,
  open: PropTypes.bool,
  setOpen: PropTypes.func.isRequired,
  dataProcess: PropTypes.string.isRequired,
}

export default DataLifeCycleModal
