import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { Box, Container, Typography } from '@material-ui/core'

import LoginForm from './components/Form'
import Page from 'components/Page'

import useSnackbar from 'hooks/useSnackbar'

import useStyles from './styles'

const Login = () => {
  const classes = useStyles()
  const location = useLocation()
  const snackbar = useSnackbar()
  const locationState = location.state
  const message = locationState
    ? locationState.error || locationState.success
    : ''

  useEffect(() => {
    if (locationState) {
      if (locationState.error) {
        snackbar.open({ message: message, variant: 'error' })
      } else {
        snackbar.open({ message: message, variant: 'success' })
      }
    }
    // eslint-disable-next-line
  }, [message, locationState])

  return (
    <Page
      className={classes.root}
      title="App DPOnet - Acesse a plataforma - LGPD e gestão de dados"
    >
      <Container maxWidth="xs" fullWidth>
        <Typography
          variant="h3"
          color="textPrimary"
          className={classes.welcomeText}
        >
          Bem-vindo(a)!
        </Typography>
        <Box mt={3}>
          <LoginForm />
        </Box>
      </Container>
    </Page>
  )
}

export default Login
