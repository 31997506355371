import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'

import helpers from 'helpers'

const styles = ({
  primaryColor,
  secondaryColor,
  secondaryBGColor,
  calculatedSecondaryColor,
}) =>
  makeStyles((theme) => ({
    paperContainer: {
      display: 'flex',
      flexDirection: 'column',
      textAlign: 'justify',
      width: '100%',
      padding: theme.spacing(2),
      gridGap: theme.spacing(1.5),
    },
    title: {
      fontSize: 24,
      fontWeight: 600,
      fontFamily: 'Arial',
      color: helpers.palette.validateColor(primaryColor),
    },
    globalTypography: {
      fontFamily: 'Arial',
      width: 'fit-content',
      color: helpers.palette.validateColor(primaryColor),
    },
    link: {
      color: helpers.palette.validateColor(primaryColor),
      width: 'fit-content',
    },
    settingsButton: {
      fontFamily: 'Arial',
      textTransform: 'initial',
      color: helpers.palette.validateColor(secondaryColor),
      '& > span': {
        fontWeight: 400,
      },
      '&:hover': {
        backgroundColor: helpers.palette.validateColor(secondaryBGColor),
      },
    },
    rejectButton: {
      fontFamily: 'Arial',
      boxShadow: 'none',
      color: helpers.palette.validateColor(secondaryColor),
      borderColor: helpers.palette.validateColor(calculatedSecondaryColor)
        ?.light,
      '& > span': {
        fontWeight: 400,
      },
      '&:hover': {
        borderColor: helpers.palette.validateColor(
          calculatedSecondaryColor?.dark,
        ),
        backgroundColor: helpers.palette.validateColor(secondaryBGColor),
      },
    },
    acceptButton: {
      fontFamily: 'Arial',
      backgroundColor: helpers.palette.validateColor(secondaryColor),
      color: helpers.palette.validateColor(
        calculatedSecondaryColor?.contrastText,
      ),
      '& > span': {
        fontWeight: 400,
      },
      '&:hover': {
        backgroundColor: helpers.palette.validateColor(
          calculatedSecondaryColor?.dark,
        ),
      },
    },
  }))

styles.propTypes = {
  primaryColor: PropTypes.string.isRequired,
  secondaryColor: PropTypes.string.isRequired,
  secondaryBGColor: PropTypes.string.isRequired,
  calculatedSecondaryColor: PropTypes.string.isRequired,
}

export default styles
