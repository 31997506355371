import React, { useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { Loader as LoaderIcon } from 'react-feather'
import PropTypes from 'prop-types'
import {
  Box,
  Button,
  Divider,
  Grid,
  Paper,
  Tooltip,
  Typography,
} from '@material-ui/core'

import { LoadingFeedback } from 'components'
import { DetailsReadingCard, ScannerCard } from './components'
import BannerHeadCard from '../BannerHeadCard'

import helpers from 'helpers'

import useSnackbar from 'hooks/useSnackbar'

import theme from 'theme'
import * as service from 'service'
import constants from 'constants/index'

const CookieValidationCard = ({ cookies, banner, refresh }) => {
  const [loading, setLoading] = useState(false)

  const snackbar = useSnackbar()
  const { watch } = useFormContext()

  const url = watch('url')

  const disableScanner = banner?.url !== url
  const loadingScanner =
    loading ||
    banner?.scanner?.status ===
      constants.cookies.banner.INITIATED_SCANNER_STATUS_ID

  const handleScan = async () => {
    try {
      setLoading(true)
      await service.cookies.banner.scanner({ bannerId: banner?.id })
      snackbar.open({
        message:
          'Escaneamento iniciado com sucesso! Isso pode levar alguns segundos.',
        variant: 'success',
      })
      refresh()
    } catch (error) {
      snackbar.open({
        message: helpers.formatters.errorMessage(error?.response?.data?.error),
        variant: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <Paper variant="outlined">
      <BannerHeadCard name="Validação do Cookie" stage="04" />
      <LoadingFeedback open={loading} />
      <Divider />
      <Box
        p={2}
        display="flex"
        flexDirection="column"
        gridGap={theme.spacing(2)}
      >
        <Box
          display="flex"
          flexDirection="column"
          gridGap={theme.spacing(2)}
          id={constants.cookies.banner.COOKIES_BANNER_SETTING_DRIVER_STEP_5}
        >
          <Box
            p={2}
            bgcolor={theme.palette.custom.light}
            borderRadius={1}
            textAlign="justify"
          >
            <Typography color="primary">
              Verifique o código do seu site clicando no botão "Escanear". Nosso
              sistema analisará automaticamente o código em busca de erros ou
              inconsistências. Aguarde o feedback que indicará qualquer problema
              ou confirmará que tudo está correto.
            </Typography>
          </Box>
          <Box>
            <Typography color="primary" variant="h6" gutterBottom>
              Detalhes da Leitura
            </Typography>
            <DetailsReadingCard scanner={banner?.scanner} />
          </Box>
          <Grid container justifyContent="flex-end">
            <Grid item xs={12} md={3} lg={2} xl={1}>
              <Tooltip
                title={
                  (disableScanner &&
                    'Salve as alterações de "Configuração do Banner" antes de prosseguir com o escaneamento.') ||
                  (loadingScanner &&
                    'Atualize a página para verificar se o escaneamento foi concluído.')
                }
              >
                <Box>
                  <Button
                    onClick={handleScan}
                    variant="contained"
                    color="primary"
                    startIcon={<LoaderIcon size={18} />}
                    disabled={disableScanner || loadingScanner}
                    fullWidth
                  >
                    Escanear
                  </Button>
                </Box>
              </Tooltip>
            </Grid>
          </Grid>
        </Box>
        <Box>
          <ScannerCard cookies={cookies} />
        </Box>
      </Box>
    </Paper>
  )
}

CookieValidationCard.propTypes = {
  banner: PropTypes.object,
  cookies: PropTypes.arrayOf(PropTypes.object),
  refresh: PropTypes.func.isRequired,
}

export default CookieValidationCard
