import React, { useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useForm, Controller } from 'react-hook-form'
import {
  Box,
  Button,
  Grid,
  Link,
  TextField,
  Typography,
} from '@material-ui/core'

import {
  AttachmentTable,
  Label,
  LoadingFeedback,
  Select as SelectComponent,
} from 'components'

import useSnackbar from 'hooks/useSnackbar'

import { routes } from 'Routes'
import * as service from 'service'
import constants from 'constants/index'
import schema from './schema'
import useStyles from './styles'
import helpers from 'helpers'

const PersonalizedServiceForm = () => {
  const snackbar = useSnackbar()
  const classes = useStyles()
  const history = useHistory()
  const location = useLocation()

  const [isLoading, setIsLoading] = useState(false)
  const [files, setFiles] = useState([])

  const { control, handleSubmit, errors, watch } = useForm({
    validationSchema: schema,
    defaultValues: {
      title: '',
      content: '',
      anotherTitle: '',
      visibilityType: 'private_type',
    },
  })

  const ticketSubject = constants.technicalFaqs.TICKET_SUBJECT
  const visibilityTypeOptions = constants.technicalFaqs.VISIBILITY_TYPE

  const handleGoBack = () => {
    history.push(routes.technicalFaqs.lgpdMain)
  }

  const handleChangeInputFile = (event) => {
    const eventFiles = event.target.files
    const attachments = [...files, ...eventFiles]
    setFiles(attachments)
  }

  const handleRemoveFile = (index) => {
    const attachments = files.filter((_, i) => i !== index)
    setFiles(attachments)
  }

  const onSubmit = async (data = {}) => {
    try {
      setIsLoading(true)

      const formData = helpers.tickets.mount(data, files, location.pathname)

      await service.dponet.tickets.create(formData)

      snackbar.open({
        message: 'Ticket enviado com sucesso!',
        variant: 'success',
      })
      history.push(routes.technicalFaqs.tickets)
    } catch (error) {
      snackbar.open({
        message:
          helpers.formatters.errorMessage(
            error?.response?.data?.error,
            false,
          ) ||
          'O sistema de de tickets está indisponível no momento. Você pode entrar em contato através do e-mail atendimento@dponet.com.br',
        variant: 'error',
      })
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Box className={classes.containerBox}>
      <LoadingFeedback open={isLoading} />
      <form onSubmit={handleSubmit(onSubmit)} id="knowledge-base-ticket">
        <Grid container>
          <Grid item xs={12}>
            <Label description="Visibilidade" item xs={12} />
            <Controller
              control={control}
              as={
                <SelectComponent
                  items={visibilityTypeOptions}
                  error={!!errors?.visibilityType}
                  helperText={errors?.visibilityType?.message}
                  className={classes.placeholder}
                />
              }
              name="visibilityType"
              mode="onBlur"
            />
          </Grid>
          <Grid item xs={12}>
            <Label description="Assunto" item xs={12} />
            <Controller
              control={control}
              as={
                <SelectComponent
                  items={ticketSubject}
                  anotherOption={true}
                  error={!!errors?.title}
                  helperText={errors?.title?.message}
                  className={classes.placeholder}
                />
              }
              name="title"
              mode="onBlur"
            />
          </Grid>
          {watch('title') === 'another' && (
            <Grid item xs={12}>
              <Controller
                name="anotherTitle"
                control={control}
                as={
                  <TextField
                    label="Outro: Assunto"
                    color="primary"
                    variant="outlined"
                    error={!!errors.anotherTitle}
                    helperText={errors?.anotherTitle?.message}
                    fullWidth
                  />
                }
                mode="onBlur"
              />
            </Grid>
          )}
          <Label
            description="Descreva sua solicitação (Máximo 3000 caracteres)"
            item
            xs={12}
          />
          <Grid item xs={12}>
            <Controller
              control={control}
              as={
                <TextField
                  type="text"
                  color="primary"
                  variant="outlined"
                  multiline
                  minRows={6}
                  error={!!errors.content}
                  helperText={errors?.content?.message}
                  fullWidth
                />
              }
              name="content"
              mode="onBlur"
            />
          </Grid>
          <Grid item xs={12}>
            <Box pt={3}>
              <Typography variant="body1">
                Para anexar arquivos,&nbsp;
                <Link
                  href="#"
                  htmlFor="input-file"
                  component="label"
                  className={classes.link}
                >
                  Clique aqui.
                </Link>
              </Typography>
              <Typography variant="caption">
                Arquivos suportados: xlsx, xls, imagens, doc, docx, ppt, pptx,
                txt, pdf
              </Typography>
              <input
                multiple
                type="file"
                id="input-file"
                accept=".xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf"
                className={classes.inputFile}
                onChange={handleChangeInputFile}
              />
            </Box>
          </Grid>
          {files.length > 0 && (
            <Grid item xs={12}>
              <AttachmentTable
                attachments={files}
                handleDelete={handleRemoveFile}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <Box display="flex" justifyContent="end" width="100%" paddingY={3}>
              <Button variant="contained" onClick={handleGoBack}>
                Voltar
              </Button>
              <Box ml={1}>
                <Button
                  variant="contained"
                  type="submit"
                  color="primary"
                  form="knowledge-base-ticket"
                >
                  Solicitar Atendimento
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </form>
    </Box>
  )
}

export default PersonalizedServiceForm
