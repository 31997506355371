import React, { useState } from 'react'

import useBeforeunload from 'hooks/useBeforeunload'
import ConsentContext from 'contexts/ConsentContext'

const ConsentProvider = ({ children }) => {
  useBeforeunload(() => {
    return 'Deseja realmente sair do site?'
  })

  const [activeStep, setActiveStep] = useState(0)
  const [lastActiveStep, setLastActiveStep] = useState(0)
  const [toNextStep, setToNextStep] = useState(false)
  const [counter, setCounter] = useState(0)
  const [dataConsentForm, setDataConsentForm] = useState({})

  const reloadOptions = () => {
    setCounter(counter + 1)
  }

  const toStep = (step, activeStep) => {
    if (step < activeStep) {
      setActiveStep(step)
    }
  }

  const nextStep = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
    if (lastActiveStep === activeStep) {
      setLastActiveStep((prevActiveStep) => prevActiveStep + 1)
    }
  }

  const backStep = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
    setLastActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  return (
    <ConsentContext.Provider
      value={{
        activeStep,
        setActiveStep,
        lastActiveStep,
        setLastActiveStep,
        toNextStep,
        setToNextStep,
        reloadOptions,
        counter,
        dataConsentForm,
        setDataConsentForm,
        toStep,
        nextStep,
        backStep,
      }}
    >
      {children}
    </ConsentContext.Provider>
  )
}

export default ConsentProvider
