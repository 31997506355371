import React, { useEffect, useState } from 'react'
import { isEmpty } from 'lodash'
import { Grid, Box, Typography, TextField, Button } from '@material-ui/core'
import { PlusCircle } from 'react-feather'

import { useForm, Controller } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { routes } from 'Routes'

import { Label } from 'components'
import { TableDocuments } from '../'
import { Asking, ResponsibleAndDate } from './components'

import schema from './schema'
import * as service from 'service'
import helpers from 'helpers'

import useSnackbar from 'hooks/useSnackbar'
import useFetch from 'hooks/useFetch'

import constants from 'constants/index'

import moment from 'moment'

const PlanActionForm = ({ planAction, isEdit, refresh, questionControl }) => {
  const history = useHistory()
  const snackbar = useSnackbar()
  const [files, setFiles] = useState([])

  const isFinalized = constants.questionControl.FINALIZED_ACTION.includes(
    questionControl?.status,
  )

  const { control, handleSubmit, errors, watch, setError, clearError } =
    useForm({
      validationSchema: schema,
      defaultValues: {
        evidence: planAction?.evidence ?? '',
        whatQuestion: planAction?.whatQuestion ?? '',
        howQuestion: planAction?.howQuestion ?? '',
        becauseQuestion: planAction?.becauseQuestion ?? '',
        whereQuestion: planAction?.whereQuestion ?? '',
        howPriceQuestion: helpers.formatters.formatToCurrencyBrl(
          planAction?.howPriceQuestion || '0',
        ),
        startDate: planAction?.startDate
          ? moment(planAction.startDate).format('YYYY-MM-DD')
          : moment().format('YYYY-MM-DD'),
        deadlineDate: planAction?.deadlineDate
          ? moment(planAction?.deadlineDate).format('YYYY-MM-DD')
          : moment().format('YYYY-MM-DD'),
        responsibleUserId: planAction?.responsibleUser?.id ?? '',
      },
    })

  const isDisabled = !isEdit || !questionControl?.openWrite

  const {
    response: responseDocuments,
    refresh: reload,
    isLoading: documentLoading,
  } = useFetch(
    service.dponet.documents.listQuestionPlanActionEvidency,
    { questionPlanActionEvidencyId: planAction?.id },
    [],
  )
  const documents = responseDocuments?.data?.documents || []

  const handleUploaderFile = async () => {
    if (isEmpty(files)) return null
    try {
      await service.dponet.documents.addQuestionPlanActionEvidency({
        questionPlanActionEvidencyId: planAction?.id,
        files: files,
      })
      reload()
      snackbar.open({
        message: 'Arquivo(s) enviado com sucesso!',
        variant: 'success',
      })
      setFiles([])
    } catch (error) {
      snackbar.open({
        message:
          helpers.formatters.errorMessage(
            error?.response?.data?.error,
            false,
          ) || 'Erro no envio dos arquivo(s)!',
        variant: 'error',
      })
    }
  }

  useEffect(() => {
    if (!isEmpty(files)) {
      handleUploaderFile()
    }
    //eslint-disable-next-line
  }, [files])

  const formattedDate = (date) => moment(date).format('DD/MM/YYYY')

  const handleReturn = () => history.push(routes.questionControls.all)
  const onSubmit = async (data) => {
    try {
      if (data?.startDate) data.startDate = formattedDate(data.startDate)
      if (data?.deadlineDate)
        data.deadlineDate = formattedDate(data.deadlineDate)

      await service.dponet.questionControls.update({
        questionPlanActionEvidencyId: planAction?.id,
        ...data,
      })
      snackbar.open({
        message: 'Plano de salva com sucesso!',
        variant: 'success',
      })
      refresh()
    } catch (error) {
      snackbar.open({
        message:
          helpers.formatters.errorMessage(
            error?.response?.data?.error,
            false,
          ) || 'Ocorreu um erro! Tente novamente.',
        variant: 'error',
      })
    }
  }

  const handleFinalized = () => {
    if (isEmpty(watch('evidence'))) {
      setError('evidence', {
        type: 'custom',
        message:
          'O envio da evidência é obrigatório para a conclusão da questão.',
      })
      return null
    }
    clearError('evidence')
    onSubmit({
      ...planAction,
      evidence: watch('evidence'),
      status: constants.questionControl.CONCLUSION_PLAN_STATUS,
    })
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        <ResponsibleAndDate
          control={control}
          errors={errors}
          isDisabled={isDisabled}
        />
        <Asking control={control} errors={errors} isDisabled={isDisabled} />
        {isFinalized && (
          <>
            <Grid item xs={12}>
              <Box mx={2}>
                <Label
                  title="Evidência"
                  titleVariant="h4"
                  descriptionVariant="subtitle2"
                  paddingBoxTitle={2}
                  description="Evidência da implementação (O que foi feito)"
                >
                  <Controller
                    control={control}
                    as={
                      <TextField
                        type="text"
                        color="primary"
                        variant="outlined"
                        multiline
                        rows={6}
                        error={!!errors.evidence}
                        helperText={
                          errors?.evidence?.message ||
                          errors?.evidence?.types?.message
                        }
                        fullWidth
                        disabled={isDisabled}
                      />
                    }
                    name="evidence"
                    mode="onBlur"
                  />
                </Label>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box display="flex" justifyContent="space-between" mx={2}>
                <Box>
                  <Typography variant="subtitle2" color="textPrimary">
                    Adicionar documentos
                  </Typography>
                  <Typography variant="caption">
                    Arquivos suportados: jpg, jpeg, png, pdf, docx, doc.
                  </Typography>
                </Box>
                <Button
                  variant="outlined"
                  startIcon={<PlusCircle size={20} />}
                  htmlFor="documents"
                  size="small"
                  component="label"
                  disabled={isDisabled}
                >
                  <Typography>Adicionar documento(s)</Typography>
                  <input
                    type="file"
                    accept=".xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.pdf"
                    multiple
                    id="documents"
                    style={{ display: 'none' }}
                    onChange={(e) => setFiles(e.target.files)}
                  />
                </Button>
              </Box>
            </Grid>
          </>
        )}
        {!isEmpty(documents) && (
          <Grid xs={12}>
            <Box m={2}>
              <TableDocuments
                files={documents}
                planEvidence={planAction}
                reload={reload}
                isLoading={documentLoading}
                isDisabled={isDisabled}
              />
            </Box>
          </Grid>
        )}
        {!isEmpty(planAction?.documents) && (
          <Grid xs={12}>
            <Box m={2}>
              <Typography variant="body1">
                Documentos recuperados da base de dados legada.
              </Typography>
              <Typography variant="body2">
                Caso esses documentos façam sentido neste plano de ação, faça o
                download do mesmo e tente reenviá-lo. Clique no botão 'Adicionar
                Documento(s)' e lembre-se de excluí-lo da base legada.
              </Typography>
              <TableDocuments
                files={planAction?.documents}
                planEvidence={planAction}
                reload={refresh}
                isLoading={false}
                isDisabled={isDisabled}
                isLegacyBase
              />
            </Box>
          </Grid>
        )}
        <Grid item xs={12}>
          <Box display="flex" justifyContent="flex-end" m={2}>
            <Box mr={isDisabled ? 0 : 2}>
              <Button
                variant="outlined"
                type="button"
                color="primary"
                onClick={handleReturn}
              >
                Voltar
              </Button>
            </Box>
            {!isDisabled && (
              <>
                <Button variant="contained" type="submit" color="primary">
                  Salvar
                </Button>
                {planAction.status === 'headway' && (
                  <Box ml={2}>
                    <Button
                      variant="contained"
                      type="button"
                      color="primary"
                      onClick={handleFinalized}
                    >
                      Finalizar
                    </Button>
                  </Box>
                )}
              </>
            )}
          </Box>
        </Grid>
      </Grid>
    </form>
  )
}

export default PlanActionForm
