import React from 'react'
import { Box, Paper, Typography } from '@material-ui/core'
import { useForm } from 'react-hook-form'
import { orderBy, flatMap } from 'lodash'

import { Questions } from 'components'

import useSnackbar from 'hooks/useSnackbar'
import useDataProcess from 'hooks/useDataProcess'

import * as service from 'service'
import schemaFinality from './schema'
import constants from 'constants/index'

const PendingLia = ({
  dataProcess = {},
  handleNext = () => {},
  questionsPendingAnswers = [],
  questionsApprovedAnswers = [],
  questions = [],
}) => {
  const snackbar = useSnackbar()
  const { setLoadingReplyLiaAnswer } = useDataProcess()

  const { LEGITIMATE_INTEREST_FINALITY, CONCRETE_SITUATION } =
    constants.liaQuestions

  const { INACTIVE } = constants.liaAnswers.STATUSES

  const filterQuestionsLegitimateInterestFinality = () => {
    return questions?.filter((question) => {
      return question?.kind === LEGITIMATE_INTEREST_FINALITY
    })
  }

  const filterQuestionsConcreteSituation = () => {
    return questions?.filter((question) => {
      return question?.kind === CONCRETE_SITUATION
    })
  }

  const filterAnswerByKind = (questions, kind) => {
    const liaQuestions = questions?.filter((question) => {
      return question?.kind === kind
    })

    const liaAnswers = flatMap(
      liaQuestions?.map((question) => question.liaAnswers),
    )
    return liaAnswers?.filter((answer) => answer?.status !== INACTIVE)
  }

  const answerPendingLegitimateInterestFinality = orderBy(
    filterAnswerByKind(questionsPendingAnswers, LEGITIMATE_INTEREST_FINALITY),
    'liaQuestionOrder',
  )

  const answerApprovedLegitimateInterestFinality = orderBy(
    filterAnswerByKind(questionsApprovedAnswers, LEGITIMATE_INTEREST_FINALITY),
    'liaQuestionOrder',
  )

  const answersPendingConcreteSituation = orderBy(
    filterAnswerByKind(questionsPendingAnswers, CONCRETE_SITUATION),
    'liaQuestionOrder',
  )

  const answersApprovedConcreteSituation = orderBy(
    filterAnswerByKind(questionsApprovedAnswers, CONCRETE_SITUATION),
    'liaQuestionOrder',
  )

  const { control, errors, handleSubmit } = useForm({
    validationSchema: schemaFinality,
    defaultValues: {
      question1:
        answerPendingLegitimateInterestFinality?.[0]?.answer ||
        answerApprovedLegitimateInterestFinality?.[0]?.answer ||
        '',
      question2:
        answersPendingConcreteSituation?.[0]?.answer ||
        answersApprovedConcreteSituation?.[0]?.answer ||
        '',
      question3:
        answersPendingConcreteSituation?.[1]?.answer ||
        answersApprovedConcreteSituation?.[1]?.answer ||
        '',
      question4:
        answersPendingConcreteSituation?.[2]?.answer ||
        answersApprovedConcreteSituation?.[2]?.answer ||
        '',
    },
  })

  const onSubmit = async (data) => {
    setLoadingReplyLiaAnswer(true)
    var liaAnswers = []

    Object.keys(data).map((key) => {
      return liaAnswers.push({
        liaQuestionOrder: key.replace(/^question/, ''),
        answer: data[key],
      })
    })

    try {
      await service.dponet.liaAnswers.create({
        liaAnswers,
        dataProcessId: dataProcess.id,
      })
      handleNext()
    } catch (error) {
      snackbar.open({
        message:
          error?.response?.data?.message ||
          'Erro ao responder as respostas para LIA!',
        variant: 'error',
      })
    } finally {
      setLoadingReplyLiaAnswer(false)
    }
  }

  return (
    <Box mt={2}>
      <form id="lia_form" onSubmit={handleSubmit(onSubmit)}>
        <Paper component={Box} padding={2}>
          <Box mb={2}>
            <Typography variant="h5">
              Interesse e finalidades legítimas
            </Typography>
          </Box>
          {filterQuestionsLegitimateInterestFinality()?.map((question) => (
            <Questions
              key={question.id}
              order={question.order}
              text={question.text}
              control={control}
              errors={errors}
            />
          ))}
        </Paper>
        <Paper component={Box} padding={2} mt={2}>
          <Box mb={2}>
            <Typography variant="h5">Situação concreta</Typography>
          </Box>
          {orderBy(filterQuestionsConcreteSituation(), 'id')?.map(
            (question) => (
              <Questions
                key={question.id}
                order={question.order}
                text={question.text}
                control={control}
                errors={errors}
              />
            ),
          )}
        </Paper>
      </form>
    </Box>
  )
}

export default PendingLia
