import React, { useEffect, useState } from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { Eye as EyeIcon } from 'react-feather'
import PropTypes from 'prop-types'
import { isEmpty } from 'lodash'
import {
  Box,
  Card,
  Chip,
  Button,
  Tabs,
  Tab,
  TablePagination,
  Hidden,
  IconButton,
} from '@material-ui/core'

import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from 'components/Table'
import { LoadingFeedback, MenuButton, Permitted } from 'components'

import {
  ActivitiesFormDialog,
  ActivitiesDestroyConfirmation,
  ActivityShowDialog,
} from '..'

import useFetch from 'hooks/useFetch'
import useSnackbar from 'hooks/useSnackbar'
import usePagination from 'hooks/usePagination'
import usePermissions from 'hooks/usePermissions'

import * as service from 'service'

import useStyles from './styles'
import helpers from 'helpers'
import constants from 'constants/index'
import useAuth from 'hooks/useAuth'

const TableRowTasks = ({
  task,
  setTask,
  setOpenDialog,
  setOpenDestroy,
  setOpenShow,
  refresh,
}) => {
  const classes = useStyles()
  const snackbar = useSnackbar()
  const permissions = usePermissions()

  const canFinalizeTask =
    [
      constants.activities.ONGOING_STATUS,
      constants.activities.OVERDUE_STATUS,
    ].includes(task?.status) &&
    permissions.permitted(constants.permissions.TASKS.FINALIZE)
  const canDeleteTask =
    task?.status === constants.activities.PENDING_STATUS &&
    permissions.permitted(constants.permissions.TASKS.DESTROY)
  const showMenuButton =
    permissions.some([
      constants.permissions.TASKS.CREATE,
      constants.permissions.TASKS.UPDATE_OBSERVATIONS,
    ]) ||
    canFinalizeTask ||
    canDeleteTask

  const handleEdit = () => {
    setTask(task)
    setOpenDialog(true)
  }

  const handleDestroy = () => {
    setTask(task)
    setOpenDestroy(true)
  }

  const handleShow = () => {
    setTask(task)
    setOpenShow(true)
  }

  const handleFinalize = async () => {
    try {
      await service.dponet.activities.finalize({
        taskId: task?.id,
      })
      refresh()
      snackbar.open({
        message: 'Atividade finalizada com sucesso',
        variant: 'success',
      })
    } catch (error) {
      snackbar.open({
        message:
          helpers.formatters.errorMessage(
            error?.response?.data?.error,
            false,
          ) || 'Ocorreu um erro! Tente novamente mais tarde.',
        variant: 'error',
      })
    }
  }

  return (
    <>
      <TableRow>
        <TableCell>{task?.id}</TableCell>
        <TableCell>{task?.name}</TableCell>
        <TableCell>{task?.responsible?.name || '-'}</TableCell>
        <TableCell>
          <Chip
            label={helpers.activities.status(task?.status).toUpperCase()}
            className={helpers.activities.statusChipColor(
              task?.status,
              classes,
            )}
            size="small"
          />
        </TableCell>
        <TableCell>{helpers.formatters.date(task?.startDate)}</TableCell>
        <TableCell>{helpers.formatters.date(task?.dueDate)}</TableCell>
        <TableCell align="center">
          <Box
            display="flex"
            alignItems="center"
            id={constants.myLgpd.HOME_DRIVER_STEP_9}
          >
            <IconButton onClick={handleShow}>
              <EyeIcon />
            </IconButton>
            {showMenuButton && (
              <MenuButton>
                <Permitted
                  someTags={[
                    constants.permissions.TASKS.CREATE,
                    constants.permissions.TASKS.UPDATE_OBSERVATIONS,
                  ]}
                >
                  <Button
                    onClick={handleEdit}
                    color="secondary"
                    fullWidth
                    size="small"
                  >
                    Editar
                  </Button>
                </Permitted>
                {canDeleteTask && (
                  <Button
                    onClick={handleDestroy}
                    color="secondary"
                    fullWidth
                    size="small"
                  >
                    Excluir tarefa
                  </Button>
                )}
                {canFinalizeTask && (
                  <Button
                    onClick={handleFinalize}
                    color="secondary"
                    fullWidth
                    size="small"
                  >
                    Finalizar tarefa
                  </Button>
                )}
              </MenuButton>
            )}
          </Box>
        </TableCell>
      </TableRow>
    </>
  )
}

const ActivitiesTable = ({
  onlyMyActivities,
  openDialog,
  setHaveTasks,
  setOpenDialog,
  setTask,
  task,
}) => {
  const [openDestroy, setOpenDestroy] = useState(false)
  const [openShow, setOpenShow] = useState(false)
  const { page, perPage, handleChangePage, handleChangeRowsPerPage } =
    usePagination(10)

  useEffect(() => {
    if (
      onlyMyActivities &&
      value === constants.activities.ACTIVITIES_PENDING_VALUE
    ) {
      setValue(constants.activities.ONGOING_STATUS)
    }
    //eslint-disable-next-line
  }, [onlyMyActivities])

  const { user } = useAuth()
  const [value, setValue] = useState(constants.activities.ALL_ACTIVITIES_VALUE)

  const handleChangeTab = (_, value) => {
    setValue(value)
  }

  const { response, isLoading, refresh } = useFetch(
    service.dponet.activities.get,
    {
      page,
      perPage,
      status: value === constants.activities.ALL_ACTIVITIES_VALUE ? '' : value,
      ...(onlyMyActivities && { responsibleId: user?.id }),
    },
    [value, onlyMyActivities, page, perPage],
  )

  const tasks = response?.data?.tasks

  useEffect(() => {
    if (!isEmpty(tasks)) setHaveTasks(true)
    else setHaveTasks(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tasks])

  return (
    <>
      <LoadingFeedback open={isLoading} />
      <Tabs
        value={value}
        onChange={handleChangeTab}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        scrollButtons="auto"
      >
        {constants.activities.ACTIVITIES_TAB.map((tab) => (
          <Tab label={tab.label} value={tab.value} key={tab.value} />
        ))}
      </Tabs>
      <Card id={constants.myLgpd.HOME_DRIVER_STEP_7}>
        <Box width="100%">
          <PerfectScrollbar options={{ useBothWheelAxes: true }}>
            <Table
              size="small"
              emptyMessage="Nenhum incidente encontrado"
              isLoading={isLoading}
              noWrap
            >
              <TableHead>
                <TableRow>
                  <TableCell width="7%">ID</TableCell>
                  <TableCell width="30%">Tarefa</TableCell>
                  <TableCell width="20%">Responsável</TableCell>
                  <TableCell width="15%">Status</TableCell>
                  <TableCell width="15%">Data de Início</TableCell>
                  <TableCell width="15%">Prazo</TableCell>
                  <TableCell width="13%" align="center">
                    Ações
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tasks?.map((taskData) => (
                  <TableRowTasks
                    key={taskData.id}
                    task={taskData}
                    refresh={refresh}
                    setOpenDialog={setOpenDialog}
                    setOpenDestroy={setOpenDestroy}
                    setOpenShow={setOpenShow}
                    setTask={setTask}
                    hover
                  />
                ))}
              </TableBody>
            </Table>
          </PerfectScrollbar>
        </Box>
        <TablePagination
          component="div"
          count={response?.data?.meta?.totalCount || 0}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          page={page - 1}
          rowsPerPage={perPage}
          rowsPerPageOptions={[5, 10, 25, 50]}
          labelRowsPerPage={<Hidden smDown>Por página</Hidden>}
          nextIconButtonProps={{ size: 'small' }}
          backIconButtonProps={{ size: 'small' }}
        />
      </Card>
      <ActivitiesFormDialog
        open={openDialog}
        setOpen={setOpenDialog}
        refresh={refresh}
        task={task}
      />
      <ActivitiesDestroyConfirmation
        open={openDestroy}
        setOpen={setOpenDestroy}
        refresh={refresh}
        taskId={task?.id}
      />
      <ActivityShowDialog
        open={openShow}
        setOpen={setOpenShow}
        refresh={refresh}
        task={task}
      />
    </>
  )
}

TableRowTasks.propTypes = {
  task: PropTypes.object,
  setTask: PropTypes.func,
  setOpenDialog: PropTypes.func,
  setOpenDestroy: PropTypes.func,
  refresh: PropTypes.func,
}

ActivitiesTable.propTypes = {
  task: PropTypes.object,
  setTask: PropTypes.func,
  setOpenDialog: PropTypes.func,
  openDialog: PropTypes.bool,
  onlyMyActivities: PropTypes.bool,
  setHaveTasks: PropTypes.func,
}

export default ActivitiesTable
