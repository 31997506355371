import constants from 'constants/index'

const convertRisk = (text) => {
  return constants?.ripdRisk?.RISK?.filter((fragility) => {
    const valueRisk = fragility?.name === text
    if (valueRisk) {
      if (fragility?.id === 5) {
        return null
      }
      return {
        id: fragility?.id,
      }
    }
  })
}

export default convertRisk
