import React, { useState, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { HelpCircle as HelpCircleIcon } from 'react-feather'
import PropTypes from 'prop-types'
import { isEmpty } from 'lodash'
import { routes } from 'Routes'
import qs from 'query-string'
import clsx from 'clsx'
import { Alert } from '@material-ui/lab'
import {
  Button,
  Box,
  CircularProgress,
  Divider,
  Paper,
  Typography,
  makeStyles,
  Tooltip,
} from '@material-ui/core'

import { ContentHeader, DriveTour } from 'components'
import { DataProcessMenuItems, ProcessOnAuditAlert } from './components'
import {
  DataCollected,
  DataTreatment,
  GeneralInformation,
  DataLifeCycle,
  PaperHeader,
  DataTreatmentAgent,
} from '../Show'
import {
  LegalFrameworks,
  Description,
  ShowNecessityProportionality,
  Fragilities,
} from '../'

import useAuth from 'hooks/useAuth'
import useDataProcessOptions from 'hooks/useDataProcessOptions'

import DataCollectedModalProvider from 'providers/DataCollectedModalProvider'
import DocumentsProvider from 'providers/DocumentsProvider'

import helpers from 'helpers'
import constants from 'constants/index'
import * as service from 'service'
import useStyles from '../../styles'

const PaperBody = ({
  isLoading,
  dataProcess,
  origin,
  onlyShow = false,
  refresh = () => {},
}) => {
  const history = useHistory()

  const handleBack = () => {
    history.push({
      pathname: routes.dataProcess.all,
      search: localStorage.getItem('ProcessFilters'),
    })
  }

  return (
    <>
      <Box id={constants.dataProcess.DATA_PROCESS_NEW_DRIVER_STEP_11}>
        <PaperHeader data={dataProcess} isLoading={isLoading} />
        <GeneralInformation
          data={dataProcess}
          isLoading={isLoading}
          origin={origin}
          onlyShow={onlyShow}
          hiddenSuggestionAlert={
            !dataProcess?.suggestedChangeStats?.dataProcess
          }
        />
      </Box>
      <Divider />
      <Description dataProcess={dataProcess} />
      <Divider />
      <DocumentsProvider documents={dataProcess.documents}>
        <DataCollectedModalProvider dataCollecteds={dataProcess.dataCollecteds}>
          <DataCollected
            dataCollecteds={dataProcess.dataCollecteds}
            isLoading={isLoading}
            dataProcessId={dataProcess.id}
            hiddenSuggestionAlert={
              !dataProcess.suggestedChangeStats.dataCollected
            }
          />
        </DataCollectedModalProvider>
      </DocumentsProvider>
      <Divider />
      <DataTreatmentAgent data={dataProcess} />
      <Divider />
      <DataLifeCycle
        data={dataProcess}
        isLoading={isLoading}
        hiddenSuggestionAlert={!dataProcess.suggestedChangeStats.lifeCycle}
      />
      <Divider />
      <DataTreatment
        isLoading={isLoading}
        dataTreatments={dataProcess.dataTreatments}
        dataProcessId={dataProcess.id}
        hiddenSuggestionAlert={!dataProcess.suggestedChangeStats.dataTreatment}
      />
      {dataProcess?.statusId === constants.dataProcess.APPROVED_STATUS &&
        !onlyShow && (
          <ShowNecessityProportionality data={dataProcess} refresh={refresh} />
        )}
      <Fragilities dataProcessId={dataProcess.id} onlyShow={onlyShow} />
      {!onlyShow && (
        <LegalFrameworks data={dataProcess} isLoading={isLoading} />
      )}
      {!onlyShow && (
        <Button variant="outlined" type="button" onClick={() => handleBack()}>
          Voltar
        </Button>
      )}
    </>
  )
}

const PaperShowDataProcess = ({ match, processId, onlyShow }) => {
  const [response, setResponse] = useState()
  const [loading, setLoading] = useState(true)
  const [reload, setReload] = useState(false)
  const [logs, setLogs] = useState()
  const [thisIsProcessUndefined, setThisIsProcessUndefined] = useState(false)
  const [alertMessage, setAlertMessage] = useState('')
  const [openTour, setOpenTour] = useState(false)

  const { user } = useAuth()

  const classes = useStyles()
  const location = useLocation()
  const search = qs.parse(location.search)
  const dataProcessId = match?.params?.dataProcessId || processId

  const refresh = () => {
    setReload(true)
    setReload(false)
  }

  useEffect(() => {
    setLoading(true)

    if (location?.state?.search) {
      localStorage.setItem('ProcessFilters', location.state.search)
    }

    const loadDataProcess = async () => {
      let response = undefined

      if (!search.showTemplate) {
        response = await service.dponet.dataProcesses.get({
          dataProcessId,
        })
      } else {
        response = await service.dponet.dataProcesses.get({
          dataProcessId,
          ...{ showTemplate: true },
        })
      }
      setResponse(response)
      const storageMode = response.data.dataProcess.storageMode
      if (storageMode === constants.dataProcess.STORY_MODE_UNDEFINED_TYPE) {
        setThisIsProcessUndefined(true)
        let generalInformation =
          response.data.dataProcess.storageMode ===
          constants.dataProcess.STORY_MODE_UNDEFINED_TYPE
        let lifeCycles = response.data.dataProcess.lifeCycles
        let lifeCyclesUndefined = false
        if (
          lifeCycles.find(
            (lifeCycle) =>
              lifeCycle.storageMode ===
              constants.dataProcess.STORY_MODE_UNDEFINED_TYPE,
          )
        ) {
          lifeCyclesUndefined = true
        }

        generalInformation && lifeCyclesUndefined
          ? setAlertMessage(
              'tempo de armazenamento do processo; tempo de armazenamento da salvaguarda e ciclo de vida.',
            )
          : generalInformation
          ? setAlertMessage('tempo de armazenamento do processo')
          : setAlertMessage(
              'tempo de armazenamento da salvaguarda e ciclo de vida',
            )
      }
    }

    const loadDataProcessLogs = async () => {
      if (!search.showTemplate) {
        let response = await service.dponet.dataProcesses.logs({
          dataProcessId,
        })
        setLogs(response?.data?.dponetAudits || [])
      } else {
        setLogs({})
      }
    }

    loadDataProcess()
      .then(() => loadDataProcessLogs())
      .finally(() => {
        setLoading(false)
      })
    // eslint-disable-next-line
  }, [dataProcessId, reload])

  const dataProcessOptions = useDataProcessOptions()

  useEffect(() => {
    if (!dataProcessOptions.data) {
      dataProcessOptions.loadData(user?.company?.id || '')
    }
  }, [dataProcessOptions.data])

  const suggestedChangeReasons =
    response?.data?.dataProcess?.suggestedChangeReasons

  const handleOpenTour = () => {
    const where = 'Processos'
    const title = 'Tour'

    const params = {
      action: 'tour-visualizar-processos',
    }

    helpers.mixpanel.track(where, title, params)
    setOpenTour(true)
  }

  return (
    <>
      {loading ? (
        <Box
          id="loadBox"
          display="flex"
          width="100%"
          minHeight="700px"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          {!onlyShow ? (
            <>
              <Box py={2} mt={1}>
                <ContentHeader title="Processo">
                  <Box className={classes.contentHeader}>
                    <Tooltip title="Iniciar o tour guiado">
                      <Box
                        className={clsx(
                          classes.contentHeaderBox,
                          classes.contentHeaderBoxXs,
                        )}
                      >
                        <Button
                          variant="contained"
                          color="primary"
                          startIcon={<HelpCircleIcon size={20} />}
                          onClick={handleOpenTour}
                          fullWidth
                        >
                          Tutorial
                        </Button>
                      </Box>
                    </Tooltip>
                    <DataProcessMenuItems
                      id={constants.dataProcess.DATA_PROCESS_SHOW_DRIVER_STEP_1}
                      dataProcess={response?.data?.dataProcess}
                      origin={search.origin}
                      dataProcessHook={dataProcessOptions}
                      logs={logs}
                      setLoading={setLoading}
                      onEvent={refresh}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                      }}
                    />
                  </Box>
                </ContentHeader>
                {thisIsProcessUndefined && (
                  <Alert
                    className={classes.alertUndefinedProcesses}
                    variant="filled"
                    severity="info"
                  >
                    Atenção! Os dados deste processo estão sem prazo de
                    eliminação definido. Pendência(s): {alertMessage}
                  </Alert>
                )}
                {response?.data?.dataProcess?.processesUnnecessary && (
                  <Alert
                    className={classes.alertUndefinedProcesses}
                    variant="filled"
                    severity="info"
                  >
                    Atenção! Este processo está com o tratamento de necessidade
                    e proporcionalidade pendente.
                  </Alert>
                )}
                {response?.data?.dataProcess?.onGoingAuditProcess && (
                  <ProcessOnAuditAlert
                    refresh={refresh}
                    auditProcess={
                      response?.data?.dataProcess?.onGoingAuditProcess
                    }
                  />
                )}
              </Box>
              {!isEmpty(suggestedChangeReasons) && (
                <Box mb={6}>
                  <Alert variant="filled" severity="info">
                    {suggestedChangeReasons.map((reason) => (
                      <Typography variant="h6" key={reason.id}>
                        {reason.description}
                      </Typography>
                    ))}
                  </Alert>
                </Box>
              )}
              <Paper className={classes.paperMargin}>
                <PaperBody
                  isLoading={loading}
                  dataProcess={response.data.dataProcess}
                  origin={search?.origin}
                  onlyShow={onlyShow}
                  refresh={refresh}
                />
              </Paper>
              <DriveTour
                stepsMatrix={
                  constants.dataProcess.DATA_PROCESS_SHOW_DRIVER_STEPS_OBJECT
                }
                customStyle={classes.customPopover}
                open={openTour}
                setOpen={setOpenTour}
              />
            </>
          ) : (
            <Box>
              <PaperBody
                isLoading={loading}
                dataProcess={response.data.dataProcess}
                onlyShow={onlyShow}
              />
            </Box>
          )}
        </>
      )}
    </>
  )
}

PaperShowDataProcess.propTypes = {
  processId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onlyShow: PropTypes.bool,
}

PaperShowDataProcess.defaultValues = {
  processId: '',
  onlyShow: false,
}

export default PaperShowDataProcess
