import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { useDropzone } from 'react-dropzone'
import {
  Box,
  Button,
  Typography,
  Link,
  FormControlLabel,
  Switch,
} from '@material-ui/core'
import moment from 'moment'
import {
  AlertTriangle as AlertIcon,
  Upload as UploadIcon,
  Download as DownloadIcon,
} from 'react-feather'
import { Controller, useForm } from 'react-hook-form'
import { isNil, isNull } from 'lodash'

import { ConfirmationDialog, SuccessDialog, QuestionnaireSupplier } from '../'

import { BaseDialog, LoadingFeedback } from 'components'

import useSnackbar from 'hooks/useSnackbar'

import constants from 'constants/index'
import * as service from 'service'
import useStyles from './styles'
import { getBaseURL } from 'service/env'
import { schemaImportSupplier } from './schema'

const ImportSupplierDialog = ({ open, setOpen }) => {
  const [file, setFile] = useState(null)
  const [openConfirmation, setOpenConfirmation] = useState(false)
  const [openSuccess, setOpenSuccess] = useState(false)
  const [loading, setLoading] = useState(false)
  const [checked, setChecked] = useState(false)
  const [date, setDate] = useState(null)
  const [questionnaires, setQuestionnaires] = useState([])

  const classes = useStyles()
  const snackbar = useSnackbar()

  const downloadModelSuppliers = () => {
    window.open(
      `${getBaseURL('dponet')}/modelo_importacao_de_fornecedores.xlsx/`,
      '_blank',
    )
  }

  const handleCloseConfirm = () => {
    setOpenSuccess(false)
    setOpenConfirmation(false)
    setOpen(false)
    setDate(null)
    setFile(null)
  }

  const onDrop = useCallback((acceptedFile) => {
    setFile(acceptedFile[0])
  }, [])

  const { getRootProps, getInputProps } = useDropzone({ onDrop })
  const { ref, ...rootProps } = getRootProps()

  const validateFile = () => {
    const validTypes = [
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'application/vnd.ms-excel',
    ]
    return file && validTypes.includes(file.type)
  }

  const handleImportSupplier = async () => {
    if (validateFile()) {
      setLoading(true)
      await service.dponet.suppliers.importSuppliers({
        fileToImport: file,
        international: checked,
        dateToSendEmails: date,
        questionnaireKinds: questionnaires,
      })
      setLoading(false)
      setOpenSuccess(true)
      setOpenConfirmation(false)
    } else {
      setLoading(false)
      snackbar.open({
        message: 'Por favor selecione um documento Excel (xlsx/xls)',
        variant: 'error',
      })
    }
    setFile(null)
  }

  const { control, handleSubmit, errors, watch } = useForm({
    validationSchema: schemaImportSupplier,
    defaultValues: { dateToSendEmails: null },
  })

  const handleOpenDialogConfirm = () => {
    if (!isNil(watch('dateToSendEmails')) && !isNull(file)) {
      setOpenConfirmation(true)
    }
  }
  const handleChangeInternationalSupplier = (event) => {
    setChecked(event.target.checked)
  }

  const handleDate = (data) => {
    setDate(moment(data.dateToSendEmails).format('DD/MM/YYYY'))
  }

  useEffect(() => {
    if (!open) {
      setChecked(false)
      setDate(null)
      setFile(null)
      setQuestionnaires([])
    }
  }, [open])

  return (
    <BaseDialog
      open={open}
      setOpen={setOpen}
      closeButtonText="Cancelar"
      actionButtonText="Importar"
      actionButton={handleOpenDialogConfirm}
      title="Importação de fornecedores"
      formRef="import-suppliers"
      loading={loading}
    >
      <LoadingFeedback open={loading} />
      <Box className={classes.box}>
        <FormControlLabel
          name="international"
          control={
            <Switch
              color="primary"
              onChange={handleChangeInternationalSupplier}
            />
          }
          label={
            <Typography align="justify" className={classes.typography}>
              Fornecedor Internacional
            </Typography>
          }
        />
        {checked && (
          <Box display="flex" p={2}>
            <AlertIcon color="orange" size={48} />
            <Box ml={1}>
              <Typography className={classes.typography}>
                Atenção! É necessário importar separadamente os fornecedores
                internacionais dos nacionais.
              </Typography>
            </Box>
          </Box>
        )}
        <Box mt={2}>
          <Typography align="justify" className={classes.typography}>
            Para importar fornecedores, é necessário seguir o modelo de planilha
            que disponibilizamos. Caso não seja seguido, podem ocorrer falhas na
            importação dos dados.
          </Typography>
        </Box>
        <Link onClick={downloadModelSuppliers}>
          <Button
            variant="contained"
            type="submit"
            color="primary"
            className={classes.buttonDownloadModel}
          >
            <DownloadIcon />
            <Typography>Baixar modelo</Typography>
          </Button>
        </Link>
      </Box>
      <Box
        {...rootProps}
        className={classes.inputImageContainer}
        mb={3}
        rootref={ref}
      >
        <input {...getInputProps()} />
        <Box m={1} mt={2}>
          <UploadIcon />
        </Box>
        <Box m={1}>
          <Typography>
            Arraste e solte ou selecione o documento com os fornecedores a serem
            importados
          </Typography>
        </Box>
      </Box>
      {file && (
        <Box mb={2}>
          <Typography>Arquivo:</Typography>
          <Typography variant="h5"> {file.path}</Typography>
        </Box>
      )}
      <form id="import-suppliers" onSubmit={handleSubmit(handleDate)}>
        <Box>
          <Controller
            as={
              <KeyboardDatePicker
                fullWidth
                format="DD/MM/yyyy"
                label="Data para o disparo do e-mail"
                inputVariant="outlined"
                onChange={(newValue) => ({
                  value: newValue,
                })}
                clearable
                disablePast
                error={!!errors.dateToSendEmails}
                helperText={errors?.dateToSendEmails?.message}
              />
            }
            control={control}
            name="dateToSendEmails"
            mode="onChange"
          />
        </Box>
      </form>

      <Box mt={2}>
        <QuestionnaireSupplier
          questionnaires={questionnaires}
          setQuestionnaires={setQuestionnaires}
        />
      </Box>

      {!loading && (
        <ConfirmationDialog
          open={openConfirmation}
          setOpen={setOpenConfirmation}
          handleConfirm={handleImportSupplier}
          actionType={constants.companySuppliers.CREATE_ACTION}
          title="Os fornecedores serão notificados!"
          subtitle={constants.companySuppliers.TEXT_MULT_SUPPLIERS}
        />
      )}
      <SuccessDialog
        open={openSuccess}
        setOpen={setOpenSuccess}
        isSupplier={false}
        isRegistrationInProgress={true}
        title="Cadastro em andamento!"
        subtitle="Informaremos por e-mail assim que todos os fornecedores forem cadastrados!"
        reload={handleCloseConfirm}
      />
    </BaseDialog>
  )
}

ImportSupplierDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  refresh: PropTypes.func,
}

export default ImportSupplierDialog
