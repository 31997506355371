const MY_LGPD = {
  SHOW: [
    'view_dashboard',
    'view_dashboard_process',
    'view_dashboard_gap',
    'view_dashboard_rnc',
  ],
}

const HOME = {
  SHOW_COMMUNICATION_TRAIL: 'show_communication_trail',
}

const COMPANIES = {
  LIST: 'list_companies',
  SHOW: 'show_company',
  CREATE: 'create_company',
  UPDATE: 'update_company',
  DELETE: 'destroy_company',
  UPDATE_SELF: 'update_self_company',
}

const INCIDENTS = {
  LIST: 'list_incidents',
  CREATE: 'create_incident',
}

const DATA_PROCESSES = {
  RESUME: 'resume_departments',
  CREATE: 'create_data_process',
  LIST: 'list_data_processes',
  LIST_AND_MANAGE_SUGGESTED: 'list_and_manage_suggested_data_processes',
  LIST_AND_MANAGE_SUGGESTED_CHANGE: 'list_and_manage_suggested_changes',
  INACTIVE_AND_REACTIVE: 'inactive_and_reactive_data_process',
  DESTROY: 'destroy_data_process',
  REVIEW: 'review_data_process',
  REOPEN: 'reopen_data_process',
}

const COMPANY_FRAGILITIES = {
  LIST_COMPANY_FRAGILITIES: 'list_company_fragilities',
  MANAGE_COMPANY_FRAGILITIES: 'create_company_fragility',
}

const PROFILES = {
  LIST: 'list_client_profiles',
  CREATE: 'create_client_profile',
  EDIT: 'edit_client_profile',
  CHANGE_STATUS: 'change_status_client_profile',
}

const DEPARTMENTS = {
  LIST: 'list_departments',
  CREATE: 'create_department',
  ACTIVATE_OR_INACTIVATE: 'activate_or_inactivate_department',
  LINK_USERS: 'link_users_to_department',
}

const PRIVACY_POLICIES = {
  LIST: 'list_privacy_policy',
  CREATE: 'create_privacy_policy',
  STAMP: 'stamp_privacy_policy',
  DESTROY: 'destroy_privacy_policy',
}

const PAYMENT_ORDERS = {
  LIST: 'index_by_company_payment_order',
}

const COMPANY_SUPPLIERS = {
  LIST_IAM_CLIENT: 'list_client_company_suppliers',
  LIST_IAM_SUPPLIER: 'list_supplier_company_suppliers',
  SHOW: 'show_company_supplier',
  UPDATE: 'update_company_supplier',
}

const SUPPLIER_SUGGESTIONS = {
  LIST: 'list_supplier_suggestions',
  MANAGE: 'accepted_or_declined_supplier_suggestions',
}

const NON_COMPLIANCE_REPORT = {
  LIST: 'list_non_compliance_reports',
  RESUME: 'resume_non_compliance_report',
  EDIT: 'edit_non_compliance_report',
  HANDLE_ACTIONS: 'handle_non_compliance_reports_actions',
  HANDLE_SPECIFIC_RISKS: 'handle_specific_risks',
  LIST_DATA_PROCESSES: 'list_all_non_compliance_report_data_process',
  LIST_LINKED_DATA_PROCESS: 'list_linked_non_compliance_report_data_process',
  LIST_QUESTIONNAIRE: 'list_all_non_compliance_report_by_questionnaire',
  LIST_LINKED_QUESTIONNAIRE:
    'list_linked_non_compliance_report_by_questionnaire',
  LIST_INCIDENT: 'list_incident_non_compliance_reports',
  LIST_RESPONSIBLE: 'list_responsable_non_compliance_reports',
  LIST_RECOMMENDATION: 'list_recomendations_non_compliance_reports',
}

const USERS = {
  SHOW: 'show_user',
  LIST: 'list_users',
  CREATE: 'create_user',
  EDIT: 'edit_user',
  CHANGE_STATUS: 'change_user_status',
  SOFT_DELETE: 'soft_delete_user_company',
}

const QUESTIONNAIRES = {
  LINK_USERS: 'link_users',
  REPLY: 'reply_questionnaire',
}

const RIPD = {
  EXPORT: 'export_ripd',
}

const DASHBOARD = {
  VIEW: 'view_dashboard',
  VIEW_PROCESS: 'view_dashboard_process',
  VIEW_RMC: 'view_dashboard_rnc',
  VIEW_QUESTIONNAIRE: 'view_dashboard_gap',
}

const DOCUMENT = {
  LIST: 'list_documents',
  CREATE: 'create_document',
  EDIT: 'update_document',
  DESTROY: 'destroy_document',
  CATEGORY_LIST: 'list_document_categories',
  LIST_PRIVATE_DOCUMENTS: 'list_private_documents',
}

const DOCUMENTS_TEMPLATE = {
  LIST: 'list_documents_template',
}

const PERMISSIONS_PRIVATE_DOCUMENT_LABEL = {
  LIST_PRIVATE_DOCUMENT: 'Ver Documentos Privados',
}

const EXPORT_INFO = {
  EXPORT_RIPD: 'export_ripd',
}

const PERMISSIONS_DEFAULT_LABEL = {
  LIST: 'Listar',
  CREATE: 'Criar',
  EDIT: 'Editar',
  DESTROY: 'Deletar',
  ACTIVATE_OR_INACTIVATE: 'Ativar/Inativar',
  SOFT_DELETE: 'Excluir',
  MANAGE: 'Gerenciar',
  VISUALIZE: 'Visualizar',
}

const TRANSLATED_PERMISSIONS_LIST = {
  companyFragilities: 'Ameaças',
  companyList: 'Dados cadastrais da empresa',
  companySuppliers: 'Fornecedores',
  consentList: 'Consentimentos',
  consentReceiptList: 'Recibos de Consentimentos',
  dashboardList: 'Minha LGPD',
  dataProcessList: 'Processos',
  departmentList: 'Departamentos',
  diagnosticsList: 'Diagnósticos',
  documentList: 'Documentos',
  documentTemplateList: 'Documentos modelo',
  incidentList: 'Incidentes',
  nonComplianceReportList: 'Registros de melhoria contínua',
  privacyPolicyList: 'Políticas de Privacidade',
  profileList: 'Perfis',
  questionnaireList: 'Questionários',
  ripdList: 'Relatório de Impacto à Proteção de Dados',
  taskList: 'Atividades',
  userList: 'Usuários',
}

const LAST_UPDATE = {
  LIST: 'list_last_updates',
}

const FAQS_DOCUMENTATIONS = {
  LIST: 'list_faqs_documentations',
}

const TASKS = {
  LIST: 'list_tasks',
  CREATE: 'create_task',
  UPDATE: 'update_task',
  DESTROY: 'destroy_task',
  UPDATE_OBSERVATIONS: 'update_observation_task',
  FINALIZE: 'finalize_task',
}

const DIAGNOSTICS = {
  UPDATE: 'update_question_control',
  TAKE_RISK: 'take_risk_question_control',
  OPEN_WRITE: 'open_write_question_control',
  LIST: 'list_question_controls',
}

const CONSENTS = {
  LIST: 'list_consent_form',
  CREATE: 'create_consent_form',
  SHOW: 'show_consent_form',
  CHANGE_FORM_STATUS: 'change_status_consent_form',
  CHANGE_RESPONSIBLE: 'change_responsible_consent_form',
  DESTROY: 'destroy_consent_form',
}

const CONSENT_RECEIPTS = {
  LIST: 'list_consent_form_answer',
  CREATE: 'create_consent_form_answer',
  SHOW: 'show_consent_form_answer',
  CHANGE_STATUS: 'change_status_consent_form_answer',
  DESTROY: 'destroy_consent_form_answer',
}

const permissions = {
  COMPANIES,
  COMPANY_FRAGILITIES,
  COMPANY_SUPPLIERS,
  CONSENT_RECEIPTS,
  CONSENTS,
  DASHBOARD,
  DATA_PROCESSES,
  DEPARTMENTS,
  DIAGNOSTICS,
  DOCUMENT,
  DOCUMENTS_TEMPLATE,
  EXPORT_INFO,
  FAQS_DOCUMENTATIONS,
  HOME,
  INCIDENTS,
  LAST_UPDATE,
  MY_LGPD,
  NON_COMPLIANCE_REPORT,
  PERMISSIONS_DEFAULT_LABEL,
  PERMISSIONS_PRIVATE_DOCUMENT_LABEL,
  PAYMENT_ORDERS,
  PRIVACY_POLICIES,
  PROFILES,
  QUESTIONNAIRES,
  RIPD,
  SUPPLIER_SUGGESTIONS,
  TASKS,
  TRANSLATED_PERMISSIONS_LIST,
  USERS,
}

export default permissions
