import React, { useState } from 'react'
import { isEmpty } from 'lodash'

import { Container, ContentHeader, LoadingFeedback, Page } from 'components'
import { AuditsPageStructure } from './components'

import useFetch from 'hooks/useFetch'

import * as service from 'service'

const AuditsShow = ({ match }) => {
  const { auditId } = match?.params
  const [loading, setLoading] = useState(false)

  const { response, isLoading, refresh } = useFetch(
    service.dponet.companyAudits.show,
    {
      auditId,
    },
  )

  const companyAudit = response?.data?.companyAudit

  return (
    <Page title="Visualizar - Auditoria de processos">
      <LoadingFeedback open={isLoading || loading} />
      <Container maxWidth={false}>
        <ContentHeader title="Visualizar auditoria" />
        {!isLoading && !isEmpty(companyAudit) && (
          <AuditsPageStructure
            cancelButtonLabel="Voltar"
            companyAudit={companyAudit}
            formType="show"
            setLoading={setLoading}
            refresh={refresh}
          />
        )}
      </Container>
    </Page>
  )
}

export default AuditsShow
