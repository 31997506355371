import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  editable: {
    border: '1px solid',
    height: 150,
    padding: theme.spacing(1.5),
    fontFamily: theme.typography.body1.fontFamily,
    fontSize: 16,
    overflow: 'auto',
    '& ol, & ul': {
      paddingLeft: theme.spacing(4),
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    '& li': {
      marginBottom: theme.spacing(0.5),
    },
  },
}))

export default styles
