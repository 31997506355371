import React from 'react'
import PropTypes from 'prop-types'
import { useForm, Controller } from 'react-hook-form'

import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  TextField,
  Select,
} from '@material-ui/core'

import TomticketDocuments from '../TomticketDocuments'

import schema from './schema'

const TomticketForm = ({ handleCreate, dataDocument, setDataDocument }) => {
  const { control, handleSubmit, errors } = useForm({
    validationSchema: schema,
    defaultValues: {
      type: '',
      title: '',
      description: '',
    },
  })

  const defaultTicketTypes = [
    { id: 'support', name: 'Suporte' },
    { id: 'financial', name: 'Financeiro' },
    { id: 'others', name: 'Outros' },
  ]

  return (
    <form onSubmit={handleSubmit(handleCreate)} id="tomticket-form">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel id="ticket-type-label">Selecione o tema</InputLabel>
            <Controller
              as={
                <Select labelId="ticket-type-label" label="Selecione o tema">
                  {defaultTicketTypes.map((type) => (
                    <MenuItem key={type.id} value={type.id}>
                      {type.name}
                    </MenuItem>
                  ))}
                </Select>
              }
              name="type"
              control={control}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Controller
            control={control}
            as={
              <TextField
                label="Titulo do ticket"
                type="text"
                color="primary"
                variant="outlined"
                error={!!errors.title}
                helperText={errors?.title?.message}
                fullWidth
              />
            }
            name="title"
            mode="onBlur"
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            control={control}
            as={
              <TextField
                label="Escreva sua solicitação"
                type="text"
                color="primary"
                variant="outlined"
                multiline
                minRows={6}
                error={!!errors.description}
                helperText={errors?.description?.message}
                fullWidth
              />
            }
            name="description"
            mode="onBlur"
          />
        </Grid>
        <Grid item xs={12}>
          <TomticketDocuments
            dataDocument={dataDocument}
            setDataDocument={setDataDocument}
          />
        </Grid>
      </Grid>
    </form>
  )
}

TomticketForm.propTypes = {
  dataDocument: PropTypes.arrayOf(PropTypes.object),
  handleCreate: PropTypes.func,
  setDataDocument: PropTypes.func,
}

TomticketForm.defaultProps = {
  dataDocument: [],
  handleCreate: () => {},
  setDataDocument: () => {},
}

export default TomticketForm
