import React from 'react'
import PropTypes from 'prop-types'

import useDataProcess from 'hooks/useDataProcess'
import useSnackbar from 'hooks/useSnackbar'
import useSelectCountry from 'hooks/useSelectCountry'

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Divider,
  Typography,
  Button,
} from '@material-ui/core'

import NewForm from './components/NewForm'

import helpers from 'helpers'
import * as service from 'service'

const DialogDataTreatment = ({
  dataProcessId,
  open,
  setLoading = () => {},
  setOpen = () => {},
  onEvent = () => {},
  ...props
}) => {
  const { reloadOptions } = useDataProcess()

  const { selectedCountries, manySelectedCountries } = useSelectCountry()

  const snackbar = useSnackbar()
  const edition = props.dataTreatment && props.dataTreatment.id ? true : false

  const onSubmit = (dataTreatment) => {
    if (props.dataTreatment && props.dataTreatment.id) {
      handleEdit(dataTreatment)
    } else {
      handleCreate(dataTreatment)
    }
  }

  const handleCreate = async (dataTreatment) => {
    try {
      setLoading(true)
      setOpen(false)

      let response = await service.dponet.dataTreatments.create({
        dataProcessId: dataProcessId,
        dataTreatment: {
          ...dataTreatment,
          international_transfer_countries: selectedCountries,
        },
      })

      await submitSharedSoftwares(
        dataTreatment.sharedSoftwares,
        response?.data?.dataTreatment?.id,
        response?.data?.dataTreatment?.dataProcessId,
      )

      onEvent()
      reloadOptions()
      setLoading(false)
      snackbar.open({
        message: 'Compartilhamento de dados criado com sucesso!',
        variant: 'success',
      })
    } catch (error) {
      setOpen(false)
      setLoading(false)
      snackbar.open({
        message: helpers.formatters.errorMessage(error?.response?.data?.error),
        variant: 'error',
      })
    }
  }

  const handleEdit = async (dataTreatment) => {
    try {
      setLoading(true)
      setOpen(false)

      let response = await service.dponet.dataTreatments.put({
        dataProcessId: dataProcessId,
        dataTreatmentId: dataTreatment.dataTreatmentId,
        dataTreatment: {
          ...dataTreatment,
          international_transfer_countries: selectedCountries,
        },
      })

      await submitSharedSoftwares(
        dataTreatment.sharedSoftwares,
        response?.data?.dataTreatment?.id,
        response?.data?.dataTreatment?.dataProcessId,
      )

      onEvent()
      reloadOptions()
      setLoading(false)
      snackbar.open({
        message: 'Compartilhamento de dados atualizado com sucesso!',
        variant: 'success',
      })
    } catch (error) {
      setOpen(false)
      setLoading(false)
      snackbar.open({
        message: helpers.formatters.errorMessage(error?.response?.data?.error),
        variant: 'error',
      })
    }
  }

  const submitSharedSoftwares = async (
    sharedSoftwares,
    dataTreatmentId,
    dataProcessId,
  ) => {
    if (!sharedSoftwares?.length) return

    for (let index in sharedSoftwares) {
      let sharedSoftware = sharedSoftwares[index]
      if (!!sharedSoftware.id) {
        await service.dponet.sharedSoftwares.put({
          dataProcessId,
          dataTreatmentId,
          sharedSoftwareId: sharedSoftware.id,
          sharedSoftware: {
            ...sharedSoftware,
            international_transfer_countries:
              manySelectedCountries[`sharedSoftwares-${index}`],
          },
        })
      } else {
        await service.dponet.sharedSoftwares.create({
          dataProcessId,
          dataTreatmentId,
          sharedSoftware: {
            ...sharedSoftware,
            international_transfer_countries:
              manySelectedCountries[`sharedSoftwares-${index}`],
          },
        })
      }
    }
  }

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      maxWidth="lg"
      fullWidth
      disableBackdropClick
    >
      <DialogTitle>
        <Box>
          <Typography variant="h4">
            {edition ? 'Editar' : 'Adicionar'} compartilhamento de dados
          </Typography>
        </Box>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <NewForm
          onSubmit={onSubmit}
          onEvent={onEvent}
          dataTreatment={props.dataTreatment}
        />
      </DialogContent>
      <Divider />
      <DialogActions>
        <Box pl={2} pr={1}>
          <Button
            type="button"
            variant="outlined"
            onClick={() => setOpen(false)}
          >
            Voltar
          </Button>
        </Box>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          form="data-treatment-form"
        >
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

DialogDataTreatment.propTypes = {
  dataProcessId: PropTypes.number.isRequired,
  open: PropTypes.bool,
  setOpen: PropTypes.func.isRequired,
  setLoading: PropTypes.func,
  onEvent: PropTypes.func,
}

export default DialogDataTreatment
