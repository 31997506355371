import React, { Fragment, useRef, useState } from 'react'
import PropTypes from 'prop-types'

import { Box, FormControl, FormHelperText, Typography } from '@material-ui/core'

import { Controller, useFormContext } from 'react-hook-form'
import { isNil, size, isEmpty } from 'lodash'

import { File as FileIcon, Upload as UploadIcon } from 'react-feather'

import helpers from 'helpers'
import styles from './styles'
import theme from 'theme'

const FileUploadInput = ({
  accept,
  controlName,
  subCaption = '',
  StartIcon = UploadIcon,
  EndIcon = FileIcon,
  title,
  multiple = false,
}) => {
  const [isDragging, setIsDragging] = useState(false)

  const { control, watch, setValue, errors } = useFormContext()

  const inputRef = useRef(null)
  const selectedFile = watch(controlName)

  const colorInput = () => {
    if (errors?.[controlName])
      return isDragging ? theme.palette.error.dark : theme.palette.error.main
    return isDragging ? theme.palette.primary.hover : theme.palette.custom.dark
  }

  const useStyles = styles({ colorInput, isDragging })
  const classes = useStyles()

  const isMultiple = size(selectedFile) > 1

  const handleClickFormControl = () => inputRef?.current?.click()

  const handleFileChange = (event) => {
    let files = event?.target?.files

    if (multiple) {
      if (!isNil(files)) return isEmpty(files) ? undefined : files
    } else {
      if (!isNil(files)) return isEmpty(files) ? undefined : files[0]
    }
  }

  const handleDragOver = (event) => {
    event.preventDefault()
    setIsDragging(true)
  }

  const handleDragLeave = () => setIsDragging(false)

  const handleDrop = (event) => {
    event.preventDefault()
    handleDragLeave()
    let files = event?.dataTransfer?.files

    if (multiple) {
      if (!isNil(files))
        setValue(controlName, isEmpty(files) ? undefined : files)
    } else {
      if (!isNil(files))
        setValue(controlName, isEmpty(files) ? undefined : files[0])
    }
  }

  return (
    <Fragment>
      <Controller
        as={
          <FormControl
            className={classes.formControl}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
            onClick={handleClickFormControl}
          >
            <input
              ref={inputRef}
              type="file"
              accept={accept || '*/*'}
              aria-label="Selecionar arquivo"
              className={classes.input}
              multiple={multiple}
            />

            {isNil(selectedFile) || isEmpty(selectedFile) ? (
              <Fragment>
                <StartIcon />
                <Typography component="span" className={classes.title}>
                  {title}
                </Typography>
                {accept && (
                  <Box display="flex" flexDirection="column">
                    <Typography variant="caption">
                      Tipos de arquivos suportados:&nbsp;
                      {helpers.formatters.file.allowedTypes(accept)}
                    </Typography>
                    {subCaption && (
                      <Typography variant="caption">{subCaption}</Typography>
                    )}
                  </Box>
                )}
              </Fragment>
            ) : (
              <Fragment>
                <Box
                  display="flex"
                  alignItems="center"
                  maxWidth="90%"
                  gridGap={theme.spacing(2)}
                >
                  <Box>
                    <EndIcon size={20} />
                  </Box>
                  <Typography noWrap>
                    {!isMultiple
                      ? multiple
                        ? selectedFile[0]?.name
                        : selectedFile?.name
                      : `${size(selectedFile)} arquivos selecionados`}
                  </Typography>
                  {!isMultiple && (
                    <Box>
                      <Typography variant="caption" noWrap width="fit-content">
                        {helpers.formatters.file.size(
                          multiple ? selectedFile[0]?.size : selectedFile?.size,
                        )}
                      </Typography>
                    </Box>
                  )}
                </Box>
                <Typography variant="caption">
                  Clique ou arraste para adicionar um novo arquivo
                </Typography>
              </Fragment>
            )}
          </FormControl>
        }
        control={control}
        name={controlName}
        onChange={([event]) => handleFileChange(event)}
        mode="onChange"
      />
      {!!errors?.[controlName] && (
        <FormHelperText error={!!errors?.[controlName]}>
          <>{errors?.[controlName]?.message}</>
        </FormHelperText>
      )}
    </Fragment>
  )
}

FileUploadInput.propTypes = {
  accept: PropTypes.string.isRequired,
  controlName: PropTypes.string.isRequired,
  subCaption: PropTypes.string,
  title: PropTypes.string.isRequired,
  multiple: PropTypes.bool,
}

export default FileUploadInput
