import React, { useEffect, useState } from 'react'
import { Link as RouterLink, useLocation, matchPath } from 'react-router-dom'
import {
  AppBar,
  IconButton,
  Toolbar,
  SvgIcon,
  Box,
  Hidden,
} from '@material-ui/core'
import { Menu as MenuIcon } from 'react-feather'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import { DisplayStaticUseLimitationNotice } from 'components'
import { Account, RedirectHelpButton, LastUpdatesButton } from './components'

import useAuth from 'hooks/useAuth'
import useDrawer from 'hooks/useDrawer'

import { routes } from 'Routes'
import constants from 'constants/index'

import useStyles from './styles'
import useCustomTheme from 'hooks/useCustomTheme'

const TopBar = ({ className, ...rest }) => {
  const [showHelpButton, setShowHelpButton] = useState(false)
  const [documentationId, setDocumentationId] = useState()

  const auth = useAuth()
  const classes = useStyles()
  const location = useLocation()
  const { customLogo } = useCustomTheme()
  const { handleOpenDrawer } = useDrawer()

  const redirectLinks = auth.redirectLinks
  const documentations = auth.faqsDocumentations

  useEffect(() => {
    const verifyMatch = (uri, id) => {
      if (!matchPath(location.pathname, { path: uri, exact: true })) {
        return
      }

      setShowHelpButton(true)
      setDocumentationId(id)
    }

    const verifyRedirectLinkMatch = (uri, filters) => {
      if (!matchPath(location.pathname, { path: uri, exact: true })) {
        return
      }
      return filters
    }

    const matchDocumentationLink = async () => {
      setShowHelpButton(false)
      documentations &&
        documentations.map((documentation) => {
          if (documentation.uri && documentation.id) {
            verifyMatch(documentation.uri, documentation.id)
          }

          return documentation
        })
    }

    const matchRedirectLink = () => {
      return redirectLinks?.map((redirectLink) => {
        verifyRedirectLinkMatch(redirectLink?.pageUri, redirectLink?.filters)
        return redirectLink
      })
    }

    matchDocumentationLink()
    matchRedirectLink()

    // eslint-disable-next-line
  }, [documentations, redirectLinks, location.key])

  return (
    <AppBar elevation={0} className={clsx(classes.root, className)} {...rest}>
      <Toolbar className={classes.toolbar}>
        {!!customLogo && (
          <Box>
            <Hidden mdDown>
              <RouterLink to={auth.homeRoute || routes.dataProcess.resume}>
                <img alt="Logo" src={customLogo} className={classes.logo} />
              </RouterLink>
            </Hidden>
          </Box>
        )}
        <Hidden lgUp>
          <IconButton
            className={classes.menuButton}
            color="inherit"
            onClick={handleOpenDrawer}
          >
            <SvgIcon fontSize="small">
              <MenuIcon />
            </SvgIcon>
          </IconButton>
        </Hidden>
        <Box ml={1} flexGrow={1} />
        <Box display="flex" justifyContent="flex-end" ml={1}>
          {auth?.company?.statusId === constants.userCompanies.TRIAL_STATUS && (
            <DisplayStaticUseLimitationNotice />
          )}
          {showHelpButton && (
            <RedirectHelpButton
              documentationId={documentationId}
              classes={classes}
            />
          )}
        </Box>
        <Box ml={1}>
          <LastUpdatesButton />
        </Box>
        <Box ml={1}>
          <Account />
        </Box>
      </Toolbar>
    </AppBar>
  )
}

TopBar.propTypes = {
  className: PropTypes.string,
}

export default TopBar
