import React from 'react'
import clsx from 'clsx'
import {
  Stepper,
  Step,
  StepConnector,
  StepLabel,
  Typography,
  makeStyles,
  Grid,
  Box,
} from '@material-ui/core'
import {
  Check as CheckIcon,
  Clear as ClearIcon,
  Remove as RemoveIcon,
} from '@material-ui/icons'

import styles from './styles'

const useStyles = makeStyles(styles)

const StepperLia = ({ lastActiveStep, steps }) => {
  const classes = useStyles()

  const Icon = (props) => {
    let { icon } = props
    icon -= 1
    const active = icon === lastActiveStep
    const completed = icon < lastActiveStep
    return (
      <Box
        className={clsx(classes.stepIcon, {
          [classes.stepIconActive]: active,
          [classes.stepIconCompleted]: completed,
        })}
      >
        {icon === lastActiveStep ? (
          <RemoveIcon />
        ) : icon < lastActiveStep ? (
          <CheckIcon />
        ) : (
          <ClearIcon />
        )}
      </Box>
    )
  }

  return (
    <Grid className={classes.root}>
      <Stepper
        className={classes.stepper}
        activeStep={lastActiveStep}
        nonLinear
        orientation="vertical"
        connector={<StepConnector className={classes.stepConnector} />}
      >
        {steps.map((label, index) => {
          let labelProps = {}
          if (lastActiveStep < index) {
            labelProps = (
              <Typography
                variant="caption"
                className={classes.stepLabelTextOptional}
              >
                Responder
              </Typography>
            )
          } else if (lastActiveStep === index) {
            labelProps = (
              <Typography
                variant="caption"
                className={classes.stepLabelTextOptional}
              >
                Respondendo
              </Typography>
            )
          } else {
            labelProps = (
              <Typography
                variant="caption"
                className={classes.stepLabelTextOptional}
              >
                Respondido
              </Typography>
            )
          }

          return (
            <Step key={label} className={classes.step}>
              <StepLabel
                StepIconComponent={Icon}
                className={classes.stepLabelText}
                optional={labelProps}
              >
                {label}
              </StepLabel>
            </Step>
          )
        })}
      </Stepper>
    </Grid>
  )
}

export default StepperLia
