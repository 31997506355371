import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { Button, Grid, TextField } from '@material-ui/core'
import { KeyboardDatePicker } from '@material-ui/pickers'

import helpers from 'helpers'

import useSnackbar from 'hooks/useSnackbar'

import schema from './schema'

const PaymentCard = ({ setLoading }) => {
  const snackbar = useSnackbar()

  const { control, handleSubmit, errors } = useForm({
    validationSchema: schema,
    defaultValues: {
      cardOwnerName: '',
      cardNumber: '',
      cardExpiringDate: null,
      cvv: '',
    },
  })

  const handleOnChange = (value, type) => {
    let inputValue = value

    if (type === 'cardNumber')
      inputValue = helpers.formatters.cardFlag.number(value)
    else if (type === 'cvv')
      inputValue = helpers.formatters.onlyNumbers(value).slice(0, 4)

    return inputValue
  }

  const PaymentCardTextField = ({ item }) => (
    <Grid item xs={6}>
      <Controller
        control={control}
        name={item?.controlName}
        onChange={([event]) =>
          handleOnChange(event.target.value, item?.controlName)
        }
        as={
          <TextField
            label={item?.label}
            error={!!errors?.[item?.controlName]}
            helperText={<>{errors?.[item?.controlName]?.message}</>}
            inputProps={{
              inputMode: item?.inputType,
            }}
            fullWidth
          />
        }
      />
    </Grid>
  )

  const onSubmit = (data) => {
    try {
      setLoading(true)

      snackbar.open({
        message: 'Método de pagamento atualizado com sucesso!',
        variant: 'success',
      })
    } catch (error) {
      snackbar.open({
        message: helpers.formatters.errorMessage(error.response.data.error),
        variant: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <Grid
      container
      spacing={2}
      component="form"
      id="payment-card-form"
      onSubmit={handleSubmit(onSubmit)}
    >
      <PaymentCardTextField
        item={{
          controlName: 'cardNumber',
          label: 'Número impresso no cartão',
          inputType: 'numeric',
        }}
      />
      <PaymentCardTextField
        item={{
          controlName: 'cardOwnerName',
          label: 'Nome impresso no cartão',
          inputType: 'text',
        }}
      />
      <Grid item xs={6}>
        <Controller
          control={control}
          name="cardExpiringDate"
          as={
            <KeyboardDatePicker
              fullWidth
              format="DD/MM/yyyy"
              label="Data de validade"
              inputVariant="outlined"
              onChange={(newValue) => ({
                value: newValue,
              })}
              error={!!errors?.cardExpiringDate}
              helperText={<>{errors?.cardExpiringDate?.message}</>}
              disablePast
              shouldDisableDate={(date) => date.isSame(new Date(), 'day')}
              clearable
            />
          }
        />
      </Grid>
      <PaymentCardTextField
        item={{
          controlName: 'cvv',
          label: 'CVV',
          inputType: 'numeric',
        }}
      />
      <Grid item xs={12}>
        <Button
          variant="outlined"
          color="primary"
          fullWidth
          type="submit"
          form="blank-slip-form"
        >
          Atualizar forma de pagamento
        </Button>
      </Grid>
    </Grid>
  )
}

export default PaymentCard
