const PENDING = 'pending'
const DISAPPROVED = 'disapproved'
const REVIEW_DISAPPROVED = 'review_disapproved'
const APPROVED = 'approved'
const INACTIVE = 'inactive'

const STATUSES = {
  APPROVED,
  DISAPPROVED,
  INACTIVE,
  PENDING,
  REVIEW_DISAPPROVED,
}

const liaAnswers = { STATUSES }

export default liaAnswers
