import React, { Fragment, useState } from 'react'
import { useHistory } from 'react-router-dom'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { Info as InfoIcon } from 'react-feather'
import PropTypes from 'prop-types'
import { reverse } from 'named-urls'
import { size } from 'lodash'
import { Box, Button, Chip, IconButton, Tooltip } from '@material-ui/core'

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from 'components/Table'
import { MenuButton } from 'components'
import { DepartmentListDialog } from './components'
import { AuditsFinalizeModal, AuditsInactivationModal } from '..'

import helpers from 'helpers'

import { routes } from 'Routes'
import useStyles from './styles'
import constants from 'constants/index'

const AuditsTable = ({
  auditConfigId = '',
  companyAudits,
  refresh,
  setLoading,
  isLoading,
}) => {
  const [open, setOpen] = useState(false)
  const [openInactivationModal, setOpenInactivationModal] = useState(false)
  const [openListDialog, setOpenListDialog] = useState(false)
  const [companyAuditData, setCompanyAuditData] = useState({})

  const classes = useStyles()
  const history = useHistory()

  const isRedirectAutomation = (audit) =>
    audit?.kind === constants.audits.PROGRAMMED_KIND &&
    audit?.status !== constants.audits.FINALIZED_STATUS_ID

  const handleRedirectAutomation = (auditConfigId) => {
    history.push(
      reverse(routes.audits.automation, {
        auditConfigId,
      }),
    )
  }

  const handleRedirectShow = async (audit) => {
    setCompanyAuditData(audit)
    if (isRedirectAutomation(audit)) handleRedirectAutomation(auditConfigId)
    else {
      history.push(
        reverse(routes.audits.show, {
          auditId: audit?.id,
        }),
      )
    }
  }

  const handleRedirectEdit = (audit) => {
    setCompanyAuditData(audit)
    if (isRedirectAutomation(audit)) handleRedirectAutomation(auditConfigId)
    else {
      history.push(
        reverse(routes.audits.edit, {
          auditId: audit?.id,
        }),
      )
    }
  }

  const handleOpenModal = (audit) => {
    setCompanyAuditData(audit)
    setOpen(true)
  }

  const handleOpenInactivationModal = (audit) => {
    setCompanyAuditData(audit)
    setOpenInactivationModal(true)
  }

  const handleOpenListDialog = (audit) => {
    setCompanyAuditData(audit)
    setOpenListDialog(true)
  }

  const auditLocation = (local) => {
    const departments = size(local?.departmentNames)
    if (departments === 1) return `Departamento de ${local?.departmentNames}`
    if (departments > 1) return `${departments} Departamentos`
    return `Empresa ${local?.companyName}`
  }

  return (
    <Box width="100%">
      <PerfectScrollbar
        options={{ wheelPropagation: false, useBothWheelAxes: true }}
      >
        <Table
          emptyMessage="Nenhuma auditoria encontrada"
          isLoading={isLoading}
          noWrap
        >
          <TableHead>
            <TableRow>
              <TableCell width="10%" align="left">
                ID
              </TableCell>
              <TableCell width="20%" align="left">
                Local
              </TableCell>
              <TableCell width="5%" align="center">
                Amostragem
              </TableCell>
              <TableCell width="15%" align="left">
                Tipo
              </TableCell>
              <TableCell width="15%" align="center">
                Data de Início
              </TableCell>
              <TableCell width="15%" align="center">
                Data Final
              </TableCell>
              <TableCell width="15%" align="center">
                Status
              </TableCell>
              <TableCell width="5%" align="center">
                Ações
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {companyAudits?.map((audit) => {
              const isPreview =
                audit?.status === constants.audits.PREVIEW_STATUS_ID
              const isInactive =
                audit?.status === constants.audits.INACTIVE_STATUS_ID
              const isFinalized =
                audit?.status === constants.audits.FINALIZED_STATUS_ID

              return (
                <TableRow key={audit?.id}>
                  <TableCell align="left">{audit?.id}</TableCell>
                  <TableCell align="left">
                    <Box display="flex" alignItems="center">
                      {auditLocation(audit?.local)}
                      {size(audit?.local?.departmentNames) > 1 && (
                        <Tooltip title="Visualizar detalhes">
                          <IconButton
                            size="small"
                            color="primary"
                            onClick={() => handleOpenListDialog(audit)}
                          >
                            <InfoIcon size={14} />
                          </IconButton>
                        </Tooltip>
                      )}
                    </Box>
                  </TableCell>
                  <TableCell align="center">
                    {`${(audit?.sampling).toLocaleString()}%`}
                  </TableCell>
                  <TableCell align="left">
                    {helpers.validates.audits.kindTranslation(audit?.kind)}
                  </TableCell>
                  <TableCell align="center">
                    {helpers.formatters.ptBrFormatDateTime(audit?.startDate)}
                  </TableCell>
                  <TableCell align="center">
                    {helpers.formatters.ptBrFormatDateTime(audit?.endDate)}
                  </TableCell>
                  <TableCell align="center">
                    <Chip
                      size="small"
                      label={helpers.validates.audits.chipStatusLabel(
                        audit?.status,
                      )}
                      style={{
                        backgroundColor:
                          helpers.validates.audits.chipStatusBgColor(
                            audit?.status,
                          ),
                        color: helpers.validates.audits.chipStatusColor(
                          audit?.status,
                        ),
                      }}
                      className={classes.chip}
                    />
                  </TableCell>
                  <TableCell align="center">
                    <MenuButton>
                      {(!isRedirectAutomation(audit) || !isPreview) && (
                        <Button
                          color="secondary"
                          fullWidth
                          onClick={() => handleRedirectShow(audit)}
                          size="small"
                        >
                          Visualizar
                        </Button>
                      )}
                      {!isFinalized && (
                        <Fragment>
                          <Tooltip
                            title={
                              !isPreview
                                ? 'Não é possível editar uma auditoria que não esteja prevista'
                                : ''
                            }
                          >
                            <Box width="100%">
                              <Button
                                color="secondary"
                                size="small"
                                fullWidth
                                onClick={() => handleRedirectEdit(audit)}
                                disabled={!isPreview}
                              >
                                Editar
                              </Button>
                            </Box>
                          </Tooltip>
                          <Tooltip
                            title={
                              isPreview || isInactive
                                ? `Não é possível finalizar uma auditoria ${
                                    isPreview ? 'prevista' : 'inativada'
                                  }`
                                : ''
                            }
                          >
                            <Box width="100%">
                              <Button
                                color="secondary"
                                size="small"
                                fullWidth
                                onClick={() => handleOpenModal(audit)}
                                disabled={isPreview || isInactive}
                              >
                                Finalizar
                              </Button>
                            </Box>
                          </Tooltip>
                          {!isFinalized && (
                            <Button
                              color="secondary"
                              size="small"
                              onClick={() => handleOpenInactivationModal(audit)}
                              fullWidth
                            >
                              {isInactive ? 'Ativar' : 'Inativar'}
                            </Button>
                          )}
                        </Fragment>
                      )}
                    </MenuButton>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </PerfectScrollbar>
      <AuditsFinalizeModal
        open={open}
        setOpen={setOpen}
        refresh={refresh}
        companyAuditId={companyAuditData?.id}
        setLoading={setLoading}
      />
      <AuditsInactivationModal
        open={openInactivationModal}
        setOpen={setOpenInactivationModal}
        refresh={refresh}
        companyAuditId={companyAuditData?.id}
        setLoading={setLoading}
        isInactive={
          companyAuditData?.status === constants.audits.INACTIVE_STATUS_ID
        }
      />
      <DepartmentListDialog
        open={openListDialog}
        setOpen={setOpenListDialog}
        departmentList={companyAuditData?.local?.departmentNames}
      />
    </Box>
  )
}

AuditsTable.propTypes = {
  auditConfigId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  companyAudits: PropTypes.arrayOf(PropTypes.object),
  refresh: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
}

export default AuditsTable
