import React from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { useHistory } from 'react-router-dom'
import { Eye as EyeIcon } from 'react-feather'
import { reverse } from 'named-urls'
import PropTypes from 'prop-types'
import {
  Box,
  Chip,
  IconButton,
  Paper,
  TablePagination,
  useMediaQuery,
} from '@material-ui/core'

import {
  Table,
  TableCell,
  TableRow,
  TableHead,
  TableBody,
} from 'components/Table'

import helpers from 'helpers'

import usePagination from 'hooks/usePagination'
import useFetch from 'hooks/useFetch'

import { routes } from 'Routes'
import useStyles from './styles'
import theme from 'theme'
import * as service from 'service'

const ProcessTable = ({ companyAuditId }) => {
  const classes = useStyles()
  const history = useHistory()

  const { page, perPage, handleChangePage, handleChangeRowsPerPage } =
    usePagination(5)

  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  })

  const { response, isLoading } = useFetch(
    service.dponet.companyAudits.getProcesses,
    { companyAuditId, page, perPage },
    [],
    !!companyAuditId,
  )

  const dataProcesses = response?.data?.dataProcesses

  const handleRedirect = (dataProcessId) => {
    history.push(
      reverse(routes.dataProcess.view, {
        dataProcessId,
      }),
    )
  }

  return (
    <Paper>
      <PerfectScrollbar
        options={{ wheelPropagation: false, useBothWheelAxes: true }}
      >
        <Table
          emptyMessage="Nenhum processo encontrado"
          noWrap
          isLoading={isLoading}
        >
          <TableHead>
            <TableRow>
              <TableCell width="15%" align="left">
                Nome do Processo
              </TableCell>
              <TableCell width="12%" align="left">
                Departamento
              </TableCell>
              <TableCell width="8%" align="center">
                Status
              </TableCell>
              <TableCell width="5%" align="center">
                Visualização
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataProcesses?.map((process) => (
              <TableRow key={process?.id}>
                <TableCell align="left">{process?.name}</TableCell>
                <TableCell align="left">{process?.department?.name}</TableCell>
                <TableCell align="center">
                  <Chip
                    size="small"
                    label={helpers.validates.audits.processChipStatusLabel(
                      process?.statusId,
                    )}
                    style={{
                      backgroundColor:
                        helpers.validates.audits.processChipStatusBgColor(
                          process?.statusId,
                        ),
                      color: helpers.validates.audits.processChipStatusColor(
                        process?.statusId,
                      ),
                    }}
                    className={classes.chip}
                  />
                </TableCell>
                <TableCell align="center">
                  <IconButton
                    size="small"
                    onClick={() => handleRedirect(process?.id)}
                  >
                    <EyeIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </PerfectScrollbar>
      <Box px={2} display="flex" justifyContent="flex-end">
        <TablePagination
          component="div"
          count={response?.data?.meta?.totalCount || 0}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          page={page - 1}
          rowsPerPage={perPage}
          rowsPerPageOptions={[5, 10, 25, 100]}
          labelRowsPerPage={isDesktop ? 'Por página' : ''}
          nextIconButtonProps={{ size: 'small' }}
          backIconButtonProps={{ size: 'small' }}
        />
      </Box>
    </Paper>
  )
}

ProcessTable.propTypes = {
  companyAuditId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

export default ProcessTable
