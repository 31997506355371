import React, { useState } from 'react'
import {
  Box,
  Button,
  Card,
  Grid,
  Hidden,
  Paper,
  TablePagination,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import { useTheme } from '@material-ui/styles'
import { HelpCircle as HelpCircleIcon } from 'react-feather'

import ticketConstants from 'constants/tickets'
import technicalFaqsConstants from 'constants/technicalFaqs'

import {
  Container,
  ContentHeader,
  DriveTour,
  FilterButton,
  Filters,
  LoadingFeedback,
  Page,
} from 'components'

import useFetch from 'hooks/useFetch'
import useFilter from 'hooks/useFilter'
import usePagination from 'hooks/usePagination'

import helpers from 'helpers'
import * as service from 'service'
import requestBoxImage from 'images/request_box_image.svg'
import { TicketTable } from '../components'

const Tickets = () => {
  const filter = useFilter()
  const theme = useTheme()

  const [openTour, setOpenTour] = useState(false)

  const { mixpanel } = helpers

  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  })

  const { perPage, page, handleChangePage, handleChangeRowsPerPage } =
    usePagination(10)

  const { JURIDIC_TICKET_TYPE, SECURITY_TICKET_TYPE } =
    ticketConstants.TICKET_TYPE

  const { response, isLoading, refresh } = useFetch(
    service.dponet.tickets.getAll,
    {
      perPage,
      page,
      ...{ ...filter.filters },
      ticket_types: [JURIDIC_TICKET_TYPE, SECURITY_TICKET_TYPE],
    },
    [page, perPage, filter.filters],
  )

  const handleOpenTour = () => {
    setOpenTour(true)
    mixpanel.track('Apoio Tecnico Solicitacoes', 'Tour', {
      action: 'tour-minhas-solicitacoes-central-de-privacidade',
    })
  }

  return (
    <Page title="Solicitações">
      <LoadingFeedback open={isLoading} />
      <Container maxWidth={false}>
        <ContentHeader title="Solicitações">
          <Box pr={1}>
            <Tooltip title="Iniciar o tour guiado">
              <Button
                variant="contained"
                color="primary"
                startIcon={<HelpCircleIcon size={20} />}
                onClick={handleOpenTour}
                fullWidth
                id={
                  technicalFaqsConstants.TECHNICAL_FAQS_TICKETS_MAIN_DRIVER_STEP_1
                }
              >
                Tutorial
              </Button>
            </Tooltip>
          </Box>
          <FilterButton setDrawerOpen={filter.setDrawerOpen} />
        </ContentHeader>
        <Paper component={Box} elevation={2} mb={4} p={3} display="flex">
          <Box py={1} display="flex" alignItems="center">
            <Hidden smDown>
              <Box ml={2}>
                <img src={requestBoxImage} alt="Logo" width={82} />
              </Box>
            </Hidden>
            <Box ml={2}>
              <Typography variant="subtitle1" paragraph>
                A plataforma disponibiliza serviços jurídicos especializados
                para auxiliar na adequação da sua organização à LGPD (Lei Geral
                de Proteção de Dados). Neste menu, oferecemos um atendimento
                jurídico altamente especializado, permitindo que você envie
                documentos para análise e receba pareceres jurídicos valiosos.
                Através dos nossos serviços, você poderá garantir a conformidade
                de sua organização com a LGPD, proporcionando segurança e
                confiança em relação ao tratamento de dados pessoais.
              </Typography>
            </Box>
          </Box>
        </Paper>

        <Card>
          <Box display="flex" width="100%" mt={2}>
            <Grid container>
              <TicketTable
                ticketData={response?.data}
                isLoading={isLoading}
                refresh={refresh}
              />
            </Grid>
          </Box>
          <Box px={2} display="flex" justifyContent="flex-end">
            {!isLoading && response ? (
              <TablePagination
                component="div"
                count={response?.data?.tickets.length}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                page={page - 1}
                rowsPerPage={perPage}
                rowsPerPageOptions={[5, 10, 25, 100]}
                labelRowsPerPage={isDesktop ? 'Por página' : ''}
                nextIconButtonProps={{ size: 'small' }}
                backIconButtonProps={{ size: 'small' }}
              />
            ) : (
              <Box py="11px">
                <Skeleton variant="rect" width={200} height={30} />
              </Box>
            )}
          </Box>
        </Card>
        <Filters filter={filter} alphabeticalOrder>
          <input textfieldinput="true" label="Identificador" name="ticketRef" />
          <select textfieldinput="true" label="Assunto" name="title">
            <option value=""></option>
            {technicalFaqsConstants.TICKET_SUBJECT.map((category) => (
              <option value={category.id}>{category.name}</option>
            ))}
          </select>
          <select textfieldinput="true" label="Status" name="statusId">
            <option value=""></option>
            <option value={ticketConstants.NEW_STATUS_ID}>Novo</option>
            <option value={ticketConstants.PENDING_STATUS_ID}>Pendente</option>
            <option value={ticketConstants.COMPLETED_STATUS_ID}>
              Concluído
            </option>
            <option value={ticketConstants.CANCELED_STATUS_ID}>
              Cancelado
            </option>
            <option value={ticketConstants.WAITING_STATUS_ID}>
              Aguardando Confirmação
            </option>
          </select>
          <input
            datepickerinput="true"
            label="Data de criação inicial"
            name="createdAtGt"
            data-target="createdAtLt"
            data-min
          />
          <input
            datepickerinput="true"
            label="Data de criação final"
            name="createdAtLt"
            data-target="createdAtGt"
            data-max
          />
          <input
            datepickerinput="true"
            label="Ultima interação incial"
            name="updatedAtGt"
            data-target="updatedAtLt"
            data-min
          />
          <input
            datepickerinput="true"
            label="Ultima interação final"
            name="updatedAtLt"
            data-target="updatedAtGt"
            data-max
          />
        </Filters>
      </Container>
      <DriveTour
        stepsMatrix={
          technicalFaqsConstants.TECHNICAL_FAQS_TICKTES_DRIVER_STEPS_OBJECT
        }
        open={openTour}
        setOpen={setOpenTour}
      />
    </Page>
  )
}

export default Tickets
