import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.up('lg')]: {
      paddingLeft: theme.spacing(8),
      paddingRight: theme.spacing(8),
    },
  },
  button: {
    padding: `${theme.spacing(2)}px ${theme.spacing(5)}px`,
    textTransform: 'none',
    borderRadius: 0,
  },
  input: {
    display: 'none',
  },
  tabLeft: {
    borderRadius: '4px 0px 0px 0px',
  },
  tabRight: {
    borderRadius: '0px 4px 0px 0px',
  },
  tabRightAndLeft: {
    borderRadius: '4px 4px 0px 0px',
  },
  financialCard: {
    width: '100%',
  },
  financialBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    gridGap: theme.spacing(1),
    padding: theme.spacing(2),
    backgroundColor: theme.palette.custom.light,
  },
  financialBoxHighlight: {
    border: `1px dashed ${theme.palette.primary.main}`,
  },
}))

export default styles
