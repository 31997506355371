/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { Alert } from '@material-ui/lab'
import { Grid, TextField, Typography } from '@material-ui/core'
import { useHistory } from 'react-router-dom'

import { AttachmentBox } from './components'
import { Card, Label, LoadingFeedback } from 'components'

import useIncidentManegement from 'hooks/useIncidentManegement'
import useSnackbar from 'hooks/useSnackbar'

import { routes } from 'Routes'
import * as service from 'service'
import formatters from 'helpers/formatters'

const AvaliationStep = ({ type }) => {
  const [loading, setLoading] = useState(false)

  const {
    wantsToAdvanceStep,
    handleNext,
    resetAdvanceStep,
    incident,
    setActiveButton,
  } = useIncidentManegement()
  const history = useHistory()
  const snackbar = useSnackbar()

  const onSubmit = async () => {
    if (type === 'create') return history.push(routes.incidents.all)

    try {
      setLoading(true)

      await service.dponet.incidents.concludeStep({
        incidentId: incident.id,
        step: 'avaliation',
      })

      resetAdvanceStep()
      handleNext()
    } catch (error) {
      snackbar.open({
        message: formatters.errorMessage(error),
        variant: 'error',
      })
    } finally {
      setLoading(false)
      resetAdvanceStep()
    }
  }

  useEffect(() => {
    if (type === 'edit') {
      if (!incident?.legalOpinion) {
        setActiveButton(false)
      }
    }
  }, [incident])

  useEffect(() => {
    if (wantsToAdvanceStep) {
      onSubmit()
    }
  }, [wantsToAdvanceStep])

  return (
    <Card title="Avaliação do Incidente">
      <LoadingFeedback open={loading} />

      {incident?.legalOpinion ? (
        <Grid container spacing={2}>
          <Label title="Parecer" xs={12} item>
            <TextField
              fullWidth
              variant="outlined"
              multiline
              minRows={4}
              value={incident?.legalOpinion}
              disabled
            />
          </Label>

          {incident?.attachments && (
            <Grid item xs={12}>
              <AttachmentBox attachment={incident?.attachments} />
            </Grid>
          )}
        </Grid>
      ) : (
        <Alert severity="info">
          <Typography>
            Por favor, aguarde! O DPO foi notificado e está avaliando seu
            incidente.
          </Typography>
        </Alert>
      )}
    </Card>
  )
}

export default AvaliationStep
