import React, { Fragment, useState } from 'react'
import { RefreshCw as RefreshCwIcon } from 'react-feather'
import { Box, Button, Typography } from '@material-ui/core'

import { ChangePaymentMethod, PaymentCard } from './components'

import useAuth from 'hooks/useAuth'

import theme from 'theme'

const PaymentMethod = () => {
  const [paymentMethod, setPaymentMethod] = useState(false)
  const { company } = useAuth()

  const handleChangePaymentMethod = () =>
    setPaymentMethod((prevState) => !prevState)

  return (
    <Box display="flex" flexDirection="column" gridGap={theme.spacing(2)}>
      {!paymentMethod ? (
        <Fragment>
          <Typography variant="h6">Este plano está vinculado</Typography>
          {!!company?.creditCard ? (
            <PaymentCard creditCard={company?.creditCard} />
          ) : (
            <Typography>
              Esta empresa não possui um método de pagamento automatizado
              cadastrado.
            </Typography>
          )}
          {/* Sera implementado na v2 da issue 2533 */}
          {/* <Button
            variant="contained"
            color="primary"
            startIcon={<RefreshCwIcon size={20} />}
            fullWidth
            onClick={handleChangePaymentMethod}
          >
            Trocar forma de pagamento
          </Button> */}
        </Fragment>
      ) : (
        <ChangePaymentMethod />
      )}
    </Box>
  )
}

export default PaymentMethod
