import React, { useState } from 'react'
import { Box, Divider, Tab, Tabs } from '@material-ui/core'

import { BankSlip, PaymentCard } from './components'

import constants from 'constants/index'
import theme from 'theme'

const ChangePaymentMethod = () => {
  const [currentTab, setCurrentTab] = useState(
    constants.settings.BANK_SLIP_STATUS_ID,
  )

  const handleTabChange = (_, value) => setCurrentTab(value)

  return (
    <Box display="flex" flexDirection="column" gridGap={theme.spacing(2)}>
      <Box>
        <Box display="flex" justifyContent="center">
          <Tabs
            value={currentTab}
            scrollButtons="auto"
            variant="scrollable"
            textColor="primary"
            onChange={handleTabChange}
          >
            {constants.settings.FINANCIAL_PAYMENTS_METHOD_TABS.map((tab) => (
              <Tab
                key={tab.value}
                label={tab.label}
                value={tab.value}
                icon={<tab.icon />}
              />
            ))}
          </Tabs>
        </Box>
        <Divider />
      </Box>
      {currentTab === constants.settings.BANK_SLIP_STATUS_ID && <BankSlip />}
      {currentTab === constants.settings.PAYMENT_CARD_STATUS_ID && (
        <PaymentCard />
      )}
    </Box>
  )
}

export default ChangePaymentMethod
