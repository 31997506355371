import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { reverse } from 'named-urls'
import PerfectScrollbar from 'react-perfect-scrollbar'
import PropTypes from 'prop-types'
import { Box, CircularProgress, Button, Chip } from '@material-ui/core'

import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from 'components/Table'
import { MenuButton, BaseConfirmationDialog } from 'components'

import usePermissions from 'hooks/usePermissions'

import { routes } from 'Routes'
import helpers from 'helpers'
import permission from 'constants/permissions'
import constants from 'constants/index'
import useStyles from './styles'

const TableRowIncidents = ({ incident, setOpenComplete, ...rest }) => {
  const history = useHistory()
  const permissions = usePermissions()
  const classes = useStyles()

  const navigateToEdit = (incidentId) => {
    history.push(
      reverse(routes.incidents.edit, {
        incidentId: incidentId,
      }),
    )
  }

  return (
    <>
      <TableRow {...rest}>
        <TableCell>{incident?.id}</TableCell>
        <TableCell>
          {helpers.formatters.date(incident?.createdAt, false)}
        </TableCell>
        <TableCell>
          {constants.incidentManegement.LABELS[
            incident?.incidentManegementStep?.step
          ] || '-'}
        </TableCell>
        <TableCell>
          {incident?.nonComplianceReport ? (
            <Chip
              label={helpers.nonComplianceReports
                .status(incident?.nonComplianceReport?.statusId)
                .toUpperCase()}
              className={helpers.nonComplianceReports.statusChipColor(
                incident?.nonComplianceReport?.statusId,
                classes,
              )}
              size="small"
            />
          ) : (
            '-'
          )}
        </TableCell>
        <TableCell>
          {helpers.nonComplianceReports.getResponsableNames(
            incident?.nonComplianceReport?.actionResponsableUsers,
            false,
          )}
        </TableCell>
        <TableCell>
          {helpers.formatters.date(
            incident?.nonComplianceReport?.deadlineDate,
            false,
          ) || '-'}
        </TableCell>
        {permissions.some([
          permission.INCIDENTS.CREATE,
          permission.INCIDENTS.LIST,
        ]) && (
          <TableCell align="right">
            <MenuButton>
              <Button
                color="secondary"
                onClick={() => navigateToEdit(incident?.id)}
                fullWidth
                size="small"
              >
                {permissions.permitted(permission.INCIDENTS.CREATE)
                  ? 'Editar'
                  : 'Visualizar'}
              </Button>
            </MenuButton>
          </TableCell>
        )}
      </TableRow>
    </>
  )
}

const IncidentsTable = ({ incidents, isLoading }) => {
  const permissions = usePermissions()
  const [openComplete, setOpenComplete] = useState(false)

  const history = useHistory()

  const handleEditComplete = () => {
    if (openComplete === false) return

    history.push(
      reverse(routes.incidents.editComplete, {
        incidentId: openComplete,
      }),
    )
  }

  return (
    <>
      {isLoading ? (
        <Box
          display="flex"
          width="100%"
          minHeight="700px"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress />
        </Box>
      ) : (
        <Box width="100%">
          <PerfectScrollbar
            options={{ wheelPropagation: false, useBothWheelAxes: true }}
          >
            <Table
              size="small"
              emptyMessage="Nenhum incidente encontrado"
              isLoading={isLoading}
              noWrap
            >
              <TableHead>
                <TableRow>
                  <TableCell width="10%">Identificador</TableCell>
                  <TableCell width="20%">Data de criação</TableCell>
                  <TableCell width="20%">Fase</TableCell>
                  <TableCell width="10%">Tratamento</TableCell>
                  <TableCell width="20%">Responsável</TableCell>
                  <TableCell width="10%">Prazo</TableCell>
                  {permissions.some([
                    permission.INCIDENTS.CREATE,
                    permission.INCIDENTS.LIST,
                  ]) && (
                    <TableCell width="10%" align="right">
                      Ações
                    </TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {incidents &&
                  incidents?.map((incident) => (
                    <TableRowIncidents
                      incident={incident}
                      key={incident.id}
                      setOpenComplete={setOpenComplete}
                      hover
                    />
                  ))}
              </TableBody>
            </Table>
          </PerfectScrollbar>
        </Box>
      )}
      <BaseConfirmationDialog
        open={openComplete !== false}
        title="Você tem certeza de que deseja mudar para o formulário completo?"
        text="A equipe jurídica irá avaliar seu incidente e informá-lo sobre a necessidade de preencher o formulário completo. O modelo completo segue as diretrizes para notificar incidentes à ANPD, sendo abrangente e detalhado."
        actionButtonText="Confirmar"
        variant="warning"
        actionButton={handleEditComplete}
        setOpen={setOpenComplete}
        dialogSize="sm"
      />
    </>
  )
}

TableRowIncidents.propTypes = {
  incident: PropTypes.object,
}

export default IncidentsTable
