import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { Box, Button, Divider, Paper, Typography } from '@material-ui/core'
import clsx from 'clsx'

import { TicketFeedback, TicketMessages } from './components'
import { Container, ContentHeader, LoadingFeedback, Page } from 'components'
import { ReplyTicketForm } from '../components'

import * as service from 'service'
import helpers from 'helpers'
import constants from 'constants/index'

import useFetch from 'hooks/useFetch'
import useSnackbar from 'hooks/useSnackbar'

import { routes } from 'Routes'
import useStyles from './styles'

const TicketDetails = ({ match }) => {
  const classes = useStyles()
  const [isLoad, setIsLoad] = useState(false)

  const history = useHistory()
  const snackbar = useSnackbar()

  const { ticketId } = match.params

  const { response, refresh, isLoading } = useFetch(
    service.dponet.tickets.get,
    {
      ticketId: ticketId,
    },
  )

  const {
    response: messagesResponse,
    refresh: messageRefresh,
    isLoading: isLoadingMessage,
  } = useFetch(service.dponet.tickets.showMessages, { ticketId: ticketId })

  const ticket = useMemo(() => response?.data?.ticket, [response])
  const ticketMessages = useMemo(
    () => messagesResponse?.data?.ticketMessages,
    [messagesResponse],
  )

  const onSubmit = async (data) => {
    try {
      setIsLoad(true)
      await service.dponet.tickets.createMessage({
        ticketId: ticketId,
        ticketMessages: data,
      })
      messageRefresh()
      snackbar.open({
        message: 'Mensagem enviadas com sucesso!',
        variant: 'success',
      })
      setIsLoad(false)
      return true
    } catch (error) {
      snackbar.open({
        message:
          helpers.formatters.errorMessage(
            error?.response?.data?.error,
            false,
          ) ||
          'O sistema de de tickets está indisponível no momento. Você pode entrar em contato através do e-mail atendimento@dponet.com.br',
        variant: 'error',
      })
      setIsLoad(false)
      return false
    }
  }

  const shouldPermitReply = useMemo(() => {
    const ticketStatuses = [
      constants.tickets.NEW_STATUS_ID,
      constants.tickets.PENDING_STATUS_ID,
    ]

    return ticketStatuses.includes(ticket?.status?.id) && ticket?.allowedRespond
  }, [ticket])

  return (
    <Page title="Detalhes Parecer Técnico">
      <LoadingFeedback open={isLoading || isLoad} />
      <Container maxWidth={false}>
        <ContentHeader title={`Solicitação ID: ${ticketId}`} />
        <Paper component={Box} elevation={2} mb={4} p={3} display="flex">
          <Box display="flex" flexDirection="column" width="100%">
            <Box display="flex" mb={2}>
              <Typography className={classes.boxId}>{ticket?.id}</Typography>
              <Typography
                className={clsx(
                  classes.boxStatus,
                  helpers.tickets.statusLabelColor(ticket?.status?.id, classes),
                )}
              >
                {ticket?.status?.name}
              </Typography>
            </Box>
            <Typography variant="h5">Assunto: {ticket?.title}</Typography>
            <Typography variant="h6">
              Categoria: Atendimento Personalizado
            </Typography>
            <Divider />

            <TicketMessages
              ticketMessages={ticketMessages}
              isLoadingMessage={isLoadingMessage}
            />

            {shouldPermitReply && (
              <>
                <ReplyTicketForm onSubmit={onSubmit} />

                <Box display="flex" justifyContent="end" mt={2}>
                  <Button
                    variant="contained"
                    onClick={() => history.push(routes.technicalFaqs.tickets)}
                  >
                    Cancelar
                  </Button>
                  <Box ml={1}>
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      form="reply-ticket-form"
                    >
                      Enviar
                    </Button>
                  </Box>
                </Box>
              </>
            )}
          </Box>
        </Paper>

        {!shouldPermitReply && (
          <TicketFeedback
            ticketId={ticketId}
            ticketFeedback={ticket?.ticketFeedback}
            refresh={refresh}
          />
        )}
      </Container>
    </Page>
  )
}

TicketDetails.propTypes = {
  match: PropTypes.object,
}

export default TicketDetails
