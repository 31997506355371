import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Portal,
  Typography,
} from '@material-ui/core'

import useSnackbar from 'hooks/useSnackbar'

import * as service from 'service'
import helpers from 'helpers'

import { LoadingFeedback } from 'components'
import { RetentionJustificationForm } from './components'

const DialogRetentionJustificationTicket = ({ open, setOpen }) => {
  const [isLoading, setIsLoading] = useState(false)
  const container = useRef()

  const handleClose = () => {
    if (open) {
      setOpen(false)
    }
  }

  const snackbar = useSnackbar()

  const handleCreate = async (data) => {
    data.title = 'Justificativa para tempo de armazenamento'
    const dataMerged = { ...data }

    try {
      setIsLoading(true)

      await service.dponet.tomticketIntermediator.createTicket({
        ...helpers.formatters.formatDataTomticket(dataMerged),
      })

      snackbar.open({
        message: 'Ticket enviado com sucesso!',
        variant: 'success',
      })
      setOpen(false)
    } catch (error) {
      snackbar.open({
        message:
          'O sistema de abertura de tickets está indisponível no momento. Você pode entrar em contato através do e-mail atendimento@dponet.com.br',
        variant: 'error',
      })
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      <Box ref={container}></Box>
      {open ? (
        <Portal container={container.current}>
          <Dialog open={open} onClose={handleClose}>
            <Box padding={2}>
              <Typography variant="h4">Formulário de contato</Typography>
            </Box>
            <Divider />
            <DialogContent>
              <LoadingFeedback open={isLoading} />
              <Box py={2.5}>
                <RetentionJustificationForm handleCreate={handleCreate} />
              </Box>
            </DialogContent>
            <Divider />
            <Box display="flex" justifyContent="flex-end" mr={2}>
              <DialogActions>
                <Box mr={2}>
                  <Button
                    type="button"
                    variant="outlined"
                    onClick={handleClose}
                    component="a"
                  >
                    Voltar
                  </Button>
                </Box>
                <Button
                  variant="contained"
                  type="submit"
                  color="primary"
                  form="tomticket-form"
                >
                  Enviar
                </Button>
              </DialogActions>
            </Box>
          </Dialog>
        </Portal>
      ) : null}
    </>
  )
}

DialogRetentionJustificationTicket.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func.isRequired,
}

DialogRetentionJustificationTicket.defaultProps = {
  open: false,
  setOpen: () => {},
}

export default DialogRetentionJustificationTicket
