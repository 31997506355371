import * as yup from 'yup'

export const schema = yup.object().shape({
  collectionDate: yup
    .date()
    .required()
    .typeError('Precisa ser uma data válida'),
  invoiceAmount: yup.string().max(9).required(),
  emails: yup
    .array()
    .of(yup.string().email().max(255))
    .required('Precisa ter pelo menos um email'),
})

export default schema
