import { makeStyles } from '@material-ui/core'

import cityBannerImage from 'images/city.png'

export default makeStyles((theme) => ({
  root: {
    background: theme.palette.subscription.primary.main,
    display: 'grid',
    width: '100%',
    minHeight: '100vh',
    overflow: 'auto',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column-reverse',
    },
    [theme.breakpoints.only('md')]: {
      gridTemplateColumns: '1fr 1fr',
    },
    [theme.breakpoints.up('lg')]: {
      gridTemplateColumns: '4fr 8fr',
    },
  },
  wrapper: {
    width: '100%',
    background: theme.palette.white,
    [theme.breakpoints.down('sm')]: {
      height: '85vh',
      borderRadius: theme.spacing(2, 2, 0, 0),
    },
    [theme.breakpoints.up('md')]: {
      borderRadius: theme.spacing(0, 2, 2, 0),
    },
  },
  rightBanner: {
    display: 'grid',
    placeItems: 'center',
    height: '100%',
    backgroundImage: `url(${cityBannerImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover, 50%',
    backgroundPosition: 'bottom',
    [theme.breakpoints.down('sm')]: {
      height: '15vh',
    },
  },
  dponetLogo: {
    width: '55%',
    maxHeight: '100%',
    height: 'auto',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '240px',
    },
  },
}))
