import MainAccountability from './Accountability'
import MainControlReport from './ControlReport'
import MainFragilityReport from './FragilityReport'
import MainManagement from './Management'
import MainRipd from './ImpactReport'
import MainRmcReport from './ReportRmc'
import MainRopa from './RopaReport'
import ValidateReport from './ValidateReport'
import TrainingReport from './TrainingReport'
import MainLia from './LiaReport'

export default {
  MainAccountability,
  MainControlReport,
  MainFragilityReport,
  MainManagement,
  MainRipd,
  MainRmcReport,
  MainRopa,
  ValidateReport,
  TrainingReport,
  MainLia,
}
