import api from 'service/api'

const dponetAPI = api.create('dponet')

const get = async () => {
  return await dponetAPI.get('/company_trails')
}

const concludedStep = async ({ companyTrailId, ...rest }) => {
  return await dponetAPI.put(
    `/company_trails/${companyTrailId}/concluded_step`,
    rest,
  )
}

export default { get, concludedStep }
