import api from 'service/api'
import * as service from 'service'
import axios from 'axios'
import { getBaseURL } from 'service/env'

const dponetAPI = api.create('dponet')
const dponetAPIWithoutErrorValidation = api.create('dponet', false)
const baseURL = getBaseURL('dponet')

const get = async ({ companyId }) => {
  return await dponetAPI.get(`/companies/${companyId}`)
}

const put = async ({ companyId, ...data }) => {
  return await dponetAPI.put(`/companies/${companyId}`, data)
}

const updateSelf = async (data) => {
  return await dponetAPI.put('/company/update_self', data)
}

const logs = async ({ companyId }) => {
  return await dponetAPI.get(`/companies/${companyId}/logs`)
}

const verifyDocument = async (document) => {
  const supplierToken = service.dponet.auth.getCompanyToken()
  return await dponetAPIWithoutErrorValidation.post(
    `/companies/verify_documents/${document}`,
    { supplierToken },
  )
}

const updateSelfLogo = async ({ logo }) => {
  var data = new FormData()
  data.append('logo', logo)
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
      'Content-Disposition': 'attachment',
      Authorization: service.dponet.auth.getToken(),
    },
  }

  return await axios({
    method: 'PUT',
    url: `${baseURL}/company/update_self_logo`,
    data,
    ...config,
  })
}

const consultQrCode = async ({ companyId }) => {
  return await dponetAPI.get(`/companies/${companyId}/consult_qrcode`)
}

const notificationRdStation = async ({ companyId }) => {
  return await dponetAPI.get(
    `companies/${companyId}/notification_rd_station_supplier`,
  )
}

const companies = {
  get,
  put,
  updateSelfLogo,
  updateSelf,
  logs,
  verifyDocument,
  consultQrCode,
  notificationRdStation,
}

export default companies
