import * as yup from 'yup'

const schema = yup.object().shape({
  evidence: yup.string(),
  whatQuestion: yup.string(),
  howQuestion: yup.string(),
  becauseQuestion: yup.string(),
  whereQuestion: yup.string(),
  howPriceQuestion: yup.string(),

  startDate: yup
    .date()
    .required('Preencha a data do incidente')
    .typeError('Preencha a data do incidente'),
  deadlineDate: yup
    .date()
    .required('Preencha a data do incidente')
    .typeError('Preencha a data do incidente'),
  responsibleUserId: yup.number().required(),
})

export default schema
