import React, { useState, useEffect } from 'react'
import { isEmpty } from 'lodash'
import { Avatar, Box, Container, Grid, Typography } from '@material-ui/core'
import { Alert } from '@material-ui/lab'

import {
  Page,
  Container as ContainerCustom,
  LoadingFeedback,
  RoundedBorder,
} from 'components'
import { Header, QuestionnaireCard, AdequacyModal } from './components'

import useFetch from 'hooks/useFetch'
import useFilter from 'hooks/useFilter'
import useAuth from 'hooks/useAuth'
import useStyles from './styles'

import helpers from 'helpers'
import * as service from 'service'
import constants from 'constants/index'

const QuestionnairesMain = () => {
  const { company } = useAuth()
  const filter = useFilter()
  const classes = useStyles()
  const { mixpanel } = helpers

  const [openModal, setOpenModal] = useState(false)
  const referrer = document.referrer
  const params = new URL(referrer || 'https://app.dponet.com.br')

  const { response, isLoading } = useFetch(
    service.dponet.questionnaires.get,
    {
      perPage: 9999,
      hideQuestions: true,
      ...{ ...filter.filters },
    },
    [filter.filters],
  )

  const fundamentalQuestionnaire = response?.data?.questionnaires?.find(
    (questionnaire) =>
      questionnaire.kind === constants.questionnaire.FUNDAMENTAL_REQUIREMENTS,
  )

  const nonCompliance =
    Number(fundamentalQuestionnaire?.complianceGap ?? 100) < 100

  const zeroQuestionnaires = isEmpty(response?.data?.questionnaires)

  const handleClose = () => {
    mixpanel.track(
      'Jornada de Fornecedores',
      'Modal: Adequação pendências de medidas de controle importantes',
      {
        action: 'Fechar-Adequação',
      },
    )
    setOpenModal(false)
  }

  useEffect(() => {
    const searchPath = params.pathname
    if (searchPath === '/supplier-step' && nonCompliance > 0) {
      setOpenModal(true)
    }
    // eslint-disable-next-line
  }, [nonCompliance])

  return (
    <Page title="Todos os questionários">
      <Container maxWidth={false} className={classes.blueContainer}>
        <LoadingFeedback open={isLoading} />
        <Header>
          <Grid item xs={12}>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              mb={5}
            >
              <Avatar className={classes.avatar}>
                <Box display="flex" alignItems="baseline" mt={0.5}>
                  <Typography variant="h3" className={classes.whiteTypography}>
                    {company?.companyCompliance?.toFixed(0) || 0}
                  </Typography>
                  <Typography
                    variant="body1"
                    className={classes.whiteTypography}
                  >
                    %
                  </Typography>
                </Box>
              </Avatar>
              <Typography variant="h3" className={classes.whiteTypography}>
                Conformidade
              </Typography>
            </Box>
          </Grid>
        </Header>
      </Container>
      <Box position="relative">
        <RoundedBorder />
      </Box>
      <ContainerCustom
        maxWidth="xl"
        spacingMd={11}
        spacingLg={16}
        spacingXl={16}
      >
        {zeroQuestionnaires && !isLoading && (
          <Alert variant="filled" severity="info" color="warning">
            <Typography>
              Você não tem permissões para visualizar questionários. Entre em
              contato com o responsável ou co-responsável pela empresa e
              solicite a sua permissão.
            </Typography>
          </Alert>
        )}
        {!isLoading && !zeroQuestionnaires && (
          <>
            <Box py={2}>
              <Typography variant="h4">
                Responda todos os questionários
              </Typography>
            </Box>
            <Grid container spacing={2}>
              {response?.data?.questionnaires?.map((questionnaire) => (
                <QuestionnaireCard
                  key={questionnaire.id}
                  questionnaire={questionnaire}
                />
              ))}
            </Grid>
          </>
        )}
      </ContainerCustom>
      <AdequacyModal
        company={company}
        open={openModal}
        handleClose={handleClose}
        nonCompliance={nonCompliance}
        setOpenModal={setOpenModal}
      />
    </Page>
  )
}

export default QuestionnairesMain
