import React from 'react'

import { Grid, TextField, Box, Typography } from '@material-ui/core'

import { useForm, Controller } from 'react-hook-form'

import moment from 'moment'

import { Select as SelectComponent } from 'components'

import helpers from 'helpers'

import schema from './schema'

import useSubscription from 'hooks/useSubscription'
import constants from 'constants/index'

import visaImg from 'images/visa.png'
import mastercardImg from 'images/mastercard.png'
import amexImg from 'images/amex.png'
import eloImg from 'images/elo.png'
import hipercardImg from 'images/hipercard.png'

const FormCreditCard = ({ submitPayment }) => {
  const months = constants.date.MONTHS.map((month) => ({
    id: month,
    name: month,
  }))
  const years = helpers.functions.yearsAhead(15).map((year) => ({
    id: year,
    name: year,
  }))

  const {
    selectedMethod,
    setDisabledButton,
    toNextSubStep,
    activeSubStep,
    setActiveSubStep,
    setPayloadPayment,
  } = useSubscription()

  const { errors, control, handleSubmit, setError } = useForm({
    validationSchema: schema,
    defaultValues: {
      name: '',
      number: '',
      cvv: '',
      month: '',
      year: '',
    },
  })

  const handleSubmitAction = async (data) => {
    const methodPaymentByBrand = helpers.functions.cardFlag(data?.number)

    if (!methodPaymentByBrand) {
      setError('number', {
        type: {
          notMatch: 'Cartão de crédito inválido!',
        },
      })
      return
    }

    if (!dateIsValid(data.month, data.year)) return

    const expirationDate = `${data.month}/${data.year}`

    delete data.month
    delete data.year
    const payload = {
      method:
        selectedMethod.id === constants.preRegistrations.CARD_METHOD
          ? methodPaymentByBrand
          : constants.preRegistrations.BILLET_METHOD,
      card: {
        ...data,
        expiration: expirationDate,
      },
    }
    setPayloadPayment(payload)
    await submitPayment(payload)

    setDisabledButton(false)
    setActiveSubStep(activeSubStep + 1)
    toNextSubStep()
  }

  const dateIsValid = (month, year) => {
    const currentYear = moment().year()
    const currentMonth = moment().month()
    year = parseInt(year)
    month = parseInt(month)

    if (year > currentYear) return true

    // O moment, retorna o mês com um valor a menos, se é Fev, ele retorna 1
    if (year === currentYear && month > currentMonth) return true

    setError('month', 'custom', 'Data inválida.')
    setError('year', 'custom', 'Data inválida.')

    return false
  }

  return (
    <form onSubmit={handleSubmit(handleSubmitAction)} id="form-submit-main">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Controller
            as={
              <TextField
                fullWidth
                error={!!errors.name}
                helperText={errors?.name?.message}
                label="Nome do Titular"
              />
            }
            control={control}
            name="name"
            mode="onChange"
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            as={
              <TextField
                fullWidth
                inputProps={{
                  maxLength: 19,
                }}
                error={!!errors.number}
                helperText={
                  errors?.number?.message ||
                  errors?.number?.types?.type?.notMatch
                }
                label="Número do Cartão"
              />
            }
            onChange={([event]) => {
              return helpers.formatters.creditCardNumber(event.target.value)
            }}
            control={control}
            name="number"
            mode="onChange"
          />
        </Grid>
        <Grid item xs={6} md={2}>
          <Controller
            name="month"
            control={control}
            as={
              <SelectComponent
                items={months}
                error={!!errors.month}
                fullWidth
                helperText={errors.month?.message}
                label="Mês (MM)"
              />
            }
            mode="onBlur"
          />
        </Grid>
        <Grid item xs={6} md={2}>
          <Controller
            name="year"
            control={control}
            as={
              <SelectComponent
                items={years}
                error={!!errors.year}
                fullWidth
                helperText={errors.year?.message}
                label="Ano (AA)"
              />
            }
            mode="onBlur"
          />
        </Grid>
        <Grid item xs={12} md={8}>
          <Controller
            as={
              <TextField
                fullWidth
                inputProps={{
                  maxLength: 4,
                }}
                error={!!errors.cvv}
                helperText={errors?.cvv?.message}
                label="Código de segurança (CVV/CVC)"
              />
            }
            control={control}
            name="cvv"
            mode="onChange"
          />
        </Grid>
        <Grid item xs={12}>
          <Box m={1}>
            <Typography variant="body1">bandeiras aceitas</Typography>
            <img src={visaImg} alt="bandeira visa" width={40} />
            <img src={mastercardImg} alt="bandeira mastercard" width={40} />
            <img src={eloImg} alt="bandeira elo" width={40} />
            <img
              src={amexImg}
              alt="bandeira AMEX (American Express)"
              width={40}
            />
            <img src={hipercardImg} alt="bandeira hipercard" width={40} />
          </Box>
        </Grid>
      </Grid>
    </form>
  )
}

export default FormCreditCard
