import React, { useState } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  TextField,
  Box,
  Grid,
  Typography,
} from '@material-ui/core'

import { useForm, Controller } from 'react-hook-form'
import { LoadingButton, Label } from 'components'

import helpers from 'helpers'

import moment from 'moment'

import * as service from 'service'

import useSnackbar from 'hooks/useSnackbar'

import * as yup from 'yup'

import constants from 'constants/index'

import useAuth from 'hooks/useAuth'

const ReopenDialog = ({ setChangeDialog, changeDialog, refresh, riskData }) => {
  const { user } = useAuth()
  const [loading, setLoading] = useState(false)
  const snackbar = useSnackbar()

  const handleReturn = () => setChangeDialog(false)

  const isView =
    riskData?.status === constants.questionControl.RISK_ASSUMED_STATUS

  const onSubmit = async (data) => {
    setLoading(true)
    try {
      await service.dponet.questionControls.takeRisk({
        questionControlId: riskData?.id,
        openWrite: false,
        status: constants.questionControl.RISK_ASSUMED_STATUS,
        takeRisk: {
          accept: true,
          description: data.description,
          responsibleName: user?.name,
          acceptDate: moment().format('DD/MM/YYYY'),
        },
      })

      handleReturn()
      refresh()
    } catch (error) {
      snackbar.open({
        message:
          helpers.formatters.errorMessage(error.response.data.error) ||
          'Erro ao habilitar a edição da medida de segurança!',
        variant: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  const { handleSubmit, control, errors } = useForm({
    validationSchema: yup.object().shape({
      description: yup.string().required(),
    }),
    defaultValues: {
      description: riskData?.takeRisk?.description || '',
    },
  })

  return (
    <Dialog fullWidth maxWidth="md" open={changeDialog} onClose={handleReturn}>
      <DialogTitle>
        {riskData?.takeRisk?.accept
          ? 'Detalhe sobre risco assumido'
          : 'Você tem certeza que deseja assumir o risco'}
      </DialogTitle>
      <Divider />
      <DialogContent>
        <form onSubmit={handleSubmit(onSubmit)} id="risk-question">
          {riskData?.takeRisk?.accept && (
            <Box m={1}>
              <Grid container spacing={2}>
                <Grid xs={12} md={6}>
                  <Label description="Responsável">
                    <Typography variant="body2">
                      {riskData?.takeRisk?.responsibleName}
                    </Typography>
                  </Label>
                </Grid>
                <Grid xs={12} md={6}>
                  <Label description="Data do aceite">
                    <Typography variant="body2">
                      {' '}
                      {riskData?.takeRisk?.acceptDate}
                    </Typography>
                  </Label>
                </Grid>
              </Grid>
            </Box>
          )}
          <Label description="Justificativa">
            <Controller
              as={
                <TextField
                  fullWidth
                  rows={6}
                  multiline
                  label=""
                  type="text"
                  helperText={errors?.description?.message}
                  error={errors?.description}
                  disabled={isView}
                />
              }
              name="description"
              control={control}
              mode="onChange"
            />
          </Label>
        </form>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Box display="flex" justifyContent="flex-end">
          <Button autoFocus onClick={handleReturn}>
            Voltar
          </Button>
          {!isView && (
            <LoadingButton
              loading={loading}
              text="Confirmar"
              autoFocus
              type="submit"
              form="risk-question"
            />
          )}
        </Box>
      </DialogActions>
    </Dialog>
  )
}

export default ReopenDialog
