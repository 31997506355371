import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Controller, useForm } from 'react-hook-form'
import {
  Box,
  FormControlLabel,
  Grid,
  makeStyles,
  Switch,
  Typography,
} from '@material-ui/core'

import { Label, Select } from 'components'

import useSnackbar from 'hooks/useSnackbar'
import useConsent from 'hooks/useConsent'
import useFetch from 'hooks/useFetch'

import helpers from 'helpers'
import * as service from 'service'
import constants from 'constants/index'

import schema from './schema'
import styles from './styles'

const useStyles = makeStyles(styles)

const Settings = ({ setTokenPrivacyPortal }) => {
  const {
    nextStep,
    toNextStep,
    setToNextStep,
    dataConsentForm,
    setDataConsentForm,
  } = useConsent()
  const snackbar = useSnackbar()
  const classes = useStyles()

  const [statusCoResponsible, setStatusCoResponsible] = useState(
    !!dataConsentForm?.profileCoResponsibleId,
  )

  const { response } = useFetch(service.dponet.profiles.get, {
    status: true,
    profileType: constants.profile.PROFILE_CLIENT_TYPE,
    withLinkedUsers: true,
    perPage: 1000,
  })

  const { control, errors, handleSubmit, setValue } = useForm({
    validationSchema: schema,
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      profileResponsibleId: dataConsentForm?.profileResponsibleId || '',
      profileCoResponsibleId: dataConsentForm?.profileCoResponsibleId || '',
    },
  })

  const onSubmit = async (data) => {
    const components = dataConsentForm.components

    try {
      const mergedData = {
        ...dataConsentForm,
        ...data,
        components: JSON.stringify(components),
      }

      setDataConsentForm(mergedData)

      const response = await service.dponet.consent.create(mergedData)

      setTokenPrivacyPortal(response?.data?.token)
      nextStep()
      setToNextStep(false)
    } catch (error) {
      snackbar.open({
        message:
          helpers.formatters.errorMessage(error, false) ||
          error?.error?.message,
        variant: 'error',
      })
    }
  }

  const handleChangeProfileCoResponsible = (event) => {
    setStatusCoResponsible(event.target.checked)
  }

  useEffect(() => {
    if (toNextStep) {
      handleSubmit(onSubmit)()

      if (errors) setToNextStep(false)
    }
    // eslint-disable-next-line
  }, [toNextStep])

  useEffect(() => {
    if (!statusCoResponsible) setValue('profileCoResponsibleId', '')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusCoResponsible])

  const profiles = [
    constants.profile.RESPONSIBLE_PROFILE_OPTION,
    ...(response?.data?.profiles ?? []),
  ]

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      id={constants.consent.NEW_CONSENT_FORM_DRIVER_STEP_4}
    >
      <Grid container spacing={2} className={classes.root}>
        <Grid item xs={12}>
          <Box>
            <Typography variant="subtitle2">Atribuir Responsável</Typography>
            <Typography color="secondary" className={classes.lineDescription}>
              É necessário atribuir um responsável para gerenciar as revogações
              dos consentimentos de titulares de dados.
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box className={classes.descriptionBox}>
            <Typography variant="subtitle2">
              Encaminhar para o responsável da organização
            </Typography>
            <Typography>
              Indique qual o responsável por operacionalizar as revogações nos
              consentimentos de titulares de dados dentro da sua organização.
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Label title="Responsável *">
            <Controller
              as={
                <Select
                  items={profiles}
                  error={!!errors.profileResponsibleId}
                  helperText={errors?.profileResponsibleId?.message}
                  fullWidth
                />
              }
              control={control}
              name="profileResponsibleId"
              mode="onBlur"
            />
          </Label>
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Switch
                checked={statusCoResponsible}
                onChange={handleChangeProfileCoResponsible}
                color="primary"
              />
            }
            label="Ativar co-responsável"
          />
        </Grid>
        {statusCoResponsible && (
          <Grid item xs={12}>
            <Label title="Co-responsável">
              <Controller
                as={
                  <Select
                    items={profiles}
                    error={!!errors.profileCoResponsibleId}
                    helperText={errors?.profileCoResponsibleId?.message}
                    fullWidth
                  />
                }
                control={control}
                name="profileCoResponsibleId"
                mode="onBlur"
              />
            </Label>
          </Grid>
        )}
      </Grid>
    </form>
  )
}

Settings.propTypes = {
  setTokenPrivacyPortal: PropTypes.func.isRequired,
}

export default Settings
