import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import { Box } from '@material-ui/core'
import {
  Check as CheckIcon,
  Close as CloseIcon,
  Lock as LockIcon,
} from '@material-ui/icons'

import useAuth from 'hooks/useAuth'

import useStyles from './styles'
import constants from 'constants/index'

const StepIcon = ({ completed, step }) => {
  const classes = useStyles()

  const {
    company: { statusId },
  } = useAuth()

  const { STEP_CERTIFICATION_INDEX, STEP_STAMP_INDEX } = constants.userSteps

  const stepsStampAndCertificate = [
    STEP_STAMP_INDEX[0],
    STEP_CERTIFICATION_INDEX[0],
  ]
  const { TRIAL_STATUS } = constants.userCompanies

  const lock =
    stepsStampAndCertificate.includes(parseInt(step)) &&
    statusId === TRIAL_STATUS

  return (
    <Box
      className={clsx(classes.stepIcon, {
        [classes.stepComplete]: completed,
      })}
    >
      {lock ? (
        <LockIcon fontSize="small" className={classes.iconClose} />
      ) : completed ? (
        <CheckIcon fontSize="small" className={classes.iconCheck} />
      ) : (
        <CloseIcon fontSize="small" className={classes.iconClose} />
      )}
    </Box>
  )
}

StepIcon.defaultProps = {
  completed: PropTypes.bool,
}

export default StepIcon
