import api from 'service/api'

const dponetAPI = api.create('dponet')
const dponetAPIWithoutTreatError = api.create('dponet', false)

const get = async ({ questionnaireId = '', ...params }) => {
  return await dponetAPI.get(`/questionnaires/${questionnaireId}`, { params })
}

const resume = async ({ ...params }) => {
  return await dponetAPI.get(
    'questionnaires/resume_questionnaires_by_company',
    { params },
  )
}

const companyCompliance = async () =>
  await dponetAPIWithoutTreatError.get(
    'questionnaires/check_company_compliance',
  )

const reply = async ({ questionnaireId, ...params }) => {
  return await dponetAPI.post(
    `/questionnaires/${questionnaireId}/reply`,
    params,
  )
}

const availableUsers = async ({ questionnaireId }) => {
  return await dponetAPI.get(
    `/questionnaires/${questionnaireId}/available_users`,
  )
}

const linkUsers = async ({ questionnaireId, ...params }) => {
  return await dponetAPI.post(
    `/questionnaires/${questionnaireId}/link_users`,
    params,
  )
}

const includeQuestion = async ({ questionnaireId, questionId }) => {
  return await dponetAPI.get(
    `/questionnaires/${questionnaireId}/include_question/${questionId}`,
  )
}

export default {
  get,
  reply,
  availableUsers,
  linkUsers,
  includeQuestion,
  resume,
  companyCompliance,
}
