import React, { useState, useEffect } from 'react'
import { isBoolean } from 'lodash'
import PropTypes from 'prop-types'

import {
  TextField,
  Box,
  MenuItem,
  Typography,
  Grid,
  Button,
  Divider,
  makeStyles,
} from '@material-ui/core'

import {
  FormContext,
  useForm,
  Controller,
  useFieldArray,
} from 'react-hook-form'

import { Label, SelectCountry } from 'components'

import useSnackbar from 'hooks/useSnackbar'
import useDataProcessOptions from 'hooks/useDataProcessOptions'
import useSelectCountry from 'hooks/useSelectCountry'
import useAuth from 'hooks/useAuth'

import SharedSoftwareForm from '../SharedSoftwareForm'

import helpers from 'helpers'

import * as service from 'service'

import constants from 'constants/index'

import styles from './styles'

const useStyles = makeStyles(styles)

const NewForm = ({ handleCloseModal, mode, submitSuccess, ...props }) => {
  const { company } = useAuth()
  const classes = useStyles()
  const [dataTreatment, setDataTreatment] = useState(props?.dataTreatment)

  const onlyShow = mode === 'show'

  const isRequiredField =
    company?.kind !== constants.userCompanies.KIND_TEMPLATE

  const {
    selectedCountries,
    setSelectedCountries,
    manySelectedCountries,
    setManySelectedCountries,
    sortNewNamesToObject,
  } = useSelectCountry()

  useEffect(() => {
    if (mode === 'edit' || mode === 'show') {
      setSelectedCountries(dataTreatment?.internationalTransfers)
    } else {
      setSelectedCountries([])
      setManySelectedCountries({})
    }
    // eslint-disable-next-line
  }, [])

  const fieldTreatment = (field) => {
    if (isRequiredField && !isBoolean(field)) return 'false'
    if (isRequiredField && isBoolean(field)) return field?.toString()
    return field?.toString()
  }

  const formMethods = useForm({
    defaultValues: {
      dataProcessId: dataTreatment.dataProcessId || '',
      dataTreatmentId: dataTreatment.id || '',
      shareProcessId:
        helpers.functions.dig(dataTreatment.shareProcess, 'id') || '',
      shareTypeId: helpers.functions.dig(dataTreatment.shareType, 'id') || '',
      description: dataTreatment.description || '',
      controllMechanism: dataTreatment.controllMechanism || '',
      sharedInAnotherSoftware: fieldTreatment(
        dataTreatment?.sharedInAnotherSoftware,
      ),
      internationalTransferDataTreatment: fieldTreatment(
        dataTreatment?.haveInternationalTransfer,
      ),
    },
  })

  const {
    handleSubmit,
    errors,
    watch,
    control,
    setValue,
    getValues,
    setError,
  } = formMethods

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'sharedSoftwares',
    keyName: 'key',
  })

  const snackbar = useSnackbar()
  const [loading, setLoading] = useState(false)
  const dataProcessOptions = useDataProcessOptions()

  const { options } = dataProcessOptions.data
  const { internalAllocationModes, shareProcesses } = options

  const setInternationalTransfersCountries = (fields) => {
    let newObject = Object.assign({}, manySelectedCountries)

    for (let index in fields) {
      const sharedSoftware = fields[index]
      newObject[`sharedSoftwares-${index}`] =
        sharedSoftware.internationalTransfers
    }

    setManySelectedCountries(newObject)
  }

  const verifyInternationalTransfer = (data) => {
    if (
      data.internationalTransferDataTreatment === 'true' &&
      selectedCountries <= 0
    ) {
      setError(
        'internationalTransferDataTreatment',
        'required',
        'Escolha no mínimo um país caso a resposta seja "Sim".',
      )
      return false
    }

    return true
  }

  useEffect(() => {
    if (getValues('internationalTransferDataTreatment') === 'false') {
      setSelectedCountries([])
    }

    // eslint-disable-next-line
  }, [getValues('internationalTransferDataTreatment')])

  useEffect(() => {
    if (getValues('sharedInAnotherSoftware') === 'true') {
      if (!dataTreatment?.sharedSoftwares?.length && !fields.length) {
        return append({ id: '', name: '', softwareAllocationType: '' })
      }

      if (!fields.length) {
        setInternationalTransfersCountries(dataTreatment?.sharedSoftwares)
        return append(dataTreatment?.sharedSoftwares)
      }
    }
    if (getValues('sharedInAnotherSoftware') === 'false') {
      setManySelectedCountries({})
      remove()
    }
    // eslint-disable-next-line
  }, [dataTreatment, getValues('sharedInAnotherSoftware')])

  const handleRemoveCountrySharedSoftwares = async (sharedSoftware, index) => {
    let newManySelectedCountries = manySelectedCountries
    delete newManySelectedCountries[`sharedSoftwares-${index}`]

    newManySelectedCountries = sortNewNamesToObject(newManySelectedCountries)
    setManySelectedCountries(newManySelectedCountries)

    for (let index in sharedSoftware?.internationalTransfers) {
      const internationalTransfer =
        sharedSoftware?.internationalTransfers[index]

      await service.dponet.sharedSoftwares.internationalTransferDestroy({
        dataProcessId: dataTreatment?.dataProcessId,
        dataTreatmentId: dataTreatment?.id,
        sharedSoftwareId: sharedSoftware?.id,
        internationalTransferId: internationalTransfer?.id,
      })
    }
  }

  const onSubmit = async (dataTreatment) => {
    const dataProcessId = dataTreatment.dataProcessId
    const dataTreatmentId = dataTreatment.dataTreatmentId
    setLoading(true)

    try {
      let response = await submitRoute({
        dataProcessId,
        dataTreatmentId,
        dataTreatment: {
          ...dataTreatment,
          international_transfer_countries: selectedCountries,
        },
      })
      await submitSharedSoftwares(
        dataTreatment.sharedSoftwares,
        response?.data?.dataTreatment?.id,
        response?.data?.dataTreatment?.dataProcessId,
      )
      submitSuccess()

      dataProcessOptions.loadData()
    } catch {
      snackbar.open({
        message: 'Ocorreu algum erro! Tente novamente!',
        variant: 'error',
      })
      setLoading(false)
    }
  }

  const submitSharedSoftwares = async (
    sharedSoftwares,
    dataTreatmentId,
    dataProcessId,
  ) => {
    if (!sharedSoftwares?.length) return

    for (let index in sharedSoftwares) {
      let sharedSoftware = sharedSoftwares[index]
      if (!!sharedSoftware.id) {
        await service.dponet.sharedSoftwares.put({
          dataProcessId,
          dataTreatmentId,
          sharedSoftwareId: sharedSoftware.id,
          sharedSoftware: {
            ...sharedSoftware,
            international_transfer_countries:
              manySelectedCountries[`sharedSoftwares-${index}`],
          },
        })
      } else {
        await service.dponet.sharedSoftwares.create({
          dataProcessId,
          dataTreatmentId,
          sharedSoftware: {
            ...sharedSoftware,
            international_transfer_countries:
              manySelectedCountries[`sharedSoftwares-${index}`],
          },
        })
      }
    }
  }

  const handleRemoveCountry = async (country) => {
    const internationalTransferCreated =
      dataTreatment?.internationalTransfers?.find(
        (internationalTransfer) =>
          internationalTransfer.country === country.country,
      )

    if (
      dataTreatment?.id &&
      dataTreatment?.dataProcessId &&
      internationalTransferCreated
    ) {
      await service.dponet.dataTreatments.internationalTransferDestroy({
        dataProcessId: dataTreatment?.dataProcessId,
        dataTreatmentId: dataTreatment?.id,
        internationalTransferId: internationalTransferCreated.id,
      })
    }
  }

  const submitRoute =
    mode === 'edit'
      ? service.dponet.dataTreatments.put
      : service.dponet.dataTreatments.create

  const addSharedSoftware = () => {
    append({ id: '', name: '', softwareAllocationType: '' })
  }

  const removeSharedSoftware = async (index, sharedSoftware) => {
    if (!!sharedSoftware?.id) {
      try {
        setLoading(true)
        await handleRemoveCountrySharedSoftwares(sharedSoftware, index)

        await service.dponet.sharedSoftwares.destroy({
          dataProcessId: dataTreatment.dataProcessId,
          dataTreatmentId: dataTreatment.id,
          sharedSoftwareId: sharedSoftware.id,
        })

        remove(index)

        // Se o meio de compartilhamento removido for o ultimo, define que nao existem meios de compartilhamentos
        if (fields.length === 1) {
          await service.dponet.dataTreatments.put({
            dataProcessId: dataTreatment.dataProcessId,
            dataTreatmentId: dataTreatment.id,
            dataTreatment: { sharedInAnotherSoftware: false },
          })
        }

        let response = await service.dponet.dataTreatments.get({
          dataProcessId: dataTreatment?.dataProcessId,
          dataTreatmentId: dataTreatment?.id,
        })

        setDataTreatment(response?.data?.dataTreatment)
        setLoading(false)

        snackbar.open({
          message: 'Meio de compartilhamento removido com sucesso!',
          variant: 'success',
        })

        setValue(
          'sharedInAnotherSoftware',
          response?.data?.dataTreatment?.sharedInAnotherSoftware?.toString() ||
            'false',
        )
      } catch {
        setLoading(false)
        snackbar.open({
          message:
            'Ocorreu um erro ao tentar remover o meio de compartilhamento! Por favor, tente novamente mais tarde.',
          variant: 'error',
        })
      }
    } else {
      remove(index)

      let newManySelectedCountries = manySelectedCountries
      delete newManySelectedCountries[`sharedSoftwares-${index}`]

      newManySelectedCountries = sortNewNamesToObject(newManySelectedCountries)
      setManySelectedCountries(newManySelectedCountries)

      // Se o meio de compartilhamento removido for o ultimo, define que nao existem meios de compartilhamentos
      if (fields.length === 1) {
        setValue('sharedInAnotherSoftware', 'false')
      }
      setLoading(false)
      snackbar.open({
        message: 'Meio de compartilhamento removido com sucesso!',
        variant: 'success',
      })
    }
  }

  const submitWithValidations = (data) => {
    const match = verifyInternationalTransfer(data)

    if (match) onSubmit(data)
  }

  return (
    <FormContext {...formMethods}>
      <form onSubmit={handleSubmit(submitWithValidations)}>
        <Grid
          container
          justify="flex-start"
          alignItems="center"
          className={classes.root}
        >
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Box mt={3} mr={2}>
              <Controller as={<></>} name="dataProcessId" control={control} />
              <Controller as={<></>} name="dataTreatmentId" control={control} />
              <Controller
                as={
                  <TextField
                    label="Com quem o dado é compartilhado?"
                    select
                    disabled={onlyShow}
                    color="primary"
                    variant="outlined"
                    error={!!errors.shareProcessId}
                    helperText={errors?.shareProcessId && 'Preencha este campo'}
                    fullWidth
                  >
                    {shareProcesses &&
                      shareProcesses.map((shrProcess) => (
                        <MenuItem key={shrProcess.id} value={shrProcess.id}>
                          <Typography>{shrProcess.name}</Typography>
                        </MenuItem>
                      ))}
                    <MenuItem key={'another'} value={'another'}>
                      <Typography>Outro</Typography>
                    </MenuItem>
                  </TextField>
                }
                control={control}
                rules={{ required: true }}
                name="shareProcessId"
                mode="onBlur"
              />
            </Box>
            {watch('shareProcessId') === 'another' && (
              <Box mr={2} mt={1}>
                <Controller
                  as={
                    <TextField
                      label="Outro: com quem o dado é compartilhado?"
                      color="primary"
                      variant="outlined"
                      error={!!errors.anotherShareProcess}
                      helperText={
                        errors?.anotherShareProcess && 'Preencha este campo'
                      }
                      fullWidth
                    />
                  }
                  control={control}
                  rules={{ required: true }}
                  name="anotherShareProcess"
                  mode="onBlur"
                />
              </Box>
            )}
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Box mt={3} mr={2}>
              <Controller
                as={
                  <TextField
                    label="Por quê o dado é compartilhado?"
                    color="primary"
                    variant="outlined"
                    disabled={onlyShow}
                    error={!!errors.description}
                    helperText={errors?.description && 'Preencha este campo'}
                    fullWidth
                  />
                }
                control={control}
                rules={{ required: true }}
                name="description"
                mode="onBlur"
              />
            </Box>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Box mt={3} mr={2}>
              <Controller
                as={
                  <TextField
                    label="Como o dado é compartilhado?"
                    color="primary"
                    variant="outlined"
                    disabled={onlyShow}
                    error={!!errors.controllMechanism}
                    helperText={
                      errors?.controllMechanism && 'Preencha este campo'
                    }
                    fullWidth
                  />
                }
                control={control}
                rules={{ required: true }}
                name="controllMechanism"
                mode="onBlur"
              />
            </Box>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Box mt={3} mr={2}>
              <Controller
                as={
                  <TextField
                    label="Tipo de compartilhamento"
                    select
                    color="primary"
                    variant="outlined"
                    disabled={onlyShow}
                    error={!!errors.shareTypeId}
                    helperText={errors?.shareTypeId && 'Preencha este campo'}
                    fullWidth
                  >
                    {internalAllocationModes.map((allocationMode) => (
                      <MenuItem
                        key={allocationMode.id}
                        value={allocationMode.id}
                      >
                        <Typography>{allocationMode.name}</Typography>
                      </MenuItem>
                    ))}
                  </TextField>
                }
                control={control}
                rules={{ required: true }}
                name="shareTypeId"
                mode="onBlur"
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box mr={2}>
              <Label
                title="Este compartilhamento é uma transferência internacional?"
                description='Caso a resposta for "Sim" selecione os países que é feito o compartilhamento.'
                item
              >
                <Controller
                  as={
                    <TextField
                      select
                      color="primary"
                      variant="outlined"
                      disabled={onlyShow}
                      error={!!errors.internationalTransferDataTreatment}
                      helperText={
                        errors?.internationalTransferDataTreatment?.message
                      }
                      fullWidth
                    >
                      <MenuItem value="false">
                        <Typography>Não</Typography>
                      </MenuItem>
                      <MenuItem value="true">
                        <Typography>Sim</Typography>
                      </MenuItem>
                    </TextField>
                  }
                  control={control}
                  rules={{ required: isRequiredField }}
                  name="internationalTransferDataTreatment"
                  mode="onBlur"
                />
              </Label>
            </Box>
            {watch('internationalTransferDataTreatment') === 'true' && (
              <Box
                className={classes.internationalTransferField}
                m={2}
                ml={0}
                p={2}
                pb={3}
              >
                <SelectCountry
                  withTable
                  disabled={onlyShow}
                  label="Qual o país destinatário?"
                  handleRemoveCountry={handleRemoveCountry}
                />
              </Box>
            )}
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Box mt={3} mr={2}>
              <Controller
                as={
                  <TextField
                    label="Para realizar este compartilhamento, você utiliza alguma plataforma, software ou aplicativo?"
                    select
                    color="primary"
                    variant="outlined"
                    disabled={onlyShow}
                    error={!!errors.sharedInAnotherSoftware}
                    helperText={
                      errors?.sharedInAnotherSoftware && 'Preencha este campo'
                    }
                    fullWidth
                  >
                    <MenuItem value="false">
                      <Typography>Não</Typography>
                    </MenuItem>
                    <MenuItem value="true">
                      <Typography>Sim</Typography>
                    </MenuItem>
                  </TextField>
                }
                control={control}
                rules={{ required: isRequiredField }}
                name="sharedInAnotherSoftware"
                mode="onBlur"
              />
            </Box>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            {watch('sharedInAnotherSoftware') === 'true' && (
              <Box mt={3} mr={2}>
                <Divider />
                {fields?.map((item, index) => (
                  <SharedSoftwareForm
                    sharedSoftware={item}
                    key={item.key}
                    index={index}
                    removeSharedSoftware={removeSharedSoftware}
                    disabled={onlyShow}
                  />
                ))}
                {!onlyShow && (
                  <Box display="flex" justifyContent="flex-end" mt={2}>
                    <Button variant="outlined" onClick={addSharedSoftware}>
                      Adicionar Meio de Compartilhamento
                    </Button>
                  </Box>
                )}
              </Box>
            )}
          </Grid>
        </Grid>
        {!onlyShow && (
          <Box display="flex" justifyContent="flex-start" my={2} mr={2}>
            <Box pr={1}>
              <Button
                type="button"
                variant="outlined"
                color="secondary"
                onClick={handleCloseModal}
              >
                Voltar
              </Button>
            </Box>
            <Button
              disabled={loading}
              type="submit"
              variant="contained"
              color="primary"
            >
              {loading ? 'Aguarde...' : 'Salvar'}
            </Button>
          </Box>
        )}
      </form>
    </FormContext>
  )
}

NewForm.propTypes = {
  handleCloseModal: PropTypes.func,
  mode: PropTypes.string,
  submitSuccess: PropTypes.func,
}

export default NewForm
