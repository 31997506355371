import permissionsConstants from 'constants/permissions'

const FUNDAMENTAL_REQUIREMENTS = 'Requisitos Fundamentais'

const HELPER_TEXT = {
  1: [
    'A lei exige que a organização realize ações com o objetivo de conscientizar, educar e treinar seus colaboradores mais importantes, como a diretoria, gestores e líderes de departamento, sobre o tema da proteção de dados pessoais e LGPD. A organização deve promover a compreensão dos conceitos fundamentais relacionados à proteção de dados, por meio de treinamentos, cursos ou outras atividades formativas, visando iniciar uma nova cultura de proteção de dados pessoais dentro da empresa.',
    'A realização do treinamento de Conceitos Fundamentais, que se encontra na primeira etapa do acesso à plataforma DPOnet, atende ao requisitopois ao menos um de seus colaboradores-chave realiza a conscientização sobre compreensão da matéria e início de uma nova cultura de proteção de dados pessoais. Além disso, a plataforma realiza, no primeiro acesso, o Treinamento  de Noções Básicas de Introdução à LGPD, com emissão de certificado.',
  ],
  2: [
    'A lei exige que a organização tenha mecanismos na organização que visam manter viva a cultura de proteção e privacidade de dados pessoais. Esses mecanismos podem incluir a realização de tarefas contínuas relacionadas ao tema, como revisões regulares de políticas e procedimentos, bem como a disseminação de conteúdos em textos e vídeos, como newsletters ou outros meios de comunicação, que promovam a conscientização e estimulem o debate sobre a proteção de dados pessoais entre os colaboradores. O objetivo é garantir que a organização mantenha um ambiente de constante atenção e engajamento com a importância da proteção dos dados pessoais.',
    'A DPOnet indica ao Representante que sejam realizadas tarefas contínuas para resolução de riscos, como treinamentos, além de enviar conteúdos por e-mail à empresa para que sejam disseminados.',
  ],
  3: [
    'A lei exige que a organização tenha mecanismos que permitem mapear as atividades de tratamento de dados pessoais e identificar a qual departamento essas atividades estão relacionadas. Isso significa que a organização possui processos ou ferramentas que permitem a catalogação e o registro das atividades de tratamento de dados pessoais realizadas em diferentes áreas ou setores da empresa. Esses mecanismos auxiliam na organização e na gestão eficiente dos dados pessoais, permitindo uma visão clara de quais departamentos são responsáveis por determinadas atividades e facilitando a implementação de medidas de proteção adequadas em cada contexto específico.',
    'Dentro da DPOnet, a etapa "Processos" faz o mapeamento automático com assertividade média-alta sobre os departamentos existentes dentro da empresa, de acordo com o segmento a qual pertence, com a divisão de departamentos. O mapeamento dos processos, ainda que não comporte uma assertividade total com a realidade da empresa, conta com os requisitos mínimos fundamentais para a identificação dos dados, titulares, salvaguardas, compartilhamentos, etc.',
  ],
  4: [
    'A lei exige que a organização tenha mecanismos que permitem identificar a finalidade de cada uma das atividades de tratamento de dados pessoais. Isso significa que a organização possui processos ou procedimentos que garantem a documentação e o registro claro das finalidades para as quais os dados pessoais são coletados, utilizados ou processados. Esses mecanismos podem incluir a elaboração de políticas internas, a criação de registros ou bancos de dados específicos, ou ainda a implementação de sistemas de gerenciamento de dados que rastreiam e documentam a finalidade de cada atividade de tratamento de dados pessoais. Essa prática é essencial para garantir a conformidade com as leis de proteção de dados e para assegurar que os dados sejam utilizados de forma adequada, transparente e em conformidade com as expectativas e direitos dos indivíduos envolvidos.',
    'Dentro da DPOnet, a etapa "Processos" conta com mecanismos para identificação da finalidade de cada processo pré-mapeado e sugerido pela plataforma.',
  ],
  5: [
    'A lei exige que a organização tenha mecanismos que permitem identificar os dados pessoais tratados de acordo com cada categoria de titulares, como colaboradores, clientes, sócios, entre outros. Isso significa que a organização possui processos ou procedimentos que permitem categorizar e classificar os dados pessoais coletados e processados com base nos diferentes grupos de titulares aos quais esses dados pertencem. Esses mecanismos podem incluir a criação de bases de dados segmentadas por categorias de titulares, a implementação de sistemas de gestão de dados que possibilitem a identificação e o rastreamento dos dados pessoais em conformidade com as categorias estabelecidas, ou ainda a utilização de ferramentas de classificação e etiquetagem dos dados. Essa prática é importante para garantir a adequada proteção e gestão dos dados pessoais, além de facilitar o cumprimento das obrigações legais e regulatórias específicas aplicáveis a cada categoria de titulares.',
    'Dentro da DPOnet, a etapa "Processos" conta com mecanismos para identificação dos dados pessoais tratados de acordo com a categoria de titulares, de cada processo pré-mapeado e sugerido pela plataforma.',
  ],
  6: [
    'A lei exige que a organização tenha mecanismos que permitem classificar os dados pessoais em pelo menos duas categorias: "dados pessoais não sensíveis" e "dados pessoais sensíveis". Isso significa que a organização possui processos ou procedimentos que possibilitam a identificação e a distinção entre essas duas categorias de dados. Esses mecanismos podem incluir a criação de políticas internas que definem os critérios de classificação, a implementação de sistemas de gerenciamento de dados que permitem a marcação ou etiquetagem adequada dos dados, ou ainda a realização de análises de risco e impacto para determinar a sensibilidade dos dados pessoais coletados. Essa prática é importante para garantir a aplicação de medidas adequadas de segurança e proteção, já que os dados pessoais sensíveis requerem um nível mais elevado de cuidado e proteção devido ao seu potencial de impacto na privacidade e na vida das pessoas envolvidas.',
    'Dentro da DPOnet, a etapa "Processos" conta com mecanismos para identificação de categorias de classificação de dados pessoais como sensíveis, de cada processo pré-mapeado e sugerido pela plataforma.',
  ],
  7: [
    'A lei exige que a organização tenha mecanismos que permitem identificar o ciclo de vida dos dados pessoais, contemplando pelo menos três aspectos: o local de armazenamento dos dados, o tempo de armazenamento e a forma como são dispostos ou descartados. Isso significa que a organização possui processos ou procedimentos que permitem rastrear e gerenciar o fluxo dos dados pessoais desde a coleta até a sua eliminação ou descarte adequado. Esses mecanismos podem incluir a documentação dos locais físicos ou virtuais onde os dados são armazenados, a definição de prazos de retenção para cada tipo de dado e a implementação de políticas e práticas que garantam a eliminação ou anonimização segura dos dados após o término do seu período de armazenamento necessário. Essa abordagem é fundamental para assegurar a conformidade com as leis de proteção de dados e para minimizar riscos de acesso não autorizado, uso indevido ou retenção excessiva de informações pessoais.',
    'Dentro da DPOnet, a etapa "Processos" conta com mecanismos para identificação do ciclo de vida dos dados pessoais cada em processo pré-mapeado e sugerido pela plataforma.',
  ],
  8: [
    'A lei exige que a organização tenha mecanismos que permitem identificar e rastrear os compartilhamentos dos dados pessoais. Isso envolve a capacidade de registrar informações importantes, como quem são os destinatários desses compartilhamentos e qual é a finalidade específica para a qual os dados são compartilhados. Esses mecanismos são essenciais para garantir a transparência, a conformidade legal e o controle adequado sobre o compartilhamento de dados pessoais. Eles possibilitam que a organização tenha uma visão clara de como os dados estão sendo compartilhados, com quem e com qual finalidade, permitindo uma gestão mais eficiente e segura dos dados pessoais, além de auxiliar nas avaliações de risco e nas revisões de conformidade.',
    'Dentro da DPOnet, a etapa "Processos" conta com mecanismos para identificação do compartilhamento dos dados pessoais cada em processo pré-mapeado e sugerido pela plataforma.',
  ],
  9: [
    'A lei exige que a organização tenha mecanismos que permitem identificar e rastrear os compartilhamentos dos dados pessoais. Isso envolve a capacidade de registrar informações importantes, como quem são os destinatários desses compartilhamentos e qual é a finalidade específica para a qual os dados são compartilhados. Esses mecanismos são essenciais para garantir a transparência, a conformidade legal e o controle adequado sobre o compartilhamento de dados pessoais. Eles possibilitam que a organização tenha uma visão clara de como os dados estão sendo compartilhados, com quem e com qual finalidade, permitindo uma gestão mais eficiente e segura dos dados pessoais, além de auxiliar nas avaliações de risco e nas revisões de conformidade.',
    'Dentro da DPOnet, a etapa "Processos" conta com mecanismos para identificação da necessidade e proporcionalidade no tratamento de todos os dados pessoais cada em processo pré-mapeado e sugerido pela plataforma.',
  ],
  10: [
    'A lei exige que a organização tenha mecanismos que permitem identificar as hipóteses legais de tratamento de dados pessoais previstas na Lei 13.709/2018, nos Artigos 7º e 11. Essas hipóteses legais são os fundamentos jurídicos que autorizam as atividades de tratamento de dados pessoais realizadas pela organização. Os mecanismos podem incluir a criação de políticas internas que mapeiam as bases legais para cada atividade de tratamento, a implementação de sistemas de gerenciamento de dados que registram as hipóteses legais aplicáveis a cada processamento de dados ou a realização de análises jurídicas regulares para garantir a conformidade com as disposições da lei. Essa prática é essencial para assegurar que todas as atividades de tratamento de dados pessoais sejam realizadas em conformidade com as bases legais estabelecidas pela legislação de proteção de dados pessoais, garantindo assim a segurança e a legitimidade dessas operações.',
    'A DPOnet realiza o enquadramento legal de todos os processos mapeados, contando com um time jurídico especializado.',
  ],
  11: [
    'A lei exige que a organização tenha mecanismos que permitem realizar uma avaliação de riscos para todas as atividades de tratamento de dados pessoais. Essa avaliação tem como objetivo identificar e mensurar os riscos associados ao tratamento de dados, utilizando critérios objetivos e justificáveis em vez de critérios subjetivos. Os mecanismos podem incluir a implementação de metodologias de avaliação de riscos reconhecidas, a criação de diretrizes ou políticas internas que estabelecem os critérios utilizados na análise de risco, a realização de avaliações regulares em conformidade com esses critérios e a documentação dos resultados obtidos. Essa prática é fundamental para a gestão efetiva de riscos de privacidade e segurança relacionados ao tratamento de dados pessoais, permitindo que a organização identifique áreas de maior risco e tome medidas adequadas para mitigar esses riscos. Além disso, o uso de critérios objetivos e justificáveis ajuda a garantir uma abordagem consistente e transparente na avaliação de riscos em conformidade com as melhores práticas e as exigências legais aplicáveis.',
    'A DPOnet possui uma matriz de riscos avaliados com base em diversas variáveis, dentre elas, o tipo de dado tratado, a categoria do titular, as formas de salvaguarda e o compartilhamento, dentre outros. Tais critérios são objetivos e considerados para a classificação em quatro níveis de risco (Baixo, Médio, Alto e Severo).',
  ],
  12: [
    'A lei exige que a organização tenha mecanismos que permitem registrar e acompanhar as alterações nas atividades de tratamento de dados pessoais, mantendo o mapeamento dessas atividades atualizado. Isso implica em estabelecer processos e procedimentos para documentar todas as mudanças relevantes, como adição, remoção ou modificação de atividades de tratamento, além de manter um registro atualizado dessas informações. Esses mecanismos são importantes para garantir a conformidade contínua com as leis de proteção de dados, bem como para uma gestão eficaz e transparente das informações pessoais. Além disso, ao manter o mapeamento atualizado, a organização pode identificar quais atividades de tratamento estão sendo realizadas, os dados envolvidos, as finalidades e os responsáveis por cada etapa, facilitando a adoção de medidas de segurança e governança adequadas.',
    'A DPOnet possui histórico de alterações com a identificação do usuário, a data e o horário em que a alteração foi realizada. O mapeamento é atualizado em tempo real, bem como o Relatório de Impacto, assim que as informações são salvas.',
  ],
  13: [
    'A lei exige que a organização conte com a presença de profissionais especializados em proteção de dados pessoais dentro da organização ou contratados de terceiros. Esses profissionais estão disponíveis para responder a dúvidas diárias relacionadas à proteção de dados e fornecer orientações sobre as atividades de tratamento de dados pessoais. Eles possuem conhecimento aprofundado sobre as leis e regulamentos de privacidade, bem como as melhores práticas de segurança da informação. Sua função é oferecer suporte aos colaboradores da organização, esclarecer questões e garantir que as atividades de tratamento de dados sejam conduzidas em conformidade com as normas de proteção de dados. Ter esses especialistas à disposição é fundamental para garantir a conformidade, a privacidade e a segurança dos dados pessoais, bem como para promover uma cultura de proteção de dados dentro da organização.',
    'A DPOnet, além de ser uma plataforma, conta com suporte completo das responsabilidades envolvidas na atividade de DPO. Por isso, somos o DPO da sua organização, fornecendo também esse serviço conforme os limites exigidos pela LGPD. Dessa forma, fornecemos profissionais especializados em proteção de dados pessoais de forma externa.',
  ],
  14: [
    'A lei exige que a organização tenha mecanismos para o registro e gerenciamento de incidentes relacionados a dados pessoais. Esses mecanismos envolvem a implementação de processos e procedimentos para registrar e acompanhar os incidentes que envolvem violações, acessos não autorizados, perda ou divulgação não intencional de dados pessoais. Eles podem incluir a criação de um sistema de registro de incidentes, a definição de responsabilidades claras para notificação e investigação de incidentes, a aplicação de medidas corretivas e preventivas, além da análise de tendências e padrões para aprimorar a segurança dos dados. O objetivo é garantir uma resposta rápida e eficaz aos incidentes, minimizando o impacto sobre os indivíduos afetados e cumprindo as obrigações legais de notificação, além de aprimorar continuamente as práticas de proteção de dados e prevenir futuros incidentes.',
    'Dentro da DPOnet, no item "Incidentes", o usuário da plataforma pode registrar um incidente de dados pessoais, que receberá suporte do nosso time especializado e DPO para tratamento.',
  ],
  15: [
    'A lei exige que a organização tenha mecanismos para identificar a necessidade de notificar a Autoridade Nacional de Proteção de Dados (ANPD) e realizar a notificação a ela no caso de incidentes de dados pessoais que apresentem graves riscos de lesão ou dano aos titulares dos dados. Esses procedimentos visam garantir que a organização esteja em conformidade com as obrigações legais de notificação estabelecidas pela legislação de proteção de dados. Eles podem incluir a definição de critérios para determinar a gravidade e os riscos associados a um incidente, a designação de responsáveis pela avaliação e decisão de notificação à ANPD, a preparação de um plano de resposta a incidentes que inclua a notificação à autoridade competente, bem como a execução efetiva dessas etapas. Esses procedimentos são fundamentais para garantir que a organização tome as medidas apropriadas em casos de incidentes significativos, protegendo os direitos e interesses dos titulares de dados pessoais e cumprindo as obrigações legais estabelecidas.',
    'A DPOnet possui canal de comunicação com titulares de dados e solicitações à ANPD, além de realizar todo o suporte e comunicação com a ANPD em caso de solicitações ou reportar incidentes, fornecendo, inclusive, pareceres técnicos.',
  ],
  16: [
    'A lei exige que a organização tenha mecanismos para a geração de notificações aos titulares de dados no caso de incidentes envolvendo dados pessoais. Esses procedimentos visam garantir que a organização cumpra com suas obrigações legais de notificar os indivíduos afetados por um incidente de dados que possa resultar em riscos para seus direitos e liberdades. Os procedimentos podem incluir a definição de critérios para determinar quando uma notificação ao titular de dados é necessária, a criação de modelos de notificação que contenham informações relevantes sobre o incidente, os riscos envolvidos e as medidas de proteção adotadas, além da definição de responsabilidades claras para a execução e entrega dessas notificações. Esses procedimentos são essenciais para garantir a transparência, a comunicação eficaz e a proteção dos direitos dos titulares de dados, fortalecendo a confiança na organização e demonstrando um compromisso com a privacidade e a segurança dos dados pessoais.',
    'A DPOnet realiza todo o suporte técnico e jurídico para a notificação de titulares de dados, após análise prévia de necessidade de notificação.',
  ],
  17: [
    'A lei exige que a organização tenha um procedimento padrão para identificar a causa de um incidente de dados pessoais e corrigir a causa da violação. Esse procedimento visa garantir que a organização conduza uma análise detalhada do incidente, buscando entender as razões que levaram à violação e implementando medidas corretivas efetivas para prevenir recorrências no futuro. O procedimento pode envolver a formação de uma equipe responsável pela investigação, a coleta de evidências relevantes, a revisão dos controles de segurança existentes, a identificação de falhas ou lacunas nos processos e sistemas, e a implementação de ações corretivas adequadas para solucionar as causas identificadas. Dessa forma, a organização pode aprender com o incidente, fortalecer seus sistemas de proteção de dados e evitar futuras violações, demonstrando um compromisso contínuo com a segurança e privacidade dos dados pessoais.',
    'A DPOnet realiza todo o suporte técnico e jurídico para investigar as causas dos incidentes reportados e sugere medidas para correção da causa da violação, indicando as melhores práticas.',
  ],
  18: [
    'A lei exige que a organização tenha mecanismos para geração de um Relatório de Impacto à Proteção de Dados (RIPD) na organização. Esse relatório contém a identificação e detalhes das atividades de tratamento de dados pessoais que representam maiores riscos aos direitos e liberdades individuais dos titulares de dados, de acordo com um critério objetivo. O relatório inclui informações essenciais, como o nome da atividade de tratamento, a finalidade, as categorias de titulares de dados envolvidos, a classificação dos dados, o ciclo de vida dos dados, os compartilhamentos, a análise de necessidade e proporcionalidade da atividade e a base legal para o tratamento. Essa prática visa promover a transparência e a conformidade com as leis de proteção de dados, além de permitir uma avaliação completa dos riscos e uma análise adequada das medidas de mitigação necessárias. O RIPD é uma ferramenta importante para a organização compreender e gerenciar os riscos associados ao tratamento de dados pessoais, adotando medidas de proteção adequadas para salvaguardar os direitos e liberdades dos titulares de dados.',
    'A plataforma DPOnet gera o Relatório de Impacto à Proteção de Dados a qualquer momento, com as informações atualizadas de acordo com os dados inseridos pelos usuários das plataformas. Todos os requisitos recomendados aparecem no Relatório.',
  ],
  19: [
    'A lei exige que a organização tenha implementado um canal facilitado de atendimento ao titular de dados pessoais pela organização, visando permitir que todos os direitos descritos na Lei Geral de Proteção de Dados (LGPD) sejam exercidos. Esse canal tem como objetivo facilitar o acesso dos titulares de dados à organização para exercerem seus direitos, como o direito de acesso, retificação, exclusão, portabilidade, revogação de consentimento, entre outros previstos na legislação. Por meio desse canal, os titulares podem entrar em contato com a organização de forma direta e simplificada, podendo fazer solicitações, apresentar queixas ou obter informações relacionadas aos seus dados pessoais. A implementação de um canal facilitado de atendimento demonstra o compromisso da organização em garantir a transparência, o respeito aos direitos dos titulares de dados e a conformidade com a legislação de proteção de dados.',
    'Através do Portal da Privacidade, oferecido pela DPOnet todas as empresas possuem um canal de atendimento ao titular de dados, e a checagem de identidade é feita pelo nosso atendimento.',
  ],
  20: [
    'A lei exige que a organização tenha mecanismos para identificar o não cumprimento de exigências legais e de medidas de segurança administrativas e técnicas, aplicáveis ao seu ramo de atuação e ao tamanho de sua atividade econômica. Esses mecanismos têm como objetivo gerenciar essas exigências de forma efetiva, estabelecendo prazos e planos de ação para implementá-las e, posteriormente, identificar o que foi efetivamente implementado. Isso envolve a criação de um sistema de monitoramento e controle, a análise periódica das exigências legais e das medidas de segurança aplicáveis, a identificação de lacunas e não conformidades, a definição de prazos realistas para implementação das ações corretivas, a execução dos planos de ação estabelecidos e o acompanhamento contínuo para verificar a implementação efetiva das medidas. Esses mecanismos visam garantir que a organização esteja em conformidade com as exigências legais e de segurança aplicáveis, demonstrando um compromisso com a proteção de dados e a privacidade dos titulares de dados pessoais.',
    'Através da plataforma DPOnet, dentro do item "Melhoria Contínua", é possível realizar a gestão de todas as não-conformidades encontradas no mapeamento e nos questionários, bem como vincular medidas de proteção, estabelecer prazos e planos de ação, e controlar documentando o que foi implementado.',
  ],
  21: [
    'A lei exige que a organização tenha mecanismos para identificar, em cada uma das atividades de tratamento de dados pessoais, a possibilidade de haver o tratamento de dados de menores de 18 anos. Esses mecanismos visam garantir o cumprimento das disposições legais relacionadas à proteção de dados de crianças e adolescentes, considerando que a legislação em alguns países, como o Regulamento Geral de Proteção de Dados (GDPR) da União Europeia, estabelece requisitos específicos para o tratamento de dados de menores de idade. A organização pode implementar medidas como análise das finalidades e dos contextos em que ocorre o tratamento de dados, verificação da idade dos titulares de dados por meio de métodos apropriados, obtenção do consentimento dos responsáveis legais quando necessário e adoção de salvaguardas adicionais para garantir a proteção dos direitos e privacidade dos menores de idade. Esses mecanismos são importantes para assegurar que a organização esteja em conformidade com as obrigações legais e éticas relacionadas ao tratamento de dados pessoais de menores.',
    'Dentro da DPOnet, na etapa "Mapeamento de Processos", é possível identificar se o titular do dado tratado é um menor de idade, registrando no processo.',
  ],
  22: [
    'As organizações precisam ter nomeado um Encarregado pelo Tratamento de Dados Pessoais (DPO). A identidade e as informações de contato do encarregado devem ser divulgadas publicamente, de forma clara e objetiva, preferencialmente no sítio eletrônico do controlador. As atividades do encarregado consistem em: I - aceitar reclamações e comunicações dos titulares, prestar esclarecimentos e adotar providências; II - receber comunicações da autoridade nacional e adotar providências; III - orientar os funcionários e os contratados da entidade a respeito das práticas a serem tomadas em relação à proteção de dados pessoais; e IV - executar as demais atribuições determinadas pelo controlador ou estabelecidas em normas complementares',
    'A DPOnet desempenha um papel fundamental como DPO Jurídico para as organizações que a contratam, exercendo os itens acima mencionados como obrigação do DPO.',
  ],
}

const QUESTIONNAIRE_REPLY_DRIVER_STEP_1 = 'questionnaire-reply-driver-step-1'
const QUESTIONNAIRE_REPLY_DRIVER_STEP_2 = 'questionnaire-reply-driver-step-2'
const QUESTIONNAIRE_REPLY_DRIVER_STEP_3 = 'questionnaire-reply-driver-step-3'

const QUESTIONNAIRE_REPLY_DRIVER_STEPS_OBJECT_WITH_PERMISSIONS = {
  ['#' + QUESTIONNAIRE_REPLY_DRIVER_STEP_1]:
    permissionsConstants.QUESTIONNAIRES.LINK_USERS,
}

const QUESTIONNAIRE_REPLY_DRIVER_STEPS_OBJECT = [
  {
    element: '#' + QUESTIONNAIRE_REPLY_DRIVER_STEP_1,
    popover: {
      description: 'Inclua um responsável para responder ao questionário.',
    },
  },
  {
    element: '#' + QUESTIONNAIRE_REPLY_DRIVER_STEP_2,
    popover: {
      description:
        'Caso a alternativa <strong>Não se aplica</strong> seja escolhida, as questões permanecerão nesta aba e podem ser incluídas novamente no questionário.',
    },
  },
  {
    element: '#' + QUESTIONNAIRE_REPLY_DRIVER_STEP_3,
    popover: {
      description:
        'Escolha umas das alternativas, podendo ser <strong>SIM</strong>, <strong>NÃO</strong> ou <strong>PARCIAL</strong>, de acordo com a realidade da sua organização.',
    },
  },
]

const USER_QUESTIONS_TAB_VALUE = 'usersQuestions'
const INACTIVE_QUESTIONS_TAB_VALUE = 'inactiveQuestions'
const RECOMMENDED_QUESTIONS_TAB_VALUE = 'recommendedQuestions'

const questionnaire = {
  FUNDAMENTAL_REQUIREMENTS,
  HELPER_TEXT,
  QUESTIONNAIRE_REPLY_DRIVER_STEPS_OBJECT,
  QUESTIONNAIRE_REPLY_DRIVER_STEPS_OBJECT_WITH_PERMISSIONS,
  QUESTIONNAIRE_REPLY_DRIVER_STEP_1,
  QUESTIONNAIRE_REPLY_DRIVER_STEP_2,
  QUESTIONNAIRE_REPLY_DRIVER_STEP_3,
  USER_QUESTIONS_TAB_VALUE,
  INACTIVE_QUESTIONS_TAB_VALUE,
  RECOMMENDED_QUESTIONS_TAB_VALUE,
}

export default questionnaire
