import React, { useEffect, useState } from 'react'
import { isEmpty } from 'lodash'
import { TextField } from '@material-ui/core'
import { Controller, useForm } from 'react-hook-form'

import { Card, Label, LoadingFeedback } from 'components'

import useIncidentManegement from 'hooks/useIncidentManegement'

import * as service from 'service'
import schema from './schema'

const DetectionStep = () => {
  const [loading, setLoading] = useState(false)

  const {
    incident,
    setIncident,
    handleNext,
    wantsToAdvanceStep,
    resetAdvanceStep,
  } = useIncidentManegement()

  const { control, errors, getValues, triggerValidation } = useForm({
    defaultValues: {
      discoveryDescription: incident?.discoveryDescription || '',
    },
    validationSchema: schema,
  })

  const onSubmit = async () => {
    setLoading(true)

    try {
      const validForm = await triggerValidation()
      const data = getValues()

      if (!validForm) return

      if (isEmpty(incident)) {
        await createIncident(data)
      } else {
        await updateIncident(data)
      }
      resetAdvanceStep()
      handleNext()
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
      resetAdvanceStep()
    }
  }

  const createIncident = async (data) => {
    const response = await service.dponet.incidents.create({
      discoveryDescription: data.discoveryDescription,
    })

    const incidentResponse = response?.data?.incident

    setIncident(incidentResponse)
  }

  const updateIncident = async (data) => {
    const response = await service.dponet.incidents.put({
      incidentId: incident.id,
      incident: {
        discoveryDescription: data.discoveryDescription,
        detectionForm: true,
      },
    })

    const incidentResponse = response?.data?.incident

    setIncident(incidentResponse)
  }

  useEffect(() => {
    if (wantsToAdvanceStep) {
      onSubmit()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wantsToAdvanceStep])

  return (
    <Card title="Detecção do Incidente">
      <LoadingFeedback open={loading} />

      <Label
        title="Descreva como ficou sabendo a respeito do incidente:"
        xs={12}
        item
      >
        <Controller
          as={
            <TextField
              fullWidth
              variant="outlined"
              multiline
              minRows={4}
              error={!!errors.discoveryDescription}
              helperText={errors?.discoveryDescription?.message}
            />
          }
          control={control}
          name="discoveryDescription"
        />
      </Label>
    </Card>
  )
}

export default DetectionStep
