import * as yup from 'yup'

const schemaFinality = yup.object().shape({
  question5: yup.string().required(),
  question6: yup.string().required(),
  question7: yup.string().required(),
  question8: yup.string().required(),
  question9: yup.string().required(),
  question10: yup.string().required(),
  question11: yup.string().required(),
})

export default schemaFinality
