import alertInfo from './alertInfo'
import categoryOptions from './categoryOptions'
import controlByCategory from './controlByCategory'
import dataMultiOptions from './dataMultiOptions'
import manageOptions from './manageOptions'
import status from './status'
import statusChipColor from './statusChipColor'
import statusDriverId from './statusDriverId'
import statusDriverSteps from './statusDriverSteps'

const questionControls = {
  alertInfo,
  categoryOptions,
  controlByCategory,
  dataMultiOptions,
  manageOptions,
  status,
  statusChipColor,
  statusDriverId,
  statusDriverSteps,
}

export default questionControls
