import React, { useState } from 'react'
import PropTypes from 'prop-types'
import useSnackbar from 'hooks/useSnackbar'
import useDataProcessOptions from 'hooks/useDataProcessOptions'
import useDataCollected from 'hooks/useDataCollected'

import {
  Modal,
  Grid,
  Typography,
  Paper,
  Button,
  IconButton as IconButtonMaterial,
} from '@material-ui/core'

import { MenuButton } from 'components/'
import useAuth from 'hooks/useAuth'

import FormModal from './Form'

import { PlusCircle } from 'react-feather'
import CloseIcon from '@material-ui/icons/Close'

const DataCollectedModal = ({
  dataCollected,
  classes,
  toSelectItems,
  selectedFields,
  mode,
  dataProcessId,
  children,
  refresh,
}) => {
  const [open, setOpen] = useState(false)
  const edit = mode === 'edit'
  const snackbar = useSnackbar()
  const { company } = useAuth()
  const dataProcessOptions = useDataProcessOptions()
  const dataCollectedHook = useDataCollected()

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const submitSuccess = () => {
    snackbar.open({
      message: `Dados tratados ${edit ? 'atualizados' : 'criado'} com sucesso`,
      variant: 'success',
    })
    handleClose()
    dataProcessOptions.loadData(company.id)
    dataCollectedHook.loadData(dataProcessId, '')
  }

  return (
    <>
      {edit ? (
        <MenuButton>
          <Button color="secondary" fullWidth onClick={handleOpen}>
            Editar
          </Button>
          {children}
        </MenuButton>
      ) : (
        <Button
          onClick={handleOpen}
          variant="outlined"
          size="small"
          startIcon={<PlusCircle size={20} />}
        >
          <Typography>Adicionar dados tratados</Typography>
        </Button>
      )}
      <Modal
        open={open}
        onClose={handleClose}
        className={classes.modal}
        aria-labelledby="modal-collected-field"
        aria-describedby="modal-collected-field-description"
        disableAutoFocus
      >
        <Paper className={classes.paperModal}>
          <Grid container justify="space-between" alignItems="center">
            <Typography variant="h4" id="modal-collected-field">
              {edit ? 'Editar' : 'Adicionar'} dados tratados
            </Typography>
            <IconButtonMaterial
              onClick={handleClose}
              color="primary"
              aria-label="close modal"
            >
              <CloseIcon />
            </IconButtonMaterial>
          </Grid>
          <FormModal
            dataCollected={dataCollected}
            toSelectItems={toSelectItems}
            selectedFields={edit ? selectedFields : []}
            handleCloseModal={handleClose}
            mode={mode}
            dataProcessId={dataProcessId}
            submitSuccess={submitSuccess}
            refresh={refresh}
          />
        </Paper>
      </Modal>
    </>
  )
}

DataCollectedModal.propTypes = {
  mode: PropTypes.oneOf(['create', 'edit']).isRequired,
}
export default DataCollectedModal
