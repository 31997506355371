import React from 'react'

import { Grid, Box, Typography, Chip } from '@material-ui/core'

const LawsLists = ({ laws }) => {
  return (
    <Grid item xs={12}>
      <Box mt={2}>
        <Typography variant="subtitle2" color="textSecondary">
          Motivo (lei que obriga fazer o armazenamento permanente)
        </Typography>
        {laws.map((law) => (
          <>
            <Chip label={law?.name} />{' '}
          </>
        ))}
      </Box>
    </Grid>
  )
}

export default LawsLists
