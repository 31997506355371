import React, { useEffect, useState } from 'react'
import { Alert } from '@material-ui/lab'
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Typography,
} from '@material-ui/core'
import { flatMap, isEmpty } from 'lodash'
import { useHistory } from 'react-router-dom'

import { BalancingSafeguarding, Finality } from './components'
import StepperLia from '../Edit/StepperLia/StepperLia'

import useFetch from 'hooks/useFetch'
import useDataProcess from 'hooks/useDataProcess'

import constants from 'constants/index'
import useStyles from './styles'
import * as service from 'service'
import { routes } from 'Routes'

const LegitimateInterest = ({ dataProcess = {}, statusPendingLia = false }) => {
  const [showAlertLia, setShowAlertLia] = useState(false)
  const [messageAlertLia, setMessageAlertLia] = useState('')
  const [activeStep, setActiveStep] = useState(0)

  const history = useHistory()

  const classes = useStyles()

  const { loadingReplyLiaAnswer } = useDataProcess()

  useEffect(() => {
    if (!!dataProcess) {
      dataProcessLegalFrameworkType(dataProcess?.legalFrameworks)
    }
  }, [dataProcess])

  const { DISAPPROVED, PENDING, REVIEW_DISAPPROVED, APPROVED } =
    constants.liaAnswers.STATUSES
  const {
    BALANCING_SAFEGUARING_STEPS,
    FINALITY_STEPS,
    LEGITIMATE_INTEREST_FINALITY,
    CONCRETE_SITUATION,
    LEGITIMATE_EXPECTATION,
    SAFEGUARDS_AND_MECHANISMS,
    ROLES,
  } = constants.liaQuestions
  const { DISAPPROVED_LIA, PENDING_LIA } = constants.dataProcess

  const dataProcessLegalFrameworkType = (legalFrameworks) => {
    const {
      FRAUD_PREVENTION_HOLDER_SECURITY,
      LEGITIMATE_INTEREST,
      LEGITIMATE_INTEREST_SHORTNED,
    } = constants.legalFrameworks

    const legalFrameworksNames = legalFrameworks.map(
      (legalFrameworks) => legalFrameworks.name,
    )

    if (
      !legalFrameworksNames.includes(FRAUD_PREVENTION_HOLDER_SECURITY) &&
      !legalFrameworksNames.includes(LEGITIMATE_INTEREST)
    ) {
      return
    }

    if (
      legalFrameworksNames.includes(FRAUD_PREVENTION_HOLDER_SECURITY) &&
      legalFrameworksNames.includes(LEGITIMATE_INTEREST)
    ) {
      setShowAlertLia(true)
      return setMessageAlertLia(
        `${LEGITIMATE_INTEREST_SHORTNED} e ${FRAUD_PREVENTION_HOLDER_SECURITY}`,
      )
    }

    if (legalFrameworksNames.includes(FRAUD_PREVENTION_HOLDER_SECURITY)) {
      setShowAlertLia(true)
      return setMessageAlertLia(FRAUD_PREVENTION_HOLDER_SECURITY)
    }

    setShowAlertLia(true)
    setMessageAlertLia(LEGITIMATE_INTEREST_SHORTNED)
  }

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }

  const handleBack = () => {
    if (activeStep === 0) {
      return history.push(routes.dataProcess.all)
    }
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const {
    response: LiaQuestionsWithAnswersResponse,
    isLoading: LiaQuestionsLoading,
  } = useFetch(
    service.dponet.liaQuestions.getWithAnswers,
    {
      dataProcessId: dataProcess.id,
    },
    [activeStep],
  )

  const {
    response: onlyQuestionsResponse,
    isLoading: loadingOnlyQuestionLoading,
  } = useFetch(
    service.dponet.liaQuestions.get,
    {
      kinds: [
        LEGITIMATE_EXPECTATION,
        SAFEGUARDS_AND_MECHANISMS,
        LEGITIMATE_INTEREST_FINALITY,
        CONCRETE_SITUATION,
      ],
      role: ROLES.CLIENT,
    },
    [],
  )

  const onlyQuestions = onlyQuestionsResponse?.data?.liaQuestions

  const liaQuestions = LiaQuestionsWithAnswersResponse?.data?.liaQuestions

  const filterPendingAnswers = () => {
    return liaQuestions?.filter((question) =>
      question.liaAnswers.some((answer) => answer.status === PENDING),
    )
  }

  const filterDisapprovedAnswer = () => {
    return liaQuestions?.filter((question) =>
      question.liaAnswers.some((answer) => answer.status === DISAPPROVED),
    )
  }

  const filterApprovedAnswers = () => {
    return liaQuestions?.filter((question) =>
      question.liaAnswers.some((answer) => answer.status === APPROVED),
    )
  }

  const filterReviewDisapprovedAnswer = () => {
    return liaQuestions?.filter((question) =>
      question.liaAnswers.some(
        (answer) => answer.status === REVIEW_DISAPPROVED,
      ),
    )
  }

  const questionsDisapprovedAnswers = flatMap(filterDisapprovedAnswer())
  const questionsPendingAnswers = flatMap(filterPendingAnswers())
  const questionsApprovedAnswers = flatMap(filterApprovedAnswers())
  const questionsReviewDisapprovedAnswers = flatMap(
    filterReviewDisapprovedAnswer(),
  )

  const whichStepsHave = () => {
    var liaAnswerStepFinalityStep = false
    var liaAnswersStepBalanceStep = false

    const finalityStep = liaQuestions?.filter((question) => {
      return FINALITY_STEPS.includes(question?.kind)
    })

    const balancingStep = liaQuestions?.filter((question) => {
      return BALANCING_SAFEGUARING_STEPS.includes(question?.kind)
    })

    if (!isEmpty(finalityStep)) {
      liaAnswerStepFinalityStep = finalityStep.some((question) => {
        return question.liaAnswers.some(
          (answer) => answer.status === DISAPPROVED,
        )
      })
    }

    if (!isEmpty(balancingStep)) {
      liaAnswersStepBalanceStep = balancingStep.some((question) => {
        return question.liaAnswers.some(
          (answer) => answer.status === DISAPPROVED,
        )
      })
    }

    const hasFinalityStep =
      liaAnswerStepFinalityStep && dataProcess?.statusId === DISAPPROVED_LIA
    const hasBalancingStep =
      liaAnswersStepBalanceStep && dataProcess?.statusId === DISAPPROVED_LIA

    return {
      hasFinalityStep,
      hasBalancingStep,
    }
  }

  const { hasFinalityStep, hasBalancingStep } = whichStepsHave()

  const getSteps = () => {
    if (
      dataProcess?.statusId === PENDING_LIA ||
      (hasFinalityStep && hasBalancingStep)
    ) {
      return ['Finalidade', 'Balanceamento e Salvaguarda']
    }
    if (hasFinalityStep) return ['Finalidade']
    if (hasBalancingStep) return ['Balanceamento e Salvaguarda']

    return []
  }

  const steps = getSteps()

  useEffect(() => {
    if (
      JSON.stringify(steps) === JSON.stringify(['Balanceamento e Salvaguarda'])
    ) {
      setActiveStep(1)
    }
  }, [steps])

  const showButtonFinish =
    activeStep === 1 ||
    (!hasBalancingStep && dataProcess?.statusId === DISAPPROVED_LIA)

  return (
    <>
      {showAlertLia && statusPendingLia ? (
        <Alert variant="filled" severity="info" component={Box} mb={3}>
          <Typography variant="body1">
            {`Este processo foi enquadrado com ${messageAlertLia}.
              Nestes casos, a Autoridade Nacional de Proteção de Dados
              (ANPD) exige uma análise detalhada. Por favor, responda as
              perguntas abaixo:`}
          </Typography>
        </Alert>
      ) : (
        <Alert variant="filled" severity="info" component={Box} mb={3}>
          <Typography variant="body1">
            Algumas respostas foram reprovadas pelo DPO, por favor, responda
            novamente.
          </Typography>
        </Alert>
      )}
      <Grid container>
        <Grid item xl={9} lg={9} md={8} xs={12}>
          {(LiaQuestionsLoading || loadingOnlyQuestionLoading) && (
            <Box
              display="flex"
              width="100%"
              minHeight="200px"
              justifyContent="center"
              alignItems="center"
            >
              <CircularProgress />
            </Box>
          )}
          {activeStep === 0 &&
            !LiaQuestionsLoading &&
            !loadingOnlyQuestionLoading && (
              <Finality
                dataProcess={dataProcess}
                handleNext={handleNext}
                questionsPendingAnswers={questionsPendingAnswers}
                questionsDisapprovedAnswers={questionsDisapprovedAnswers}
                questionsApprovedAnswers={questionsApprovedAnswers}
                questionsReviewDisapprovedAnswers={
                  questionsReviewDisapprovedAnswers
                }
                hasBalancingStep={hasBalancingStep}
                questions={onlyQuestions}
              />
            )}
          {activeStep === 1 &&
            !LiaQuestionsLoading &&
            !loadingOnlyQuestionLoading && (
              <BalancingSafeguarding
                dataProcess={dataProcess}
                questionsPendingAnswers={questionsPendingAnswers}
                questionsDisapprovedAnswers={questionsDisapprovedAnswers}
                questionsApprovedAnswers={questionsApprovedAnswers}
                questions={onlyQuestions}
              />
            )}
        </Grid>
        <Grid item xl={3} lg={3} md={4} xs={12}>
          <StepperLia lastActiveStep={activeStep} steps={steps} />
          <Box p={1} display="flex" justifyContent="flex-end">
            <Button
              variant="outlined"
              onClick={handleBack}
              className={classes.button}
            >
              VOLTAR
            </Button>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              form="lia_form"
              className={classes.button}
              disabled={loadingReplyLiaAnswer}
            >
              {showButtonFinish ? 'FINALIZAR' : 'AVANÇAR'}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </>
  )
}

export default LegitimateInterest
