import React, { useState } from 'react'
import { Box, Button, Chip, Grid, Paper, Typography } from '@material-ui/core'
import { MinusCircle as MinusCircleIcon } from 'react-feather'
import DOMPurify from 'dompurify'
import PerfectScrollbar from 'react-perfect-scrollbar'

import { Container, ContentHeader, LoadingFeedback, Page } from 'components'
import { ConsentLogBox, ConsentRevoactionDialog } from './components'

import useFetch from 'hooks/useFetch'

import constants from 'constants/index'
import * as service from 'service'
import helpers from 'helpers'

import useStyles from './styles'

const ConsentReceiptDetails = ({ match }) => {
  const classes = useStyles()

  const [openDialog, setOpenDialog] = useState(false)

  const {
    AUTHORIZED_CONSENT,
    DPONET_PLATFORM,
    LEGAL_BASE_LABEL_CONSENT,
    STATUS_LABEL_RECEIPT,
  } = constants.consent

  const { consentFormId, consentReceiptId } = match.params

  const { response, isLoading } = useFetch(service.dponet.consent.getReceipt, {
    consentFormId,
    id: consentReceiptId,
  })

  const consentFormAnswer = response?.data?.consentFormAnswer
  const answers =
    consentFormAnswer?.answers && JSON.parse(consentFormAnswer?.answers)

  const handleActionDialog = () => {
    setOpenDialog(!openDialog)
  }

  return (
    <Page title="Detalhes do recibo">
      <LoadingFeedback open={isLoading} />
      <Container maxWidth={false}>
        <Box>
          <ContentHeader title="Detalhes do recibo">
            {consentFormAnswer?.platform === DPONET_PLATFORM &&
              consentFormAnswer?.status === AUTHORIZED_CONSENT && (
                <Box marginX={1}>
                  <Button
                    startIcon={<MinusCircleIcon />}
                    className={classes.buttonError}
                    onClick={handleActionDialog}
                  >
                    Revogar Consentimento
                  </Button>
                </Box>
              )}
          </ContentHeader>
          <Box display="flex" width="100%" height="auto">
            <Paper className={classes.fullPaperWidth}>
              <Box width="100%" p={2} fontWeight={500}>
                <Typography variant="subtitle1" color="primary">
                  Detalhes do Recibo
                </Typography>
              </Box>
            </Paper>
          </Box>
          <Grid container spacing={2}>
            <Grid item md={8}>
              <Paper className={classes.receiptTitleBox}>
                <Typography variant="subtitle1">
                  {consentFormAnswer?.consentForm?.title}
                </Typography>
                <Typography variant="caption" color="textSecondary">
                  {
                    LEGAL_BASE_LABEL_CONSENT[
                      consentFormAnswer?.consentForm?.legalFrameworkId
                    ]
                  }
                </Typography>
              </Paper>
              <Paper className={classes.fullPaperWidth}>
                <PerfectScrollbar
                  options={{ wheelPropagation: false, useBothWheelAxes: true }}
                >
                  <Box
                    p={2}
                    minHeight={150}
                    fontFamily="Roboto"
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(
                        consentFormAnswer?.consentForm?.content,
                      ),
                    }}
                  />
                </PerfectScrollbar>
              </Paper>
              <ConsentLogBox consentFormAnswer={consentFormAnswer} />
            </Grid>
            <Grid item md={4}>
              <Paper>
                <Box
                  minHeight={85}
                  padding={2}
                  className={classes.formTitleBox}
                >
                  <Typography variant="subtitle2">
                    Título do Formulário
                  </Typography>
                  <Typography color="textSecondary">
                    {consentFormAnswer?.consentForm?.title}
                  </Typography>
                </Box>
              </Paper>
              <Paper>
                <Box minHeight={85} padding={2}>
                  <Typography variant="subtitle2">Status</Typography>
                  <Chip
                    className={
                      consentFormAnswer?.status === AUTHORIZED_CONSENT
                        ? classes.statusLabelAuthorized
                        : classes.statusLabelRevoked
                    }
                    label={STATUS_LABEL_RECEIPT[consentFormAnswer?.status]}
                  />
                </Box>
              </Paper>
              <Paper>
                <Box minHeight={85} padding={2}>
                  <Typography variant="subtitle2">E-mail</Typography>
                  <Typography color="textSecondary">
                    {consentFormAnswer?.userEmail}
                  </Typography>
                </Box>
              </Paper>
              <Paper>
                <Box minHeight={85} padding={2}>
                  <Typography variant="subtitle2">Criado em</Typography>
                  <Typography color="textSecondary">
                    {helpers.formatters.date(consentFormAnswer?.createdAt)}
                  </Typography>
                </Box>
              </Paper>
              <Paper>
                <Box minHeight={85} padding={2}>
                  <Typography variant="subtitle2">
                    Última atualização
                  </Typography>
                  <Typography color="textSecondary">
                    {helpers.formatters.date(consentFormAnswer?.updatedAt)}
                  </Typography>
                </Box>
              </Paper>
              {answers &&
                Object.entries(answers).map(
                  ([key, value]) =>
                    value && (
                      <Paper key={key}>
                        <Box minHeight={85} padding={2}>
                          <Typography variant="subtitle2">{key}</Typography>
                          <Typography color="textSecondary">{value}</Typography>
                        </Box>
                      </Paper>
                    ),
                )}
            </Grid>
          </Grid>
        </Box>
      </Container>
      <ConsentRevoactionDialog
        open={openDialog}
        onClose={handleActionDialog}
        consent={consentFormAnswer}
      />
    </Page>
  )
}

export default ConsentReceiptDetails
