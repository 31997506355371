import React, { useState } from 'react'
import {
  Modal,
  Grid,
  Typography,
  Paper,
  TableContainer,
  IconButton as IconButtonMaterial,
} from '@material-ui/core'
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined'
import CloseIcon from '@material-ui/icons/Close'
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from 'components/Table'

import { IconButton } from 'components'

import helpers from 'helpers'

const DataCollectedModal = ({ collectedFields, classes }) => {
  const [open, setOpen] = useState(false)

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      <IconButton
        size="small"
        Icon={VisibilityOutlinedIcon}
        text={'Visualizar'}
        onClick={handleOpen}
      />
      <Modal
        open={open}
        onClose={handleClose}
        className={classes.modal}
        aria-labelledby="modal-collected-field"
        aria-describedby="modal-collected-field-description"
        disableAutoFocus
      >
        <Paper className={classes.paperModal}>
          <Grid container justify="space-between" alignItems="center">
            <Typography variant="h4" id="modal-collected-field">
              Visualizar dados tratados
            </Typography>
            <IconButtonMaterial
              onClick={handleClose}
              color="primary"
              aria-label="close modal"
            >
              <CloseIcon />
            </IconButtonMaterial>
          </Grid>
          <TableContainer className={classes.tableContainerModal}>
            <Table
              size="small"
              emptyMessage="Nenhum campo coletado encontrado"
              noWrap
              stickyHeader
              id="modal-collected-description"
            >
              <TableHead>
                <TableRow>
                  <TableCell>Dado</TableCell>
                  <TableCell>Tipo</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {collectedFields.map((collectedField) => (
                  <TableRow
                    key={helpers.functions.dig(
                      collectedField.dataCollectedOption,
                      'id',
                    )}
                  >
                    <TableCell>
                      {helpers.functions.dig(
                        collectedField.dataCollectedOption,
                        'name',
                      )}
                    </TableCell>
                    <TableCell>
                      {helpers.functions.dig(
                        collectedField.dataCollectedOption,
                        'dataTypeName',
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Modal>
    </>
  )
}

export default DataCollectedModal
