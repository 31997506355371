import React, { useEffect, useState } from 'react'
import { isEmpty } from 'lodash'

import {
  Box,
  Card,
  MenuItem,
  TextField,
  Typography,
  useMediaQuery,
} from '@material-ui/core'
import { FormCreditCard, Methods } from '..'
import { Label } from 'components'

import useSubscription from 'hooks/useSubscription'

import constants from 'constants/index'
import helpers from 'helpers'

import theme from 'theme'
import useStyles from './styles'

const PaymentStep = ({ submitPayment }) => {
  const [day, setDay] = useState(
    constants.preRegistrations.DATE_EXPIRATION_BILLET[0].id,
  )
  const classes = useStyles()
  const {
    selectedMethod,
    data,
    setDisabledButton,
    setSelectedDateExpirationBillet,
    selectedDateExpirationBillet,
  } = useSubscription()

  const handleDate = () => {
    const date = helpers.preRegistration.dateExpirationBillet(day)

    setSelectedDateExpirationBillet(date)
  }

  const currentDay = new Date().getDate()

  useEffect(() => {
    setDisabledButton(false)
    handleDate()
    // eslint-disable-next-line
  }, [day])

  const isDesktop = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  })

  return (
    <>
      <Typography variant="h4" className={classes.styleText}>
        Selecione o método de pagamento
      </Typography>
      <Box mt={2.5} mb={isDesktop ? 5 : 2}>
        <Methods />
      </Box>
      <Box mt={2.5} mb={isDesktop ? 5 : 2}>
        <Card>
          <Box p={2.5}>
            {/* {selectedMethod.id !== constants.preRegistrations.BILLET_METHOD ? (
              <FormCreditCard submitPayment={submitPayment} />
            ) : ( */}
            <Box maxWidth="100vw" mb={2.5}>
              <Typography variant="h4">{`${
                data?.responsibleUser?.name.split(' ')[0]
              }, para gerar o seu boleto, clique no botão "Avançar".`}</Typography>
              <Box mt={3}>
                <Label title="Escolha o dia do vencimento do boleto">
                  <TextField
                    select
                    color="primary"
                    variant="outlined"
                    className={classes.selectfield}
                    onChange={(event) => setDay(event.target.value)}
                    defaultValue={
                      constants.preRegistrations.DATE_EXPIRATION_BILLET[0].id
                    }
                  >
                    {constants.preRegistrations.DATE_EXPIRATION_BILLET.filter(
                      (dateExpiration) => dateExpiration.id !== currentDay,
                    ).map((dateDisplayed) => (
                      <MenuItem key={dateDisplayed.id} value={dateDisplayed.id}>
                        <Typography>{dateDisplayed.name}</Typography>
                      </MenuItem>
                    ))}
                  </TextField>
                  <Box mt={1}>
                    {!isEmpty(selectedDateExpirationBillet) && (
                      <Typography variant="body1">
                        Vencimento do boleto: {selectedDateExpirationBillet}
                      </Typography>
                    )}
                  </Box>
                </Label>
              </Box>
              <Box className={classes.noticeBillet}>
                <Typography variant="body1" className={classes.noticeText}>
                  OBSERVAÇÕES:
                </Typography>
                <Box my={2.5}>
                  <Typography variant="body2" className={classes.noticeText}>
                    O acesso à plataforma será concedido somente após a
                    confirmação do pagamento.
                  </Typography>
                </Box>
                <Typography variant="body2" className={classes.noticeText}>
                  Se o pagamento for realizado antecipadamente, o acesso será
                  liberado assim que houver a confirmação, não sendo necessário
                  aguardar a data de vencimento.
                </Typography>
              </Box>
            </Box>
            {/* )} */}
          </Box>
        </Card>
      </Box>
    </>
  )
}

export default PaymentStep
