export const getBaseURL = (project) => envsBaseUrl[project][env]
export const getWebsiteURL = () => envsBaseUrl.website[env]
export const getApplicationUrl = () => applicationUrl[env]
export const getProfilesURL = () => profilesBaseUrl[env]
export const getTomTicketURL = () => tomTicketBaseUrl[env]
export const getGoogleAnalyticsTag = () => googleAnalyticsTag[env]
export const getMarketPlaceToken = () => marketPlaceToken[env]
export const getPaymentAuthKey = () => paymentAuthKey[env]
export const getMixPanelAuthKey = () => mixPanelAuthKey[env]
export const getGoogleRecaptchaToken = () => googleRecaptchaToken[env]
export const getEditorTyneMceToken = () => editorTyneMceToken[env]
export const getSentryDsn = () => sentryDsn[env]

const env = process.env.REACT_APP_API || 'development'

const envsBaseUrl = {
  dponet: {
    development: 'http://api.localhost:3001',
    sandbox: 'https://api.sandbox.dponet.com.br',
    production: 'https://api.dponet.com.br',
  },
  marketPlace: {
    development: 'http://api.backoffice.localhost:3012',
    sandbox: 'https://api.backoffice.parceiros.sandbox.dponet.com.br',
    production: 'https://api.backoffice.parceiros.dponet.com.br',
  },
  website: {
    development: 'https://www.dponet.com.br',
    sandbox: 'https://www.dponet.com.br',
    production: 'https://www.dponet.com.br',
  },
  cookies: {
    development: 'http://api.localhost:3013',
    sandbox: 'https://cookies.sandbox.dponet.com.br',
    production: 'https://cookies.dponet.com.br',
  },
  privacyPortal: {
    development: 'http://localhost:3000',
    sandbox: 'https://www.sandbox.privacidade.com.br',
    production: 'https://www.privacidade.com.br',
  },
}

const applicationUrl = {
  development: 'http://localhost:3002',
  sandbox: 'https://app.sandbox.dponet.com.br',
  production: 'https://app.dponet.com.br',
}

const marketPlaceToken = {
  development: 'd556bf32c2b53e1798e7a4f6a3b8226b',
  sandbox: 'd556bf32c2b53e1798e7a4f6a3b8226b',
  production: 'd556bf32c2b53e1798e7a4f6a3b8226b',
}

const profilesBaseUrl = {
  development: 'http://localhost:3001/profiles?auth_token_v2=',
  sandbox: 'https://dpms.sandbox.immunizesystem.com/profiles?auth_token_v2=',
  production: 'https://dpms.immunizesystem.com/profiles?auth_token_v2=',
}

const tomTicketBaseUrl = {
  development: 'https://dponet.tomticket.com/helpdesk/login?jwt',
  sandbox: 'https://dponet.tomticket.com/helpdesk/login?jwt',
  production: 'https://dponet.tomticket.com/helpdesk/login?jwt',
}

const paymentAuthKey = {
  development: 'afb075c950ffb53e02d1389083dadc50',
  sandbox: '36535fe3091f2523aae626fec5dfae68',
  production: 'e3fab64d276bf445fe69bc7ff1421f53',
}

const googleAnalyticsTag = {
  development: '',
  sandbox: 'G-579FW2GB8T',
  production: 'G-RSRSGTQL19',
}

const mixPanelAuthKey = {
  development: '9afd4e6a1452acceb1de9c0736505de7',
  sandbox: '9afd4e6a1452acceb1de9c0736505de7',
  production: '6c206c8e6614b19120cf3f2640b7ff5c',
}

const googleRecaptchaToken = {
  development: '6LcUO9wlAAAAAFP4FAWvGoNeWOtbK-yCq40eKOvC',
  sandbox: '6LcUO9wlAAAAAFP4FAWvGoNeWOtbK-yCq40eKOvC',
  production: '6LfkOdwlAAAAAKtuo2MBKEEFkn9BRr9wvEPRu_6Y',
}

const editorTyneMceToken = {
  development: 'mhfqqnu8h9sdp9poi354uh2bqva111r9vm65bhe462kyux5i',
  sandbox: '04cwdxlxaqi28ar132hvaii2e6v6lmmk8z11ccd3nms3bscj',
  production: '04cwdxlxaqi28ar132hvaii2e6v6lmmk8z11ccd3nms3bscj',
}

const sentryDsn = {
  development:
    'https://3dbbae5ad7ffa0cfbb84a7f5ca46319b@o4506654783176704.ingest.sentry.io/4506654796677120',
  sandbox:
    'https://d935f6ac97ed5dde6c72a029167a1273@o4506654783176704.ingest.sentry.io/4506667497357312',
  production:
    'https://a14d8ee7d9d801818c1acdaf9fc4a003@o4506756782620672.ingest.sentry.io/4506756820893696',
}
