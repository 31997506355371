import React from 'react'
import { Box, Grid } from '@material-ui/core'

import { Container, Page, RoundedBorder } from 'components'
import { Header } from './components'

import useAuth from 'hooks/useAuth'
import useStyles from './styles'

import helpers from 'helpers'
import constants from 'constants/index'

const Accountability = () => {
  const {
    company: { statusId },
    setFreeTrialBlock,
  } = useAuth()
  const classes = useStyles()

  const isTrail = statusId === constants.userCompanies.TRIAL_STATUS

  const generateAccountabilityReport = () => {
    if (isTrail) {
      setFreeTrialBlock(true)
      return
    }
    helpers.reports.accountabilityStatement()
  }

  return (
    <Page className={classes.root} title={'Prestação de Contas'}>
      <Box position="relative">
        <RoundedBorder />
      </Box>
      <Container maxWidth={false} spacingXl={15} spacingLg={10} spacingMd={5}>
        <Box py={5}>
          <Grid container spacing={10}>
            <Grid item xs={12}>
              <Header
                type="accountability"
                reportTour="prestacao-de-contas"
                buttonAction={generateAccountabilityReport}
              />
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Page>
  )
}

export default Accountability
