import React, { useState, useEffect } from 'react'
import { Box, Typography, Link } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { reverse } from 'named-urls'
import PropTypes from 'prop-types'
import { isEmpty } from 'lodash'

import { Card, LoadingBox, Permitted } from 'components'

import constants from 'constants/index'
import * as service from 'service'
import { routes } from 'Routes'
import useStyles from './styles'

const ProfileSection = ({ simplifiedProfile, profileId }) => {
  const responsibleProfile = profileId === constants.user.RESPONSIBLE_PROFILE_ID

  const history = useHistory()
  const classes = useStyles()

  const [profile, setProfile] = useState({})
  const [loading, setLoading] = useState(true)

  const [permissionsResume, setPermissionsResume] = useState('-')

  const navigateToEditProfile = () =>
    history.push(reverse(routes.profiles.edit, { profileId: profile?.id }))

  const loadProfilePermissionsResume = () => {
    if (responsibleProfile) {
      setPermissionsResume('Todas as permissões')

      return
    }

    if (isEmpty(profile)) {
      setDefaultPermissionsResume()

      return
    }

    const profileFunctionalities = profile.profileFunctionalities
    const permissionsLists = constants.permissionsLists

    const truePermissions = profileFunctionalities.filter((permission) => {
      return permission.status === true
    })

    if (isEmpty(truePermissions)) {
      setDefaultPermissionsResume()

      return
    }

    const truePermissionsTags = truePermissions.map((permission) => {
      return permission.tag
    })
    const permissionsListsKeys = Object.keys(permissionsLists)
    const labelledPermissions = {}

    permissionsListsKeys.forEach((permissionListKey) => {
      permissionsLists[permissionListKey].forEach((permission) => {
        if (truePermissionsTags.includes(permission.tag)) {
          if (!labelledPermissions[permissionListKey]) {
            labelledPermissions[permissionListKey] = []
          }

          labelledPermissions[permissionListKey].push(permission.name)
        }
      })
    })

    setPermissionsResume(mountResume(labelledPermissions))
  }

  const setDefaultPermissionsResume = () => {
    if (permissionsResume !== '-') setPermissionsResume('-')
  }

  const mountResume = (labelledPermissions) => {
    var resume = ''
    Object.keys(labelledPermissions).forEach((permissionKey) => {
      resume =
        resume +
        constants.permissions.TRANSLATED_PERMISSIONS_LIST[permissionKey] +
        ': ' +
        labelledPermissions[permissionKey].join(', ') +
        ';\n'
    })

    return resume
  }

  const getProfile = async () => {
    if (responsibleProfile) {
      setProfile({})
    } else {
      setLoading(true)
      const response = await service.dponet.profiles.get({ profileId })

      setProfile(response?.data?.profile || {})
    }

    setLoading(false)
  }

  useEffect(() => {
    getProfile()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileId])

  useEffect(() => {
    if (!loading) loadProfilePermissionsResume()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile, loading])

  return (
    <Card title="Perfil do usuário selecionado" paddingBottom={0}>
      {loading ? (
        <LoadingBox minHeight="200px" />
      ) : (
        <>
          <Box>
            <Typography variant="subtitle2" color="textPrimary">
              Nome do perfil
            </Typography>
            <Typography variant="body2">
              <Permitted
                tag={constants.permissions.PROFILES.EDIT}
                fallback={simplifiedProfile?.name}
                show={!responsibleProfile}
              >
                <Link onClick={navigateToEditProfile} className={classes.link}>
                  {simplifiedProfile?.name}
                </Link>
              </Permitted>
            </Typography>
          </Box>
          <Box mt={2}>
            <Typography variant="subtitle2" color="textPrimary">
              Descrição
            </Typography>
            <Typography variant="body2">
              {simplifiedProfile?.description}
            </Typography>
          </Box>
          <Box mt={2}>
            <Typography variant="subtitle2" color="textPrimary">
              Acessos e permissões
            </Typography>
            <Typography variant="body2" className={classes.permissionsResume}>
              {permissionsResume}
            </Typography>
          </Box>
        </>
      )}
    </Card>
  )
}

ProfileSection.propTypes = {
  simplifiedProfile: PropTypes.object.isRequired,
  profileId: PropTypes.number.isRequired,
}

export default ProfileSection
