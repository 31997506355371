import React, { useState } from 'react'

import * as service from 'service'
import DataProcessOptionsContext from 'contexts/DataProcessOptionsContext'

const DataProcessOptionsProvider = ({ children }) => {
  const [data, setData] = useState(null)
  const [dataProcesses, setDataProcesses] = useState([])
  const [reload, setReload] = useState(false)
  const [sourceOptions, setSourceOptions] = useState([])

  const loadData = async () => {
    const response = await service.dponet.dataProcesses.getRelationOptions({
      companyId: service.dponet.auth.getCompany(),
    })

    setData(response.data)
  }

  return (
    <DataProcessOptionsContext.Provider
      value={{
        data,
        loadData,
        dataProcesses,
        setDataProcesses,
        reload,
        setReload,
        sourceOptions,
        setSourceOptions,
      }}
    >
      {children}
    </DataProcessOptionsContext.Provider>
  )
}

export default DataProcessOptionsProvider
