import React, { useState } from 'react'
import {
  Modal,
  Grid,
  Typography,
  Paper,
  Button,
  IconButton as IconButtonMaterial,
} from '@material-ui/core'

import { PlusCircle as PlusCircleIcon } from 'react-feather'
import { Close as CloseIcon } from '@material-ui/icons'

import useDataTreatment from 'hooks/useDataTreatment'
import useSnackbar from 'hooks/useSnackbar'

import { MenuButton, Permitted } from 'components'

import NewForm from './NewForm'

const DataTreatmentModal = ({ dataTreatment, classes, mode, children }) => {
  const [open, setOpen] = useState(false)
  const snackbar = useSnackbar()
  const dataTreatmentHook = useDataTreatment()

  const edit = mode === 'edit'

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const submitSuccess = () => {
    snackbar.open({
      message: `Compartilhamento de dados ${
        edit ? 'atualizados' : 'criado'
      } com sucesso`,
      variant: 'success',
    })
    handleClose()
    reloadData()
  }

  const reloadData = () => {
    dataTreatmentHook.loadData('')
  }

  return (
    <>
      {edit ? (
        <Permitted tag="create_data_process">
          <MenuButton>
            <Button color="secondary" fullWidth onClick={handleOpen}>
              Editar
            </Button>
            {children}
          </MenuButton>
        </Permitted>
      ) : (
        <Permitted tag="create_data_process">
          <Button
            onClick={handleOpen}
            variant="outlined"
            startIcon={<PlusCircleIcon size={20} />}
            size="small"
          >
            <Typography>Adicionar compartilhamento de dados</Typography>
          </Button>
        </Permitted>
      )}
      <Modal
        open={open}
        onClose={handleClose}
        className={classes.modal}
        aria-labelledby="modal-collected-field"
        aria-describedby="modal-collected-field-description"
        disableAutoFocus
      >
        <Paper className={classes.paperModal}>
          <Grid container justify="space-between" alignItems="center">
            <Typography variant="h4" id="modal-collected-field">
              {edit ? 'Editar' : 'Adicionar'} compartilhamento de dados
            </Typography>
            <IconButtonMaterial
              onClick={handleClose}
              color="primary"
              aria-label="close modal"
            >
              <CloseIcon />
            </IconButtonMaterial>
          </Grid>
          <NewForm
            mode={mode}
            dataTreatment={dataTreatment}
            handleCloseModal={handleClose}
            submitSuccess={submitSuccess}
          />
        </Paper>
      </Modal>
    </>
  )
}

export default DataTreatmentModal
