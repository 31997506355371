import React from 'react'
import { Box, Card, TablePagination, useMediaQuery } from '@material-ui/core'

import { InvoiceTable } from './components'

import usePagination from 'hooks/usePagination'
import useFetch from 'hooks/useFetch'

import theme from 'theme'
import * as service from 'service'

const Invoices = () => {
  const { perPage, page, handleChangePage, handleChangeRowsPerPage } =
    usePagination(5)

  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  })

  const { response, isLoading, refresh } = useFetch(
    service.dponet.paymentOrders.listingByCompany,
    {
      page,
      perPage,
    },
    [page, perPage],
  )

  const paymentOrders = response?.data?.paymentOrders

  return (
    <Card>
      <InvoiceTable
        isLoading={isLoading}
        paymentOrders={paymentOrders}
        refresh={refresh}
      />
      <Box px={2} display="flex" justifyContent="flex-end">
        <TablePagination
          component="div"
          count={response?.data?.meta?.totalCount || 0}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          page={page - 1}
          rowsPerPage={perPage}
          rowsPerPageOptions={[5, 10, 25, 100]}
          labelRowsPerPage={isDesktop ? 'Por página' : ''}
          nextIconButtonProps={{ size: 'small' }}
          backIconButtonProps={{ size: 'small' }}
        />
      </Box>
    </Card>
  )
}

export default Invoices
