import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { Hidden } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

import styles from './styles'

const useStyles = makeStyles(styles)

const RoundedBorder = ({ showRoundedGray, showRoundedWhite, className }) => {
  const classes = useStyles()

  return (
    <Hidden mdDown>
      {(showRoundedGray || showRoundedWhite) && (
        <div className={clsx(classes.floatingBlue, className)}>
          {showRoundedGray && <div className={classes.floatingGray}></div>}
          {showRoundedWhite && <div className={classes.floatingWhite}></div>}
        </div>
      )}
    </Hidden>
  )
}

RoundedBorder.propTypes = {
  showRoundedGray: PropTypes.bool,
  className: PropTypes.string,
}

RoundedBorder.defaultProps = {
  showRoundedGray: true,
  showRoundedWhite: false,
}

export default RoundedBorder
