import accountabilityStatement from './accountabilityStatement'
import components from './components'
import dashboard from './dashboard'
import questionControl from './questionControl'
import rmcs from './rmcs'
import fragilities from './fragilities'
import training from './training'
import lia from './lia'

const reports = {
  accountabilityStatement,
  components,
  dashboard,
  fragilities,
  lia,
  questionControl,
  rmcs,
  training,
}

export default reports
