const styles = (theme) => ({
  ellipsisTable: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '9rem',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    outline: 0,
  },
  paperModal: {
    [theme.breakpoints.up('sm')]: {
      width: '70%',
    },
    margin: theme.spacing(1),
    padding: theme.spacing(2, 2, 3),
    marginRight: theme.spacing(4),
    outline: 'none',
  },
  tableContainerModal: {
    marginTop: theme.spacing(2),
    maxHeight: 'auto',
  },
})

export default styles
