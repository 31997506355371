import moment from 'moment'

const calcDays = (demoEndDate) => {
  const dateNow = moment().format('YYYY-MM-DD')
  const endDate = moment(demoEndDate).format('YYYY-MM-DD')
  const diffDate = moment(endDate).diff(moment(dateNow))
  const duration = moment.duration(diffDate)

  if (duration._data.days > 0) {
    return duration._data.days
  }

  return 0
}

export default calcDays
