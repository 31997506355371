import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  cardContainer: {
    transition: '0.3s ease-in-out',
  },

  cardActionContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    padding: 14,
    alignItems: 'flex-start',
  },
  logoContainer: {
    borderRadius: 10,
    width: 170,
    height: 150,
    objectFit: 'scale-down',
    [theme.breakpoints.down('md')]: {
      width: 150,
      height: 130,
    },
    [theme.breakpoints.down('sm')]: {
      width: 130,
      height: 110,
    },
  },
  description: {
    display: 'flex',
    paddingTop: 5,
    marginBlock: 7,
    height: 160,
    overflow: 'auto',
  },
  partnerName: {
    fontWeight: 700,
    marginTop: 20,
  },
}))

export default styles
