const PENDING_STATUS = 1
const ANSWERED_STATUS = 2
const INACTIVE_STATUS = 3

const AFFIRMATIVE_ANSWER = 'Sim'
const NEGATIVE_ANSWER = 'Não'
const NOT_APPLICABLE_ANSWER = 'Não se aplica'
const PARTIAL_ANSWER = 'Parcial'

const TABS = [
  {
    value: 'all',
    label: 'Todas',
  },
  {
    value: 'pending',
    label: 'Pendentes',
  },
  {
    value: 'answered',
    label: 'Respondidas',
  },
]

const questions = {
  AFFIRMATIVE_ANSWER,
  NEGATIVE_ANSWER,
  NOT_APPLICABLE_ANSWER,
  PENDING_STATUS,
  ANSWERED_STATUS,
  INACTIVE_STATUS,
  TABS,
  PARTIAL_ANSWER,
}

export default questions
