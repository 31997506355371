const cardFlag = (cardNumber) => {
  /**
   * cardFlag
   * Return id of card flag by number
   * 1 - MASTERCARD
   * 2 - VISA
   * 3 - AMEX
   * 4 - ELO
   * 5 - HIPERCARD
   *
   *
   * @param {string} cardNumber
   */
  let cardNumberDiff = cardNumber.replace(/[^0-9]+/g, '')

  let cards = {
    2: /^4[0-9]{12}(?:[0-9]{3})/,
    1: /^5[1-5][0-9]{14}/,
    // diners: /^3(?:0[0-5]|[68][0-9])[0-9]{11}/,
    3: /^3[47][0-9]{13}/,
    // discover: /^6(?:011|5[0-9]{2})[0-9]{12}/,
    5: /^(606282\d{10}(\d{3})?)|(3841\d{15})/,
    4: /^((((636368)|(438935)|(504175)|(451416)|(636297))\d{0,10})|((5067)|(4576)|(4011))\d{0,12})/,
    // jcb: /^(?:2131|1800|35\d{3})\d{11}/,
    // aura: /^(5078\d{2})(\d{2})(\d{11})$/,
  }

  for (var flagId in cards) {
    if (cards[flagId].test(cardNumberDiff)) {
      return parseInt(flagId)
    }
  }

  return null
}

export default cardFlag
