import React from 'react'
import PropTypes from 'prop-types'

import { useForm, Controller } from 'react-hook-form'

import { Grid, TextField } from '@material-ui/core'

import { Label } from 'components'

import schema from './schema'

const RetentionJustificationForm = ({ handleCreate }) => {
  const { control, handleSubmit, errors } = useForm({
    validationSchema: schema,
    defaultValues: {
      description: '',
    },
  })

  return (
    <form onSubmit={handleSubmit(handleCreate)} id="tomticket-form">
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Label
            title="Motivo"
            description="Por favor, explique o(s) motivo(s) para o armazenamento permanente destes dados"
            xs={12}
            titleVariant="h5"
            item
          ></Label>
        </Grid>
        <Grid item xs={12}>
          <Controller
            control={control}
            as={
              <TextField
                label="Escreva seu(s) motivo(s)"
                type="text"
                color="primary"
                variant="outlined"
                multiline
                rows={6}
                error={!!errors.description}
                helperText={errors?.description?.message}
                fullWidth
              />
            }
            name="description"
            mode="onBlur"
          />
        </Grid>
        <Grid item xs={12}></Grid>
      </Grid>
    </form>
  )
}

RetentionJustificationForm.propTypes = {
  handleCreate: PropTypes.func,
}

RetentionJustificationForm.defaultProps = {
  handleCreate: () => {},
}

export default RetentionJustificationForm
