import { useContext } from 'react'

import SubscriptionContext from 'contexts/SubscriptionContext'

const useSubscription = () => {
  const context = useContext(SubscriptionContext)

  if (context === undefined) {
    throw new Error(
      'useSubscription must be used within a SubscriptionContext.Provider',
    )
  }

  return {
    activeSubStep: context.activeSubStep,
    coupon: context.coupon,
    currentDocument: context.currentDocument,
    currentKind: context.currentKind,
    data: context.data,
    demoTermsAccept: context.demoTermsAccept,
    disabledButton: context.disabledButton,
    finalPrice: context.finalPrice,
    isDemoSubscription: context.isDemoSubscription,
    isTrialSubscription: context.isTrialSubscription,
    isLoading: context.isLoading,
    loadDataBySupplier: context.loadDataBySupplier,
    loadDataByToken: context.loadDataByToken,
    nextStep: context.nextStep,
    payloadPayment: context.payloadPayment,
    paymentData: context.paymentData,
    plans: context.plans,
    policyAccept: context.policyAccept,
    recaptcha: context.recaptcha,
    recaptchaRef: context.recaptchaRef,
    resetRecaptcha: context.resetRecaptcha,
    responsibleData: context.responsibleData,
    selectedDateExpirationBillet: context.selectedDateExpirationBillet,
    selectedMethod: context.selectedMethod,
    selectedPlan: context.selectedPlan,
    selectedSegment: context.selectedSegment,
    setActiveSubStep: context.setActiveSubStep,
    setCoupon: context.setCoupon,
    setCurrentDocument: context.setCurrentDocument,
    setCurrentKind: context.setCurrentKind,
    setData: context.setData,
    setDemoTermsAccept: context.setDemoTermsAccept,
    setDisabledButton: context.setDisabledButton,
    setFinalPrice: context.setFinalPrice,
    setIsLoading: context.setIsLoading,
    setPayloadPayment: context.setPayloadPayment,
    setPaymentData: context.setPaymentData,
    setPlans: context.setPlans,
    setPolicyAccept: context.setPolicyAccept,
    setRecaptcha: context.setRecaptcha,
    setResponsibleData: context.setResponsibleData,
    setSelectedDateExpirationBillet: context.setSelectedDateExpirationBillet,
    setSelectedMethod: context.setSelectedMethod,
    setSelectedPlan: context.setSelectedPlan,
    setSelectedSegment: context.setSelectedSegment,
    setStatus: context.setStatus,
    setTermsAccept: context.setTermsAccept,
    setValidForm: context.setValidForm,
    status: context.status,
    termsAccept: context.termsAccept,
    toNextStep: context.toNextStep,
    toNextSubStep: context.toNextSubStep,
    validForm: context.validForm,
    isDemoSubscriptionFinish: context.isDemoSubscriptionFinish,
  }
}

export default useSubscription
