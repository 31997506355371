import React from 'react'
import PropTypes from 'prop-types'

import { useHistory } from 'react-router-dom'
import { reverse } from 'named-urls'
import { routes } from 'Routes'

import { MenuButton, Permitted, ButtonAction } from 'components'

import permission from 'constants/permissions'
import constants from 'constants/index'

import useSnackbar from 'hooks/useSnackbar'
import usePermissions from 'hooks/usePermissions'

import helpers from 'helpers'
import * as service from 'service'

const ClientActions = ({
  supplier,
  setCompanySupplier,
  setOpenResendSolicitation,
  setOpenConfirmation,
  setConfirmationData,
}) => {
  const permissions = usePermissions()
  const { mixpanel } = helpers
  const history = useHistory()
  const isInactive =
    supplier.status === constants.companySuppliers.INACTIVE_STATUS
  const isApproved =
    supplier.status === constants.companySuppliers.APPROVED_STATUS
  const canDestroy =
    [
      constants.companySuppliers.INDICATED_STATUS,
      constants.companySuppliers.REFUSED_STATUS,
    ].includes(supplier.status) &&
    permissions.permitted(permission.COMPANY_SUPPLIERS.UPDATE)

  const snackbar = useSnackbar()

  const handleResendSolicitation = () => {
    setCompanySupplier(supplier)
    setOpenResendSolicitation(true)
  }

  const mixpanelTrack = () => {
    mixpanel.track(
      'Fornecedores',
      isApproved ? 'Inativar fornecedor' : 'Excluir fornecedor',
      {
        action: isApproved ? 'fornecedor inativado' : 'fornecedor removido',
      },
    )
  }

  const inactivateSubmit = async () => {
    try {
      await service.dponet.suppliers.inactivate({ supplierId: supplier.id })
      mixpanelTrack()
    } catch (error) {
      snackbar.open({
        message:
          helpers.formatters.errorMessage(error.response.data.error) ||
          'Ocorreu um erro ao inativar o fornecedor',
        variant: 'error',
      })
      throw error
    }
  }

  const destroySubmit = async () => {
    try {
      await service.dponet.suppliers.softDelete({ supplierId: supplier.id })
      mixpanelTrack()
    } catch (error) {
      snackbar.open({
        message:
          helpers.formatters.errorMessage(error.response.data.error) ||
          'Ocorreu um erro ao remover o fornecedor',
        variant: 'error',
      })
      throw error
    }
  }

  const handleInactivate = () => {
    setConfirmationData({
      actionType: constants.companySuppliers.INACTIVATE_ACTION,
      title: 'Inativar vínculo?',
      subtitle: 'Deseja confirmar a inativação de vínculo com o fornecedor?',
      handleConfirm: inactivateSubmit,
    })
    setOpenConfirmation(true)
    setCompanySupplier(supplier)
  }

  const handleRemove = () => {
    setConfirmationData({
      actionType: constants.companySuppliers.DESTROY_ACTION,
      title: 'Excluir vínculo?',
      subtitle: 'Deseja confirmar a exclusão do vínculo com o fornecedor?',
      handleConfirm: destroySubmit,
    })
    setOpenConfirmation(true)
    setCompanySupplier(supplier)
  }

  const redirect = (route) => {
    return history.push(
      reverse(route, {
        supplierId: supplier.id,
      }),
    )
  }

  if (
    (!canDestroy &&
      !isApproved &&
      !supplier.canResendSolicitation &&
      !supplier.editionPermitted) ||
    (isInactive && !permissions.permitted(permission.COMPANY_SUPPLIERS.UPDATE))
  ) {
    return <></>
  }

  return (
    <MenuButton>
      {isApproved && (
        <Permitted tag={permission.COMPANY_SUPPLIERS.SHOW}>
          <ButtonAction
            color="secondary"
            fullWidth
            action={() => redirect(routes.mySuppliers.show)}
            name="Visualizar"
          />
        </Permitted>
      )}
      <Permitted tag={permission.COMPANY_SUPPLIERS.UPDATE}>
        {supplier.canResendSolicitation && !isApproved && (
          <ButtonAction
            color="secondary"
            fullWidth
            action={handleResendSolicitation}
            name={isInactive ? 'Reativar vínculo' : 'Reenviar solicitação'}
          />
        )}
        <ButtonAction
          color="secondary"
          fullWidth
          action={() => redirect(routes.mySuppliers.edit)}
          name="Editar"
        />
        {isApproved && (
          <ButtonAction
            color="secondary"
            fullWidth
            action={handleInactivate}
            name="Inativar"
          />
        )}
        {canDestroy && (
          <ButtonAction
            color="secondary"
            fullWidth
            action={handleRemove}
            name="Excluir"
          />
        )}
      </Permitted>
    </MenuButton>
  )
}

ClientActions.propTypes = {
  supplier: PropTypes.object,
  setCompanySupplier: PropTypes.func,
  setOpenResendSolicitation: PropTypes.func,
  setOpenConfirmation: PropTypes.func,
  setConfirmationData: PropTypes.func,
}

export default ClientActions
