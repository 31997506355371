import React from 'react'
import { DisapprovedLia, PendingLia } from './components'

import constants from 'constants/index'

const Finality = ({
  dataProcess = {},
  handleNext = () => {},
  questionsPendingAnswers = [],
  questionsDisapprovedAnswers = [],
  questionsReviewDisapprovedAnswers = [],
  questionsApprovedAnswers = [],
  hasBalancingStep = false,
  questions = [],
}) => {
  const { PENDING_LIA, DISAPPROVED_LIA } = constants.dataProcess

  const statusPendingLia = dataProcess?.statusId === PENDING_LIA
  const statusDisapprovedLia = dataProcess?.statusId === DISAPPROVED_LIA

  return (
    <>
      {statusPendingLia && (
        <PendingLia
          dataProcess={dataProcess}
          handleNext={handleNext}
          questionsPendingAnswers={questionsPendingAnswers}
          questionsApprovedAnswers={questionsApprovedAnswers}
          questions={questions}
        />
      )}

      {statusDisapprovedLia && (
        <DisapprovedLia
          dataProcess={dataProcess}
          handleNext={handleNext}
          questionsReviewDisapprovedAnswers={questionsReviewDisapprovedAnswers}
          questionsDisapprovedAnswers={questionsDisapprovedAnswers}
          hasBalancingStep={hasBalancingStep}
        />
      )}
    </>
  )
}

export default Finality
