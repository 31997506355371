import React from 'react'
import {
  Box,
  Typography,
  Grid,
  makeStyles,
  CircularProgress,
} from '@material-ui/core'

import { TableDataCollecteds } from '../../TableDataCollecteds'
import { DataCollectedModal } from '../../DataCollectedModal'
import Documents from '../Documents'

import useDataProcessOptions from 'hooks/useDataProcessOptions'
import useDataCollected from 'hooks/useDataCollected'
import useDocuments from 'hooks/useDocuments'

import helpers from 'helpers'

import styles from './styles'
import constants from 'constants/index'

const useStyles = makeStyles(styles)

const DataCollected = ({ dataProcessId, isLoading, refresh }) => {
  const classes = useStyles()
  const dataCollectedHook = useDataCollected()
  const documentsHook = useDocuments()
  const loading =
    isLoading || dataCollectedHook.isLoading || documentsHook.loading
  const dataProcessOptions = useDataProcessOptions()
  const { options } = dataProcessOptions.data
  const { dataCollectedOptions } = options
  const { mountLabelList } = helpers.dataCollected

  const toSelectItems = mountLabelList(dataCollectedOptions)

  return (
    <>
      {loading ? (
        <Box
          id="loadBox"
          display="flex"
          width="100%"
          minHeight="700px"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Box id={constants.dataProcess.DATA_PROCESS_EDIT_DRIVER_STEP_4}>
            <Box display="flex" justifyContent="space-between">
              <Box mt={2}>
                <Typography variant="h5">
                  Quais são os dados tratados?
                </Typography>
              </Box>
              <Box mt={2}>
                <DataCollectedModal
                  dataProcessId={dataProcessId}
                  classes={classes}
                  dataCollected={{ dataProcessId }}
                  toSelectItems={toSelectItems}
                  selectedFields={[]}
                  mode="create"
                  refresh={refresh}
                />
              </Box>
            </Box>
            <Grid container className={classes.dataCollectedContainer}>
              <Grid className={classes.dataCollectedTable} item xs={12}>
                <TableDataCollecteds
                  // dataCollecteds={dataCollecteds}
                  dataProcessId={dataProcessId}
                  classes={classes}
                  edit
                  dataCollectedOptions={dataCollectedOptions}
                  refresh={refresh}
                />
              </Grid>
              <Grid item xl={1} />
            </Grid>
          </Box>
          <Documents dataProcessId={dataProcessId} />
        </>
      )}
    </>
  )
}

export default DataCollected
