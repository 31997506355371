import * as yup from 'yup'

const dataTreatmentSchema = (isRequired) => {
  return yup.object().shape({
    titularCategoryId: yup.string().required(),
    titularAgeRanges: yup
      .array()
      .of(
        yup.object().shape({
          id: yup.number().required(),
          name: yup.string().required(),
        }),
      )
      .min(1),
    ...(isRequired
      ? {
          holderNumber: yup.number().required(),
        }
      : { holderNumber: yup.number().nullable() }),
    anotherPersonalNames: yup.string(),
    anotherComportamentalNames: yup.string(),
    anotherFinancialNames: yup.string(),
  })
}

export default dataTreatmentSchema
