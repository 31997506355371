import api from 'service/api'

const dponetAPI = api.create('dponet')

const get = async ({ ...params }) => {
  return await dponetAPI.get(`/company_audits`, { params })
}

const show = async ({ auditId = '' }) => {
  return await dponetAPI.get(`/company_audits/${auditId}`)
}

const create = async ({ ...data }) => {
  return await dponetAPI.post(`/company_audits`, { companyAudit: data })
}

const update = async ({ auditId = '', ...data }) => {
  return await dponetAPI.put(`/company_audits/${auditId}`, {
    companyAudit: data,
  })
}

const getProcesses = async ({ companyAuditId = '', ...params }) => {
  return await dponetAPI.get(
    `/company_audits/${companyAuditId}/data_processes`,
    { params },
  )
}

const changeStatus = async ({ companyAuditId = '', ...data }) => {
  return await dponetAPI.put(
    `/company_audits/${companyAuditId}/change_status`,
    { companyAudit: data },
  )
}

export default { changeStatus, create, get, getProcesses, show, update }
