import React, { useState } from 'react'
import { isEmpty } from 'lodash'
import { Grid, Button } from '@material-ui/core'

import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from 'components/Table'
import { MenuButton } from 'components'

import useSnackbar from 'hooks/useSnackbar'

import * as service from 'service'
import { getBaseURL } from 'service/env'

import useStyles from './styles'

import helpers from 'helpers'

const TableDocuments = ({
  files,
  planEvidence,
  reload,
  isLoading,
  isDisabled,
  isLegacyBase,
}) => {
  const classes = useStyles()
  const [loadingDelete, setLoadingDelete] = useState(false)

  const snackbar = useSnackbar()

  const handleDeleteDocument = async (document) => {
    const documentName = helpers.functions.documentName(document.url)
    setLoadingDelete(true)
    try {
      if (isLegacyBase) {
        await service.dponet.documents.deleteQuestionPlanActionEvidencyLegacy({
          questionPlanActionEvidencyId: planEvidence?.id,
          documentName: documentName,
        })
      } else {
        await service.dponet.documents.deleteQuestionPlanActionEvidency({
          questionPlanActionEvidencyId: planEvidence?.id,
          documentId: document?.id,
        })
      }

      snackbar.open({
        message: `${document?.filename || documentName} deletado com sucesso!`,
        variant: 'success',
      })
    } catch {
      snackbar.open({
        message: `Ocorreu algum erro ao deletar ${
          document?.filename || documentName
        }!`,
        variant: 'error',
      })
    } finally {
      setLoadingDelete(false)
      reload()
    }
  }

  const handleOpenFile = (document) => {
    const url = isLegacyBase
      ? `${getBaseURL('dponet')}${document?.url}`
      : document?.url

    return window.open(url, '_blank')
  }

  return (
    <Table size="small" emptyMessage="Nenhum documento encontrado" noWrap>
      <TableHead>
        <TableRow>
          <TableCell>Documentos</TableCell>
          <TableCell align="right" className={classes.actionHeader}>
            Ações
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {!isLoading && !isEmpty(files) && (
          <>
            {files?.map((document, index) => (
              <TableRow key={index}>
                <TableCell>
                  {document?.filename ||
                    helpers.functions.documentName(document?.url)}
                </TableCell>
                <TableCell align="right">
                  <Grid container item justify="flex-end">
                    <MenuButton>
                      <Button
                        color="secondary"
                        fullWidth
                        onClick={() => handleOpenFile(document)}
                      >
                        Visualizar
                      </Button>
                      <Button
                        color="secondary"
                        fullWidth
                        onClick={() => handleDeleteDocument(document)}
                        disabled={isDisabled || loadingDelete}
                      >
                        {loadingDelete ? 'Aguarde...' : 'Remover'}
                      </Button>
                    </MenuButton>
                  </Grid>
                </TableCell>
              </TableRow>
            ))}
          </>
        )}
      </TableBody>
    </Table>
  )
}

export default TableDocuments
