import api from 'service/api'

const dponetAPI = api.create('dponet')

const list = async ({ ...params }) => {
  return await dponetAPI.get('/question_controls/', { params })
}

const show = async ({ questionControlId }) => {
  return await dponetAPI.get(`/question_controls/${questionControlId}`)
}

const enableWrite = async ({ questionControlId }) => {
  return await dponetAPI.patch(
    `/question_controls/${questionControlId}/open_write`,
  )
}

const create = async ({ ...data }) => {
  return dponetAPI.post(`/question_controls`, {
    questionControl: data,
  })
}

const update = async ({ questionPlanActionEvidencyId, ...data }) => {
  return await dponetAPI.put(
    `/question_plan_action_evidencies/${questionPlanActionEvidencyId}`,
    { questionPlanActionEvidency: data },
  )
}

const createPlan = async ({ ...data }) => {
  return await dponetAPI.post(`/question_plan_action_evidencies/`, {
    questionPlanActionEvidency: data,
  })
}

const updateSelf = async ({ questionControlId, ...data }) => {
  return await dponetAPI.put(`/question_controls/${questionControlId}`, {
    questionControl: data,
  })
}

const takeRisk = async ({ questionControlId, ...data }) => {
  return await dponetAPI.put(
    `/question_controls/${questionControlId}/take_risk`,
    {
      questionControl: data,
    },
  )
}

const logs = async ({ questionControlId }) => {
  return await dponetAPI.get(`/question_controls/${questionControlId}/logs`)
}

const listCustomKinds = () => {
  return dponetAPI.get('/question_controls/list_custom_kinds')
}

const destroy = async (questionControlId) => {
  return await dponetAPI.delete(`/question_controls/${questionControlId}`)
}

export default {
  list,
  show,
  enableWrite,
  update,
  create,
  createPlan,
  updateSelf,
  logs,
  takeRisk,
  listCustomKinds,
  destroy,
}
