import React from 'react'
import { Container } from '@material-ui/core'

import { ContentHeader, Page } from 'components'
import { Form } from './components'

const IncidentManagementCreate = () => {
  return (
    <Page title="Novo incidente">
      <Container maxWidth={false}>
        <ContentHeader title="Novo Incidente" />

        <Form type="create" />
      </Container>
    </Page>
  )
}

export default IncidentManagementCreate
