const LEGITIMATE_INTEREST_FINALITY = 'legitimate_interest_finality'
const CONCRETE_SITUATION = 'concrete_situation'
const LEGITIMATE_EXPECTATION = 'legitimate_expectation'
const SAFEGUARDS_AND_MECHANISMS = 'safeguards_and_mechanisms'
const NATURE_OF_PERSONAL_DATA = 'nature_of_personal_data'
const DATA_CHILDREN_TEENAGERS = 'data_children_teenagers'
const TREATMENT_INTENDED_PURPOSE = 'treatment_intended_purpose'
const MINIMIZATION = 'minimization'
const RISKS_IMPACTS_FUNDAMENTAL_RIGHTS_FREEDOMS =
  'risks_impacts_fundamental_rights_freedoms'
const CONCLUSION_LIA_DPO = 'conclusion_lia'

const FINALITY_STEPS = [LEGITIMATE_INTEREST_FINALITY, CONCRETE_SITUATION]
const BALANCING_SAFEGUARING_STEPS = [
  LEGITIMATE_EXPECTATION,
  SAFEGUARDS_AND_MECHANISMS,
]

const CLIENT = 'client'
const DPO = 'dpo'

const ROLES = { CLIENT, DPO }

const KINDS = {
  CONCRETE_SITUATION,
  DATA_CHILDREN_TEENAGERS,
  LEGITIMATE_EXPECTATION,
  LEGITIMATE_INTEREST_FINALITY,
  MINIMIZATION,
  NATURE_OF_PERSONAL_DATA,
  RISKS_IMPACTS_FUNDAMENTAL_RIGHTS_FREEDOMS,
  SAFEGUARDS_AND_MECHANISMS,
  TREATMENT_INTENDED_PURPOSE,
  CONCLUSION_LIA_DPO,
}

const REPORT_LIA_LABELS = {
  TABLE: {
    FINALITY: 'Finalidade',
    NECESSECITY: 'Necessidade',
    BALANCING_SAFEGUARDIN: 'Balanceamento e Salvarguarda',
    CONCLUSION: 'Conclusão',
  },
  SUBTABLE: {
    NATURE_OF_PERSONAL_DATA: 'Natureza dos dados pessoais',
    DATA_CHILDREN_TEENAGERS: 'Dados de crianças e adolescentes',
    LEGITIMATE_INTEREST_FINALITY: 'Interesse e finalidades legítimas',
    CONCRETE_SITUATION: 'Situação concreta',
    TREATMENT_INTENDED_PURPOSE: 'Tratamento e finalidade pretendida',
    MINIMIZATION: 'Minimização',
    LEGITIMATE_EXPECTATION: 'Legítima expectativa',
    RISKS_IMPACTS_FUNDAMENTAL_RIGHTS_FREEDOMS:
      'Riscos e impactos aos direitos e liberdades fundamentais',
    SAFEGUARDS_AND_MECHANISMS:
      'Salvaguardas e mecanismos de opt-out e de oposição',
  },
  FIRST_QUESTION: '1 - Qual a finalidade do tratamento?',
}

const liaQuestions = {
  BALANCING_SAFEGUARING_STEPS,
  CONCRETE_SITUATION,
  FINALITY_STEPS,
  KINDS,
  LEGITIMATE_EXPECTATION,
  LEGITIMATE_INTEREST_FINALITY,
  REPORT_LIA_LABELS,
  ROLES,
  SAFEGUARDS_AND_MECHANISMS,
}

export default liaQuestions
