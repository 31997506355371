import * as yup from 'yup'

const schema = yup.object().shape({
  title: yup.string().required(),
  subtitle: yup.string(),
  formKind: yup.object().required().typeError('Selecione uma categoria'),
  categoryName: yup.string().when('questionKind', {
    is: (value) => value?.id === 'another',
    then: yup.string().required(),
  }),
  status: yup.string().required().typeError('Selecione um status'),
})

export default schema
