import { colors } from '@material-ui/core'

const white = '#FFFFFF'
const black = '#233154'
const primaryMain = '#233154'
const primaryLight = '#F3FAFF'
const primaryHover = '#0763A0'
const primaryTopBar = '#0763A0'
const secondaryDark = '#233154'
const secondaryMain = '#657786'
const secondaryLight = '#AAB8C2'
const secondarySuperLight = '#E1E8ED'
const backgroundDefault = '#F4F6F8'
const backgroundInputColor = '#F3FAFF'
const linkHelp = '#3024AE'
const menuTitle = '#233154'
const mainMenu = '#546E7A'

const textSecondaryTwo = '#2883BF'

const newAuth = '#48A1CA'
const azure = '#087DF1'
const azureTranslucent = '#087DF138'
const whiteBlue = '#F3FAFF'
const yellowOrange = '#FF9800'
const darkRed = '#B71C1C'
const greenPantone = '#80B92B'
const spanishGray = '#999999'
const teal = '#148585'
const blueViolet = '#671684'
const veryDarkGrayishBlue = '#343A40'
const grapePurple = '#BA2980'
const lightGray = '#EEE'
const cianDark = '#263238'

const yellowWarningLight = '#FFC107'
const yellowWarningDark = '#FFB03D'
const redErrorLight = '#F87B87'
const redErrorDark = '#DC3545'
const blueLight = '#60ACF8'
const blueDark = '#092A3B'
const greenLight = '#5DEE63'
const greenSnowFlurry = '#E3FFBA'
const greenDark = greenPantone
const pinkMagenta = '#F43D9E'
const trailWarning = '#2196F3'
const LightPastelBlue = '#E6F5FF'

const paper = '#E0E0E0'

const palette = {
  LightPastelBlue,
  black,
  white,
  azure,
  azureTranslucent,
  whiteBlue,
  yellowOrange,
  darkRed,
  greenPantone,
  spanishGray,
  teal,
  blueViolet,
  veryDarkGrayishBlue,
  grapePurple,
  yellowWarningLight,
  yellowWarningDark,
  redErrorLight,
  redErrorDark,
  blueLight,
  blueDark,
  greenLight,
  greenDark,
  pinkMagenta,
  trailWarning,
  greenSnowFlurry,
  lightGray,
  cianDark,
  primary: {
    contrastText: white,
    dark: colors.indigo[900],
    main: primaryMain,
    newAuth: newAuth,
    light: primaryLight,
    hover: primaryHover,
    topBar: primaryTopBar,
  },
  secondary: {
    contrastText: white,
    dark: secondaryDark,
    main: secondaryMain,
    light: secondaryLight,
    superLight: secondarySuperLight,
  },
  success: {
    contrastText: white,
    dark: colors.green[900],
    main: colors.green[600],
    light: colors.green[400],
  },
  tertiary: {
    main: '#80B92B',
  },
  info: {
    contrastText: white,
    dark: colors.blue[900],
    main: colors.blue[600],
    light: colors.blue[400],
  },
  warning: {
    contrastText: white,
    dark: colors.orange[900],
    main: colors.orange[600],
    light: colors.orange[400],
  },
  error: {
    contrastText: white,
    dark: colors.red[900],
    main: colors.red[600],
    light: colors.red[400],
  },
  text: {
    primary: secondaryDark,
    menu: secondaryMain,
    mainMenu: mainMenu,
    menuTitle: menuTitle,
    secondary: secondaryMain,
    link: colors.blue[600],
    linkHelp: linkHelp,
    backgroundInputColor: backgroundInputColor,
  },
  categoryCard: {
    mainColor: '#657786',
    hoverColor: '#9A9AA0',
    selectedColor: '#006EA0',
    selectedHoverColor: '#007EB7',
  },
  statuses: {
    pending: '#376FB6',
    disapproved: '#E83A49',
    approved: '#16A63C',
    inactive: '#FCC109',
  },
  background: {
    default: backgroundDefault,
    paper: white,
    snackbar: '#E3FFBB',
  },
  icon: colors.blueGrey[600],
  divider: colors.grey[300],
  navigator: {
    selectedColor: primaryTopBar,
    text: {
      primary: colors.grey[50],
      secondary: '#8CC9E4',
    },
    borderColor: 'transparent',
  },
  partnerGreen: {
    dark: '#097222',
    middle: '#6CC38A',
    light: '#D6FFE4',
  },
  partnerBlue: {
    dark: '#087DF1',
    middle: '#79B9F8',
    light: backgroundInputColor,
  },
  subscription: {
    primary: {
      main: '#233154',
      light: '#B8DAF7',
      dark: '#076EA0',
    },
    warning: {
      error: '#e83a49',
      success: '#80B92B',
      alert: '#fcc109',
      info: {
        main: '#376fb6',
        secondary: ' #9f9e9e',
      },
    },
  },
  dashboard: {
    main: '#096fa1',
    text: '#7e929b',
    report: {
      text: {
        secondary: textSecondaryTwo,
      },
    },
    chart: {
      primary: {
        approved: '#16A63C',
        disapproved: '#E83A49',
        inactive: '#9F9E9E',
        pending: '#376FB6',
        revision: '#FCC109',
        inProgress: '#FF7A00',
        treated: '#AADD5F',
        reviewLia: '#FFEBAC',
      },
      secondary: {
        approved: '#87F3A4',
        pending: '#B8DAF7',
        disapproved: '#FF8A8A',
        revision: '#FFEBAC',
      },
      label: '#657786',
      measures: {
        adopted: '#16A63C',
        pending: '#FF7A00',
        overdue: '#E83A49',
        riskAssumed: '#FECC31',
        treated: '#AADD5F',
        inProgress: '#0763A0',
        inactive: '#546E7A',
      },
    },
    card: {
      incident: {
        main: '#c10d1a',
        secondary: '#ff8a8a',
      },
      gradient: {
        main: '#46a0c9',
      },
    },
    icons: {
      blue: '#096fa1',
      green: '#16A63C',
    },
  },
  marketplace: {
    card: {
      hover: colors.grey[100],
      border: '#4199D2',
    },
    banner: {
      cookies: '#243155',
    },
    section: {
      moreDetails: '#80B92C',
    },
  },
  code: {
    background: '#ECECEC',
  },
  layout: {
    main: '#243155',
    secondary: '#0763A0',
    textPrimary: colors.grey[50],
  },
  fragility: {
    undefined: '#A9A9A9',
    low: '#4CAF50',
    medium: '#ACCA2E',
    high: '#F4A460',
    severe: '#E53935',
  },
  paper: {
    border: paper,
  },
  custom: {
    light: '#E7F2FB',
    main: '#6FA2CF',
    dark: '#657786',
    superDark: '#2E373E',
    border: '#E0E0E0',
  },
  neutral: {
    main: '#546E7A',
  },
  statusLabel: {
    success: {
      primary: '#16A63C',
      secondary: '#B4FFC8',
    },
    error: {
      primary: '#DC3545',
      secondary: '#FFE0E3',
    },
  },
  cardFlags: {
    amex: {
      primary: '#1F72CD',
      grayscale: '#757575',
    },
  },
  textfield: {
    border: '#0000003A',
  },
}

export default palette
