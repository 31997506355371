import React, { useState } from 'react'

import PropTypes from 'prop-types'
import { Box } from '@material-ui/core'
import useStyles from './styles'

import { Editor } from '@tinymce/tinymce-react'

import { getEditorTyneMceToken } from 'service/env'
import LoadingFeedback from 'components/LoadingFeedback'

const TinymceTextEditor = ({ onChange, value, height }) => {
  const classes = useStyles()

  const [loading, setLoading] = useState(true)
  const handleEditorChange = (editor) => onChange(editor)

  const onLoad = () => setLoading(false)

  return (
    <Box className={classes.editor}>
      <LoadingFeedback open={loading} />
      <Editor
        onEditorChange={handleEditorChange}
        apiKey={getEditorTyneMceToken()}
        onLoadContent={onLoad}
        init={{
          height: height,
          plugins: [
            'advlist',
            'autolink',
            'lists',
            'link',
            'image',
            'charmap',
            'anchor',
            'searchreplace',
            'visualblocks',
            'code',
            'fullscreen',
            'insertdatetime',
            'media',
            'table',
            'preview',
            'wordcount',
          ],
          toolbar:
            'undo redo | formatselect | bold italic | alignleft aligncenter alignright | bullist numlist outdent indent ',
          language: 'pt_BR',
        }}
        value={value}
      />
    </Box>
  )
}

TinymceTextEditor.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
  height: PropTypes.number,
}

TinymceTextEditor.defaultValues = {
  height: 500,
}

export default TinymceTextEditor
