import { routes } from 'Routes'
import { reverse } from 'named-urls'
import { isEmpty } from 'lodash'
import { getBaseURL } from 'service/env'
import { saveAs } from 'file-saver'

import defineStatus from './defineStatus'
import defineSubtitle from './defineSubtitle'

const mountTrails = (history, trainningJourney, userSteps, certification) => {
  const goToStep = (step) =>
    history.push(
      `${reverse(routes.trainningJourney, {
        trainningJourneyId: trainningJourney?.id,
      })}?step=${step}`,
    )

  const downloadCertification = () => {
    const certificationLink = certification?.certification
    const imageSrc = `${getBaseURL('dponet')}/${certificationLink}`
    saveAs(imageSrc, `${trainningJourney.name}.png`)
  }

  const trails = trainningJourney?.trainningSteps?.map((trail, index) => {
    const userStep = userSteps?.find(
      (userStep) => userStep?.trainningStepId === trail?.id,
    )

    return {
      title: trail.description,
      subtitle: defineSubtitle(userStep || trail),
      status: defineStatus(!!userStep?.concluded),
      concluded: !!userStep?.concluded,
      action: {
        text: 'Assistir',
        method: () => goToStep(index + 1),
      },
    }
  })

  const someUncompleted = trails?.filter((trail) => !trail.concluded)
  const unlockedCertification = !!certification && isEmpty(someUncompleted)

  return [
    ...(!!trails ? trails : []),
    {
      title: 'Certificado',
      concluded: unlockedCertification,
      status: defineStatus(unlockedCertification),
      subtitle: defineSubtitle(unlockedCertification ? certification : {}),
      action: {
        text: 'Baixar',
        method: downloadCertification,
      },
    },
  ]
}

export default mountTrails
