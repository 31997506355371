import { useContext } from 'react'

import DataProcessOptionsContext from 'contexts/DataProcessOptionsContext'

const useDataProcessOptions = () => {
  const context = useContext(DataProcessOptionsContext)

  if (context === undefined) {
    throw new Error(
      'useDataProcessOptions must be used within a DataProcessOptionsContext.Provider',
    )
  }

  return {
    data: context.data,
    loadData: context.loadData,
    dataProcesses: context.dataProcesses,
    setDataProcesses: context.setDataProcesses,
    reload: context.reload,
    setReload: context.setReload,
    sourceOptions: context.sourceOptions,
    setSourceOptions: context.setSourceOptions,
  }
}

export default useDataProcessOptions
