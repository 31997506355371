import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { Search as SearchIcon } from 'react-feather'
import PropTypes from 'prop-types'
import DOMPurify from 'dompurify'
import { isEmpty } from 'lodash'
import {
  Box,
  Button,
  Grid,
  Link,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core'
import { Info as InfoIcon } from '@material-ui/icons'

import {
  Container,
  ContentHeader,
  LoadingFeedback,
  Page,
  PaperHeader,
} from 'components'

import helpers from 'helpers'

import useFetch from 'hooks/useFetch'
import useAuth from 'hooks/useAuth'

import { routes } from 'Routes'
import * as service from 'service'
import constants from 'constants/index'
import useStyles from './styles'

const InformationSecurityShow = ({ match }) => {
  const [faqTitle, setFaqTitle] = useState('')
  const [faqDescription, setFaqDescription] = useState('')
  const [searchTerm, setSearchTerm] = useState('')

  const classes = useStyles()
  const history = useHistory()
  const {
    userCompany: { company },
  } = useAuth()

  const { subCategoryId } = match?.params

  const { INFORMATION_SECURITY_ID, ACTIVE_STATUS } = constants.technicalFaqs

  const { response: faqsResponse, isLoading: faqsIsLoading } = useFetch(
    service.dponet.technicalFaqs.get,
    {
      minimal: true,
      status: ACTIVE_STATUS,
      subCategoryId,
      categoryId: INFORMATION_SECURITY_ID,
    },
    [subCategoryId],
  )

  const { response: subCategoryResponse, isLoading: subCategoryIsLoading } =
    useFetch(
      service.dponet.technicalFaqs.getSubcategory,
      {
        minimal: true,
        status: ACTIVE_STATUS,
        subCategoryId,
      },
      [subCategoryId],
      !!subCategoryId,
    )

  const technicalFaqs = faqsResponse?.data?.technicalFaqs
  const faqSubcategory = subCategoryResponse?.data?.knowledgeBaseSubcategory

  const filteredSearchTerm =
    !!technicalFaqs &&
    technicalFaqs.filter((item) => {
      return item.title.toLowerCase().includes(searchTerm.toLowerCase())
    })

  const handleFaqCardChange = (title, description) => {
    setFaqTitle(title)
    setFaqDescription(description)
  }

  useEffect(() => {
    if (technicalFaqs) {
      setFaqTitle(technicalFaqs[0]?.title)
      setFaqDescription(technicalFaqs[0]?.description)
    }
  }, [technicalFaqs])
  const handleTrackFaqView = (faq) => {
    const titleWithUnderscores = faq?.title
      ? faq.title.replace(/\s+/g, '_')
      : 'segurança_da_informação'
    const action = `visualizar_${titleWithUnderscores}-si`

    const eventData = {
      action: action,
    }

    helpers.mixpanel.track(
      `${faqSubcategory?.title.replace(/\s+/g, '_') || ''}_faqs-si`,
      action,
      eventData,
    )
    handleFaqCardChange(faq?.title, faq?.description)
  }

  const handleTrackGoBack = (faqSubcategory) => {
    const titleWithUnderscores = faqSubcategory?.title
      ? faqSubcategory.title.replace(/\s+/g, '_')
      : 'segurança_da_informação'
    const action = `voltar-${titleWithUnderscores}`

    const eventData = {
      action: action,
    }

    helpers.mixpanel.track('segurança_da_informação', action, eventData)
    history.push(routes.technicalFaqs.informationSecurityMain)
  }

  const isNotTrial = company.statusId !== constants.userCompanies.TRIAL_STATUS

  return (
    <Page title="Segurança da Informação">
      <LoadingFeedback
        open={(subCategoryIsLoading && !!subCategoryId) || faqsIsLoading}
      />
      <Container maxWidth={false}>
        <ContentHeader title="Segurança da Informação" />
        <Paper>
          <PaperHeader
            title={faqSubcategory?.title || 'Segurança da Informação'}
            actionButton={
              <Tooltip title='GUIA DE PESQUISA EM NOSSA BASE DE CONHECIMENTO: Acesse a plataforma e localize o campo de busca central. Insira sua palavra-chave ou frase específica. Para uma busca mais refinada, utilize os filtros disponíveis ao lado do campo. Após definir seus critérios, clique em "Pesquisar" e navegue pelos resultados apresentados. Cada resultado contém um título e uma breve descrição; clique neles para obter informações detalhadas. Lembre-se de usar palavras-chave precisas e ajustar filtros conforme necessário para melhor eficiência.'>
                <InfoIcon />
              </Tooltip>
            }
          />
          <Box className={classes.containerBox}>
            <Box>
              <Typography variant="h6">Perguntas frequentes:</Typography>
            </Box>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={4}>
                <TextField
                  fullWidth
                  size="small"
                  placeholder="Pesquisa"
                  InputProps={{
                    startAdornment: (
                      <SearchIcon className={classes.searchIcon} />
                    ),
                  }}
                  variant="outlined"
                  onChange={(event) => setSearchTerm(event.target.value)}
                />
                <PerfectScrollbar>
                  <Box mt={3} maxHeight="46vh">
                    <Box paddingRight={2}>
                      {isEmpty(technicalFaqs) && !faqsIsLoading ? (
                        <Typography variant="body2" gutterBottom>
                          Nenhuma base de conhecimento encontrada!
                        </Typography>
                      ) : (
                        !faqsIsLoading &&
                        filteredSearchTerm.map((faq) => (
                          <Link
                            key={faq?.id}
                            component={Typography}
                            href="#"
                            gutterBottom
                            underline="none"
                            className={classes.link}
                            onClick={() => {
                              handleTrackFaqView(faq)
                            }}
                          >
                            {faq?.title}
                          </Link>
                        ))
                      )}
                    </Box>
                  </Box>
                </PerfectScrollbar>
              </Grid>
              <Grid item xs={12} lg={8}>
                <Paper className={classes.cardDescription}>
                  <PerfectScrollbar>
                    {isEmpty(faqTitle) && !faqsIsLoading ? (
                      <Box
                        height="100%"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Typography
                          variant="h6"
                          className={classes.typographyDescription}
                        >
                          Nenhuma base de conhecimento encontrada!
                        </Typography>
                      </Box>
                    ) : (
                      <Box paddingRight={2}>
                        {faqTitle && (
                          <Typography variant="h4">{faqTitle}</Typography>
                        )}
                        <Box
                          fontFamily="Roboto"
                          gridGap={10}
                          dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(faqDescription),
                          }}
                        />
                      </Box>
                    )}
                  </PerfectScrollbar>
                </Paper>
              </Grid>
            </Grid>
            <Box display="flex" justifyContent="end">
              <Button
                variant="contained"
                onClick={() => handleTrackGoBack(faqSubcategory)}
                className={classes.mainButton}
              >
                Voltar
              </Button>

              {isNotTrial && (
                <Box ml={1}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() =>
                      history.push(
                        routes.technicalFaqs.informationSecurityTicket,
                      )
                    }
                  >
                    Entrar em Contato
                  </Button>
                </Box>
              )}
            </Box>
          </Box>
        </Paper>
      </Container>
    </Page>
  )
}

InformationSecurityShow.propTypes = {
  match: PropTypes.object,
}

export default InformationSecurityShow
