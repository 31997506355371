import React from 'react'

import PropTypes from 'prop-types'

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Typography,
  IconButton,
  Divider,
} from '@material-ui/core'

import { Close as CloseIcon } from '@material-ui/icons'

import NewForm from '../DataTreatmentModal/NewForm'

const DataTreatmentShowModal = ({ open, setOpen, dataTreatment }) => {
  return (
    <Dialog open={open} onClose={() => setOpen(false)} maxWidth="md" fullWidth>
      <DialogTitle>
        <Grid container justify="space-between" alignItems="center">
          <Typography variant="h4">Visualizar dados compartilhados</Typography>
          <IconButton
            onClick={() => setOpen(false)}
            color="primary"
            aria-label="close modal"
          >
            <CloseIcon />
          </IconButton>
        </Grid>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <NewForm
          mode="show"
          dataTreatment={dataTreatment}
          handleCloseModal={() => setOpen(false)}
        />
      </DialogContent>

      <DialogActions />
    </Dialog>
  )
}

DataTreatmentShowModal.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  dataTreatment: PropTypes.object,
}

export default DataTreatmentShowModal
