import React, { useState } from 'react'
import { Box, Button, Container, Tooltip, Typography } from '@material-ui/core'
import { HelpCircle as HelpCircleIcon } from 'react-feather'

import { Page, LoadingFeedback, ContentHeader, DriveTour } from 'components'
import { BannerForm } from './components'

import helpers from 'helpers'
import * as service from 'service'
import useFetch from 'hooks/useFetch'
import constants from 'constants/index'

import useStyles from './styles'

const BannerConfiguration = ({ match }) => {
  const { mixpanel } = helpers

  const {
    COOKIES_BANNER_SETTING_DRIVER_STEPS_OBJECT,
    COOKIES_BANNER_SETTING_DRIVER_STEP_1,
  } = constants.cookies.banner

  const [loading, setLoading] = useState(false)
  const [openTour, setOpenTour] = useState(false)

  const classes = useStyles()

  const id = match.params.id

  const { response, isLoading, refresh } = useFetch(
    service.cookies.banner.get,
    { bannerId: id },
  )

  const banner = response?.data?.banner

  const handleOpenTour = () => {
    setOpenTour(true)
    mixpanel.track('Configurações-banner-Cookies', 'Tour', {
      action: 'tour-configuracoes-baner-cookies',
    })
  }

  return (
    <Page title="Configurações do banner e cookies">
      <LoadingFeedback open={loading || isLoading} />
      <Container maxWidth={false} className={classes.container}>
        <ContentHeader title="Configurações do banner e cookies">
          <Tooltip title="Iniciar o tour guiado">
            <Box mr={1}>
              <Button
                variant="contained"
                color="primary"
                startIcon={<HelpCircleIcon size={20} />}
                onClick={handleOpenTour}
                fullWidth
                id={COOKIES_BANNER_SETTING_DRIVER_STEP_1}
              >
                Tutorial
              </Button>
            </Box>
          </Tooltip>
        </ContentHeader>
        {!isLoading && (
          <BannerForm
            banner={banner}
            refresh={refresh}
            loading={loading}
            setLoading={setLoading}
          />
        )}
      </Container>
      <DriveTour
        stepsMatrix={COOKIES_BANNER_SETTING_DRIVER_STEPS_OBJECT}
        open={openTour}
        setOpen={setOpenTour}
      />
    </Page>
  )
}

export default BannerConfiguration
