import React, { useEffect, useState } from 'react'

import { Box, Hidden, Typography, useMediaQuery } from '@material-ui/core'
import { DownloadButton, IconButton, InfoDialog, Text } from '../'
import { LoadingFeedback } from 'components'
import { Info } from 'react-feather'

import { saveAs } from 'file-saver'

import useFirstStep from 'hooks/useFirstStep'
import useAuth from 'hooks/useAuth'
import useSnackbar from 'hooks/useSnackbar'

import certificateImage from 'images/certificado_jornada.png'

import { getBaseURL } from 'service/env'
import constants from 'constants/index'
import helpers from 'helpers'
import * as service from 'service'

import useStyles from './styles'
import theme from 'theme'

const CertificationPage = () => {
  const classes = useStyles()
  const baseURL = getBaseURL('dponet')
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)

  const snackbar = useSnackbar()

  const {
    activeStep,
    activeSubStep,
    certificationLink,
    setBackgroundKind,
    setCertificationLink,
  } = useFirstStep()
  const { userCompany, company } = useAuth()

  const isTrial = company?.statusId === constants.userCompanies.TRIAL_STATUS

  const certificationRequest = async () => {
    if (isTrial) return
    setLoading(true)
    const response =
      await service.dponet.usersCompanies.consultOrCreateCertificate({
        id: userCompany?.id,
        cert_type: 'INTRODUCTION_LGPD',
      })
    setCertificationLink(
      response?.data?.userCompanyCertification?.certification,
    )
    setLoading(false)
  }

  useEffect(() => {
    if (!certificationLink) certificationRequest()
    //eslint-disable-next-line
  }, [])

  const downloadCertification = () => {
    if (isTrial) {
      return snackbar.open({
        message:
          'Contrate nossa plataforma e aproveite ao máximo essa funcionalidade.',
        variant: 'error',
      })
    }

    const imageSrc = `${baseURL}/${certificationLink}`
    saveAs(imageSrc, 'certificado-de-introduçao-à-lgpd.png')
  }

  useEffect(
    () => setBackgroundKind('topRight'),
    //eslint-disable-next-line
    [],
  )

  const handleOpen = () => setOpen(true)

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'), {
    defaultMatches: true,
  })

  const mixpanelInfoId = helpers.userSteps.defineMixpanelId(
    activeStep,
    activeSubStep,
    'informação',
  )

  return (
    <>
      <LoadingFeedback open={loading} />
      <Text color="primary" fontSize="5vh" variant="h1" gutterBottom={isMobile}>
        {!isMobile && (
          <span
            className={classes.spanImage}
            role="img"
            aria-label="Congratulations"
          >
            🥳{' '}
          </span>
        )}
        Compartilhe seu conhecimento
      </Text>
      <Text
        color="secondary"
        fontSize="3vh"
        variant="h4"
        gutterBottom
        className={classes.noWrap}
      >
        Parabéns! Você concluiu a primeira etapa da sua jornada de adequação
      </Text>
      <Box width="100%" display="flex" className={classes.card}>
        <Box
          mt={isMobile ? 1 : 0}
          width={isMobile ? '100%' : '40%'}
          display="flex"
          alignItems="center"
          justifyContent={isMobile ? 'space-between' : 'center'}
        >
          <Box width="10%" />
          <img
            src={certificateImage}
            alt="stamp-client"
            width={isMobile ? '60%' : '100%'}
            className={classes.imgCertificate}
          />
          <Hidden mdUp>
            <Box alignSelf="start" pr={1} width="10%">
              <IconButton
                paddingButton={4}
                icon={<Info />}
                onClick={handleOpen}
                mixpanelId={mixpanelInfoId}
              />
            </Box>
          </Hidden>
        </Box>
        <Box
          width={isMobile ? '100%' : '60%'}
          pt={isMobile ? 2 : 0}
          px={isMobile ? 1 : 2}
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          textAlign="left"
        >
          <Box>
            <Box
              width="100%"
              display="flex"
              justifyContent="space-between"
              alignItems="start"
              mt={2}
            >
              <Box mt={2} px={isMobile ? 0 : 2}>
                <Typography
                  variant={isMobile ? 'h3' : 'h1'}
                  className={classes.titleCard}
                  gutterBottom
                >
                  Certificado de introdução à LGPD
                </Typography>
              </Box>
              <Hidden smDown>
                <IconButton
                  icon={<Info />}
                  onClick={handleOpen}
                  mixpanelId={mixpanelInfoId}
                />
              </Hidden>
            </Box>
            <Typography
              variant={isMobile ? 'subtitle1' : 'h4'}
              paragraph
              className={classes.typographyDescription}
            >
              Esse certificado evidencia que você deu início à sua jornada de
              conhecimento à Lei Geral de Proteção de Dados.
            </Typography>
          </Box>
          <Box width="100%" mb={2} mx={isMobile ? 0 : 2}>
            <DownloadButton
              label="Baixar certificado"
              onClick={downloadCertification}
              mixpanelId="download-etapa_certificado"
              disabled={loading}
            />
          </Box>
        </Box>
      </Box>
      <InfoDialog
        open={open}
        setOpen={setOpen}
        {...constants.userSteps.DIALOG_INFOS.CERTIFICATION}
      />
    </>
  )
}

export default CertificationPage
