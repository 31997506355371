const styles = (theme) => ({
  root: {
    width: '100vw',
    justifyContent: 'center',
    height: '100%',
    flexDirection: 'column',
    paddingBottom: 80,
    [theme.breakpoints.down('md')]: {
      paddingBottom: 0,
    },
  },
})

export default styles
