import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import { Grid, TextField, Switch, FormControlLabel } from '@material-ui/core'
import { useForm, Controller } from 'react-hook-form'

import * as service from 'service'
import helpers from 'helpers'
import constants from 'constants/index'

import schemaUser from './schema'
import { KeyboardDatePicker } from '@material-ui/pickers'

const SupplierForm = ({
  onSubmit,
  companySupplier,
  gridLg,
  clearable,
  setGetValues,
  international,
  setInternational,
  questionnaires = [],
  setOpenSuccess = () => {},
}) => {
  const [isSupplierInvite, setIsSupplierInvite] = useState()

  const disabledApproved =
    companySupplier.status === constants.companySuppliers.APPROVED_STATUS

  const editDisabled =
    !!companySupplier?.id &&
    (disabledApproved || !companySupplier.editionPermitted)

  const { control, handleSubmit, errors, reset, getValues } = useForm({
    validationSchema: schemaUser(
      international,
      disabledApproved,
      isSupplierInvite,
    ),
    defaultValues: {
      name: companySupplier?.supplier?.name || '',
      document: companySupplier?.supplier?.document || '',
      email: companySupplier?.supplierResponsibleEmail || '',
      dateToSendEmails: companySupplier?.dateToSendEmails || null,
    },
  })

  const submit = async (data) => {
    if (isSupplierInvite) {
      await service.dponet.supplierInvite.create({
        supplierInvite: {
          questionnairesParams: { questionnaireKinds: questionnaires },
          document: data.document,
        },
      })

      return setOpenSuccess(true)
    }

    const isInternational = { international: international }
    const dateToSendEmails = editDisabled
      ? null
      : {
          dateToSendEmails: moment(data.dateToSendEmails).format('DD/MM/YYYY'),
        }
    delete data.dateToSendEmails
    const supplier = { ...data, ...isInternational, ...dateToSendEmails }
    await onSubmit(supplier)
    if (clearable) {
      reset()
    }
  }

  useEffect(() => {
    setGetValues(() => getValues)
    // eslint-disable-next-line
  }, [])

  const handleChangeInternationalSupplier = (event) => {
    setInternational(event.target.checked)
  }

  const handleChangeSupplierInvite = (event) => {
    setIsSupplierInvite(event.target.checked)
  }

  return (
    <form id="form-supplier" onSubmit={handleSubmit(submit)}>
      <Grid container spacing={2}>
        {!international && !companySupplier?.id && (
          <Grid item xs={12} lg={gridLg}>
            <FormControlLabel
              name="supplierInvite"
              control={
                <Switch
                  color="primary"
                  checked={isSupplierInvite}
                  onChange={handleChangeSupplierInvite}
                  disabled={disabledApproved}
                />
              }
              label="Convidar via link"
            />
          </Grid>
        )}
        {!isSupplierInvite && !companySupplier?.id && (
          <Grid item xs={12} lg={gridLg}>
            <FormControlLabel
              name="international"
              control={
                <Switch
                  color="primary"
                  checked={international}
                  onChange={handleChangeInternationalSupplier}
                  disabled={disabledApproved}
                />
              }
              label="Fornecedor Internacional"
            />
          </Grid>
        )}

        <Grid item xs={12} lg={gridLg}>
          {!isSupplierInvite && (
            <Controller
              as={
                <TextField
                  label="Razão social/Nome"
                  type="text"
                  color="primary"
                  variant="outlined"
                  error={!!errors.name}
                  helperText={errors?.name?.message}
                  disabled={disabledApproved}
                  fullWidth
                />
              }
              control={control}
              name="name"
              mode="onBlur"
            />
          )}
        </Grid>
        <Grid item xs={12} lg={gridLg}>
          {international ? (
            <Controller
              as={
                <TextField
                  label="Documento"
                  type="text"
                  color="primary"
                  variant="outlined"
                  error={!!errors.internationalDocument}
                  helperText={errors?.internationalDocument?.message}
                  disabled={editDisabled}
                  fullWidth
                />
              }
              control={control}
              name="internationalDocument"
              mode="onBlur"
            />
          ) : (
            <Controller
              as={
                <TextField
                  label="CNPJ/CPF"
                  type="text"
                  color="primary"
                  variant="outlined"
                  error={!!errors.document}
                  helperText={errors?.document?.message}
                  disabled={editDisabled}
                  fullWidth
                />
              }
              onChange={([e]) => {
                return helpers.formatters.documentFormatter(e.target.value)
              }}
              control={control}
              name="document"
              mode="onBlur"
            />
          )}
        </Grid>
        <Grid item xs={8}>
          {!isSupplierInvite && (
            <Controller
              as={
                <TextField
                  label="E-mail de um responsável do fornecedor"
                  type="email"
                  color="primary"
                  variant="outlined"
                  error={!!errors.email}
                  helperText={errors?.email?.message}
                  disabled={disabledApproved}
                  fullWidth
                />
              }
              control={control}
              name="email"
              mode="onBlur"
            />
          )}
        </Grid>
        <Grid item xs={4}>
          {!isSupplierInvite && (
            <Controller
              as={
                <KeyboardDatePicker
                  fullWidth
                  format="DD/MM/yyyy"
                  label="Data para o disparo do e-mail"
                  inputVariant="outlined"
                  onChange={(newValue) => ({
                    value: newValue,
                  })}
                  clearable
                  error={!!errors.dateToSendEmails}
                  helperText={errors?.dateToSendEmails?.message}
                  disabled={disabledApproved}
                  disablePast
                />
              }
              control={control}
              name="dateToSendEmails"
              mode="onChange"
            />
          )}
        </Grid>
      </Grid>
    </form>
  )
}

SupplierForm.propTypes = {
  onSubmit: PropTypes.func,
  companySupplier: PropTypes.object,
  gridLg: PropTypes.number,
  clearable: PropTypes.bool,
  international: PropTypes.bool,
  setInternational: PropTypes.func,
  setOpenSuccess: PropTypes.func,
  questionnaires: PropTypes.array,
}

SupplierForm.defaultProps = {
  onSubmit: () => {},
  companySupplier: {},
  gridLg: 12,
  clearable: false,
  international: false,
  setInternational: () => {},
}

export default SupplierForm
