import React, { Fragment, useState } from 'react'
import clsx from 'clsx'
import { Box, Card, CardActionArea, Typography } from '@material-ui/core'

import useAuth from 'hooks/useAuth'

import { Invoices, PaymentMethod } from './components'
import { LoadingFeedback } from 'components'

import theme from 'theme'
import useStyles from './styles'
import constants from 'constants/index'

const Financial = () => {
  const { company } = useAuth()
  const [currentTab, setCurrentTab] = useState(
    constants.settings.INVOICES_STATUS_ID,
  )
  const [loading, setLoading] = useState(false)

  const classes = useStyles()
  const PAYMENT_METHOD_STATUS_ID = constants.settings.PAYMENT_METHOD_STATUS_ID

  const menus = constants?.settings.FINANCIAL_MENU_ITEMS.filter(
    (item) => item.id !== PAYMENT_METHOD_STATUS_ID || !!company.creditCard,
  )

  return (
    <Fragment>
      <LoadingFeedback open={loading} />
      <Card component={Box} p={2}>
        <Box display="flex" flexDirection="column" gridGap={theme.spacing(2)}>
          <Box display="flex" gridGap={theme.spacing(2)}>
            {menus.map((item, index) => (
              <Card
                key={index}
                variant="outlined"
                className={clsx(classes.financialCard, {
                  [classes.financialBoxHighlight]: currentTab === item?.id,
                })}
              >
                <CardActionArea onClick={() => setCurrentTab(item?.id)}>
                  <Box className={classes.financialBox}>
                    <item.Icon />
                    <Typography variant="h6">{item?.label}</Typography>
                  </Box>
                </CardActionArea>
              </Card>
            ))}
          </Box>
          {currentTab === constants.settings.INVOICES_STATUS_ID && (
            <Invoices setLoading={setLoading} />
          )}
          {currentTab === PAYMENT_METHOD_STATUS_ID && (
            <PaymentMethod setLoading={setLoading} />
          )}
        </Box>
      </Card>
    </Fragment>
  )
}

export default Financial
