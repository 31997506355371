import React, { useState } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import { Box, Container, useMediaQuery } from '@material-ui/core'
import { ArrowRight as ArrowRightIcon } from 'react-feather'
import { InfoDialog, RoundedButton, Stepper } from '../'

import useFirstStep from 'hooks/useFirstStep'
import useAuth from 'hooks/useAuth'

import constants from 'constants/index'
import helpers from 'helpers'
import theme from 'theme'

import useStyles from './styles'

const ContentBase = ({
  children,
  backgroundKind,
  nextButtonText,
  maxWidth,
  hideStepper,
  buttonAlign,
  ...rest
}) => {
  const [openConfirmation, setOpenConfirmation] = useState(false)
  const classes = useStyles()
  const {
    activeStep,
    activeSubStep,
    handleBack,
    handleNext,
    isCenteredScreen,
    isStampStep,
    isStampOrCertificationStep,
    isCheckpointSubStep,
    nextDisabled,
    showBackButton,
    showNextButton,
  } = useFirstStep()

  const { company, user } = useAuth()

  const isDesktop = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  })

  const showBackground = isDesktop && !!backgroundKind

  const handleConfirmation = () => setOpenConfirmation(true)

  const handleNextOrConfirmation = () => {
    if (
      !user?.allStepsConcluded &&
      isStampOrCertificationStep &&
      company?.statusId !== constants.userCompanies.TRIAL_STATUS
    ) {
      return handleConfirmation()
    }

    handleNext()
  }

  const handleNextDialog = () => {
    handleNext()
    setOpenConfirmation(false)
  }

  return (
    <Box
      className={clsx(classes.content, {
        [classes.hexagonBackground]: showBackground,
        [classes[backgroundKind]]: showBackground,
        [classes.fullHeight]: isCheckpointSubStep,
      })}
      width="100%"
      mt={4}
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
    >
      <Container
        className={clsx(classes.container, {
          [classes.centeredContainer]: !isCenteredScreen && isDesktop,
        })}
        maxWidth={maxWidth}
        disableGutters={isCenteredScreen}
      >
        {!hideStepper && (
          <Box
            width="100%"
            display="flex"
            alignItems="center"
            justifyContent="center"
            mb={isDesktop ? 4 : 3}
          >
            <Stepper />
          </Box>
        )}
        <Box
          display="flex"
          flexDirection="column"
          height="inherit"
          {...(isDesktop
            ? {
                minHeight: '70vh',
                justifyContent: isCenteredScreen ? 'center' : 'flex-start',
              }
            : { mb: 14 })}
          className={clsx({
            [classes.widthContent]: !isCenteredScreen && isDesktop,
          })}
          {...rest}
        >
          {children}
          <Box
            display="flex"
            alignItems={isCheckpointSubStep ? 'end' : 'center'}
            {...(isDesktop && { justifyContent: buttonAlign })}
            {...(isCheckpointSubStep && { height: '100%' })}
            width="100%"
            mt={2}
          >
            {showBackButton && (
              <Box
                mr={isDesktop ? 2 : 0}
                {...(!isDesktop && { width: '100%' })}
              >
                <RoundedButton
                  onClick={handleBack}
                  variant="text"
                  fullWidth={!isDesktop}
                  mixpanelId={helpers.userSteps.defineMixpanelId(
                    activeStep,
                    activeSubStep,
                    'voltar',
                  )}
                >
                  Voltar
                </RoundedButton>
              </Box>
            )}
            {showNextButton && (
              <RoundedButton
                icon={<ArrowRightIcon />}
                variant="primary"
                withShadow
                withPadding
                fullWidth={!isDesktop}
                onClick={handleNextOrConfirmation}
                disabled={nextDisabled}
                mixpanelId={helpers.userSteps.defineMixpanelId(
                  activeStep,
                  activeSubStep,
                  nextButtonText,
                )}
              >
                {nextButtonText}
              </RoundedButton>
            )}
          </Box>
        </Box>
        <InfoDialog
          open={openConfirmation}
          setOpen={setOpenConfirmation}
          actionAcceptButton={handleNextDialog}
          textButton="Sim"
          {...constants.userSteps.DIALOG_INFOS.DOWNLOAD_CONFIRMATION(
            isStampStep,
          )}
        />
      </Container>
    </Box>
  )
}

ContentBase.propTypes = {
  backgroundKind: PropTypes.oneOf(['topLeft', 'topRight']),
  nextButtonText: PropTypes.string,
  maxWidth: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']),
  buttonAlign: PropTypes.oneOf(['start', 'end', 'center']),
  hideStepper: PropTypes.bool,
}

ContentBase.defaultProps = {
  nextButtonText: 'Avançar',
  maxWidth: 'md',
  hideStepper: false,
  buttonAlign: 'end',
}

export default ContentBase
