import React from 'react'
import PropTypes from 'prop-types'

import { Button } from '@material-ui/core'

import useAuth from 'hooks/useAuth'

import constants from 'constants/index'

const ButtonAction = ({
  id = '',
  variant = 'text',
  color = 'primary',
  action,
  name,
  startIcon,
  endIcon,
  size = 'medium',
  style,
  className,
  disabled,
  fullWidth = false,
}) => {
  const { company, setFreeTrialBlock } = useAuth()

  const handleAction = () => {
    if (company?.statusId === constants.userCompanies.TRIAL_STATUS) {
      return setFreeTrialBlock(true)
    }

    action()
  }

  return (
    <Button
      id={id}
      className={className}
      style={style}
      startIcon={startIcon}
      endIcon={endIcon}
      variant={variant || 'text'}
      color={color || 'primary'}
      size={size || 'medium'}
      onClick={handleAction}
      disabled={disabled}
      fullWidth={fullWidth}
    >
      {name}
    </Button>
  )
}

ButtonAction.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  variant: PropTypes.string,
  color: PropTypes.string,
  action: PropTypes.func,
  name: PropTypes.string,
  startIcon: PropTypes.node,
  endIcon: PropTypes.node,
  size: PropTypes.string,
  style: PropTypes.object,
  fullWidth: PropTypes.bool,
}

export default ButtonAction
