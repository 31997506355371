import React, { useEffect, useState } from 'react'
import {
  Box,
  Button,
  Container,
  Grid,
  TextField,
  makeStyles,
  Tab,
  Tabs,
  Tooltip,
  InputAdornment,
} from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import {
  Search as SearchIcon,
  HelpCircle as HelpCircleIcon,
} from 'react-feather'
import { reverse } from 'named-urls'
import { ListLastUpdates } from 'views/LastUpdates/components'

import {
  ContentHeader,
  DialogTomticket,
  DriveTour,
  FilterButton,
  LoadingBox,
  Page,
} from 'components'
import { FaqsDocumentationTab, FilterFaqsDocumentations } from './components'

import helpers from 'helpers'

import useFilter from 'hooks/useFilter'
import useSnackbar from 'hooks/useSnackbar'

import constants from 'constants/index'
import * as service from 'service'
import styles from './styles'

import { routes } from 'Routes'

const FaqDocumentations = () => {
  const useStyles = makeStyles(styles)
  const history = useHistory()
  const classes = useStyles()
  const snackbar = useSnackbar()
  const filter = useFilter()
  const { mixpanel } = helpers

  const [loading, setLoading] = useState(false)
  const [faqsDocumentations, setFaqsDocumentations] = useState([])
  const [categories, setCategories] = useState([])
  const [tab, setTab] = useState(constants.faqsDocumentations.FAQ)
  const [kind, setKind] = useState(constants.faqsDocumentations.KINDS.FAQ)
  const [searchInput, setSearchInput] = useState('')
  const [filteredResults, setFilteredResults] = useState([])
  const [valueInput, setValueInput] = useState('')
  const [openTour, setOpenTour] = useState(false)
  const [dialogContactTickets, setDialogContactTickets] = useState(false)

  const isLastUpdateTab = tab === constants.faqsDocumentations.LAST_UPDATES

  const handleChangeTab = (_, value) => {
    setTab(value)
    if (value === constants.faqsDocumentations.FAQ) {
      setKind(constants.faqsDocumentations.KINDS.FAQ)
      filter.setFilters({})
      setFilteredResults([])
      setValueInput('')
      setSearchInput('')
    } else {
      setKind(constants.faqsDocumentations.KINDS.DOCUMENTATION)
      filter.setFilters({})
      setFilteredResults([])
      setValueInput('')
      setSearchInput('')
    }
  }

  useEffect(() => {
    setLoading(true)
    const loadFaqsDocumentations = async () => {
      const response = await service.dponet.faqsDocumentations.get({
        kind: kind,
        draft: false,
        visualization: constants.faqsDocumentations.VISUALIZATION.CC,
        minimal: true,
        perPage: 10000000,
        active: true,
        ...{ ...filter.filters },
      })
      const data = response?.data?.faqsDocumentations
      setFaqsDocumentations(data)
    }
    const loadCategories = async () => {
      const response = await service.dponet.faqsDocumentationsCategories.get({
        categoryType:
          kind === constants.faqsDocumentations.KINDS.FAQ
            ? 'faq_category'
            : 'doc_category',
      })
      const data = response?.data?.faqsDocumentationsCategories
      setCategories(data)
    }
    loadFaqsDocumentations()
    loadCategories()
    setLoading(false)
  }, [kind, filter.filters])

  const seeMoreButton = async (faqDocumentationId) => {
    try {
      setLoading(true)
      history.push(
        reverse(routes.faqsDocumentations.show, {
          faqsDocumentationId: faqDocumentationId,
        }),
      )
    } catch (error) {
      snackbar.open({
        message:
          error?.response?.data?.message ||
          'Não foi possível visualizar a FAQ/Documentação',
        variant: 'error',
      })
    }
    setLoading(false)
  }

  const searchItems = (searchValue) => {
    setSearchInput(searchValue)
    setValueInput(searchValue)
    if (searchInput !== '') {
      const filteredData = faqsDocumentations.filter((item) => {
        return Object.values(item)
          .join('')
          .toLowerCase()
          .includes(searchInput.toLowerCase())
      })
      setFilteredResults(filteredData)
    } else {
      setFilteredResults(faqsDocumentations)
    }
  }

  const handleOpenTour = () => {
    setOpenTour(true)
    mixpanel.track('Central de Ajuda', 'Tour', {
      action: `tour-central-de-ajuda`,
    })
  }

  return (
    <Page title="Central de Ajuda">
      <Container maxWidth={false} className={classes.container}>
        <Box>
          <Box minHeight={85}>
            <ContentHeader title="Central de Ajuda">
              <Tooltip title="Iniciar o tour guiado">
                <Box pr={1}>
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<HelpCircleIcon size={20} />}
                    onClick={handleOpenTour}
                    fullWidth
                    id={
                      constants.faqsDocumentations
                        .FAQS_DOCUMENTATION_MAIN_DRIVER_STEP_1
                    }
                  >
                    Tutorial
                  </Button>
                </Box>
              </Tooltip>

              {!isLastUpdateTab && (
                <FilterButton
                  className={classes.filterButton}
                  setDrawerOpen={filter.setDrawerOpen}
                />
              )}
            </ContentHeader>
          </Box>
          <Box
            id={
              constants.faqsDocumentations.FAQS_DOCUMENTATION_MAIN_DRIVER_STEP_2
            }
          >
            <TextField
              disabled={isLastUpdateTab}
              className={classes.faqDocBox}
              fullWidth
              value={valueInput}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon size={20} />
                  </InputAdornment>
                ),
              }}
              variant="outlined"
              onChange={(e) => searchItems(e.target.value)}
            />
          </Box>
        </Box>
        {loading && <LoadingBox />}
        <Box className={classes.faqDocBox}>
          <Tabs
            value={tab}
            onChange={() => handleChangeTab}
            indicatorColor="primary"
            textColor="primary"
          >
            {constants.faqsDocumentations.TABS.map((tab) => (
              <Tab
                key={tab.value}
                label={tab.label}
                value={tab.value}
                onClick={(event) => handleChangeTab(event, tab.value)}
                id={
                  tab.value === constants.faqsDocumentations.FAQ
                    ? constants.faqsDocumentations
                        .FAQS_DOCUMENTATION_MAIN_DRIVER_STEP_3
                    : constants.faqsDocumentations
                        .FAQS_DOCUMENTATION_MAIN_DRIVER_STEP_4
                }
              />
            ))}
          </Tabs>
        </Box>

        <Box className={classes.boxFlex}>
          <Grid container>
            <Grid item xs={12}>
              <Box display="flex" justifyContent="flex-end">
                <Button
                  variant="contained"
                  onClick={() => setDialogContactTickets(true)}
                >
                  Não encontrei o que eu desejava
                </Button>
              </Box>
            </Grid>
            {isLastUpdateTab ? (
              <ListLastUpdates mt={1.5} />
            ) : (
              <FaqsDocumentationTab
                classes={classes}
                loading={loading}
                faqsDocumentations={faqsDocumentations}
                filteredResults={filteredResults}
                searchInput={searchInput}
                seeMoreButton={seeMoreButton}
                valueInput={valueInput}
              />
            )}
          </Grid>
        </Box>
      </Container>
      <FilterFaqsDocumentations
        filter={filter}
        kind={kind}
        categories={categories}
      />
      <DriveTour
        stepsMatrix={
          constants.faqsDocumentations.FAQS_DOCUMENTATION_DRIVER_STEPS_OBJECT
        }
        open={openTour}
        setOpen={setOpenTour}
      />
      <DialogTomticket
        open={dialogContactTickets}
        setOpen={setDialogContactTickets}
      />
    </Page>
  )
}

export default FaqDocumentations
