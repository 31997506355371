import { isNil, isEmpty } from 'lodash'

import constants from 'constants/index'

const size = (unit, sizeLimit, files) => {
  if (isNil(files) || (files instanceof FileList && isEmpty(files))) return true

  const exponentiation = constants.validations.BYTE_UNIT_EXPONENT[unit]
  if (files instanceof FileList)
    return Array.from(files).every(
      (file) => file?.size <= sizeLimit * exponentiation,
    )
  else return files?.size <= sizeLimit * exponentiation
}

const type = (permittedTypes, files) => {
  if (isNil(files) || (files instanceof FileList && isEmpty(files))) return true

  if (files instanceof FileList)
    return Array.from(files).every((file) =>
      permittedTypes.includes(file?.type),
    )
  else return permittedTypes.includes(files?.type)
}

export default { size, type }
