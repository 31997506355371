import {
  CornerDownLeft as CornerDownLeftIcon,
  CornerUpLeft as CornerUpLeftIcon,
} from 'react-feather'
import {
  FormatBold as FormatBoldIcon,
  FormatItalic as FormatItalicIcon,
  FormatUnderlined as FormatUnderlinedIcon,
  FormatStrikethrough as FormatStrikethroughIcon,
  FormatQuote as FormatQuoteIcon,
  FormatListNumbered as FormatListNumberedIcon,
  FormatListBulleted as FormatListBulletedIcon,
  FormatAlignLeft as FormatAlignLeftIcon,
  FormatAlignCenter as FormatAlignCenterIcon,
  FormatAlignRight as FormatAlignRightIcon,
} from '@material-ui/icons'

const HEADING_ONE_LABEL_BUTTON = 'heading-one'
const HEADING_TWO_LABEL_BUTTON = 'heading-two'
const HEADING_THREE_LABEL_BUTTON = 'heading-three'

const TOOLBAR_GROUPS = [
  [
    {
      id: 1,
      format: 'bold',
      type: 'mark',
      Icon: FormatBoldIcon,
      description: 'Negrito',
      shortcutKey: 'Ctrl+B',
    },
    {
      id: 2,
      format: 'italic',
      type: 'mark',
      Icon: FormatItalicIcon,
      description: 'Itálico',
      shortcutKey: 'Ctrl+I',
    },
    {
      id: 3,
      format: 'underline',
      type: 'mark',
      Icon: FormatUnderlinedIcon,
      description: 'Sublinhado',
      shortcutKey: 'Ctrl+U',
    },
    {
      id: 4,
      format: 'strikethrough',
      type: 'mark',
      Icon: FormatStrikethroughIcon,
      description: 'Tachado',
      shortcutKey: 'Ctrl+S',
    },
  ],
  [
    {
      id: 5,
      format: 'superscript',
      type: 'mark',
      Icon: CornerUpLeftIcon,
      description: 'Sobrescrito',
      shortcutKey: 'Ctrl+E',
    },
    {
      id: 6,
      format: 'subscript',
      type: 'mark',
      Icon: CornerDownLeftIcon,
      description: 'Subscrito',
      shortcutKey: 'Ctrl+R',
    },
  ],
  [
    {
      id: 7,
      format: HEADING_ONE_LABEL_BUTTON,
      type: 'block',
      description: '1ª Título',
      shortcutKey: 'Ctrl+1',
    },
    {
      id: 8,
      format: HEADING_TWO_LABEL_BUTTON,
      type: 'block',
      description: '2ª Título',
      shortcutKey: 'Ctrl+2',
    },
    {
      id: 9,
      format: HEADING_THREE_LABEL_BUTTON,
      type: 'block',
      description: '3ª Título',
      shortcutKey: 'Ctrl+3',
    },
    {
      id: 10,
      format: 'blockquote',
      type: 'block',
      Icon: FormatQuoteIcon,
      description: 'Citação',
      shortcutKey: 'Ctrl+Q',
    },
  ],
  [
    {
      id: 11,
      format: 'ordered-list',
      type: 'block',
      Icon: FormatListNumberedIcon,
      description: 'Lista ordenada',
      shortcutKey: 'Ctrl+L',
    },
    {
      id: 12,
      format: 'unordered-list',
      type: 'block',
      Icon: FormatListBulletedIcon,
      description: 'Lista não ordenada',
      shortcutKey: 'Ctrl+O',
    },
  ],
  [
    {
      id: 13,
      format: 'align-left',
      type: 'block',
      Icon: FormatAlignLeftIcon,
      description: 'Alinhar à esquerda',
      shortcutKey: 'Ctrl+⬅',
    },
    {
      id: 14,
      format: 'align-center',
      type: 'block',
      Icon: FormatAlignCenterIcon,
      description: 'Alinhar ao centro',
      shortcutKey: 'Ctrl+⬆',
    },
    {
      id: 15,
      format: 'align-right',
      type: 'block',
      Icon: FormatAlignRightIcon,
      description: 'Alinhar à direita',
      shortcutKey: 'Ctrl+➡',
    },
  ],
]

const TOOLBAR_BUTTONS_LABEL = {
  [HEADING_ONE_LABEL_BUTTON]: 'H1',
  [HEADING_TWO_LABEL_BUTTON]: 'H2',
  [HEADING_THREE_LABEL_BUTTON]: 'H3',
}

export default {
  TOOLBAR_GROUPS,
  TOOLBAR_BUTTONS_LABEL,
  HEADING_ONE_LABEL_BUTTON,
  HEADING_TWO_LABEL_BUTTON,
  HEADING_THREE_LABEL_BUTTON,
}
