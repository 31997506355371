import React, { useState } from 'react'
import PropTypes from 'prop-types'

import useSnackbar from 'hooks/useSnackbar'
import useDataProcess from 'hooks/useDataProcess'

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Typography,
} from '@material-ui/core'
import { LoadingFeedback } from 'components'
import Form from './components/Form'

import constants from 'constants/index'
import * as service from 'service'
import helpers from 'helpers'

const DialogDataLifeCycle = ({
  dataProcessId,
  open,
  setLoading = () => {},
  setOpen = () => {},
  refresh = () => {},
  mode,
  dataLifeCycle = {},
  dataProcess,
  ...props
}) => {
  const snackbar = useSnackbar()
  const { reloadOptions } = useDataProcess()
  const [modalLoading, setModalLoading] = useState(false)
  const edit = mode === 'edit'

  const { STORY_MODE_DEFINED_TYPE } = constants.dataProcess

  const valueDataProcess = dataProcess?.value
  const volumetryDataProcess = dataProcess?.volumetry
  const storageMode = dataProcess?.storageMode

  const onSubmit = async (data, watch, setError) => {
    if (storageMode === STORY_MODE_DEFINED_TYPE) {
      let formattedStorageTimeDataProcess =
        helpers.dataProcess.storageTimeValidation(
          valueDataProcess,
          volumetryDataProcess,
        )

      let formattedStorageTimeLifeCycle =
        helpers.dataProcess.storageTimeValidation(
          watch('value'),
          watch('volumetry'),
        )

      if (formattedStorageTimeDataProcess < formattedStorageTimeLifeCycle) {
        setError('value', {
          type: {
            invalid:
              'Insira uma data menor do que a informada nas informações gerais do processo',
          },
        })

        setError('volumetry', {
          type: {
            invalid:
              'Insira uma data menor do que a informada nas informações gerais do processo',
          },
        })
        return
      }
    }

    setModalLoading(true)
    setLoading(true)

    try {
      if (edit) {
        await service.dponet.dataLifeCycles.put({
          dataProcessId,
          dataLifeCycleId: dataLifeCycle.id,
          lifeCycle: { ...data },
        })
      } else {
        await service.dponet.dataLifeCycles.create({
          dataProcessId,
          lifeCycle: { ...data },
        })
      }

      refresh()
      reloadOptions()
      setModalLoading(false)
      setOpen(false)

      snackbar.open({
        message: `Salvaguarda e ciclo de vida ${
          edit ? 'editado' : 'criado'
        } com sucesso!`,
        variant: 'success',
      })
    } catch (error) {
      snackbar.open({
        message: 'Ocorreu algum erro! Tente novamente!',
        variant: 'error',
      })
    }
    setLoading(false)
  }

  return (
    <>
      <LoadingFeedback open={modalLoading} />
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        maxWidth="md"
        fullWidth
        {...props}
      >
        <DialogTitle>
          <Box>
            <Typography variant="h4">
              {edit ? 'Editar' : 'Adicionar'} salvaguarda e ciclo de vida
            </Typography>
          </Box>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Form
            onSubmit={onSubmit}
            dataProcessId={dataProcessId}
            dataLifeCycle={dataLifeCycle}
            dataProcess={dataProcess}
          />
        </DialogContent>
        <Divider />
        <DialogActions>
          <Box pl={2} pr={1}>
            <Button
              type="button"
              variant="outlined"
              onClick={() => setOpen(false)}
            >
              Voltar
            </Button>
          </Box>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            form="data-life-cycle-form"
          >
            Salvar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

DialogDataLifeCycle.propTypes = {
  dataProcessId: PropTypes.number.isRequired,
  open: PropTypes.bool,
  setOpen: PropTypes.func.isRequired,
  setLoading: PropTypes.func,
  onEvent: PropTypes.func,
  mode: PropTypes.oneOf(['create', 'edit']).isRequired,
  refresh: PropTypes.func,
  dataProcess: PropTypes.object.isRequired,
}

export default DialogDataLifeCycle
