import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { useHistory } from 'react-router-dom'

import useSnackbar from 'hooks/useSnackbar'
import useAuth from 'hooks/useAuth'

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Divider,
  Typography,
  Button,
  TextField,
} from '@material-ui/core'

import { schemaDialogRipds } from './schemas'

import { useForm, Controller } from 'react-hook-form'
import * as service from 'service'
import { LoadingButton } from 'components'
import { RipdIncite } from './components'
import constants from 'constants/index'

const DialogExportRipds = ({ open, setOpen, ripdIncite }) => {
  const { company, setFreeTrialBlock } = useAuth()
  const history = useHistory()
  const snackbar = useSnackbar()

  const [isLoading, setIsLoading] = useState(false)

  const { handleSubmit, control, errors } = useForm({
    defaultValues: {
      receiverEmail: '',
    },
    validationSchema: schemaDialogRipds(
      company?.statusId !== constants.userCompanies.TRIAL_STATUS,
    ),
  })

  const onSubmit = async (data) => {
    if (company?.statusId === constants.userCompanies.TRIAL_STATUS) {
      return setFreeTrialBlock(true)
    }
    setIsLoading(true)
    try {
      const response = await service.dponet.ripds.exportsRipd({
        ripds: {
          receiverEmail: data?.receiverEmail,
          justification_report: 'Princípio da segurança e da prevenção',
          comment: '',
          fragilities: [],
          departments: [],
          legal_frameworks: [],
        },
      })

      snackbar.open({
        message: response.data.message,
        variant: 'success',
      })
    } catch (error) {
      snackbar.open({
        message:
          error?.response?.data?.message ||
          'Erro ao gerar o Relatório de impacto!',
        variant: 'error',
      })
    }
    closeDialog()
    setIsLoading(false)
  }

  const closeDialog = () => {
    setOpen(false)
    history.replace()
  }

  // Remover quando criar a tela home
  if (ripdIncite) {
    return <RipdIncite open={open} setOpen={setOpen} exportRipd={onSubmit} />
  }

  const onClose = (e, reason) => {
    if (reason === 'backdropClick') return
    setOpen(false)
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        <Box>
          <Typography variant="h4">Gerar RIPD</Typography>
        </Box>
      </DialogTitle>
      <Divider />
      <Box my={1}>
        <DialogContent>
          <Box>
            <Typography>
              Especifique o email a qual será enviado o Relatório de impacto.
            </Typography>
          </Box>
        </DialogContent>
      </Box>
      <form onSubmit={handleSubmit(onSubmit)} id="send-ripds-email">
        <Box mb={2} mx={3}>
          <Controller
            as={
              <TextField
                fullWidth
                label="Email de destino"
                type="email"
                helperText={errors?.receiverEmail?.message}
                error={errors?.receiverEmail}
              />
            }
            defaultValue=""
            name="receiverEmail"
            control={control}
            mode="onChange"
          />
        </Box>
      </form>
      <Divider />
      <DialogActions p={2}>
        <Box pl={2} pr={1}>
          <Button
            type="button"
            variant="outlined"
            onClick={() => closeDialog()}
          >
            Cancelar
          </Button>
        </Box>
        <LoadingButton
          variant="contained"
          type="submit"
          color="primary"
          form="send-ripds-email"
          text="Gerar Ripd"
          loading={isLoading}
        />
      </DialogActions>
    </Dialog>
  )
}

DialogExportRipds.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  ripdIncite: PropTypes.bool, // Remover quando criar a tela home
}

DialogExportRipds.defaultValues = {
  setOpen: () => {},
  ripdIncite: false, // Remover quando criar a tela home
}

export default DialogExportRipds
