import activities from './activities'
import auditConfigs from './auditConfigs'
import auditProcesses from './auditProcesses'
import auth from './auth'
import companies from './companies'
import companyAudits from './companyAudits'
import companyDocuments from './companyDocuments'
import companyFragilities from './companyFragilities'
import companyFreeTrials from './companyFreeTrials'
import companySizes from './companySizes'
import companyTrails from './companyTrails'
import consent from './consent'
import cookieConsentCompanies from './cookieConsentCompanies'
import cookieConsents from './cookieConsents'
import coupons from './coupons'
import dataCollectedOptions from './dataCollectedOptions'
import dataCollecteds from './dataCollecteds'
import dataLifeCycles from './dataLifeCycles'
import dataProcesses from './dataProcesses'
import dataTreatmentAgents from './dataTreatmentAgents'
import dataTreatments from './dataTreatments'
import departments from './departments'
import documents from './documents'
import documentsTemplate from './documentsTemplate'
import faqsDocumentations from './faqsDocumentations'
import faqsDocumentationsCategories from './faqsDocumentationsCategories'
import incidents from './incidents'
import internationalTransfer from './internationalTransfers'
import lastUpdates from './lastUpdate'
import legalFrameworks from './legalFrameworks'
import liaAnswers from './liaAnswers'
import liaQuestions from './liaQuestions'
import moodleLotus from './moodleLotus'
import myLgpd from './myLgpd'
import necessityProportionality from './necessityProportionality'
import nonComplianceReportActions from './nonComplianceReportActions'
import nonComplianceReports from './nonComplianceReports'
import observerInvite from './observerInvite'
import outsourcedDpo from './outsourcedDpo'
import paymentOrders from './paymentOrders'
import payments from './payments'
import plans from './plans'
import preRegistrations from './preRegistrations'
import privacyPolicies from './privacyPolicies'
import privacyStamps from './privacyStamps'
import profiles from './profiles'
import questionControls from './questionControls'
import questionnaires from './questionnaires'
import reports from './reports'
import reportVersions from './reportVersions'
import residualRisks from './residualRisks'
import resume from './resume'
import ripds from './ripds'
import securityMeasureCategories from './securityMeasureCategories'
import securityMeasures from './securityMeasures'
import segments from './segments'
import sharedSoftwares from './sharedSoftwares'
import specificRisks from './specificRisks'
import suggestedChanges from './suggestedChanges'
import suggestedSuppliers from './suggestedSuppliers'
import supplierInvite from './supplierInvite'
import suppliers from './suppliers'
import technicalFaqs from './technicalFaqs'
import tickets from './tickets'
import tomticketIntermediator from './tomticketIntermediator'
import trainningJourneys from './trainningJourneys'
import twoFactorAuthentication from './twoFactorAuthentication'
import users from './users'
import usersCompanies from './usersCompanies'
import whiteLabel from './whiteLabel'

export default {
  activities,
  auditConfigs,
  auditProcesses,
  auth,
  companies,
  companyAudits,
  companyDocuments,
  companyFragilities,
  companyFreeTrials,
  companySizes,
  companyTrails,
  consent,
  cookieConsentCompanies,
  cookieConsents,
  coupons,
  dataCollectedOptions,
  dataCollecteds,
  dataLifeCycles,
  dataProcesses,
  dataTreatmentAgents,
  dataTreatments,
  departments,
  documents,
  documentsTemplate,
  faqsDocumentations,
  faqsDocumentationsCategories,
  incidents,
  internationalTransfer,
  lastUpdates,
  legalFrameworks,
  liaAnswers,
  liaQuestions,
  moodleLotus,
  myLgpd,
  necessityProportionality,
  nonComplianceReportActions,
  nonComplianceReports,
  observerInvite,
  outsourcedDpo,
  paymentOrders,
  payments,
  plans,
  preRegistrations,
  privacyPolicies,
  privacyStamps,
  profiles,
  questionControls,
  questionnaires,
  reports,
  reportVersions,
  residualRisks,
  resume,
  ripds,
  securityMeasureCategories,
  securityMeasures,
  segments,
  sharedSoftwares,
  specificRisks,
  suggestedChanges,
  suggestedSuppliers,
  supplierInvite,
  suppliers,
  technicalFaqs,
  tickets,
  tomticketIntermediator,
  trainningJourneys,
  twoFactorAuthentication,
  users,
  usersCompanies,
  whiteLabel,
}
