import React, { useState } from 'react'
import { Typography, Box, Button, Hidden } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { routes } from 'Routes'

import { BaseConfirmationDialog, LoadingFeedback } from 'components'

import useAuth from 'hooks/useAuth'
import useSnackbar from 'hooks/useSnackbar'

import helpers from 'helpers'
import * as service from 'service'

import clock from 'images/clock.png'
import useStyles from './styles'

const DisplayStaticUseLimitationNotice = () => {
  const [rdMarketing, setRdMarketing] = useState(false)
  const history = useHistory()
  const [loading, setLoading] = useState(false)

  const snackbar = useSnackbar()
  const { company } = useAuth()
  const classes = useStyles()

  const endDate = helpers.formatters.calcDays(company?.demoPartner?.demoEndDate)

  const handleNotify = async () => {
    setLoading(true)
    try {
      const response = await service.dponet.companyFreeTrials.notify_hire()
      const success = response?.data?.success

      if (success) {
        setRdMarketing(true)
      } else {
        snackbar.open({
          message: response?.data?.message,
          variant: 'error',
        })
      }
    } catch (error) {
      snackbar.open({
        message: helpers.formatters.errorMessage(error.response.data.error),
        variant: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  const handleRedirect = () => {
    if (company?.preRegistrationToken) {
      history.push({
        pathname: routes.subscription.default,
        search: `segment=${company?.segment?.name}&token=${company?.preRegistrationToken}`,
      })
    } else {
      handleNotify()
    }
  }

  return (
    <>
      <LoadingFeedback open={loading} />
      <Box className={classes.alert}>
        <Hidden smDown>
          <Box display="flex" alignItems="center">
            <img src={clock} alt="Logo" width={30} />
            <Box ml={1}>
              <Typography>Período teste</Typography>
            </Box>
          </Box>
        </Hidden>
        <Box display="flex" alignItems="center" className={classes.restDays}>
          <Typography className={classes.number}>{endDate}</Typography>
          <Box ml={1}>
            <Typography>dias restantes</Typography>
          </Box>
        </Box>
        <Box className={classes.boxButton}>
          <Button
            type="button"
            variant="outlined"
            className={classes.button}
            onClick={handleRedirect}
            fullWidth
          >
            REALIZAR CONTRATAÇÃO
          </Button>
        </Box>
      </Box>
      {rdMarketing && (
        <BaseConfirmationDialog
          displayedButtons={false}
          title="Solicitação de contratação da plataforma"
          text="Essa solicitação foi encaminhada à nossa equipe, e entraremos em contato para dar continuidade ao
                processo de contratação."
          open={rdMarketing}
          setOpen={setRdMarketing}
          variant="success"
          loading={loading}
        />
      )}
    </>
  )
}

export default DisplayStaticUseLimitationNotice
