import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
  Box,
  Button,
  Card,
  Hidden,
  Tab,
  TablePagination,
  Tabs,
  Tooltip,
} from '@material-ui/core'
import { HelpCircle as HelpCircleIcon } from 'react-feather'
import { PlusCircle } from 'react-feather'

import {
  ButtonAction,
  Container,
  ContentHeader,
  DriveTour,
  FilterButton,
  Filters,
  Page,
} from 'components'
import { ReceiptTable } from './components'

import useFetch from 'hooks/useFetch'
import useFilter from 'hooks/useFilter'
import usePagination from 'hooks/usePagination'

import constants from 'constants/index'
import * as service from 'service'
import { routes } from 'Routes'
import helpers from 'helpers'

import useStyles from './styles'

const ConsentMain = () => {
  const classes = useStyles()
  const filter = useFilter()
  const history = useHistory()

  const {
    CONSENT_RECEIPTS_TABS,
    ALL_STATUSES_CONSENT,
    ACTIVE_STATUS_ID,
    INACTIVE_STATUS_ID,
    RECEIPT_STATUSES,
  } = constants.consent

  const [tab, setTab] = useState(CONSENT_RECEIPTS_TABS[0].value)
  const [statuses, setStatuses] = useState(ALL_STATUSES_CONSENT)
  const [openTour, setOpenTour] = useState(false)

  const { perPage, page, handleChangePage, handleChangeRowsPerPage } =
    usePagination(10)

  const handleChangeTab = (_, value) => {
    setTab(value)
    if (value === CONSENT_RECEIPTS_TABS[0].value) {
      return setStatuses(ALL_STATUSES_CONSENT)
    }
    if (value === CONSENT_RECEIPTS_TABS[1].value) {
      return setStatuses(ACTIVE_STATUS_ID)
    }
    if (value === CONSENT_RECEIPTS_TABS[2].value) {
      return setStatuses(INACTIVE_STATUS_ID)
    }
  }

  const { response, isLoading, refresh } = useFetch(
    service.dponet.consent.listReceipts,
    {
      perPage,
      page,
      statuses: statuses,
      ...filter.filters,
    },
    [perPage, page, statuses, filter.filters],
  )

  const receipts = response?.data?.consentFormAnswers

  const handleNew = () => history.push(routes.consent.consentReceiptNew)

  const handleOpenTour = () => {
    const where = 'Recibo de consentimento'
    const title = 'Tour'

    const params = {
      action: 'tour-recibos-consentimento',
    }

    helpers.mixpanel.track(where, title, params)
    setOpenTour(true)
  }

  return (
    <Page title="Recibo de Consentimento">
      <Container maxWidth={false}>
        <Box>
          <ContentHeader title="Recibo de Consentimento">
            <Tooltip title="Iniciar o tour guiado">
              <Box className={classes.contentHeaderBox}>
                <Button
                  id={constants.consent.RECEIPT_MAIN_DRIVER_STEP_0}
                  variant="contained"
                  color="primary"
                  startIcon={<HelpCircleIcon size={20} />}
                  onClick={handleOpenTour}
                  fullWidth
                >
                  Tutorial
                </Button>
              </Box>
            </Tooltip>
            <Box pl={1}>
              <ButtonAction
                variant="contained"
                color="primary"
                action={handleNew}
                startIcon={<PlusCircle width="18" />}
                name="Registrar Consentimento"
              />
            </Box>
            <Box pl={1}>
              <FilterButton setDrawerOpen={filter.setDrawerOpen} />
            </Box>
          </ContentHeader>
        </Box>
        <Box>
          <Tabs
            value={tab}
            indicatorColor="primary"
            textColor="primary"
            onChange={() => handleChangeTab}
          >
            {CONSENT_RECEIPTS_TABS.map((tab) => (
              <Tab
                key={tab.value}
                label={tab.label}
                value={tab.value}
                onClick={(event) => handleChangeTab(event, tab.value)}
              />
            ))}
          </Tabs>
        </Box>
        {!isLoading && (
          <Card>
            <ReceiptTable receipts={receipts} refresh={refresh} />
            <TablePagination
              component="div"
              count={response?.data?.meta?.totalCount || 0}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              page={page - 1}
              rowsPerPage={perPage}
              labelRowsPerPage={<Hidden smDown>Por página</Hidden>}
              rowsPerPageOptions={[5, 10, 25, 100]}
              nextIconButtonProps={{ size: 'small' }}
              backIconButtonProps={{ size: 'small' }}
            />
          </Card>
        )}
        <Filters filter={filter}>
          <input textfieldinput="true" label="Identificador" name="id" />
          <select textfieldinput="true" label="Status" name="statuses">
            <option value=""></option>
            {RECEIPT_STATUSES.map((status) => (
              <option key={status.id} value={status.id}>
                {status.name}
              </option>
            ))}
          </select>
          <input textfieldinput="true" label="Plataforma" name="platform" />
          <input
            textfieldinput="true"
            label="Identificador do formulário"
            name="consentFormId"
          />
          <input textfieldinput="true" label="E-mail" name="userEmail" />
        </Filters>
      </Container>
      <DriveTour
        stepsMatrix={constants.consent.RECEIPT_MAIN_DRIVER_STEPS_OBJECT}
        open={openTour}
        setOpen={setOpenTour}
      />
    </Page>
  )
}

export default ConsentMain
