import React from 'react'
import PropTypes from 'prop-types'

import { Box, Container, useMediaQuery } from '@material-ui/core'
import { ArrowRight as ArrowRightIcon } from 'react-feather'
import { RoundedButton } from 'components'

import { Stepper } from '..'

import theme from 'theme'

import useStyles from './styles'

const ContentBase = ({
  children,
  showActions,
  nextButtonText,
  maxWidth,
  hideStepper,
  buttonAlign,
  handleNext,
  hideBackButton,
  handleBack,
  isFirstStep,
  activeStep,
  ...rest
}) => {
  const classes = useStyles()
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  })

  return (
    <Box
      width="100%"
      mt={4}
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
    >
      <Container className={classes.container} maxWidth={maxWidth}>
        {!hideStepper && (
          <Container maxWidth="md">
            <Box
              width="100%"
              display="flex"
              alignItems="center"
              justifyContent="center"
              mb={isDesktop ? 4 : 3}
            >
              <Stepper activeStep={activeStep} />
            </Box>
          </Container>
        )}
        <Box
          display="flex"
          flexDirection="column"
          height="inherit"
          alignItems="center"
          justifyContent="center"
          {...(isDesktop
            ? {
                minHeight: '70vh',
              }
            : { mb: 14 })}
          {...rest}
        >
          {children}
          {showActions && (
            <Box
              display="flex"
              alignItems={'center'}
              {...(isDesktop
                ? { justifyContent: buttonAlign }
                : {
                    flexDirection: 'column',
                  })}
              width="100%"
              mt={2}
            >
              {!hideBackButton && (
                <Box
                  mr={isDesktop ? 2 : 0}
                  {...(!isDesktop && { width: '100%', mb: 1 })}
                >
                  <RoundedButton
                    onClick={handleBack}
                    variant="outlined"
                    withPadding
                    fullWidth={!isDesktop}
                  >
                    Voltar
                  </RoundedButton>
                </Box>
              )}

              <RoundedButton
                {...(isFirstStep && { icon: <ArrowRightIcon /> })}
                variant="primary"
                withShadow
                withPadding
                fullWidth={!isDesktop}
                onClick={() => handleNext()}
              >
                {nextButtonText}
              </RoundedButton>
            </Box>
          )}
        </Box>
      </Container>
    </Box>
  )
}

ContentBase.propTypes = {
  nextButtonText: PropTypes.string,
  maxWidth: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']),
  buttonAlign: PropTypes.oneOf(['start', 'end', 'center']),
  hideStepper: PropTypes.bool,
}

ContentBase.defaultProps = {
  nextButtonText: 'Avançar',
  maxWidth: 'xl',
  hideStepper: false,
  showActions: true,
  buttonAlign: 'end',
}

export default ContentBase
