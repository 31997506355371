const ACTIVE = 'ACTIVE'
const INACTIVE = 'INACTIVE'
const COMPANY_AVALIATION_STATUS = 2
const TRIAL_STATUS = 8
const TRIAL_CANCEL_STATUS = 9

const PARTNER_TRIAL = 'DPONET_TRIAL'
const PARTNER_ANPPD = 'ANPPD'

const KIND_TEMPLATE = 'T'

export default {
  ACTIVE,
  INACTIVE,
  COMPANY_AVALIATION_STATUS,
  TRIAL_STATUS,
  TRIAL_CANCEL_STATUS,
  PARTNER_TRIAL,
  PARTNER_ANPPD,
  KIND_TEMPLATE,
}
