/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'

import PropTypes from 'prop-types'

import useAuth from 'hooks/useAuth'
import useSnackbar from 'hooks/useSnackbar'
import useCustomTheme from 'hooks/useCustomTheme'

import helpers from 'helpers'

const QuestionControlPdf = ({
  exportingPdf,
  setExportingPdf,
  questionControls,
  isComplete,
  gapStats,
  shouldCreateVersion,
  refresh,
}) => {
  const snackbar = useSnackbar()
  const { customTheme, customLogo } = useCustomTheme()
  const { userCompany } = useAuth()

  const customStyles = customTheme?.palette?.layout

  useEffect(() => {
    if (exportingPdf) {
      if (questionControls && gapStats) {
        helpers.reports.questionControl.exportPdf(
          userCompany,
          customStyles,
          customLogo,
          gapStats,
          questionControls,
          isComplete,
          setExportingPdf,
          snackbar,
          shouldCreateVersion,
          refresh,
        )
      }
      questionControls = null
      gapStats = null
    }
  }, [exportingPdf, questionControls, gapStats])

  return <></>
}

QuestionControlPdf.propTypes = {
  setExportingPdf: PropTypes.func,
  questionControls: PropTypes.array,
  isComplete: PropTypes.bool,
}

QuestionControlPdf.defaultProps = {
  setExportingPdf: () => {},
  isComplete: true,
}

export default QuestionControlPdf
