import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  boxMain: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    padding: 25,
    border: '2px dashed #546E7A',
    borderRadius: 5,
    width: '100%',
    height: '100%',
  },
  attachmentName: {
    color: theme.palette.text.mainMenu,
    padding: '3px 0px',
  },
}))

export default styles
