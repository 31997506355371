import React from 'react'

import { DisapprovedLia, PendingLia } from './components'

import constants from 'constants/index'

const BalancingSafeguarding = ({
  dataProcess = {},
  questionsDisapprovedAnswers = [],
  questions = [],
  questionsPendingAnswers = [],
  questionsApprovedAnswers = [],
}) => {
  const { PENDING_LIA, DISAPPROVED_LIA } = constants.dataProcess

  const statusPendingLia = dataProcess?.statusId === PENDING_LIA
  const statusDisapprovedLia = dataProcess?.statusId === DISAPPROVED_LIA

  return (
    <>
      {statusPendingLia && (
        <PendingLia
          dataProcess={dataProcess}
          questions={questions}
          questionsPendingAnswers={questionsPendingAnswers}
          questionsApprovedAnswers={questionsApprovedAnswers}
        />
      )}

      {statusDisapprovedLia && (
        <DisapprovedLia
          dataProcess={dataProcess}
          questionsDisapprovedAnswers={questionsDisapprovedAnswers}
        />
      )}
    </>
  )
}

export default BalancingSafeguarding
