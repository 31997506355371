import React from 'react'

import { Container } from '@material-ui/core'

import useStyles from './styles'

import { Page } from 'components'

import PaperShowDataProcess from './components/PaperShowDataProcess'

import DataTreatmentModalProvider from 'providers/DataTreatmentModalProvider'
import RmcProvider from 'providers/RmcProvider'

const Details = ({ match }) => {
  const classes = useStyles()

  return (
    <>
      <RmcProvider>
        <Page className={classes.root} title="Detalhes do processo">
          <Container maxWidth={false} className={classes.container}>
            <DataTreatmentModalProvider>
              <PaperShowDataProcess match={match} />
            </DataTreatmentModalProvider>
          </Container>
        </Page>
      </RmcProvider>
    </>
  )
}

export default Details
