import { colors } from '@material-ui/core'
import { Receipt as ReceiptIcon } from '@material-ui/icons'
import {
  CreditCard as CreditCardIcon,
  FileText as FileTextIcon,
} from 'react-feather'

const FINANCIAL_NOT_PAID_STATUS = 'NOT_PAID'
const FINANCIAL_WAITING_PAYMENT_STATUS = 'WAITING_PAYMENT'
const FINANCIAL_WAITING_CONFIRM_STATUS = 'WAITING_CONFIRM'
const FINANCIAL_PAID_STATUS = 'PAID'
const FINANCIAL_CANCELED_STATUS = 'CANCELED'
const FINANCIAL_FAILURE_STATUS = 'FAILURE'
const FINANCIAL_OVERDUE_STATUS = 'OVERDUE'
const FINANCIAL_INTEGRATION_STATUS = 'INTEGRATION'
const FINANCIAL_PRE_CAPTURED_STATUS = 'PRE_CAPTURED'

const HIDE_BILLET_STATUSES = [
  FINANCIAL_PRE_CAPTURED_STATUS,
  FINANCIAL_PAID_STATUS,
  FINANCIAL_CANCELED_STATUS,
  FINANCIAL_INTEGRATION_STATUS,
  FINANCIAL_WAITING_CONFIRM_STATUS,
]

const INVOICES_STATUS_ID = 'invoices'
const PAYMENT_METHOD_STATUS_ID = 'payment_method'

const BANK_SLIP_STATUS_ID = 1
const PAYMENT_CARD_STATUS_ID = 2

const FINANCIAL_PAYMENTS_METHOD_TABS = [
  { value: BANK_SLIP_STATUS_ID, label: 'Boleto', icon: ReceiptIcon },
  { value: PAYMENT_CARD_STATUS_ID, label: 'Cartão', icon: CreditCardIcon },
]

const FINANCIAL_MENU_ITEMS = [
  {
    id: INVOICES_STATUS_ID,
    label: 'Faturas',
    Icon: FileTextIcon,
  },
  {
    id: PAYMENT_METHOD_STATUS_ID,
    label: 'Meios de pagamento',
    Icon: CreditCardIcon,
  },
]

const FINANCIAL_CHIP_STATUS_LABEL = {
  [FINANCIAL_NOT_PAID_STATUS]: 'Pendente',
  [FINANCIAL_WAITING_PAYMENT_STATUS]: 'Pendente',
  [FINANCIAL_WAITING_CONFIRM_STATUS]: 'Pendente',
  [FINANCIAL_PAID_STATUS]: 'Pago',
  [FINANCIAL_CANCELED_STATUS]: 'Cancelado',
  [FINANCIAL_FAILURE_STATUS]: 'Falha',
  [FINANCIAL_OVERDUE_STATUS]: 'Atrasado',
  [FINANCIAL_INTEGRATION_STATUS]: 'Integração',
  [FINANCIAL_PRE_CAPTURED_STATUS]: 'Processando pagamento',
}

const FINANCIAL_CHIP_STATUS_COLOR = {
  [FINANCIAL_NOT_PAID_STATUS]: colors.blue[800],
  [FINANCIAL_WAITING_PAYMENT_STATUS]: colors.blue[800],
  [FINANCIAL_WAITING_CONFIRM_STATUS]: colors.blue[800],
  [FINANCIAL_PAID_STATUS]: colors.green[800],
  [FINANCIAL_CANCELED_STATUS]: colors.red[800],
  [FINANCIAL_FAILURE_STATUS]: colors.red['A700'],
  [FINANCIAL_OVERDUE_STATUS]: colors.pink[800],
  [FINANCIAL_INTEGRATION_STATUS]: colors.indigo[800],
  [FINANCIAL_PRE_CAPTURED_STATUS]: colors.deepPurple[800],
}

const FINANCIAL_CHIP_STATUS_BACKGROUND_COLOR = {
  [FINANCIAL_NOT_PAID_STATUS]: colors.blue[100],
  [FINANCIAL_WAITING_PAYMENT_STATUS]: colors.blue[100],
  [FINANCIAL_WAITING_CONFIRM_STATUS]: colors.blue[100],
  [FINANCIAL_PAID_STATUS]: colors.green[100],
  [FINANCIAL_CANCELED_STATUS]: colors.red[100],
  [FINANCIAL_FAILURE_STATUS]: colors.red['A100'],
  [FINANCIAL_OVERDUE_STATUS]: colors.pink[100],
  [FINANCIAL_INTEGRATION_STATUS]: colors.indigo[100],
  [FINANCIAL_PRE_CAPTURED_STATUS]: colors.deepPurple[100],
}

const settings = {
  FINANCIAL_MENU_ITEMS,
  FINANCIAL_CHIP_STATUS_LABEL,
  FINANCIAL_CHIP_STATUS_COLOR,
  FINANCIAL_CHIP_STATUS_BACKGROUND_COLOR,
  FINANCIAL_PAYMENTS_METHOD_TABS,
  BANK_SLIP_STATUS_ID,
  PAYMENT_CARD_STATUS_ID,
  INVOICES_STATUS_ID,
  PAYMENT_METHOD_STATUS_ID,
  FINANCIAL_PAID_STATUS,
  HIDE_BILLET_STATUSES,
}

export default settings
